import { Component, OnInit, Injector, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NoteContent, Medication, User } from "../../../shared/models";
import {
    NotesService,
    FeedbackService,
    UserService,
    AvailabilityService,
    PrescriptionFlagUpdateService,
    DiagnosisDSM5Service,
    isPsychRole,
    iAmDoctor,
    iAmPsychiatrist,
    iAmPsychologist,
    iAmPsychRole,
    canAccessConsultationNotes_LaboratoryRequest,
    canAccessConsultationNotes_Medication,
    canAccessConsultationNotes_Diagnosis,
    canAccessConsultationNotes_NextMeeting,
    canAccessConsultationNotes_PhysicalExam,
    consulationNotes_Label_MedicalAbstract,
    consulationNotes_Label_MedicalCertificate,
    consulationNotes_Label_AdmittingOrder,
    canAccessConsultationNotes_Diagnosis_CustomInput,
    canAccessConsultationNotes_Diagnosis_PsychiatristDropdown,
    consulationNotes_MedicalAbstractMultipleFields,
} from "../../../services";
// import { PrescriptionFlagUpdateService } from '../../../services/prescription-flag-update.service';
import * as _ from "lodash";
import { ToastrManager } from "ng6-toastr-notifications";
import { faSquare, faCheckSquare, faTrashAlt, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { Observable } from "rxjs";
import { TypeaheadMatch } from "ngx-bootstrap/typeahead";
import { DOCTOR_MED_SHEET_NEW } from "../../feedback/doctor-med-sheet-new";
import { ActivatedRoute, Router } from "@angular/router";
import "moment-timezone";
import moment from "moment";

@Component({
    selector: "app-medications",
    templateUrl: "./medications.component.html",
    styleUrls: ["./medications.component.scss"],
})
export class MedicationsComponent implements OnInit, OnChanges {
    editorConfig: any;
    savingNotes = false;
    sendingLabRequest = false;
    sendingLabPreview = false;
    sendingFlagUpdate = false;
    sendingFlagUpdateLoader = false;
    sendingMedication = false;
    sendingMedicationPreview = false;
    sendingMedicalAbstract = false;
    sendingMeidicalAbstractPreview = false;
    sendingCertificate = false;
    sendingCertificatePreview = false;
    sendingAdmittingOrder = false;
    SendingAdmittingOrderPreview = false;
    sendingPatientInstrctions = false;
    sendingPatientInstrctionsPreview = false;
    onSaveChanges = false;
    noteContent: NoteContent;
    medications: any[];
    debouncedCallSave: any;
    debouncedCallSaveMeds: any;
    minDate: Date;
    eventId: any;
    currentEventData: any;
    dataUpdated = false;
    needS2License: boolean;
    medNotes: any;
    nextMeeting: any;
    tab: any = "0";
    request_addon_med_cert : boolean;
    request_addon_med_abstract : boolean;


    docMedSheet: any[];
    user: User;

    labRequests: any[];
    labTests: any[];
    requestTypes: any[];
    testTypes: any[];
    requestTypesFiltered: any[];
    labRequestNotes: string;
    medCerts: any[];
    medCertRecomendation: string;
    admittingOrder: string;
    medExpiration: any;
    medHasSignature: boolean;
    saving = 0;
    feedbackData: any;
    dsmDefaultVaue = null;

    PhysicalExamFormDirty = false;

    role: false;

    userInfo: any;

    appointmentStatus: number

    selectedType: any
    selectedClinic: any

    public datePicked: any;
    public start_at = { hour: 8, minute: 0 };
    public end_at = { hour: 17, minute: 0 };
    timezone: string;

    fa = {
        checkboxFalse: faSquare,
        checkboxTrue: faCheckSquare,
        trashIcon: faTrashAlt,
        plusIcon: faPlusSquare,
    };

    public availabilityService: any;

    selectedDSM5 = [];
    selectedDSMIndex = null;
    dsm = [];
    dsmCurrentList: any = [];
    dsmLoading: boolean = false;
    medicationsCustom = [];
    dsmDisplayOthers = false;

    isPsychRole = isPsychRole;
    iAmPsychRole = iAmPsychRole;
    iAmDoctor = iAmDoctor;
    iAmPsychiatrist = iAmPsychiatrist;
    canAccessConsultationNotes_Medication = canAccessConsultationNotes_Medication;
    canAccessConsultationNotes_LaboratoryRequest = canAccessConsultationNotes_LaboratoryRequest;
    consulationNotes_Label_MedicalAbstract = consulationNotes_Label_MedicalAbstract;
    iAmPsychologist = iAmPsychologist;
    canAccessConsultationNotes_Diagnosis = canAccessConsultationNotes_Diagnosis;
    canAccessConsultationNotes_Diagnosis_CustomInput = canAccessConsultationNotes_Diagnosis_CustomInput;
    canAccessConsultationNotes_Diagnosis_PsychiatristDropdown =
        canAccessConsultationNotes_Diagnosis_PsychiatristDropdown;
    consulationNotes_Label_MedicalCertificate = consulationNotes_Label_MedicalCertificate;
    consulationNotes_Label_AdmittingOrder = consulationNotes_Label_AdmittingOrder;
    canAccessConsultationNotes_NextMeeting = canAccessConsultationNotes_NextMeeting;
    consulationNotes_MedicalAbstractMultipleFields = consulationNotes_MedicalAbstractMultipleFields;
    canAccessConsultationNotes_PhysicalExam = canAccessConsultationNotes_PhysicalExam;

    @Input() event: any;
    @Input() medSheet: any;
    @Input() selectedSection: any = null;

    constructor(
        private noteService: NotesService,
        private toastr: ToastrManager,
        private feedbackService: FeedbackService,
        userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private prescriptionFlagUpdateService: PrescriptionFlagUpdateService,
        private dSM5Service: DiagnosisDSM5Service,
        injector: Injector
    ) {
        this.noteContent = new NoteContent();
        this.editorConfig = {
            height: "250px",
            editable: true,
            toolbar: [
                ["italic", "bold", "underline"],
                ["orderedList", "unorderedList"],
            ],
        };
        this.user = userService.getStoredCredentials();
        this.medications = [];
        this.debouncedCallSave = _.debounce(this.saveNote, 1500, { maxWait: 2000, trailing: true });
        this.debouncedCallSaveMeds = _.debounce(this.updateMedications, 1500, { maxWait: 2000, trailing: true });
        this.minDate = new Date();
        this.availabilityService = injector.get(AvailabilityService);
        this.generateSheet();

        this.activatedRoute.params.subscribe((data) => {
            this.eventId = data.eventId;
        });

        userService.getUserInfo().subscribe((data: any) => {
            this.userInfo = data;
            // console.log(data);
            // console.log(this.userInfo);
            this.userInfo.roles.data.forEach((role: any) => {
                this.role = role.name;
            });
        });
    }

    ngOnInit() {
        this.timezone = moment.tz.guess();

        // Commented out only for testing
        // this.eventId = "106"
        // if ( JSON.parse(localStorage.getItem('sessionEventInfo')) ){
        //     const { eventId } = JSON.parse(localStorage.getItem('sessionEventInfo'))
        //     this.eventId = eventId
        // }

        // const { eventId } = JSON.parse(localStorage.getItem('sessionEventInfo'))
        // this.eventId = eventId
        this.sendingFlagUpdate = true;
        this.onSaveChanges = false;
        this.initLabRequests();

        this.medHasSignature = true;

        this.dsmDefaultVaue = this.isPsychRole(this.user.roles) ? null : 0;
    }

    initLabRequests() {
        this.labRequests = [];
        this.testTypes = [];
        this.labTests = [];
        this.requestTypesFiltered = [];
        this.medCerts = [];

        this.noteService.getDiagnostics("request").subscribe((data: any) => {
            this.labTests = data;
        });

        this.noteService.getDiagnostics("type").subscribe((data: any) => {
            this.requestTypes = data;
        });
    }

    saveNote() {
        this.savingNotes = true;
        const noteContents = {
            ...this.noteContent,
            labRequests: this.labRequests,
            labRequestNotes: this.labRequestNotes,
            admittingOrder: this.admittingOrder,
            nextMeeting: this.nextMeeting,
            medCert: this.medCerts,
            medCertRecomendation: this.medCertRecomendation,
            medExpiration: this.medExpiration,
            medHasSignature: this.medHasSignature,
        };

        this.saving += 1;

        let diagnosis = "DSM5: ";

        if (!iAmPsychRole()) {
            diagnosis = "";
        }

        if (noteContents.diagnosis.length) {
            diagnosis += "\n" + noteContents.diagnosis + "\n";
        }

        this.selectedDSM5.forEach((item) => {
            diagnosis += " > " + item.label;
        });

        noteContents.diagnosis = diagnosis;

        noteContents["diagnosis_2"] = this.dsm;
        // console.log('noteContents', noteContents)
        this.noteService
            .saveNote({
                event_id: this.eventId,
                appointment_status: this.appointmentStatus,
                notes: noteContents,
            })
            .subscribe((v) => {
                this.saving -= 1;
                this.savingNotes = false;
                this.dataUpdated = false;
            });
    }

    tabChange(id) {
        if (id == 1) {
            if (this.selectedDSM5.length === 0 && this.dsmCurrentList.length == 0) {
                this.getDSMRoot();
            }
        }
    }

    dsmSelectedValue(e) {
        this.selectedDSM5.push(e);
        this.dsmCurrentList = [];
        this.getDSMDescendants(e.id);
    }

    dsmSelectedValue_2(index, e) {
        // console.log('dsmSelectedValue_2', index, e)
        this.dsm[index].lvl.push({
            id: e.id,
            name: e.label,
        });
        this.dsmCurrentList = [];
        this.getDSMDescendants(e.id);
    }

    removeSelectedDSM_2(index, id) {
        this.selectedDSMIndex = index;
        this.dsmDisplayOthers = false;
        this.dsm[index].lvl.forEach((v, k) => {
            if (id == v.id) {
                this.dsmDisplayOthers = !!!k;
                this.dsm[index].lvl.splice(k);
            }
        });

        if (this.dsm[index].lvl.length) {
            id = this.dsm[index].lvl[this.dsm[index].lvl.length - 1].id;
            this.getDSMDescendants(id);
        } else {
            this.getDSMRoot();
        }
    }

    removeDSMRow(index) {
        this.dsm.splice(index, 1);
    }

    addDSMRow() {
        this.getDSMRoot();
        this.dsm.push({ lvl: [] });
        this.dsmDisplayOthers = true;
        this.selectedDSMIndex = this.dsm.length - 1;

        // console.log('this.dsm', this.dsm)
    }

    selectDSMRow(index) {
        this.selectedDSMIndex = index;
        this.dsm[index].lvl.length;

        if (this.dsm[index].lvl.length) {
            let id = this.dsm[index].lvl[this.dsm[index].lvl.length - 1].id;
            this.getDSMDescendants(id);
        } else {
            this.getDSMRoot();
        }
    }

    removeSelectedDSM(id) {
        this.selectedDSM5.forEach((v, k) => {
            if (id == v.id) {
                this.selectedDSM5.splice(k);
            }
        });

        if (this.selectedDSM5.length) {
            id = this.selectedDSM5[this.selectedDSM5.length - 1].id;
            this.getDSMDescendants(id);
        } else {
            this.getDSMRoot();
        }
    }

    getDSMRoot() {
        // console.log('this.dsmDefaultVaue', this.dsmDefaultVaue)

        if (this.dsmDefaultVaue !== 0) {
            this.dsmLoading = true;
            this.dSM5Service.root().subscribe(
                (result) => {
                    this.dsmCurrentList = result;
                    this.dsmLoading = false;
                },
                (error) => {
                    this.dsmLoading = false;
                }
            );
        } else {
            this.dsmLoading = false;
        }
    }

    getDSMDescendants(id) {
        this.dsmLoading = true;
        this.dSM5Service.descendants(id).subscribe(
            (result) => {
                this.dsmCurrentList = result;
                this.dsmLoading = false;
            },
            (error) => {
                this.dsmLoading = false;
            }
        );
    }

    callSave() {
        this.dataUpdated = true;
    }

    callSaveMeds(medications: Medication[]) {
        // console.log('callsavemeds', medications)

        medications.forEach((med, i) => {
            if (!med.id) {
                medications[i].generic_name = med.generic;
                medications[i].brand_name = med.brand;
            }
        });

        this.medications = medications;
        this.dataUpdated = true;
    }

    onSave() {
        this.onSaveChanges = true;
        this.saveNote();
        this.updateMedications();
        this.submitAddonBtn();
    }

    updateNextMeeting(nextMeeting: any) {
        console.log(nextMeeting)

        this.nextMeeting = nextMeeting;
        this.saveNote();
        this.updateAvailability();
    }


    selectedNextMeetingType(type:any) {
        this.selectedType = type
    }

    selectedNextMeetingClinic(clinic:any) {
        this.selectedClinic = clinic
    }

    updateAvailability() {
        const pickedDateTime = this.nextMeeting.split("-");
        const datePicked = pickedDateTime[0].split(" ");
        // console.log(
        //     pickedDateTime,
        //     datePicked,
        //     pickedDateTime[1].split(' ')
        // );

        let start_at_hour = datePicked.slice(3, 4).toString().split(":")[0];

        start_at_hour = datePicked[4] == "PM" ? 12 + parseInt(start_at_hour) : start_at_hour;

        const start_at = moment(datePicked.slice(0, 3).join(" "))
            .tz(this.timezone)
            // .hour(datePicked.slice(3,4).toString().split(':')[0])
            .hour(start_at_hour)
            .minute(datePicked.slice(3, 4).toString().split(":")[1]);

        let end_at_hour = pickedDateTime[1].split(" ")[1].split(":")[0];
        end_at_hour = pickedDateTime[1].split(" ")[2] == "PM" ? 12 + parseInt(end_at_hour) : end_at_hour;

        const end_at = moment(datePicked.slice(0, 3).join(" "))
            .tz(this.timezone)
            .hour(end_at_hour)
            .minute(pickedDateTime[1].split(" ")[1].split(":")[1]);

        // console.log(
        //     this.nextMeeting,
        //     start_at,
        //     end_at,
        //     start_at.format(),
        //     end_at.format(),
        //     start_at_hour
        // );

       
        const { clinic_id, offline } = this.event;

        let diff_update = moment.utc(end_at, "HH:mm:ss").diff(moment.utc(start_at, "HH:mm:ss"), "minutes");

        if (start_at && end_at && diff_update <= 60) {
            this.availabilityService
                .store({
                    start_at: start_at.format(),
                    end_at: end_at.format(),
                    label: "available",
                    type: this.selectedType,
                    duration: diff_update,
                    timezone: "Asia/Manila",
                    clinic_id: this.selectedClinic ? this.selectedClinic : null,
                })
                .subscribe(
                    (response) => {
                        this.toastr.successToastr("Availability has been set", "Saved!");
                    },
                    (error) => {
                        if (error.error.message === "invalid_start_date") {
                            this.toastr.errorToastr(
                                "Please check your selected schedule",
                                "Verify that your selected schedule is valid or is not in conflict with your other schedules"
                            );
                        } else if (error.error.message.type) {
                            this.toastr.errorToastr("Please select next meeting type", "Failed!")
                        } else if (error.error.message.clinic_id) {
                            this.toastr.errorToastr("Please select a Clinic", "Failed!")
                        } else {
                        this.toastr.errorToastr("Encountered an error while setting availability", "Failed!");
                        }
                    }
                );
        } else {
            this.toastr.warningToastr("Start time and end time cant be determined", "Warning!");
        }
    }

    startDateValidity = () => {
        const start_at = moment(this.datePicked)
            .tz(this.timezone)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute);
        return start_at.isSameOrBefore(moment().tz(this.timezone), "h");
    };

    endDateValidity = () => {
        const start_at = moment(this.datePicked)
            .tz(this.timezone)
            .hour(this.start_at.hour)
            .minute(this.start_at.minute);
        const end_at = moment(this.datePicked).tz(this.timezone).hour(this.end_at.hour).minute(this.end_at.minute);

        return end_at.isSameOrBefore(start_at, "minute");
    };

    addMedication(medication: Medication) {
        console.log("med", medication, this.medications);
        this.medications = [...this.medications, medication];

        this.callSave();
    }

    mappedMedications() {
        const medications = this.medications.map((med) => {
            let instructions = med.instructions ? med.instructions : med.instruction ? med.instruction : ""; // med.medicine.instruction
            // let qty_tabs_dispensed = med.qty_tabs_dispensed ? med.qty_tabs_dispensed : med.tabsDispensed
            // let qty_tabs_dispensed = med.tabsDispensed ? med.tabsDispensed : med.qty_tabs_dispensed
            let qty_tabs_dispensed = med.tabsDispensed;

            if (med.id) {
                qty_tabs_dispensed = med.tabsDispensed;
            }
            // console.log(med.qty_tabs_dispensed, med.tabsDispensed, med)
            return {
                generic_name: med.generic,
                brand_name: med.brand,
                is_s2: med.is_s2,
                is_injectable: med.is_injectable,
                id: med.id,
                medicine_id: med.medicine_id ? med.medicine_id : null,
                qty_tabs_dispensed: qty_tabs_dispensed,
                dosage: med.dosage,
                instructions: instructions,
                is_deleted: med.is_deleted,
                is_custom: med.is_custom,
                from_custom_table: med.from_custom_table,
            };
        });
        return medications;
    }

    updateMedications() {
        if (this.medications.length && !this.medExpiration) {
            // ALERT
            let d =
                "<strong>We couldn't save your consulation notes this time</strong><br/>Please set the Prescription Expiration Date under the Medication Section";
            this.toastr.warningToastr(d, null, { enableHTML: true, timeOut: 9500 });
        } else {
            const data = {
                event_id: this.eventId,
                is_print_s2: this.needS2License,
                notes: this.medNotes, // JSON.stringify(this.noteContent),
                items: this.mappedMedications(),
            };

            this.saving += 1;

            // console.log('updateMedications', data)
            return this.noteService
                .saveMedicines(data)
                .toPromise()
                .then(
                    (response: any) => {
                        this.saving -= 1;
                        this.savingNotes = false;
                        this.currentEventData = response.data;
                        this.dataUpdated = false;
                        this.toastr.successToastr("Changes Saved!");

                        response.data.items.forEach((item, i) => {
                            response.data.items[i]["is_custom"] = false;
                        });
                        response.data.customs.forEach((item, i) => {
                            response.data.customs[i]["is_custom"] = true;
                        });

                        this.medications = response.data.items;
                    },
                    (e) => {
                        this.savingNotes = false;
                        let d = "";
                        for (let m in e.error.message) {
                            e.error.message[m].forEach((mess) => {
                                d += `<div>${mess}</div>`;
                            });
                        }
                        this.toastr.errorToastr(d, null, { enableHTML: true });
                    }
                );
        }
    }

    previewPrescription() {
        this.sendingMedicationPreview = true;
        this.updateMedications().then(() => {
            const event_id = this.eventId;
            this.noteService.previewPrescription({ event_id }).subscribe((data: any) => {
                const file = new Blob([data], { type: "application/pdf" });
                const fileURL = URL.createObjectURL(file);
                this.sendingMedicationPreview = false;
                window.open(fileURL);
            }),
                (e) => this.toastr.errorToastr("Something went wrong.");
        });
    }

    onSelectLabTest(e: TypeaheadMatch, i: number) {
        this.requestTypesFiltered = this.requestTypes
            .filter((type) => type.request.id === e.item.id)
            .map((type) => ({
                ...type,
                display: `${type.name} ${type.test && type.test.name ? type.test.name : ""}`,
            }));
    }

    onSelectType(e: TypeaheadMatch, i: number, model: string) {
        if (e.item.test && e.item.test.name) {
            this[model][i].diagnosticTest = e.item.test.name;
        }
    }

    generateSheet() {
        const medSheet = DOCTOR_MED_SHEET_NEW.map((val) => {
            let item;
            if (_.isString(val)) {
                item = {
                    label: val,
                    key: _.camelCase(val),
                    type: val === "hr" ? "hr" : "textarea",
                };
            }

            if (_.isObject(val)) {
                const obj = val as any;
                item = {
                    ...obj,
                    key: obj.key ? obj.key : _.camelCase(obj.label),
                };
            }
            return item;
        });

        this.docMedSheet = medSheet;
        this.feedbackData = {};
    }

    submitClickBtn() {
        this.saving += 1;
        this.feedbackService.saveDoctorFeedback(this.eventId, this.user.id, this.feedbackData).subscribe(
            (data) => {
                this.saving -= 1;
                this.toastr.successToastr("Data saving successful.");
            },
            (e) => {
                this.toastr.errorToastr(e.error.message || "Something went wrong.");
            }
        );
    }

    onSendPrescription() {
        let eventId = 0;
        this.activatedRoute.params.subscribe((data) => {
            eventId = data.eventId;
        });
        this.sendingMedication = true;

        this.feedbackService.sendMedicalPrescription(eventId, this.user.id).subscribe(
            (data) => {
                this.sendingMedication = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => {
                this.toastr.errorToastr(e.error.message || "Something went wrong.");
            }
        );
    }

    onUpdateFlag() {
        this.sendingFlagUpdateLoader = true;
        this.prescriptionFlagUpdateService.updateFlagDeliver(this.eventId, "deliver-to-lifeport").subscribe(
            (data) => {
                this.toastr.successToastr("Deliver For LifePort successfully.");
                this.sendingFlagUpdate = false;
                this.sendingFlagUpdateLoader = false;
            },
            (e) => {
                this.toastr.errorToastr(e.error.message || "Something went wrong.");
            }
        );
        // console.log('here');
    }

    saveLabRequests() {}

    viewAdmitOrder() {
        this.SendingAdmittingOrderPreview = true;
        this.noteService.genReport("admitting", this.eventId).subscribe(
            (response) => this.downloadFile(response),
            (e) => this.toastr.errorToastr("Couldnt retrieve data for admitting-order.")
        );
    }

    onSendAdmittingOrder() {
        this.sendingAdmittingOrder = true;
        this.noteService.sendEmail("admitting", this.eventId).subscribe(
            (response) => {
                this.sendingAdmittingOrder = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Admitting Order.")
        );
    }

    viewCert() {
        this.sendingCertificatePreview = true;
        this.noteService.genReport("certificate", this.eventId).subscribe(
            (response) => this.downloadFile(response),
            (e) => this.toastr.errorToastr("Couldnt retrieve data for certificate.")
        );
    }

    onSendCertificate() {
        this.sendingCertificate = true;
        this.noteService.sendEmail("certificate", this.eventId).subscribe(
            (response) => {
                this.sendingCertificate = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Certificate.")
        );
    }

    viewFeedback() {
        this.sendingMeidicalAbstractPreview = true;
        this.noteService.genReport("feedback", this.eventId).subscribe(
            (response) => this.downloadFile(response),
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Feedback.")
        );
    }

    onSendMedicalAbstract() {
        this.sendingMedicalAbstract = true;
        this.noteService.sendEmail("feedback", this.eventId).subscribe(
            (response) => {
                this.sendingMedicalAbstract = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Medical Abstract.")
        );
    }

    viewLabReq() {
        this.sendingLabPreview = true;
        this.noteService.genReport("laboratory", this.eventId).subscribe(
            (response) => this.downloadFile(response),
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Laboratory Request.")
        );
    }

    onSendLaboratoryRequest() {
        this.sendingLabRequest = true;
        this.noteService.sendEmail("laboratory", this.eventId).subscribe(
            (response) => {
                this.sendingLabRequest = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Laboratory Request.")
        );
    }

    viewPatientInstruction() {
        this.sendingPatientInstrctionsPreview = true;
        this.noteService.genReport("px-instructions", this.eventId).subscribe(
            (response) => {
                this.sendingPatientInstrctionsPreview = false;
                this.downloadFile(response);
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Patient Instructions")
        );
    }

    onSendPatientInstruction() {
        this.sendingPatientInstrctions = true;
        this.noteService.sendEmail("px-instructions", this.eventId).subscribe(
            (response) => {
                this.sendingPatientInstrctions = false;
                this.toastr.successToastr("Email sent successfully.");
            },
            (e) => this.toastr.errorToastr("Couldnt retrieve data for Patient Instructions")
        );
    }

    downloadFile(response: any) {
        this.sendingCertificatePreview = false;
        this.SendingAdmittingOrderPreview = false;
        this.sendingLabPreview = false;
        this.sendingMeidicalAbstractPreview = false;

        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
    }

    customDsm(index, e) {
        if (!this.dsm[index].lvl.length) {
            this.dsm[index].lvl.push({ id: null, name: "" });
        }

        this.dsm[index].lvl[0]["name"] = e.target.value;
    }

    canDropdownDSM = () => {
        let s = this.isPsychRole(this.user.roles);
        // console.log('s', s)
        return s;
        // return false;
    };

    onPEFormDirty = ($e) => {
        this.PhysicalExamFormDirty = $e;
    };


    submitAddonBtn = () => {

        const data = {
            request_addon_med_cert : this.request_addon_med_cert,
            request_addon_med_abstract : this.request_addon_med_abstract,
        };

        this.saving += 1;
        this.availabilityService.requestAddon(this.eventId, data).subscribe(
            (data) => {
                this.saving -= 1;
                // this.toastr.successToastr("Data saving successful.");
            },
            (e) => {
                this.toastr.errorToastr(e.error.message || "Something went wrong.");
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.event && changes.event.currentValue) {
            this.event = changes.event.currentValue;
            const notes = this.event.notes ? this.event.notes.notes : null;
            const prescriptions = this.event.prescriptions;

            this.request_addon_med_cert = this.event.request_addon_med_cert;
            this.request_addon_med_abstract = this.event.request_addon_med_abstract;
    
            // PROGRESS NOTES
            if (notes) {
                // console.log('notes.', notes.progress)
                this.noteContent.progress = {
                    type: "doc",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "", // notes.progress
                                },
                            ],
                        },
                    ],
                };

                this.noteContent.progress = notes.progress;

                this.noteContent.patientInstructions = "";
                if (notes.patientInstructions) {
                    this.noteContent.patientInstructions = notes.patientInstructions;
                }
            }

            // DSM
            if (notes && notes.diagnosis) {
                // console.log('notes.diagnosis', notes.diagnosis)
                let diagnosis = notes.diagnosis;

                if (iAmPsychRole()) {
                    if (!notes.diagnosis.match(/(DSM5\:\s\s\>\s)/)) {
                        diagnosis = diagnosis.replace("DSM5: \n", "").split(" > ");
                        this.noteContent.diagnosis = diagnosis;
                    } else {
                        diagnosis = diagnosis.replace("DSM5:  > \n", "").split(" > ");
                        // console.log('match', diagnosis)

                        // console.log('diagnosis', diagnosis, diagnosis.length);

                        diagnosis.forEach((diag) => {
                            this.dSM5Service.getByName(diag).subscribe((res) => {
                                // console.log('res', res)
                            });
                        });

                        if (diagnosis.length) {
                            // this.dsm[0] =
                            this.dSM5Service.getByName(diagnosis[diagnosis.length - 1]).subscribe((res) => {
                                if (res) {
                                    res["descendants"].forEach((e) => {
                                        this.dsmSelectedValue(e);
                                    });

                                    this.dsmSelectedValue(res["source"]);
                                }
                            });
                        }
                    }
                } else {
                    this.noteContent.diagnosis = notes.diagnosis;
                }
            }

            // DSM 2
            if (this.event.notes && this.event.notes.dsmnotes) {
                this.event.notes.dsmnotes.forEach((diag) => {
                    const dsms = [];
                    diag.levels.forEach((dsm) => {
                        if (dsm) {
                            dsms.push(dsm);
                            // this.dSM5Service.getById(dsmId)
                            // .subscribe(response => {

                            // })
                        }
                    });
                    this.dsm.push({ lvl: dsms });
                });
            }

            // CUSTOM DSM
            // console.log('this.event.notes.custom_diagnosis', this.event.notes.custom_diagnosis[0])
            if (this.event.notes && this.event.notes.custom_diagnosis) {
                if (this.event.notes.custom_diagnosis.length) {
                    this.event.notes.custom_diagnosis.forEach((dsm) => {
                        this.dsm.push({
                            lvl: [
                                {
                                    id: null,
                                    name: dsm.name,
                                },
                            ],
                        });
                    });
                }
            }

            // LAB REQUESTS
            if (notes) {
                this.labRequests = notes.labRequests;
                this.labRequestNotes = notes.labRequestNotes;
            } else {
                // Default value
                this.labRequestNotes = "Hi-Precision / Better Steps Tele-assessments - For Discount";
            }

            // MEDICATION
            if (prescriptions.length) {
                // prescriptions[0].items.forEach((item, i) => {
                //     prescriptions[0].items[i]['is_custom'] = false
                // })
                prescriptions[0].customs.forEach((item, i) => {
                    prescriptions[0].customs[i]["from_custom_table"] = true;
                });

                // console.log('prescriptions[0]', prescriptions[0])
                this.medications = prescriptions[0].items;
                this.medicationsCustom = prescriptions[0].customs;
                this.medNotes = prescriptions[0].notes;
            }

            if (notes && notes.medExpiration) {
                this.medExpiration = new Date(notes.medExpiration);
            }

            if (notes && notes.medCertRecomendation) {
                this.medCertRecomendation = notes.medCertRecomendation;
            }

            // ADMITTING ORDERS
            if (notes && notes.admittingOrder) {
                this.admittingOrder = notes.admittingOrder;
            }
        }

        if (changes.medSheet && changes.medSheet.currentValue) {
            let medSheet = changes.medSheet.currentValue;
            for (var prop in medSheet) {
                // obj[prop] = 'xxx';
                let newProp = prop.replace(/([-_][a-z])/gi, ($1) => {
                    return $1.toUpperCase().replace("-", "").replace("_", "");
                });

                medSheet[newProp] = medSheet[prop];
            }

            this.feedbackData = changes.medSheet.currentValue;
        }

        if (changes.selectedSection && changes.selectedSection.currentValue) {
            console.log(changes.selectedSection);
            this.tab = changes.selectedSection.currentValue;
        }
    }
}
