import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ArchiveService {
    endpoint = environment.ws_url;

    constructor(private http: HttpClient) { }

    listArchives() {
        return this.http.get(`${this.endpoint}/archives`);
    }

    getArchives(sessionId: string): Observable<any> {
        return this.http.get(`${this.endpoint}/archives?sessionId=${sessionId}`);
    }

    getDownloadLink(id: string): Observable<any> {
        return this.http.get(`${this.endpoint}/download-link/${id}`)
            .map(data => data['url']);
    }
}
