import { Component, OnInit } from '@angular/core';
import { RouterLinkActive, Router } from '@angular/router';

import { AuthenticationService } from '../../authentication/authentication.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

    public isRxfort: any;
    public isAdmin: any;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private userService: UserService
    ) { }

    ngOnInit() {
        // console.log(this.authenticationService);
        // this.userService.checkRole(['admin']);

        this.isRxfort = this.userService.checkRole(['rxfort']);
        this.isAdmin = this.userService.checkRole(['admin']);
    }

    logout() {
        this.authenticationService.logout()
            .subscribe(() => {
                // this.router.navigate(['/login'], { replaceUrl: true })
                window.location.href = environment.BASE_URL + 'login';
            });
    }
}
