import { Validators } from '@angular/forms';
import { CustomValidator } from "../validations/custom.validator";


export const BILLING_INFO_MODEL = {
    'firstName': [null, Validators.compose( [ Validators.required, Validators.minLength(2), Validators.maxLength(30) ] ) ],
    'lastName': [null, Validators.compose( [ Validators.required, Validators.minLength(2), Validators.maxLength(30) ] ) ],
    'middleName': [null, Validators.compose( [ Validators.required, Validators.minLength(2), Validators.maxLength(30) ] ) ],
    'address': [null, Validators.compose( [ Validators.required ] ) ],
    'address2': [null, Validators.compose( [ Validators.required ] ) ],
    'city': [null, Validators.compose( [ Validators.required ] ) ],
    'telno': [null, Validators.compose( [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(7), Validators.maxLength(15), CustomValidator.cannotContainSpace ] ) ],
    'state': [null, Validators.compose( [ Validators.required ] ) ],
    'country': ['PH', Validators.compose( [ Validators.required ] ) ],
    'zip': [null, Validators.compose( [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(4), Validators.maxLength(10), CustomValidator.cannotContainSpace ] ) ],
    // 'selectedPaymentMethod': [null, Validators.compose([Validators.required])],
};

export const BILLING_INFO_ERRORS = [
    { name: 'required', text: 'This field is required', rules: ['touched', 'dirty'] },
    { name: 'minlength', text: 'Minimum number of characters ', rules: ['touched', 'dirty'] },
    { name: 'maxlength', text: 'Maximum number of characters ', rules: ['touched', 'dirty'] },
];
