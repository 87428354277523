import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, TemplateRef  } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ToastrManager } from 'ng6-toastr-notifications';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';

@Component({
    selector: 'section-medical-certificate',
    templateUrl: './medical-certificate.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionMedicalCertificateComponent implements OnChanges {
    @Input() data: object
    @Input() eventId
    @Output() newData = new EventEmitter
   
    modalRef: BsModalRef;
    title = (!iAmPsychologist()) ? 'Medical Certificate' : 'Consultation Report'
    value = null
    match = true
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private modalService: BsModalService,
        private noteService: NotesService,
        private toastr: ToastrManager
    ) {}

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
        this.modalRef.setClass('modal-lg');
    }

    updatedData = (value) => {
        this.newData.emit(value)
    }

    modalDecline = () => {
        this.modalRef.hide()
    }

    downloadPDF = () => {
        this.noteService.genReport('certificate', this.eventId)
        .subscribe(
            response => downloadPDFFile(response),
            e => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue && 
            typeof changes.data.currentValue.medCertRecomendation === 'string'
        ) {
            if (changes.data.currentValue.medCertRecomendation.length) {
                this.value = changes.data.currentValue.medCertRecomendation
            }
        }
    }

}
