<nav class="navbar navbar-expand-sm navbar-light bg-light fixed-top"
    *ngIf="showHeader">
    <a class="navbar-brand" [routerLink]="['/me']"><img
            src="./assets/images/logo.png" style="width:300px;"></a>
</nav>

<div class="container p-5 mt-5 text-center" *ngIf="!loading && !isAllowed">
    <span style="font-size: 9em;">
        <fa-icon [icon]="faLock"></fa-icon>
    </span>
    <div>
        You do not have permission to access this page. Click <a
            [routerLink]="'/me'">here</a> to go back to the dashboard.
    </div>
</div>

<div class="container p-5 mt-5 text-center" *ngIf="!loading && isAllowed &&
    expired">
    <span style="font-size: 9em;">
        <fa-icon [icon]="faLock"></fa-icon>
    </span>

    <h4 *ngIf="!isPatient"><strong>{{ patientName(patientRecord) }}</strong></h4>
    <h4 *ngIf="isPatient"><strong>{{ doctorName(doctorRecord) }}</strong></h4>
    <h4 *ngIf="event"><strong>{{event.start_at.date | date:'MMMM d, y h:mm'}} -
            {{event.end_at.date | date:'h:mm a'}}</strong></h4>
    <!-- [{{patientRecord | json}}] -->
    <hr />
    <!-- [{{event | json}}] -->
    <div *ngIf="!isPatient">
        <p>
            This consulation notes is locked because of the schedule date and
            time may have passed or payment is not settled.
        </p>
        <p>You may view the patient's consultation note in your <a
                [routerLink]="'/me/patients/'+patientRecord.id">My Patients</a>
            page.</p>
    </div>
    <div *ngIf="isPatient">
        <p>
            This consulation notes is locked because of the schedule date and
            time may have passed or payment is not settled.
        </p>
    </div>
</div>

<div class="container p-5 mt-5" *ngIf="isAllowed && !expired && isPatient">
    <span class="h3 lead">Feedback Form</span>
    <div class="row">
        <div class="col">
            <div class="m-3">
                <p class="mb-0 lead">
                    <strong>
                        {{questions[0].query}}
                    </strong>
                </p>
                <div class="row ml-4">
                    <div class="mt-4">
                        <h5>Rate your over-all experience with us</h5>
                    </div>
                    <div class="ml-3 mt-3">
                        <ngb-rating [(rate)]="rating" [max]="max">
                            <ng-template let-fill="fill" let-index="index">
                                <i class="fa fa{{ fill === 100 ? '-star' : '-star-o'}}" [class.filled]="fill === 100" style="font-size:40px;"></i>
                            </ng-template>
                        </ngb-rating>
                    </div>
                    <div class="ml-3 mt-4">
                        <p class="font-italic">{{displayRate()}}</p>
                    </div>
                </div>
                
                <div class="ml-4 mt-2">
                    <div class="form-group">
                        <textarea class="form-control" placeholder="Describe your experience"
                            [(ngModel)]="patientResponse" rows="5"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <pre class="my-2">
        For your suggestions, comments and feedback, please send it to the hereinunder email address:
            Name: Fareda Fatima Flores, MD, FPPA (Life)
            Designation: Executive Director
            Phone Number: +639189057825
            Email Address: faredafatimaflores@yahoo.com
    </pre>
    <div class="row">
        <div class="col text-right">
            <hr>
            <button class="btn btn-primary" (click)="submitClickBtn()"
                [disabled]="saving">{{saving? 'Saving' :'Submit'}} <i
                    *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
        </div>
    </div>
    <!-- <pre>{{questions|json}}</pre> -->
</div>
<div class="container mt-5" [class.p-5]="showHeader" *ngIf="isAllowed &&
    !expired && !isPatient">
    <div class="alert alert-primary text-left font-italic font-weight-bold">
        * In line with our contribution to the Philippine Mental Health Statistics Program, subject to anonymity, please fill-up the &nbsp; &nbsp;corresponding diagnosis accordingly.
    </div>
    <div class="mt-2 pull-left">
        <span *ngIf="!patientRecord">
            Loading...
        </span>
        <span *ngIf="patientRecord && event && event.start_at">
            Consultation Notes for: <strong>{{patientRecord.first_name}}
                {{patientRecord.last_name}}</strong>
            <div>Consultation Type:<strong> {{event.offline ? 'FACE-TO-FACE' : 'VIRTUAL'}} </strong></div>
            <div>{{formatDate(event.start_at.date, 'LL')}}
                {{formatDate(event.start_at.date,
                'hh:mm A')}}-{{formatDate(event.end_at.date, 'hh:mm A')}}</div>    
        </span>
    </div>

    <div class="mt-2 text-right">
        <a class="btn btn-primary"
            placement="top"
            ngbTooltip="A new window will open..."
            *ngIf="patientRecord"
            [href]="'/me/patients/' + patientRecord.id"
            target="_blank">
            View Patient's Record
        </a>

        &nbsp;<button class="btn btn-primary" (click)="goToAppointment()">Go to
            Appointments</button>
    </div>
    <app-medications [event]="event" [medSheet]="feedbackData"
        [selectedSection]="selectedSection"></app-medications>
</div>