import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NotesService extends AbstractService {

    endpoint = environment.serverUrl + 'user/events/notes';

    constructor(http: HttpClient) {
        super(http);
    }

    saveNote(data: any) {
        return this.http.post(this.endpoint + '?', data)
            .map(result => result, error => error);
    }

    getNotes() {
        return this.http.get(this.endpoint + '?filter=id;event_id')
            .map(result => result, error => error);
    }

    getDiagnostics(type: string) {
        return this.http.get(environment.serverUrl + 'diagnostic?type=' + type + '&limit=0')
            .map(result => result, error => error);
    }

    getNote(id: any) {
        return this.http.get(`${this.endpoint}/${id}`)
            .map(result => result, error => error);
    }

    saveMedicines(data: any) {
        const url = environment.serverUrl + 'event-prescriptions';
        return this.http.post(url, data);
    }

    previewPrescription(data: any) {
        const url = environment.serverUrl + 'event-prescriptions/preview';
        return this.http.post(url, data, { responseType: 'arraybuffer' });
    }

    genReport(type: any, event_id: any) {
        const url = environment.serverUrl + 'reports/generate?type=' + type + '&event_id=' + event_id;
        return this.http.get(url, { responseType: 'arraybuffer' });
    }

    sendEmail(type: any, event_id: any) {
        const url = environment.serverUrl + 'reports/email?type=' + type + '&event_id=' + event_id;
        return this.http.get(url, { responseType: 'arraybuffer' });
    }

    updateMedicines(id: any, data: any) {
        const url = environment.serverUrl + 'event-prescriptions/' + id;
        return this.http.put(url, data);
    }

    getMedicineList() {
        const url = environment.serverUrl + 'suppdata?data=medicines;medicine-generic-names';
        return this.http.get(url)
            .map(data => {
                const list = Object.keys(data['medicines']).map(key => {
                    const med = data['medicines'][key];
                    return {
                        raw: med,
                        medId: med.id,
                        genericId: med.generic.id,
                        genericName: med.generic.name,
                        brand: med.name,
                        remarks: med.remarks,
                        instructions: '',
                        dosageList: med.dosages.map(d => `${d} ${med.unit}`)
                    };
                });
                return {
                    list,
                    generics: data['medicine-generic-names']
                };
            });
    }

    getMedicinesFromLastConsultation = (event_id) => {
        const url = environment.serverUrl + 'user/events/last-consultation';
        return this.http.post<any[]>(url, {event_id})
        .map(data => {
             return data
        });
    }

    saveLatestConsultation = (event_id) => {
        const url = environment.serverUrl + 'user/events/latest-consultation';
        return this.http.post<any[]>(url, {event_id})
        .map(data => {
             return data
        });
    }
}
