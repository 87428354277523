<div *ngIf="match">
    <h6 class="text-muted">
        {{title}}
        <span 
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor() && !iAmPsychologist()"
        >
            <fa-icon 
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"
            ></fa-icon>
        </span> 
</h6>
    
    <div *ngIf="typeOf(value) == 'object' && value.length && value[0].diagnosticRequest">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th width="5px">#</th>
                    <th width="120px">Request</th>
                    <th width="120px">Type</th>
                    <th width="120px">Diagnostic Test</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let lr of value ; index as i">
                    <td class="p-1 text-center">
                        <pre [innerHTML]="i+1"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="lr.diagnosticRequest"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="lr.diagnosticType"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="lr.diagnosticTest"></pre>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="typeOf(value) == 'string'">
        <pre [innerHTML]="value"></pre>
    </div>
</div>
