import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DashboardComponent } from './dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { OverlayComponent } from '../../../shared/overlay/overlay.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        NgbCollapseModule,
    ],
    declarations: [
        DashboardComponent,
        SidebarComponent,
        OverlayComponent
    ],
    providers: [
    ]
})
export class DashboardModule { }
