import { Component, Input, OnInit } from "@angular/core";
import $ from "jquery";
import moment from "moment";
import "fullcalendar";

@Component({
    selector: "app-calendar",
    template: `
        <div *ngIf="!options" class="alert alert-danger" role="alert">
            <strong>Error!</strong> Missing Calendar options!
        </div>
        <div class="w-100" id="calendar"></div>
    `,
})
export class CalendarComponent implements OnInit {
    @Input("options") options: any;

    ngOnInit() {
        if (this.options) {
            const options = {
                dayRender: (date, cell) => {
                    if (date.isBefore(moment(), "day")) {
                        cell.css("background-color", "#f1f1f1");
                    }
                },
                eventRender: (e, element) => {
                    if (moment(e.start).isBefore(moment(), "d")) {
                        element.css("background-color", "#ababab");
                        element.css("border", "none");
                    }
                },
                ...this.options,
            };
            // console.log('options =>', options);
            $("#calendar").fullCalendar(options);
        }
    }

    refresh() {
        $("#calendar").fullCalendar("refetchEvents");
    }
}
