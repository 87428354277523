
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';


export const MEDICINES_MODEL = {
  'name' : [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
  'generic_name_id' : [null, Validators.compose([Validators.required])],
  'unit' : [null, Validators.compose([Validators.required])],
  'dosage' : [null, Validators.compose([Validators.required])],
  'remarks' : [null, Validators.compose([Validators.required])],
  'is_injectable' : [null, Validators.compose([Validators.required])],
  'is_active' : [null, Validators.compose([Validators.required])],
  'price' : [null, Validators.compose([Validators.required])],
}

export const ERRORS = [
  { name: 'required', text: 'This field is required', rules: ['touched', 'dirty'] },
  { name: 'minlength', text: 'Minimum number of characters ', rules: ['touched', 'dirty'] }
];

export const ROLES_MODEL = {
  'roles': FormArray,
};