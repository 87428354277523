import { FormControl, Validators } from '@angular/forms';

export const REGISTRATION_FORM = {
    'email': new FormControl('', {validators: [Validators.required, Validators.email] }),
    'password': new FormControl('', {validators: [Validators.required, Validators.minLength(8)] }),
    'confirm_password': new FormControl('', {validators: [Validators.required]}),
    'first_name': new FormControl('', {validators: [Validators.required]}),
    'middle_name': new FormControl('', {validators: [Validators.required]}),
    'last_name': new FormControl('', {validators: [Validators.required]}),
    'agree': new FormControl('', {validators: [Validators.requiredTrue]}),
};
