<div>
  <ngx-datatable
      [rows]="rows"
      [columns]="columns"
      [columnMode]="'force'"
      [footerHeight]="30"
      [rowHeight]="'auto'"
      class="small material"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='handlePager($event)'
      (sort)="handleSort($event)"

      class="small material"
  >

    <ngx-datatable-column name="ID" props="id" maxWidth="60">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="From" props="from">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.user.first_name }}  {{ row.user.last_name }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Email" props="email">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.user.email }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Message" props="comment">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.comment }}
        </ng-template>
    </ngx-datatable-column>
    
    <ngx-datatable-column name="Date Sent" props="updated_at" maxWidth="150">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.updated_at }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Action" sortable="false" prop="action" maxWidth="100">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          {{column.name}}
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <a *ngIf="settings.crud.view.allow" [routerLink]="[getViewLink(row)]"><i class="fa fa-eye text-warning"></i></a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>
