import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

// import { DhomeRoutingModule } from './dhome-routing.module';
// import { DhomeComponent } from './dhome.component';
import { AvailabilityComponent } from './availability.component'
import { SlotModule } from './slot/slot.module'
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin
]);
@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        SlotModule,
        FullCalendarModule,
        NgbModule,
        TypeaheadModule.forRoot(),
        NgbTypeaheadModule,
        // DhomeRoutingModule,
        // SharedModule
    ],
    declarations: [
        AvailabilityComponent
    ]
})
export class AvailabilityModule { }
