import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { AvailabilityService } from '../../../../../services'
import { patientName, avatar, getDayOfWeek, formattedPrice, is30Minutes  } from '../../../../../services/helpers'

import { faCheckCircle, faTrash, faSave } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment'
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-slot',
  templateUrl: './slot.component.html',
  styleUrls: ['./slot.component.scss']
})

export class SlotComponent implements OnInit, OnChanges {

  @Input() datePicked: any = null
  @Input() slotDetails: any = {}
  @Input() savingEvent: boolean = false
  @Input() actionMode: string = null
  @Input() selectedEvent: any = null
  @Input() rateCharge: any = null
  @Output() slotDetailsChange: EventEmitter<any> = new EventEmitter()
  @Output() saveEvent: EventEmitter<any> = new EventEmitter()
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter()

  public fa = {
    faSave, faCheckCircle, faTrash
  }
  public isValidTime = true

  selectType = (type) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, type })
  }

  selectClinic = (params) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, ...params })
  }

  selectDuration = (duration) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, duration })
  }

  selectStartAt = (start_at) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, start_at })
  }

  selectEndAt = (end_at) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, end_at })
  }

  selectTime = (d) => {
    this.slotDetailsChange.emit({ ...this.slotDetails, ...d })
  }

  panelToOpen = (panel?) => {
    if (panel) {
      return panel
    }

    if (this.slotDetails.type) {
      if (this.slotDetails.type == 'virtual' && !this.slotDetails.duration) {
        return 'panel-step-2'
      }

      if (this.slotDetails.type == 'face' && !this.slotDetails.duration) {
        if (this.slotDetails.clinic_id) {
          return 'panel-step-2'
        }
      }
    }

    if (this.slotDetails.type && this.slotDetails.duration) {
      return 'panel-step-3'
    }

    return 'panel-step-1'
  }

  disablePanel = (panel) => {
    switch (panel) {
      case 2:
        return !this.slotDetails.type || (this.slotDetails.type == 'face' && !this.slotDetails.clinic_id)
      case 3:
        return !this.slotDetails.type || !this.slotDetails.duration
    }

    return false
  }


  formatDateSelected = () => {
    if (this.datePicked) {
      return moment(this.datePicked).format('MMMM D, YYYY')
    }
  }

  isStartAtValid = () => {
    this.isValidTime = false

    if (!this.slotDetails.start_at || !this.slotDetails.start_at.hour) {
      return false
    }

    const start_at = moment(this.datePicked)
      .tz(this.slotDetails.timezone)
      .hour(this.slotDetails.start_at.hour)
      .minute(this.slotDetails.start_at.minute)

    this.isValidTime = moment().tz(this.slotDetails.timezone).isBefore(start_at)

    return this.isValidTime
  }

  isValidForm = () => {
    if (
      !this.slotDetails.type
      || !this.slotDetails.duration
      || !this.isValidTime
    ) {
      return false
    }

    return true
  }


  _saveEvent = () => {
    this.saveEvent.emit()
  }

  _deleteEvent = () => {
    this.deleteEvent.emit()
  }

  disableSaveEventBtn = () => {
    return this.slotDetails.type == 'face' && !this.slotDetails.clinic_id || !this.isValidForm() || this.savingEvent ||
   (!this.getRateLabel())
  }

  getRateLabel = () => {
    if (!this.slotDetails || !this.slotDetails.start_at || !this.slotDetails.end_at) {
      return false
    }
    return formattedPrice(
        this.rateCharge, 
        getDayOfWeek(moment(this.datePicked)),
        is30Minutes(moment(this.slotDetails.start_at), moment(this.slotDetails.end_at)),
        this.slotDetails.type
    );

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.datePicked && changes.datePicked.currentValue) {
      // console.log(changes.datePicked)
    }


    if (changes.slotDetails && changes.slotDetails.currentValue) {
      // console.log(changes.slotDetails.currentValue)
      this.isStartAtValid()
    }
  }

  ngOnInit() {
    this.isValidTime = true
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if(this.selectedEvent?.status == 'BOOKED') {
      switch ($event.panelId) {
        case "panel-step-1":
        case "panel-step-2":
            return $event.preventDefault()

      }
    }
}

  constructor(
    public availabilityService: AvailabilityService
  ) { }
}
