<div *ngIf="loading">
    Loading...
</div>

<div *ngIf="!loading">
    <select (change)="selectValue($event.target.value)" class="form-control" >
        <option value="">-- Select --</option>
        <option value="0" *ngIf="displayOthers">** OTHERS **</option>
        <option *ngFor="let row of list" value="{{row.id}}">{{ row.label }}</option>
    </select>
</div>