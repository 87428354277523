import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RoleService extends AbstractService {
    endpoint = environment.serverUrl + 'roles';

    constructor(http: HttpClient) {
        super(http);
    }
}
