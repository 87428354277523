import {
    Component,
    OnInit,
    TemplateRef,
    Injector,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
    Output,
    EventEmitter,
    Input,
} from "@angular/core";
import { PhysicalExamService, errorvalidationParse } from "../../../services";
import * as _ from "lodash";
import { ToastrManager } from "ng6-toastr-notifications";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { FormGroup, FormBuilder } from "@angular/forms";
import { faPencilAlt, faArrowCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { SECTIONS } from "./SECTIONS";

import "moment-timezone";
import moment from "moment";

@Component({
    selector: "app-physical-exam",
    templateUrl: "./physical-exam.component.html",
    styleUrls: ["./physical-exam.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PhysicalExamComponent implements OnInit, OnChanges {
    modalRef?: BsModalRef;
    SECTIONS = SECTIONS;
    formGroup: FormGroup;
    formBuilder: FormBuilder;
    PEForm = null;
    formDirty = false;
    savingForm = false;
    initialValue = {};

    faPencilAlt = faPencilAlt;
    faArrowCircleUp = faArrowCircleUp;
    faArrowCircleDown = faArrowCircleDown;

    @Output() onPEFormDirty: EventEmitter<any> = new EventEmitter();
    @Input() physicalExam = null;
    @Input() eventId = null;

    constructor(
        private injector: Injector,
        private toastr: ToastrManager,
        private physicalExamService: PhysicalExamService,
        private modalService: BsModalService
    ) {
        this.formBuilder = injector.get(FormBuilder);

        let formModels = {};

        SECTIONS.forEach((v, k) => {
            // v.model[v.key][0] = this.physicalExam[v.key]
            formModels = { ...formModels, ...v.model };
        });

        this.PEForm = this.formBuilder.group(formModels);
        this.onFieldsChanges();
    }

    ngOnInit() {
        if (this.physicalExam) {
            // POPULATE FORM
            SECTIONS.forEach((v, k) => {
                // console.log(v.key, this.PEForm.controls[v.key], this.PEForm.get(v.key), this.physicalExam[v.key])
                this.PEForm.controls[v.key].patchValue(this.physicalExam[v.key]);
            });
        }
    }

    onFieldsChanges(): void {
        this.PEForm.valueChanges.subscribe((val) => {
            let hasDirty = false;
            SECTIONS.forEach((v, k) => {
                if (this.PEForm.controls[v.key].dirty) {
                    hasDirty = true;
                }
            });

            this.formDirty = hasDirty;
            this.onPEFormDirty.emit(hasDirty);
        });
    }

    doSave = () => {
        this.savingForm = true;
        let data = this.PEForm.value;

        data["event_id"] = this.eventId;
        this.physicalExamService.store(data).subscribe(
            (response) => {
                this.initialValue = data;

                // RESET TO DEFAULT
                this.savingForm = false;
                this.formDirty = false;
                this.onPEFormDirty.emit(false);
                this.PEForm.markAsPristine();

                this.toastr.successToastr("Physical Exam Updated", "Saved!");
            },
            (error) => {
                this.savingForm = false;
                let m = errorvalidationParse(error);
                this.toastr.errorToastr(m.join(","), "Warning!", { enableHTML: true });
            }
        );
    };

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: "modal-m modal-dialog-centered",
        });
    }

    confirm(): void {
        this.PEForm.reset(this.initialValue);
        this.modalRef?.hide();
    }

    decline(): void {
        this.modalRef?.hide();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.physicalExam && changes.physicalExam.currentValue) {
            this.initialValue = changes.physicalExam.currentValue;
        }
    }
}
