import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';
import 'rxjs/add/observable/throw';
import { HttpClient } from '@angular/common/http';
import { LoginContext } from '../core/authentication/login-context.interface';
import { User, Role } from '../shared/models';
import { Observable } from '../../../node_modules/rxjs';
import { StorageType, StorageService } from './storage.service';


@Injectable()
export class UserService extends AbstractService {
    static fullName: string
    endpoint = environment.serverUrl + 'users'
    userData = new Subject<any>()
    profilePicture = new Subject<any>()
    avatars = new Subject<any>()
    credentialsKey = 'credentials'
    storageType = StorageType.LOCAL // TEMPORARILY STATIC
    emailVerified = new BehaviorSubject(false)
    rawUser: any;

    _srvcs = new BehaviorSubject({})
    _tc = new BehaviorSubject([])

    public get currentUser() {
        return this.getStoredCredentials();
    }

    constructor(http: HttpClient) {
        super(http);
        this.userData.subscribe(data => {
            UserService.fullName = data;
        });
    }

    getList(e?: any) {
        return this.index(e);
    }


    getUserByToken() {
        const token = this.currentUser.token;
        return this.http.post(this.endpoint + '/by-token', { token })
            .map((response: any) => {
                return response.data;
                // login successful if there's a jwt token in the response
            });
    }

    updatePhoto(userId: number, data: any) {
        // const headers = new Headers();
        // /** No need to include Content-Type in Angular 4 */
        // headers.append('Content-Type', 'multipart/form-data');
        // // const options = new RequestOptions({ headers: headers });

        // return this.http.post(this.endpoint + `/${userId}/picture`, data)
        //     .map((response: any) => {
        //         // console.log(response);
        //         return response;
        //     },
        //         (err: any) => {
        //             // console.log(err);
        //             return err;
        //         });
    }

    register(data: any) {
        return this.http.post(this.endpoint + '/register', data)
            .map(
                (response: any) => {
                    // console.log(response);
                    return response;
                },
                (err: any) => {
                    // console.log(err);
                    return err;
                });
    }

    updateDetails(id: any, params: any) {
        return this.http.put(this.endpoint + '/details/' + id, params)
            .map(({ data }: any) => data, e => e);
    }

    updateEmail(id: any, params: any) {
        return this.http.put(this.endpoint + '/email/' + id, params)
            .map((result: any) => {
                return result;
            }, (err: any) => {
                return err;
            }
            );
    }

    updatePassword(id: any, params: any) {
        return this.http.put(this.endpoint + '/password/' + id, params)
            .map((result: any) => {
                return result;
            }, (err: any) => {
                return err;
            }
            );
    }

    passwordResetRequest(email: string) {
        const endpoint = environment.serverUrl + 'user/forgot-password';
        const params = { email };
        return this.http.post(endpoint, params, { observe: 'response' });
    }

    passwordReset(params: any) {
        const endpoint = environment.serverUrl + 'user/reset-password';
        return this.http.post(endpoint, params, { observe: 'response' });
    }

    verifyEmail(email: string, token: string) {
        const endpoint = environment.serverUrl + `users/verify/${email}/${token}`;
        return this.http.get(endpoint, { observe: 'response' })
            .map((result: any) => {
                return result;
            },
                (error: any) => {
                    return error;
                });
    }

    setRoles(userId: any, roles: Array<any>[]) {
        return this.http.post(this.endpoint + '/' + userId + '/assign-roles', roles)
            .map(
                (response: any) => {
                    return response;
                },
                (error: any) => {
                    // console.log(error);
                    return error;
                }
            );
    }

    setStatus(userId:any, status: Array<any>[]) {
        return this.http.post(this.endpoint + '/' + userId + '/status', status)
            .map(
                (response: any) => {
                    return response;
                },
                (error: any) => {
                    // console.log(error);
                    return error;
                }
            );
    }

    authenticate(data: LoginContext): Observable<User> {
        return this.http.post(
            this.endpoint + '/auth',
            JSON.stringify(data)
        ).map(
            (response: any) => {
                const user = new User();
                user.createFromAuth(response.data);
                return user;
            },
            (error: any) => {
                return error;
            }
        );
    }

    getUserInfo() {
        const userId = this.getStoredCredentials().id;
        const url = environment.serverUrl + 'users/' + userId;
        return this.http.get(url)
            .map((response: any) => response.data);
    }

    updateStoredCredentials(user: any) {
        // console.log('updateStoredCredentials user',user)
        const storedCredentials = this.currentUser;
        const newCredentials = JSON.stringify({ ...storedCredentials, ...user });
        StorageService.storage(this.storageType).setItem(this.credentialsKey, newCredentials);

        const newUserData = this.getStoredCredentials();
        this.userData.next(`${newUserData.fullName}`);
    }

    setPicture (url: string) {
        this.profilePicture.next(url);
        StorageService.storage(this.storageType).setItem('picture', url);
        return true;
    }

    setAvatars (avatars) {
        this.avatars.next(avatars);
        StorageService.storage(this.storageType).setItem('avatars', avatars);
        
        // UPdate the "credentials" key
        const storedCredentials = JSON.parse(StorageService.storage(this.storageType).getItem('credentials'))
        storedCredentials.avatar = avatars;
        StorageService.storage(this.storageType).setItem('credentials', JSON.stringify(storedCredentials));

        return true;
    }

    getPicture () {
        const store = StorageService.storage(this.storageType);
        return store.getItem('picture');
    }

    getAvatars () {
        const store = StorageService.storage(this.storageType);
        return store.getItem('avatars');
    }

    getStoredCredentials(): User {
        const store = StorageService.storage(this.storageType);
        const storedCredentials = JSON.parse(store.getItem(this.credentialsKey));
        const user = new User();
        
        if (storedCredentials) {
            user.createFromStorage(storedCredentials);
        }

        return user;
    }

    clearStoredCredentials(): void {
        StorageService.storage(this.storageType).removeItem(this.credentialsKey);
    }

    checkRole(roles: any) {
        const credentials = this.currentUser;
        let status = false;

        if (credentials.roles && credentials.roles.length) {
            credentials.roles.forEach((userRole: any) => {
                roles.filter(function (role: string) {
                    if (userRole.name === role) {
                        status = true;
                    }
                });
            });
        }

        return status;
    }

    checkUserRole(roleToCheck: string) {

        const credentialsKey = 'credentials';
        let savedCredentials;
        savedCredentials = sessionStorage.getItem(credentialsKey) ?
            sessionStorage.getItem(credentialsKey) : localStorage.getItem(credentialsKey);

        const credentials = JSON.parse(savedCredentials);

        let status = false;
        if (credentials.roles) {
            credentials.roles.forEach((role: any) => {
                if (role.name === roleToCheck) {
                    status = true;
                }
            });
        }

        return status;
    }

    me() {
        return this.http.get(
          `${environment.serverUrlV2}/me`,
          {
            headers: {
              'Access-Control-Allow-Origin':'*',
              'Accept': environment.API_HEADER_ACCEPT_v2
            }
          })
        .map((result: any) => {
            this._srvcs.next(result.data._srvs)
            this._tc.next(result.data._tc)
            
            const store = StorageService.storage(this.storageType);
            const storedCredentials = JSON.parse(store.getItem(this.credentialsKey));
          return result;
        }, (err: any) => {
          console.log(err)
            return err;
        });
      }
}
