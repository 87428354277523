<div style="max-width: 100%; min-width:300px;" class="m-auto">
    <div class="m-2">
        <label for="date" class="h5">Date</label>
        <input type="text" id="date" placeholder="Enter Date" (bsValueChange)="getAppointments($event)" [minDate]="minDate"
            class="form-control m-auto" bsDatepicker>
    </div>
    <!-- <div class="m-2">
        <label for="slot" class="h5">Time</label>
        <input [(ngModel)]="selectedSlot" id="slot" [placeholder]="placeholder()" [disabled]="loadingSlots || !slots.length || newTimeSlot"
            [typeahead]="slots" [typeaheadMinLength]="0" option class="form-control mb-2 m-auto" typeaheadOptionField="timeslot">
    </div> -->
    
    <div class="row">
        <div class="col-4 m-2">
            <label class="font-weight-bold">Next Meeting Type:</label>
            <select class="form-control" name="type" (change)="onSelectType($event)"
                [(ngModel)]="type">
                    <option value="virtual">Virtual</option> 
                    <option value="face" [disabled]="clinicList.length === 0">Face-to-Face</option> 
            </select>
        </div>

        <div class="col-4 m-2" *ngIf="selectType">
            <label class="font-weight-bold">Clinic:</label>
            <select class="form-control" name="clinic" (change)="onSelectClinic($event)"
                [(ngModel)]="clinic">
                    <option *ngFor="let clinic of clinicList" [value]="clinic.id">
                    {{clinic.name}}
                    </option> 
            </select>
        </div>
    </div>

    <div class="m-2  px-2 pb-2 pt-3">
        <h6>Create new timeslot <input type="checkbox" [(ngModel)]="newTimeSlot"></h6>
        <div *ngIf="newTimeSlot">
            <div class="row">
                <div class="col-4 text-right align-items-center pt-3">
                    Start time:
                </div>
                <div class="col">
                    <timepicker [(ngModel)]="time.start" class="m-auto" minuteStep="30"></timepicker>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 text-right align-items-center pt-3">
                    End time:
                </div>
                <div class="col">
                    <timepicker [(ngModel)]="time.end" class="m-auto" minuteStep="30"></timepicker>
                </div>
            </div>
        </div>
    </div>
    <hr>
    <div class="m-2">
        <strong>Appointment Type : {{ event?.offline ? 'FACE-TO-FACE' : 'VIRTUAL' }}</strong>
    </div>
    <div *ngIf="event?.offline" class="m-2">
        <strong>Clinic: {{ event?.clinic.name }}</strong>
    </div>
    <div class="row">
        <div class="col pl-4">
            <b>Duration: {{selectedSlot || newTimeSlot ? getDuration() : 0}} minutes</b>
        </div>
        <div class="col text-right pr-4">
            <button class="btn btn-outline-primary"
                [class.disabled]="!(selectedDay && ((newTimeSlot && time.start&&time.end) || selectedSlot))"
                [disabled]="!(selectedDay && ((newTimeSlot && time.start&&time.end) || selectedSlot))"
                (click)="saveNextAppointMent()">{{ saving ? 'Saving' : 'Save' }} <i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
        </div>
    </div>
</div>
