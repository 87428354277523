<crud-header 
*ngIf="formType=='create'"
[settings]="settings" 
[action]="'create'"
></crud-header>

<crud-header 
*ngIf="formType=='edit'"
[settings]="settings" 
[action]="'edit'"
></crud-header>
<ngb-tabset>
    <ngb-tab title="Medicine Information">
        <ng-template ngbTabContent>
            <form [formGroup]="rForm" novalidate  autocomplete="off"> 

                <div class="row">
                    <div class="form-group col-6">
                        <label for="name">Name <span class="required text-danger">*</span></label>
                        <input type="text" class="form-control" id="name" formControlName="name" autocomplete="off">
                        
                        <div class="small text-warning" ngxErrors="name" #errorName="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                <div *ngSwitchCase="'minlength'">
                                    {{ error.text }} {{ errorName.getError('minlength')?.requiredLength }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ error.text }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label for="generic_name_id">Generic Name</label>
                        <select class="form-control" id="generic_name_id" formControlName="generic_name_id">
                            <option *ngFor="let generic of genericList" [ngValue]="generic.id">
                                {{ generic.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-4">
                        <label for="unit">Unit <span class="required text-danger">*</span></label>
                        <input type="text" class="form-control" id="unit" formControlName="unit" autocomplete="off">
                        
                        <div class="small text-warning" ngxErrors="unit" #errorUnit="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.unit" [when]="error.rules">
                                <div [ngSwitch]="error.unit">
                                <div *ngSwitchCase="'minlength'">
                                    {{ error.text }} {{ errorUnit.getError('minlength')?.requiredLength }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ error.text }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="dosage">Dosage</label>
                        
                        <input type="dosage" class="form-control" id="dosage" formControlName="dosage" autocomplete="dosage">
                        
                        <div class="small text-warning" ngxErrors="dosage" #errorDosage="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                <div *ngSwitchCase="'minlength'">
                                    {{ error.text }} {{ errorDosage.getError('minlength')?.requiredLength }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ error.text }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-4">
                        <label for="price">Price</label>
                        
                        <input type="price" class="form-control" id="price" formControlName="price" autocomplete="price">
                        
                        
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <label for="remarks">Remarks</label>
                        <textarea class="form-control" id="remarks" formControlName="remarks" autocomplete="remarks">
                        </textarea>
                        
                        <div class="small text-warning" ngxErrors="remarks" #errorGenericName="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                <div *ngSwitchCase="'minlength'">
                                    {{ error.text }} {{ errorGenericName.getError('minlength')?.requiredLength }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ error.text }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12">
                        <input type="checkbox" class="" id="is_active" formControlName="is_active" autocomplete="off"> Active
                    </div>
                    <div class="form-group col-12">
                        <input type="checkbox" class="" id="is_injectable" formControlName="is_injectable" autocomplete="off"> Injectable
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 offset-3">
                        <crud-form-buttons
                            *ngIf="formType=='edit'"
                            [settings]="settings"
                            [action]="'edit'"
                            [rForm]="rForm"
                            (doSave)="doUpdate($event)"
                        ></crud-form-buttons>                      
                    </div>
                    <crud-form-buttons
                            *ngIf="formType=='create'"
                            [settings]="settings"
                            [action]="'create'"
                            [rForm]="rForm"
                            (doSave)="doSave($event)"
                        ></crud-form-buttons> 
                </div>

            </form>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
        