<div class="form-check form-check-inline">
  <label (click)="_selectDuration(60)" >
    <input 
      type="radio" 
      value="60" 
      name="duration" 
      [checked]="selectedDuration==60"
    /> 1 Hour
  </label>
</div>

<div class="form-check form-check-inline">
  <label (click)="_selectDuration(30)" >
    <input 
      type="radio" 
      value="30" 
      name="duration" 
      [checked]="selectedDuration==30"
    /> 30 Minutes
  </label>
</div>