import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PhysicalExamService extends AbstractService{

  endpoint = environment.serverUrl + 'physical-exam';

    constructor(http: HttpClient) {
        super(http);
    }

    store = (data) => {

      return this.http.post(
        `${environment.serverAdminUrlV2}/api/physical-exam`, 
        data,
        {
          headers: {
            'Access-Control-Allow-Origin':'*',
            'Accept': environment.API_HEADER_ACCEPT_v2
          }
        }
      ).map((result: any) => {
        return result;
      }, (err: any) => {
        console.log(err)
          return err;
      });
    }
}
