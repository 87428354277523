<div *ngIf="match">
    <h6 class="text-muted">
        {{title}} 
        <span
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"
            ></fa-icon>
        </span> 
        <span 
            class="d-print-none badge badge-dark cta mr-2"
            (click)="openModal(formModal)"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faPencilAlt"
                ngbTooltip="Click to edit"
            ></fa-icon>
        </span>
        <span 
            class="d-print-none badge badge-dark cta mr-2"
            (click)="openModal(emailModal)"
            *ngIf="iAmDoctor()"
        >
        <!-- (click)="sendEmail()" -->
            <fa-icon 
                [icon]="fa.faPaperPlane"
                ngbTooltip="Email to patient"
            ></fa-icon>
        </span>
    </h6>
    
    <div *ngIf="iAmDoctor() && !iAmPsychologist()" class="items px-2">
        <div class="item mb-2" *ngFor="let item of sections | keyvalue: asIsOrder; let i = index">
            <div class="label">
                <strong>{{item.value}}</strong>
            </div>
            <pre *ngIf="value[item.key] && value[item.key].length">{{value[item.key]}}</pre>
            <pre *ngIf="!value[item.key] || !value[item.key].length"><em class="text-muted">Not specified</em></pre>
        </div> 
    </div>
    <div *ngIf="iAmPsychologist()">
        <pre [innerHTML]="value.general_data"></pre>
    </div>
</div>


<ng-template #formModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{title}}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <section-form-medical-abstract 
            [feedbackData]="value"
            [eventId]="eventId"
            (updatedData)="updatedData($event)"
            (modalDecline)="modalDecline()"
        ></section-form-medical-abstract>
    </div>
</ng-template>


<ng-template #emailModal>
    <div class="modal-body text-center">
        <section-medical-abstract-email 
            (modalDecline)="modalDecline()"
            [eventId]="eventId"
            [title]="title"
        ></section-medical-abstract-email>
    </div>
</ng-template>
