import { Component, OnInit, Injector, TemplateRef } from '@angular/core';

import { ListComponent as CrudListComponent } from '../../../../../core/base/crud';

import { MedicinesService } from '../../../../../services/medicines.service';

import { settings } from '../../settings';

import { BsModalService } from 'ngx-bootstrap/modal';

import { isNull } from 'util';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends CrudListComponent {

  public columns: any;
  selectedRow: any;
  public search: any;

  constructor(injector: Injector, private modalService: BsModalService) {
    super(injector);
    this.setSettings(settings);
    this.service = injector.get(MedicinesService);


  }

  getEditLink(row: any) {
      return this.settings.crud.update.url + row.id;
  }

  deleteRow(row: any, templateRef: TemplateRef<any>): void {
    this.selectedRow = row;
    this.modalRef = this.modalService.show(templateRef);
  }

  confirm() {
    this.service.destroy(this.selectedRow.id)
      .subscribe(data => {
          this.modalRef.hide();
      });
  }

  decline() {
    this.modalRef.hide();
    this.selectedRow = isNull;
  }

  searchFunc()
  {
    // this only returns uri search: name:remarks:unit
    let uri = this.service.getSearch(this.search);

    this.getList(uri);

  }

  onKeySearch(event: any)
  {
    if ( event.which === 13 ) {
      this.searchFunc();
    }
    this.search = event.target.value;
  }

}
