import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SecretaryService extends AbstractService {
  endpoint = `${environment.serverUrl}secretaries`;




  getSecretary(params = {}) {
    return this.http.get(
      `${this.endpoint}${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  getPatients(params = {}) {
    return this.http.get(
      `${this.endpoint}/my/patients/${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }
  

  myDoctor() {
    return this.http.get(
      `${this.endpoint}/my/doctors`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  getCalendarByDoctorId(drId, params = {}) {
    return this.http.get(
      `${this.endpoint}/my/calendar/${drId}${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }
  getClinicHoursByDoctorId(drId,params = {}) {
    return this.http.get(
      `${this.endpoint}/my/schedule/${drId}${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  getByDoctorId(drId, params = {}) {
    return this.http.get(
      `${this.endpoint}doctor/${drId}${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  createSecretary(data: any) {
    return this.http.post(
      this.endpoint,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  assignSecretary(data: any) {
    return this.http.post(
      `${this.endpoint}/assign-to-dr`,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  unAssignSecretary(data: any) {
    return this.http.post(
      `${this.endpoint}/unassign`,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  createClinicHours(drId: any,data: any) {
    return this.http.post(
      `${this.endpoint}/my/schedule/${drId}`,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  updateClinicHours(drId: any,eventId: any,data: any) {
    return this.http.put(
      `${this.endpoint}/my/schedule/${drId}/${eventId}`,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  updateSecretary(id: any, params: any) {
    return this.http.put(
      `${this.endpoint}/${id}`,
      params,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }


  showSecretary(secretaryId) {
    return this.http.get(
      `${this.endpoint}/${secretaryId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  removeClinicHours(drId: any, eventId: any) {
    return this.http.delete(
      `${this.endpoint}/my/schedule/${drId}/${eventId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  remove(id) {
    return this.http.delete(
      `${this.endpoint}${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }


  constructor(http: HttpClient) {
    super(http);
  }
}
