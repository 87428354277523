import { Component, Input, AfterViewInit } from "@angular/core";
import { ITEMS, SECTIONS, DataItem } from "./data-sheet";
import { camelCase, isEmpty, isString, isArray } from "lodash";
import { faCheckCircle, faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faCircle, faSquare } from "@fortawesome/free-regular-svg-icons";
import { MentalHealthSheetService, UserService, typeOf } from "../../../../../services";
import { Router } from "@angular/router";
import { ToastrManager } from "ng6-toastr-notifications";
import moment from "moment";

@Component({
    selector: "app-data-sheet-sec",
    templateUrl: "./data-sheet.component.html",
    styleUrls: ["./data-sheet.component.scss"],
})
export class DataSheetSecComponent implements AfterViewInit {
    @Input() userId: number;
    sectionMap: Object;
    sections = SECTIONS;
    loading = true;
    showAll = false;
    jumpTo: number;
    items: DataItem[] = ITEMS;
    data: any;
    isPatient: any;
    requiredFields: boolean;

    fa = {
        checked: faCheckCircle,
        unchecked: faCircle,
        checkboxFalse: faSquare,
        checkboxTrue: faCheckSquare,
    };

    stepper = 0;

    typeOf = typeOf;

    constructor(
        private dataService: MentalHealthSheetService,
        private userService: UserService,
        public toastr: ToastrManager,
        private router: Router
    ) {
        // this.router.navigate(['/me/account/settings'])
        this.loading = false;
        this.activateSheet();
        this.dataService.mentalHealthData.subscribe((newData) => {
            this.data = { ...this.data, ...newData };

            this.data.generalInformation.dateOfBirth = moment(this.data.generalInformation.dateOfBirth);
            if (typeof this.data.reasonForConsultation == "string") {
                let v = this.data.reasonForConsultation;
                this.data.reasonForConsultation = {
                    reasonForConsultation: this.data.reasonForConsultation,
                };
            } else if (!this.data.reasonForConsultation) {
                this.data.reasonForConsultation = {
                    reasonForConsultation: "",
                };
            }

            if (this.data["date_of_birth"]) {
                this.data["date_of_birth"] = new Date(this.data["date_of_birth"]);
            }

            this.loading = false;
        });

        const user = this.userService.getStoredCredentials();
        this.isPatient = !!user.roles.find(({ id }) => id === 3);

        if (!this.isPatient) {
            this.stepper = 1;
        }
    }

    ngAfterViewInit() {
        this.dataService.get(this.userId).subscribe();
    }
    onValueChange = ($e) => {
        if ($e) {
            var date = moment($e).format("YYYY-M-D");
        }
    };
    activateSheet() {
        this.data = {};

        this.sectionMap = SECTIONS.reduce((sects, name) => {
            const key = camelCase(name);

            this.data[key] = {};
            const items = this.items.filter((i) => i.sectionKey === key);
            items.forEach((item) => {
                if (item.type === "table") {
                    this.data[key][item.key] = item.initValue;
                } else {
                    this.data[key][item.key] = null;
                }
            });
            const uniq = (v, i, a) => a.indexOf(v) === i;
            const groups = items.map((i) => i.group).filter(uniq);
            const groupNames = items
                .filter((i) => !!i.group && !!i.groupName)
                .map(({ group, groupName }) => ({ group, groupName }))
                .reduce((names, { group, groupName }) => ({ ...names, [group]: groupName }), {});

            return {
                ...sects,
                [key]: { name, groups, groupNames },
            };
        }, {});
    }

    cc(str: string): string {
        return camelCase(str);
    }

    getItems(sectionKey: string, groupNo: number) {
        return this.items.filter((i) => i.group === groupNo && i.sectionKey === sectionKey);
    }

    getItemRow(item: DataItem) {
        if (this.data[item.sectionKey][item.key] === undefined) {
            this.data[item.sectionKey][item.key] = [{}];
        }

        return this.data[item.sectionKey][item.key];
    }

    onCheckboxClick(item: DataItem, value: string) {
        let modelValue = this.data[item.sectionKey][item.key] || [];

        if (!!modelValue && modelValue.includes(value)) {
            modelValue = modelValue.filter((v) => v !== value);
        } else {
            modelValue = [...modelValue, value];
        }

        this.data[item.sectionKey][item.key] = modelValue;
    }

    canDisplayLabel(item: DataItem) {
        const isExistingNonEmptyString = !!item.label && isString(item.label) && !isEmpty(item.label);
        const nonDisplayTypes = [];
        let canDisplay = true;

        if (item.parent) {
            const parentData = this.data[item.sectionKey][item.parent];
            const requiredParentData = item.requireParent;
            canDisplay = isArray(parentData)
                ? parentData.includes(requiredParentData)
                : parentData === requiredParentData;
        }

        return isExistingNonEmptyString && !nonDisplayTypes.includes(item.type) && canDisplay;
    }

    isActive(item: DataItem, value: string) {
        const modelValue = this.data[item.sectionKey][item.key] as string[];
        return !!modelValue && modelValue.includes(value);
    }

    getIcon(item: DataItem, value: string) {
        return this.isActive(item, value) ? faCheckSquare : faSquare;
    }

    canDisplayItem(item: DataItem) {
        let canDisplay = true;

        if (item.parent) {
            const parentData = this.data[item.sectionKey][item.parent];
            const requiredParentData = item.requireParent;
            canDisplay = isArray(parentData)
                ? parentData.includes(requiredParentData)
                : parentData === requiredParentData;
        }

        return canDisplay;
    }

    isTicked(row: any, value: string) {
        return row[value] ? faCheckSquare : faSquare;
    }

    saveData() {
        if (this.isPatient) {
            let duplicateContact = false;

            // this.toastr.successToastr('Medical Sheet saved successfully.');
            if (this.stepper === 0) {
                const check1 = this.data["agreeToCollectionAndProcessing"];
                // if false or unanswered dont go to next page
                if (!check1) {
                    this.requiredFields = true;
                    return;
                }
                this.familyInformationCheckTable();
                this.patientMedicalHistory();
            } else if (this.stepper === 1 || this.stepper === 2) {
                duplicateContact = this.validateContacts();

                const invalid = this.validateData(this.stepper);

                // if invalid dont go to next page
                if (invalid) {
                    this.requiredFields = true;
                    window.scroll(0, 0);
                    return;
                }
            }

            if (duplicateContact) {
                window.scroll(0, 0);
                return;
            } else {
                this.loading = true;
                let dataToSubmit = this.data;

                let d = moment(this.data["date_of_birth"]);

                if (d.isValid()) {
                    dataToSubmit["date_of_birth"] = dataToSubmit["generalInformation"]["dateOfBirth"] =
                        d.format("YYYY-M-D");
                }

                this.dataService.saveChanges(dataToSubmit, this.stepper).subscribe(() => {
                    // this.toastr.successToastr('Section sheet saved!');
                    if (!!this.requiredFields) {
                        this.requiredFields = false;
                    }
                    // TODO: If last page, update completed flag
                    if (this.stepper === 18) {
                        this.toastr.successToastr("Medical Sheet saved successfully.");
                        this.toastr.successToastr("You will be redirected to your Account page in 5 seconds.");
                        setTimeout(() => {
                            this.router.navigate(["/me/account/settings"]);
                        }, 5000);
                    } else {
                        this.toastr.successToastr("Section Page Saved!");
                    }
                    this.stepUp();
                });
            }
        } else {
            this.stepUp();
        }
    }

    familyInformationCheckTable() {
        if (this.data && this.data.familyInformation.familyRelationship) {
            let familyRelationship = [];

            this.data.familyInformation.familyRelationship.forEach(function (relationship, key) {
                if (relationship instanceof Array && relationship.length <= 0) {
                    familyRelationship[key] = {
                        relationship: "",
                        primaryCaregiver: "",
                        name: "",
                        age: "",
                        educationalAttainment: "",
                        homeAddress: "",
                        occupation: "",
                        emotionalRelationship: "",
                    };
                } else {
                    familyRelationship[key] = relationship;
                }
            });

            this.data.familyInformation.familyRelationship = familyRelationship;
        }
    }

    patientMedicalHistory() {
        if (this.data && this.data.pastPsychiatricAndMedicalIllnesses) {
            let pastMedicalHistory = [];
            let patientPsychHistory = [];
            let substanceUseOrAddictionHistory = [];

            this.data.pastPsychiatricAndMedicalIllnesses.pastMedicalHistory.forEach(function (medicalHistory, key) {
                if (medicalHistory instanceof Array && medicalHistory.length <= 0) {
                    pastMedicalHistory[key] = {
                        adverseEvents: "",
                        date: "",
                        diagnosis: "",
                        hospitalOrFacility: "",
                        outcome: "",
                        treatmentOrIntervention: "",
                    };
                } else {
                    pastMedicalHistory[key] = medicalHistory;
                }
            });

            if (
                this.data.pastPsychiatricAndMedicalIllnesses.patientPsychHistory &&
                this.data.pastPsychiatricAndMedicalIllnesses.patientPsychHistory.length > 0
            )
                this.data.pastPsychiatricAndMedicalIllnesses.patientPsychHistory.forEach(function (psychHistory, key) {
                    if (psychHistory instanceof Array && psychHistory.length <= 0) {
                        patientPsychHistory[key] = {
                            date: "",
                            diagnosisOrSymptom: "",
                            healthFacilityOrHospital: "",
                            interventionDetails: "",
                            interventionType: "",
                            outcomeAdverseDrugEvent: "",
                            outcomeFunctionality: "",
                            outcomeSymptoms: "",
                            treatmentSetting: "",
                        };
                    } else {
                        patientPsychHistory[key] = psychHistory;
                    }
                });

            if (
                this.data.substanceUseAddictionHistory.substanceUseOrAddictionHistory &&
                this.data.substanceUseAddictionHistory.substanceUseOrAddictionHistory.length > 0
            )
                this.data.substanceUseAddictionHistory.substanceUseOrAddictionHistory.forEach(function (
                    substanceHistory,
                    key
                ) {
                    if (substanceHistory instanceof Array && substanceHistory.length <= 0) {
                        substanceUseOrAddictionHistory[key] = {
                            substance: "",
                            start: "",
                            stop: "",
                            frequency: "",
                            amount: "",
                            orf: "",
                            sfr: "",
                            srb: "",
                            sva: "",
                            ompi: "",
                            remarks: "",
                        };
                    } else {
                        substanceUseOrAddictionHistory[key] = substanceHistory;
                    }
                });

            if (!this.data.sourceOfReferral.otherData) {
                this.data.sourceOfReferral.otherData = [];
            }

            this.data.pastPsychiatricAndMedicalIllnesses.pastMedicalHistory = pastMedicalHistory;
            this.data.pastPsychiatricAndMedicalIllnesses.patientPsychHistory = patientPsychHistory;
            this.data.substanceUseAddictionHistory.substanceUseOrAddictionHistory = substanceUseOrAddictionHistory;
        }
    }

    stepDown() {
        const bottom = this.isPatient ? 0 : 1;
        if (this.stepper > bottom) {
            this.stepper--;
        }
    }

    stepUp() {
        if (this.stepper < this.sections.length) {
            this.stepper++;
        }
    }

    jumpToPage() {
        this.stepper = +this.jumpTo;
    }

    validateData(sectionNo) {
        let invalid = false;

        this.items.forEach((item) => {
            if (item.sectionNo === sectionNo) {
                const value = this.data[item.sectionKey][item.key];

                if (item.required && (!value || value == undefined)) {
                    invalid = true;
                } else if (item.key == "dateOfBirth") {
                    let d = moment(this.data["date_of_birth"]);
                    if (!d.isValid()) {
                        invalid = true;
                    }
                }
            }
        });
        return invalid;
    }

    validateContacts() {
        let invalid = false;

        if (
            this.data.generalInformation.contactLandLine !== null &&
            this.data.generalInformation.landlineNumber === this.data.generalInformation.contactLandLine
        ) {
            this.toastr.errorToastr(
                "Contact Person's landline should not have the same number with the Patient landline contact information!"
            );
            invalid = true;
        }

        if (
            this.data.generalInformation.contactMobileNo !== null &&
            this.data.generalInformation.mobileNumber === this.data.generalInformation.contactMobileNo
        ) {
            this.toastr.errorToastr(
                "Contact Person's mobile number should not have the same number with the Patient mobile contact information!"
            );
            invalid = true;
        }

        if (invalid) {
            window.scroll(0, 0);
            return true;
        }
    }
}
