import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersModule } from './users/users.module';
import { RolesModule } from './roles/roles.module';
import { HomeModule } from './home/home.module';
import { MedicinesModule } from './medicines/medicines.module';
import { GenericNamesModule } from './generic-names/generic-names.module';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { CompaniesModule } from './companies/companies.module';
import { MessagesModule } from './messages/messages.module';
import { OrdersModule } from './orders/orders.module';
import { FeedbacksModule } from './feedbacks/feedbacks.module';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
    imports: [
        CommonModule,
        UsersModule,
        RolesModule,
        HomeModule,
        MedicinesModule,
        GenericNamesModule,
        CompaniesModule,
        AdminRoutingModule,
        MessagesModule,
        FeedbacksModule,
        OrdersModule
    ],
    declarations: [
        AdminComponent
    ],
    exports: [
        AdminComponent,
    ],
    providers: [
        BsModalService
    ]
})
export class AdminModule { }
