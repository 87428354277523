<div class="card">
    <div class="card-header">
        <form class="form-inline">
            <span class="navbar-text mr-2">
                Showing records from:
            </span>

            <input class="form-control mr-sm-2" type="text" style="width:210px" bsDaterangepicker name="dateRange"
                [bsValue]="dateRange" (bsValueChange)="onValueChange($event)" aria-label="Search">
        </form>

    </div>
    <div class="card-body">

        <div class="row">
            <div class="col-3">
                <button *ngFor="let record of records | async" type="button"
                    class="log-date list-group-item list-group-item-action text-center p-1"
                    [class.active]="selectedRecord.value?.id===record.id" (click)="onSelectRecord(record)">
                    {{record.date | date: 'longDate'}}
                </button>
            </div>
            <div class="col-9">
                <ng-container *ngIf="!selectedRecord.value; else marsForm">

                    <div id="mars-chart">
                        <div class="side-label-wrapper">
                            <div class="side-label top">Adherent</div>
                            <div class="side-label bottom">Non-adherent</div>
                        </div>
                        <table *ngIf="chartData"
                            class="charts-css column show-data-axes data-spacing-20 show-primary-axis show-10-secondary-axes show-labels show-heading">

                            <caption> Medical Adherence Score </caption>

                            <thead>
                                <tr>
                                    <th scope="col"> Date </th>
                                    <th scope="col"> Score </th>
                                </tr>
                            </thead>
                            <tbody>

                                <tr *ngFor="let row of chartData">

                                    <th scope="row"> {{row[0]}} </th>
                                    <td [style]="calcSize(row)"> {{row[1]}}</td>
                                </tr>

                            </tbody>

                        </table>

                        <div class="primary-axis"> Date </div>
                        <div class="data-axis"> Score </div>
                    </div>
                
                </ng-container>


                <ng-template #marsForm>
                    <div class="marsFormContainer">
                        <h4 class="date">{{selectedRecord.value.date | date:'longDate'}}</h4>
                        <span *ngIf="selectedRecord.value.apptDate">
                            Appointment date: {{selectedRecord.value.apptDate | date:'longDate'}}
                        </span>
                        <span *ngIf="selectedRecord.value.bookingDate">
                            Booking date: {{selectedRecord.value.bookingDate | date:'longDate'}}
                        </span>
                        <table id="mars-form">
                            <thead>
                                <tr>
                                    <th class="questionHCell">Question</th>
                                    <th>Yes</th>
                                    <th>No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let q of questions; index as i">
                                    <td>
                                        {{i + 1}}. {{q.question}}
                                    </td>
                                    <ng-container *ngIf="q.isBooleanAnswer">
                                        <td>
                                            {{q.answer ? '✔️': ''}}
                                        </td>
                                        <td>
                                            {{q.answer ? '': '✔️'}}
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="!q.isBooleanAnswer">
                                        <td colspan="2">
                                            {{q.answer}}
                                        </td>
                                    </ng-container>
                                </tr>
                                <tr>
                                    <th style="text-align: right">
                                        Score
                                    </th>
                                    <th style="text-align: center;" scope="row" colspan="2">
                                        {{selectedRecord.value.score}}
                                    </th>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </ng-template>

            </div>
        </div>

    </div>
</div>
