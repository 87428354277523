export const instructions = [
    'Take 1/4 tablet at bedtime',
    'Take 1/4 tablet twice a day',
    'Take 1/4 tablet thrice a day',
    'Take 1/2 tablet at bedtime',
    'Take 1/2 tablet twice a day',
    'Take 1/2 tablet thrice a day',
    'Take one tablet once a day',
    'Take one tablet at bedtime',
    'Take one tablet twice a day',
    'Take one tablet thrice a day',
    'Take 1 1/2 tablet at betime',
    'Take 1 1/2 tablet once a day',
    'Take 1 1/2 tablet twice a day',
    'Take 1 1/2 table thrice a day',
    'Take 1/4 tablet as needed',
    'Take 1/2 tablet as needed',
    'Take one tablet as needed'
];

export const injectableInstructions = [
    'Inject above preparation every 14 days',
    'Inject above preparation every 28 days',
    'Inject above preparation every 30 days',
    'Inject above preparation every _ day of the month'
]
