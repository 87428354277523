import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { EventFeedbacksService } from '../../../../../services/event-feedbacks.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { MESSAGES_MODEL, ROLES_MODEL, ERRORS } from '../../feedbacks.model';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent extends CrudEditComponent implements OnInit {

  formRoles: FormGroup;
  errors = ERRORS;
  message: string;
  from: string;
  name: string;
  phone: string;
  href: string;

  public vcr: any;

  constructor(
    injector: Injector,
    vcr: ViewContainerRef,
    private activeRoute: ActivatedRoute
  ) {
    super(injector);
    this.service = injector.get(EventFeedbacksService);
    this.setSettings(settings);
    this.vcr = vcr;
  }

  ngOnInit() {
    const routeParams = this.activeRoute.snapshot.params;
    this.rForm = this.formBuilder.group(MESSAGES_MODEL, { updateOn: 'blur' });
    this.service.getFeedback(routeParams.id).subscribe((result: any) => {
      this.message = result.data.comment;
      this.name = result.data.user.first_name+" "+result.data.user.last_name;
      this.from = result.data.user.email;
      this.phone = result.data.user.general_information.phone;
      this.href = 'mailto:'+result.data.user.email;
    });
  }
}
