import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "../abstract.service";
import { map, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { formatDate } from "../helpers";
import moment from "moment";
import { isNumber } from "lodash";

export interface RawQuestion {
    id: number;
    answer: number | string;
    question: string;
}

export class MARSQuestion {
    id: number;
    answer: boolean | string;
    question: string;
    isBooleanAnswer: boolean;
    constructor(raw: RawQuestion) {
        const { answer, id, question } = raw;
        this.id = id;
        if (isNumber(answer)) {
            this.isBooleanAnswer = true;
            this.answer = Boolean(answer);
            this.question = question;
        } else {
            this.isBooleanAnswer = false;
            this.answer = `${raw.answer}`;
            this.question = raw["questions"];
        }
    }
}

export class MARSRecord {
    id: number;
    date: Date;
    score: number;
    isCompleted?: boolean;
    booking_id: number;
    bookingDate: Date;
    apptDate: Date;
    constructor(raw: ResponseDataRaw) {
        this.id = raw.id;
        this.date = moment(raw.created_at).toDate();
        this.score = raw.total_score;
        this.isCompleted = Boolean(raw.is_completed);
        this.booking_id = raw.booking_id;
        // this.apptDate = new Date();
        // this.bookingDate = new Date();
    }
}

export interface ResponseDataRaw {
    created_at: string; // "2021-11-12 15:54:29",
    id: number; // 5,
    is_completed: boolean; // 1,
    total_score: number; // 4,
    booking_id: number;
}

@Injectable()
export class OtsukaMARSService extends AbstractService {
    endpoint = environment.serverUrl + "otsuka-mars";

    headers = {
        "Access-Control-Allow-Origin": "*",
        Accept: environment.API_HEADER_ACCEPT_v2,
    };

    constructor(public http: HttpClient) {
        super(http);
    }

    getScores(patient_id: number, dateRange?: [Date, Date]) {
        const headers = { ...this.headers };

        let filter = "";
        if (dateRange && dateRange[0] && dateRange[1]) {
            const from = formatDate(dateRange[0]).toString();
            const to = formatDate(dateRange[1]).toString();
            filter = `?dateRange=start_at:${from} 00/00/00:>=|start_at:${to} 23/59/59:<=`;
        }
        return this.http
            .post(this.endpoint + `/scores` + filter, { patient_id }, { headers })
            .pipe(map((response: { data: ResponseDataRaw[] }) => response.data.map((el) => new MARSRecord(el))));
    }

    getDetails(record: MARSRecord): Observable<MARSQuestion[]> {
        const { booking_id } = record;
        const headers = { ...this.headers };
        return this.http
            .post(this.endpoint + `/px-answers`, { booking_id }, { headers })
            .pipe(map((response: { data: RawQuestion[] }) => response.data.map((el) => new MARSQuestion(el))));
    }
}
