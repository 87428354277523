import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@angular/http';

import { UsersComponent } from './users.component';
import { UserRoutingModule } from './users-routing.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxErrorsModule } from '@ultimate/ngxerrors';


import { UserService } from '../../../services/user.service';
import { RoleService } from '../../../services/role.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CrudModule } from '../../../core/base/crud';

import { ListComponent, EditComponent, CreateComponent } from './crud';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    UserRoutingModule,
    NgxDatatableModule,
    NgxErrorsModule,
    NgbModule,
    HttpModule,
    CrudModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    UsersComponent,
    ListComponent,
    EditComponent,
    CreateComponent,
  ],
  exports: [],
  providers: [
    UserService,
    RoleService,
    BsModalService,
  ]
})
export class UsersModule { }
