import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Event, NavigationStart, NavigationError } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { mergeMap } from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Logger } from './core/logger.service';
import { I18nService } from './core/i18n.service';
import { UserService } from './services'

import { ToastrManager } from 'ng6-toastr-notifications';

const log = new Logger('App');

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public OtsukaFormTrigger = false
    public showPageNavigationProgressBar = false

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private toastr: ToastrManager,
        vRef: ViewContainerRef,
        private userService: UserService
    ) {


        // this.toastr.setRootViewContainerRef(vRef);
        this.router.events.subscribe((event: Event) => {
            // console.log(event)
            if (event instanceof NavigationStart) {
                this.showPageNavigationProgressBar = true
                // Show loading indicator
                // console.log('NavigationStart',  event)
            }

            if (event instanceof NavigationEnd) {
                // Hide loading indicator
                this.showPageNavigationProgressBar = false

                // let services = this.userService._srvcs.getValue();
                // if (services && services['_otsuka']) {
                //     console.log('here', services['_otsuka'].pop)
                //     this.OtsukaFormTrigger = false;
                //     this.OtsukaFormTrigger = services['_otsuka'].pop
                // }

                // console.log('NavigationEnd', event, this.userService._srvcs.getValue())
            }

            if (event instanceof NavigationError) {
                // Hide loading indicator

                // Present error to user
                // console.log(event.error);
                // console.log('NavigationError',  event)
            }
        });
    }

    OtsukaTrigger = (status) => {
        this.OtsukaFormTrigger = status
    }

    ngOnInit() {
        
        this.userService.me().subscribe()
        

        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        log.debug('init');

        // Setup translations
        this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

        const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                const title = event['title'];
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });
    }

}
