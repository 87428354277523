<div class="row">
    <div class="col d-flex">
        <span class="h3">Doctor Clinic Hours</span>
        <button class="btn btn-outline-info pull-right rounded-circle ml-auto"
            (click)="calendarComponent.getApi().refetchEvents()">
            <fa-icon [icon]="fa.faSync"></fa-icon>
        </button>
    </div>
</div>

<hr>
<div class="card calendar-card">
    <div class="card-body">
        <!-- <div
            class="overlay"
            [ngClass]="{'show': calendarLoading}"
        ></div> -->
        <div class="row mb-1">
            <!-- 
            <div class="col">
                <span class="small text-danger">
                    We detected your device's timezone
                    is set
                    <strong>{{computerTimezone}}</strong>
                </span>
                <br />
                You are viewing this calendar in
                <input
                    [(ngModel)]="timezone"
                    (typeaheadOnSelect)="calendarComponent.getApi().refetchEvents()"
                    [typeahead]="timezones"
                    [ngbTypeahead]="searchTimezone"
                    #instance="ngbTypeahead"
                    (focus)="focus$.next($any($event).target.value)"
                    (click)="click$.next($any($event).target.value)"
                    />
                timezone
            </div> -->
        </div>
        <div class="alert alert-primary text-center" role="alert">
            <strong>
                Dates and times displayed in the calendar are based on <em>Philippine
                    Standard Time</em>.
                If you are located in a different timezone please use this <a
                    href="https://dateful.com/time-zone-converter"
                    target="_new">tool</a>.
            </strong>
        </div>
        <div class="alert alert-warning small">
            You are viewing this calendar in <strong>Asia/Manila</strong>
            timezone
        </div>
        <full-calendar [options]="calendarOptions" #calendar></full-calendar>
        <!-- <app-calendar [options]="calendarOptionst" #calendar></app-calendar> -->
    </div>
</div>

<ng-template #eventDetailsTemplate let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title">
            {{selectedEvent? (selectedEvent.start_at.date | date:'MMMM d, y') +
            ' (' +
            selectedEvent.status + ')' : ''}}
            <span class="small" *ngIf="selectedEvent">
                ID: {{selectedEvent.timeslot_id }}
            </span>
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <!-- <div class="modal-body text-center m-5" *ngIf="loading">
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i> LOADING
    </div> -->
    <div class="modal-body" *ngIf="!confirm">

        <div *ngIf="selectedEvent && !selectedEvent.canEdit &&
            !!selectedEvent.patient">
            <div class="col-md-12 text-center lead">Booked by <strong>{{selectedEvent.patient.first_name}}
                    {{selectedEvent.patient.last_name}}</strong></div>
        </div>


        <div *ngIf="selectedEvent && !selectedEvent.canEdit &&
            !selectedEvent.patient">
            <div class="col-md-12 text-center lead">Booked by a patient</div>
        </div>

        <!-- ADD SLOT -->
        <div *ngIf="selectedEvent && selectedEvent.canEdit || addEvent">
            <p class="lead text-center" *ngIf="!selectedEvent">
                Open a slot for <strong>{{getDatePicked()}}</strong>
            </p>

            <!-- <p class="lead text-center" *ngIf="selectedEvent">
                {{selectedEvent.timeslot_id }}
            </p> -->
            <app-slot [datePicked]="datePicked" [actionMode]="actionMode"
                [slotDetails]="slotDetails"
                [selectedEvent]="selectedEvent" [savingEvent]="savingEvent"
                (saveEvent)="saveEventV2($event)"
                (deleteEvent)="deleteEvent($event)"
                (slotDetailsChange)="slotDetailsChange($event)"
                [rateCharge]="rateCharge"></app-slot>


        </div>
    </div>
    <div class="modal-body" *ngIf="confirm && !loading">
        <p class="h5 text-center">Are you sure you want to delete the event?</p>
    </div>
    <div class="modal-footer d-none">
        <div *ngIf="selectedEvent && selectedEvent.canEdit && !confirm">
            <button type="button" class="btn btn-outline-danger mr-2"
                (click)="confirm=true" [disabled]="loading"
                *ngIf="selectedEvent && selectedEvent.canDelete">Remove</button>
            <button type="button" class="btn btn-primary"
                (click)="updateEvent()" *ngIf="selectedEvent"
                [disabled]="loading">Update</button>
        </div>
        <div *ngIf="selectedEvent && confirm">
            <button type="button" class="btn btn-outline-danger mr-2"
                (click)="deleteEvent()" [disabled]="loading"
                *ngIf="selectedEvent">Yes</button>
            <button type="button" class="btn btn-outline-dark"
                (click)="confirm=false" [disabled]="loading"
                *ngIf="selectedEvent">No</button>
        </div>
        <button type="button" class="btn btn-primary mr-2" (click)="saveEvent()"
            [disabled]="loading"
            *ngIf="!selectedEvent" [disabled]="startDateValidity() ||
            endDateValidity()">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="c()"
            [disabled]="loading"
            *ngIf="!confirm">Close</button>
    </div>
</ng-template>




<ng-template #confirmUpdate let-c="close" let-d="dismiss">
    <div class="modal-header">
        <div class="modal-title">Confirm Schedule Change</div>
        <button type="button" class="close" aria-label="Close" (click)="d()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>By pressing "Yes", you confirm the following:</p>
        <ul class="small">
            <li>This change of schedule has the consent of the patient</li>
        </ul>

        <p>Do you want to confirm?</p>
        <div class="text-center">
            <button type="button" class="btn btn-default mr-2"
                (click)="confirmUpdateConfirm()">Yes</button>
            <button type="button" class="btn btn-primary"
                (click)="confirmDecline()">No</button>
        </div>
    </div>
</ng-template>