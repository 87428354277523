import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { camelCase } from 'lodash';
import { UserService } from './user.service';
import { MentalHealthSheetServiceV2 } from './v2/mental-health-sheet-service';
import { SECTION_KEYS } from '../shared/components/data-sheet/data-sheet';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class MentalHealthSheetService {
    private url = environment.serverUrl + 'mental-health-data';

    mentalHealthData = new Subject<any>();
    isMentalDataComplete: any;
    private recordId: number;
    private userId: number;


    constructor(
        private http: HttpClient,
        private userService: UserService,
        private mentalHealthServiceV2: MentalHealthSheetServiceV2
        ) {
        this.userId = this.userService.currentUser.id;
    }

    public saveChanges(data: any, section: number) {
        if (this.recordId !== undefined) {

            // if(section === 1) {
            //     const body = {
            //         mentalHealthDataId: this.recordId,
            //         fields: data[SECTION_KEYS[section]] 
            //     }

            //     return this.mentalHealthServiceV2.update(body, section).pipe(switchMap(() => this.get()))
            // }

            return this.update(data);
        } else {
            return this.create(data);
        }

    }

    public get(id?: number): Observable<any> {
        return this.http.get(this.url + '/user/' + (id ? id : this.userId))
            .map((response: any) => response.data)
            .map((data: any) => {
                this.mentalHealthData.next(data.patient_data);
                this.recordId = data.id;
                this.isMentalDataComplete = data.isMentalDataComplete;
                return data;
            });
    }

    public getUserData(id: any){
        return this.http.get(this.url + '/user/' + id)
            .map((response: any) => response.data);
    }

    private update(body: any) {
        return this.http.put(`${this.url}/${this.recordId}`, body)
            .map((response: any) => response.data)
            .map((data: any) => {
                this.mentalHealthData.next(data.patient_data);
                this.recordId = data.id;
                this.isMentalDataComplete = data.isMentalDataComplete;
                return data;
            });
    }

    private create(body: any) {
        return this.http.post(this.url, body)
            .map((response: any) => response.data)
            .map((data: any) => {
                this.mentalHealthData.next(data.patient_data);
                this.recordId = data.id;
                this.isMentalDataComplete = data.isMentalDataComplete;
                return data;
            });
    }
}
