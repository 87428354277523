<!-- <div class="row">
    <div class="col">
        <span class="h3">Therapists</span>
    </div>
</div>
<hr> -->

<div class="card">
    <div class="card-header">
        <nav class="navbar navbar-light d-flex align-items-center filter-block">

            <div class="form-group col-sm-12 col-md-3" style="display: flex; flex-direction: inherit;">
            <form class="form-inline">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle>Sort:
                        {{sortType.getValue() === 0 ? 'Alphabetical' : 'Most Recent'}} </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        Alphabetical
                        <!-- <button class="dropdown-item" 
                            (click)="sortType.next(0)"
                        >Alphabetical</button> -->
                        <button class="dropdown-item" 
                            (click)="sortAlphabetical()"
                        >Alphabetical</button>
                        <!-- <button class="dropdown-item" 
                            (click)="sortMostRecent()"
                        >Most Recent</button> -->
                    </div>
                </div>
            </form>
            <form style="padding-left:10px;" class="form-inline ml-1">
                <button class="btn btn-outline-secondary" (click)="setSort()">
                    <fa-icon [icon]="sortClass"></fa-icon>
                </button>

                <label 
                    class="form-check-inline small" 
                    [class.disabled]="filterMyTherapistsLoading"
                    *ngIf="role.patient"
                >
                    <input type="checkbox" id="inlineCheckbox1" value="option1"  
                        (click)="doFilterMyTherapists()" 
                        [disabled]="filterMyTherapistsLoading"> Show my therapists only
                </label>
            </form>
            </div>

            <div class="form-group col-sm-12 col-md-3">
                <div class="input-group">
                <input type="text" class="form-control  mr-sm-2" placeholder="Search" (keyup)="onKeySearch($event)" value="{{searchTerm}}">
                <button class="btn btn-outline-success my-2 my-sm-0" type="button" (click)="startSearch()">Search</button>
            </div>

    
            </div>


        </nav>
    </div>

    <ul class="list-group" *ngIf="currentList && currentList.length > 0; else emptyRecord">
        <li class="list-group-item" *ngFor="let row of currentList">
            <!-- <img src="{{row.picture || defaultPicture }}" class="rounded-circle bg-secondary float-left my-2 mx-3"
                 alt="..."> -->
            <img src="{{avatar(300, row.avatar) }}" class="rounded-circle bg-secondary float-left my-2 mx-3"
                 alt="...">
            <div class="d-flex justify-content-between align-items-center m-2">
                <h5 class="ml-3 mr-auto list-name">
                    {{row.first_name}} {{row.last_name}}<span *ngIf="isPsychiatrist(row.general_information) && this.isPatient">, MD</span><br>
                    
                    <span *ngIf="role == 'patient'">
                        
                        <strong class="bold">{{row.title || 'Psychiatrist'}}</strong> <br>

                        <span *ngIf="row.general_information && row.general_information.fellowOrDiplomate">
                            <strong *ngIf="row.general_information.fellowOrDiplomate == 'fellow'" >
                                Fellow Philippine Psychiatric Association
                            </strong>

                            <strong *ngIf="row.general_information.fellowOrDiplomate == 'diplomate'" >
                                Diplomate Philippine Psychiatric Association
                            </strong>
                            
                            <strong *ngIf="row.general_information.fellowOrDiplomate == 'associate member'" >
                                Associate Member
                            </strong>

                            <br>
                        </span>

                        <strong *ngIf="row.general_information && row.general_information.specialty && row.general_information.specialty != 'Undefined'" >
                            {{ row.general_information.specialty }}
                        </strong>

                    </span>
                </h5>
                <a *ngIf="isPatient" class="btn btn-primary" [routerLink]="['/me/psychiatrists', row.id]" role="button">
                    View <span>Availability</span>
                </a>

                <a *ngIf="!isPatient" class="btn btn-primary" [routerLink]="['/me/secretary/patients', row.id]" role="button">
                    View <span>Details</span>
                </a>
            </div>
        </li>
    </ul>

    <ng-template #emptyRecord>
        <p class="text-center" style="padding-top:10px;"><i>No Results to Display</i></p>
    </ng-template>

        
   <pagination
        class="d-flex justify-content-center p-2"
        *ngIf="total > perPage"
        [totalItems]="total"
        [itemsPerPage]="perPage"
        [maxSize]="5" 
        [boundaryLinks]="true"
        (pageChanged)="pageChanged($event)"
        [(ngModel)]="currentPage">        
    </pagination>
</div>
