import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'section-patient-instructions',
    templateUrl: './patient-instructions.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionPatientInstructionsComponent implements OnChanges {
    @Input() data: object
    @Input() eventId

    match = true
    title = 'Patient Instructions';
    value = '<em class="text-muted">Not specified</em>'

    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private noteService: NotesService
    ) {}

    downloadPDF = () => {
        this.noteService.genReport('px-instructions', this.eventId)
        .subscribe(
            response => downloadPDFFile(response),
            e => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        
        if (
            changes.data && 
            changes.data.currentValue && 
            changes.data.currentValue.notes &&
            typeof changes.data.currentValue.notes.patientInstructions === 'string'
        ) {
            if (changes.data.currentValue.notes.patientInstructions.length) {
                this.value = changes.data.currentValue.notes.patientInstructions
            }
        }
    }

}
