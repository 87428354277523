import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { ClinicService } from '../../../../../services/clinic.service'
import { UserService } from '../../../../../services/user.service'

@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss'],
})

export class ClinicComponent implements OnInit, OnChanges {

  @Input() slotDetails
  @Output() selectClinic: EventEmitter<any> = new EventEmitter()
  @Output() panelToOpen: EventEmitter<any> = new EventEmitter()

  public user = null
  public clinicList = []
  public isLoading: boolean = false

  selectedClinic = null

  _selectClinic = (clinic_id, clinic_name) => {
    this.selectedClinic = clinic_id
    this.selectClinic.emit({ clinic_id, clinic_name })
  }


  getList = () => {
    this.isLoading = true
    this.clincService.getByDoctorId(
      this.user.id,
      { search: 'status:1', orderBy: 'name', sortedBy: 'asc' }
    )
      .subscribe(
        res => {
          this.clinicList = res.data
          this.isLoading = false
        },
        error => {
          this.isLoading = false
        }
      )
  }


  ngOnInit() {

    this.getList()
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slotDetails) {
      // console.log('duration', changes.slotDetails.currentValue.duration, this.slotDetails, changes.slotDetails)


    }
  }

  constructor(
    private clincService: ClinicService,
    private userService: UserService
  ) {

    this.user = this.userService.getStoredCredentials()

  }
}
