<table style="width: 100%;height: 100%;">
    <tbody>
        <tr style="height: 90%;">
            <td style="height:100%;">
                <!-- class="d-flex flex-column" style="flex-grow: 3; overflow-y:auto;" -->
                <div 
                    #scrollme 
                    [scrollTop]="scrollme.scrollHeight" 
                    style="
                        height:inherit;
                        overflow: auto;
                    "
                >   
                    <div *ngFor="let o of messages" class="bubble rounded mt-1 p-1 pr-3" [ngClass]="o.isSender? 'bg-rcvr ml-4' : 'mr-4'">
                        <div class="body inline p-1 px-2 rounded" >
                            <!-- <small class="username">{{o.isSender? 'You' : o.senderName}}</small> -->
                            <div class="content">
                                {{_.unescape(o.message)}}
                            </div>
                        </div>
                    </div>
                </div>

            </td>
        </tr>
            <tr>
                <td>
                <div class="sending small text-right">
                    <i *ngIf="sending">Sending message...</i>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <textarea 
                            class="form-control mb-1" 
                            #message 
                            (keyup.enter)="onEnter(message.value);message.value=''" 
                            rows="2"
                            placeholder="Enter message here..."></textarea>
                        <button 
                            type="button" 
                            class="btn btn-primary w-100"
                            (click)="onEnter(message.value);message.value=''" 
                            [disabled]="sending"
                        >
                            <span *ngIf="sending">Sending ...</span>
                            <span *ngIf="!sending">Send</span>
                        </button>
                    </div>
                </div>

            </td>
        </tr>
        
    </tbody>
</table>

