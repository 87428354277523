import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import * as moment from "moment";

@Injectable()
export class FeedbackService extends AbstractService {
    baseUrl = environment.serverUrl + "event-feedbacks";
    endpoint = environment.serverUrl + "event-feedbacks";

    constructor(http: HttpClient) {
        super(http);
    }

    check = (event_id) => {
        return this.http.post(this.baseUrl + "/check", { event_id });
    };

    savePatientFeedback(eventId: any, userId: any, comment: any, rating: number) {
        const data = { eventId, userId, comment, rating };
        return this.http.post(this.baseUrl, data);
    }

    sendMedicalPrescription(eventId: any, userId: any) {
        const data = { eventId, userId, type: "prescription" };
        return this.http.post(this.baseUrl + "/send-medication", data);
    }

    saveDoctorFeedback(eventId: any, userId: any, comment: any) {
        const data = { eventId, userId, ...comment };
        return this.http.post(this.baseUrl, data);
    }

    getPatientFeedback(eventId: any) {
        return this.http.get(this.baseUrl + `/${eventId}/patient`);
    }

    getDoctorFeedback(eventId: any) {
        return this.http.get(this.baseUrl + `/${eventId}/medical-practitioner`);
    }
}
