<crud-header [settings]="settings" [action]="'list'" (refreshList)="refreshList()"></crud-header>
Companies table here
<!-- <div>

    <ngx-datatable [rows]="rows" [columns]="columns" [columnMode]="'force'" [footerHeight]="30" [rowHeight]="'auto'" [externalPaging]="true"
        [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)='handlePager($event)' (sort)="handleSort($event)"
        [loadingIndicator]="loading" class="small material">
        <ngx-datatable-column name="ID" props="id">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort($event)">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" props="name">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.first_name}} {{row.last_name}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Roles" props="roles">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span *ngFor="let role of value.data" class="badge badge-pill badge-primary" style="margin-right:2px">{{ role.name }}</span>

            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" props="status">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="badge badge-pill badge-default" *ngIf="value!=''" [class.badge-success]="value=='active'" [class.badge-danger]="value=='inactive'"
                    [class.badge-warning]="value=='suspended' || value=='banned'">{{ value | uppercase }}</span>

                <span class="badge badge-pill badge-warning" *ngIf="value==''">NOT SET</span>
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="Date Joined" props="date_joined">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ row.date_joined }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions" sortable="false" prop="id">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                {{column.name}}
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <a *ngIf="settings.crud.update.allow" [routerLink]="getEditLink(row)">
                    <i class="fa fa-pencil text-warning"></i>
                </a>
                <a *ngIf="settings.crud.delete.allow" href="javascript:void(0)" (click)="onDeleteRow(row)">
                    <i class="fa fa-trash text-danger"></i>
                </a>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div> -->
