import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';



@Component({
    selector: 'section-next-meeting',
    templateUrl: './next-meeting.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionNextMeetingComponent implements OnChanges {
    @Input() data: object

    match = true
    title = 'Schedule for Next Meeting'
    value = '<em class="text-muted">Not specified</em>'

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue && 
            changes.data.currentValue.notes && 
            typeof changes.data.currentValue.notes.nextMeeting === 'string'
        ) {
            if (changes.data.currentValue.notes.nextMeeting.length) {
                this.value = changes.data.currentValue.notes.nextMeeting
            }
        }
    }

}
