import { 
    Component, Injector, Input, 
    OnChanges, SimpleChanges, Output, 
    EventEmitter, ElementRef, ViewChild 
} from '@angular/core';
import { DiagnosisDSM5Service } from '../../services';

@Component({
    selector: 'app-dsm5-dropdown',
    templateUrl: './dsm5-dropdown.component.html',
    styleUrls: ['./dsm5-dropdown.component.scss']
})
export class DSM5Component implements OnChanges {
    @Input() list: any
    @Input() loading: boolean = false
    @Input() dsm
    @Input() displayOthers
    @Input() defaultValue
    @Output() selectedValue = new EventEmitter()
    @ViewChild('custom') custom: ElementRef

    dSM5Service: any
    isCustom = false
    default = null

    constructor(
        injector: Injector
    ) {
        this.dSM5Service = injector.get(DiagnosisDSM5Service)
    }

    selectValue(id) {
        console.log('selectedValue', this.list, id)
        if (id == 0) {
            console.log('this.custom', this.custom)
            this.selectedValue.emit({
                id: null,
                name: 'a',
            })
        } else {
            this.list.forEach((value, key) => {
                if(value.id == id) {
                    console.log('this', value)
                    this.selectedValue.emit(value)
                }
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.loading){
            this.loading = changes.loading.currentValue
        }

        if (changes.dsm) {
            console.log(changes.dsm)
        }

        if (changes.defaultValue && typeof changes.defaultValue.currentValue === 'number') {
            console.log('this.selectValue(this.default)')
            this.default = changes.defaultValue.currentValue
            this.selectValue(this.default)
        }

        console.log(changes.list);

        // this.doSomething(changes.categoryId.currentValue);
        // You can also use categoryId.previousValue and 
        // categoryId.firstChange for comparing old and new values

    }

}
