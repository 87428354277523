<div class="row login-container d-flex justify-content-center align-items-center bg-light m-0 p-0" style="height: 99vh">
  <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 login-con" >
    <div class="text-center my-3">
        <a [routerLink]="['/me']">
            <img src="./assets/images/logo.png" style="width:330px;">
        </a>
    </div>
    <div class="row  m-0">
      <div class="col-12 col-lg-10 col-xl-10 m-auto" >
        <div class="card bg-light border-0">
          <div class="card-body p-4">
            <h3 class="text-center">Reset Password</h3>
            <hr />

            <div class="p-3" *ngIf="!hasParams && !emailSent">
              <div class="card-title text-center">Enter your email address and we'll send you a password reset email</div>
              <form #rf1="ngForm">
                <div class="form-group mt-3">
                  <input
                    type="email"
                    name="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    required class="form-control"
                    aria-describedby="emailHelp"
                    placeholder="Enter email" ngModel>
                </div>
                <button type="button" class="btn btn-primary btn-block" (click)="requestReset(rf1.value)" [disabled]="!rf1.valid">Send</button>
              </form>
              <div class="mt-3">
                <a routerLink="/login">
                  <i class="fa fa-long-arrow-left"></i> Back to Login
                </a>
              </div>
              <div>
                <a routerLink="/register">
                  <i class="fa fa-long-arrow-left"></i> Patient Sign Up
                </a>
              </div>
              
            </div>
            <div class="p-3" *ngIf="!hasParams && emailSent">
                <h5 class="card-title text-center">A password reset link has been sent to {{email || 'your email'}}</h5>
            </div>
            <div class="p-3" *ngIf="hasParams && !hasReset">
                <h5 class="card-title text-center mb-3">Please enter your new password</h5>
                <form #rf2="ngForm">
                    <div class="form-group">
                      <input type="password" required class="form-control"  name="password"placeholder="Enter password" ngModel>
                    </div>
                    <div class="form-group">
                      <input type="password" required  name="password_confirmation" class="form-control" placeholder="Re-enter password" ngModel>
                    </div>
                    <button type="button" class="btn btn-primary btn-block" (click)="resetPassword(rf2.value)" [disabled]="!rf2.valid">Send</button>
                </form>
            </div>
            <div class="p-3" *ngIf="hasParams && hasReset">
                <h5 class="card-title text-center mb-3">Your password has been reset.</h5>
                <h6 class="text-center"><a href="/login">Click here to login</a></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="colophon py-4 text-white">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="copyright">
        <small>Copyright &copy;  {{year|date:'y'}} Metro Psych Facility. All Rights Reserved.</small>
      </div>
      <div class="footer-links">
        <small><a class="text-white" href="../../../../assets/docs/TermsAndConditions.pdf" target="_blank"><u>Terms and Conditions</u></a></small> |
        <small><a class="text-white" href="../../../../assets/docs/PrivacyPolicy.pdf" target="_blank"><u>Privacy Policy</u></a></small>
      </div>
    </div>
  </div>
</div>