import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateComponent } from './crud/create/create.component';
import { EditComponent } from './crud/edit/edit.component';
import { ListComponent } from './crud/list/list.component';
import { GenericNamesRoutingModule } from './generic-names-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpModule } from '@angular/http';
import { CrudModule } from '../../../core/base/crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericNamesService } from '../../../services/generic-names.service';
import { GenericNamesComponent } from './generic-names.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    GenericNamesRoutingModule,
    NgxDatatableModule,
    NgxErrorsModule,
    NgbModule,
    HttpModule,
    CrudModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CreateComponent,
    EditComponent,
    ListComponent,
    GenericNamesComponent
  ],
  providers: [
    GenericNamesService,
    BsModalService,
  ]
})
export class GenericNamesModule { }
