<div *ngIf="match">
    <h6 class="text-muted">
        {{title}}
        <span
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor() && !iAmPsychologist()">
            <fa-icon
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"></fa-icon>
        </span>
        <span
            class="d-print-none badge badge-dark cta mr-2"
            (click)="redirectToConsultationNote()"

            *ngIf="iAmDoctor()">
            <fa-icon
                [icon]="fa.faPencilAlt"
                ngbTooltip="Click to edit"></fa-icon>
        </span>
        <span
            class="d-print-none badge badge-dark cta mr-2"
            (click)="openModal(emailModal)"
            *ngIf="iAmDoctor() && !iAmPsychologist()">
            <!-- (click)="sendEmail()" -->
            <fa-icon
                [icon]="fa.faPaperPlane"
                ngbTooltip="Email to patient"></fa-icon>
        </span>
    </h6>
    <div *ngIf="typeOf(value) == 'object' && value.length">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th width="5px">#</th>
                    <th width="120px">Generic</th>
                    <th width="120px">Brand</th>
                    <th width="120px">Dosage</th>
                    <th width="120px">Quantity</th>
                    <th>Instructions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let medication of value ; index as i">
                    <td class="p-1 text-center">
                        <pre [innerHTML]="i+1"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="medication.generic_name"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="medication.brand_name"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="medication.dosage"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="medication.qty_tabs_dispensed"></pre>
                    </td>
                    <td class="p-1">
                        <pre [innerHTML]="medication.instructions"></pre>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div *ngIf="typeOf(value) == 'string' || (typeOf(value) == 'object' &&
        !value.length)">
        <pre [innerHTML]="value"></pre>
    </div>
</div>



<ng-template #emailModal>
    <div class="modal-body text-center">
        <section-prescription-email
            (modalDecline)="modalDecline()"
            [eventId]="eventId"
            [title]="title"></section-prescription-email>
    </div>
</ng-template>
