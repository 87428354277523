import { VideoCallComponent } from './video-call.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { VideoCallV2Component } from './video-call-v2.component';
// import { MedicationsComponent } from './medications/medications.component';

const routes: Routes = [
    {
        path: 'call/:sessionId',
        component: VideoCallComponent,
    },
    {
        path: 'call-v2/:sessionId',
        component: VideoCallV2Component
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class VideoCallRoutingModule {}
