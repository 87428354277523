import { Component, Injector, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { OtsukaService } from './service'
import { UserService } from '../../services'


@Component({
    selector: 'app-otsuka-survey-form',
    templateUrl: './otsuka-survey-form.component.html',
    styleUrls: ['./otsuka-survey-form.component.scss']
})
export class OtsukaSurveyFormComponent implements OnChanges {
    @Input() trigger: any
    @ViewChild('thankYou') mentalNotify: ElementRef;
    @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;

    modalRef?: BsModalRef;

    public isModalShown = false
    public showThankYouModal = false
    private bookingId = null
    public loadingQuestions = false
    public processingAnswer = false
    public medications = ''
    public medicationRequiredError = false

    questions = [];

    constructor(
        public service: OtsukaService,
        private userService: UserService
    ) {

        userService._srvcs
            .subscribe(
                services => {
                    // console.log('trig ots service', services)
                    if (
                        services &&
                        services['_otsuka'] &&
                        services['_otsuka'].pop &&
                        services['_otsuka'].bid
                    ) {
                        // this.OtsukaFormTrigger = services['_otsuka'].pop
                        this.bookingId = services['_otsuka'].bid
                        if (!this.isModalShown) {
                            this.getQuestions()
                            this.showModal()
                        }
                    } else {
                        this.hideModal()
                    }
                }
            )
    }

    showModal(): void {
        // TRIGGER modal only if there is a flag from backend
        this.isModalShown = true;
    }

    hideModal(): void {
        this.autoShownModal?.hide();
        // this.OtsukaTrigger.emit(false)
    }

    onHidden(): void {
        this.isModalShown = false;
    }


    setAnswer = (answer, question_id) => {
        this.processingAnswer = true

        this.medicationRequiredError = false

        if (!this.medications.length) {
            this.medicationRequiredError = true
        }

        this.service.setAnswer({
            booking_id: this.bookingId,
            question_id,
            answer,
        }).subscribe(
            response => {
                // console.log(response)
                this.processingAnswer = false
                let srvcs = this.userService._srvcs.getValue()
                srvcs['_otsuka'] = { ...srvcs['_otsuka'], ...response.data }
                // this.userService._srvcs.next(srvcs)

                // if (!response.data.pop) {
                //     this.showThankYouModal = true
                // }

                this.getQuestions()
            },
            error => {
                console.log(error)
                this.processingAnswer = false
            }
        )
    }

    submit =  ()  => {
        this.medicationRequiredError = false

        if (!this.medications.length) {
            this.medicationRequiredError = true
        } else {
            this.processingAnswer = true
            this.service.setAnswer({
                booking_id: this.bookingId,
                question_id: 'medications',
                answer: this.medications,
            }).subscribe(
                response => {
                    // console.log(response)
                    this.processingAnswer = false
                    let srvcs = this.userService._srvcs.getValue()
                    srvcs['_otsuka'] = { ...srvcs['_otsuka'], ...response.data }
                    this.userService._srvcs.next(srvcs)
                    
                    if (!response.data.pop && response.data.is_completed) {
                        this.hideModal()
                        this.showThankYouModal = true
                    } else {
                        this.getQuestions()
                    }
                },
                error => {
                    console.log(error)
                    this.processingAnswer = false
                }
            )
            }
    }

    getQuestions = () => {
        this.loadingQuestions = true
        let user = this.userService.getStoredCredentials()
        this.service.getPxQuestions(user.id, this.bookingId)
            .subscribe(
                result => {                    
                    this.medications = result.data.pop()
                    this.medications = this.medications['answer']
                    this.questions = result.data
                    this.loadingQuestions = false
                },

                error => {
                    this.loadingQuestions = false
                }
            )
    }

    setMedication = (e) => {
        console.log('setM', e.target.value)

        this.medications = e.target.value
    }

    closeThankYou = () => {
        this.showThankYouModal = false
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.trigger) {
            // console.log('changes.trigger.currentValue', changes.trigger.currentValue)
            if (changes.trigger.currentValue) {
                // this.showModal()
            }
        }
    }

}
