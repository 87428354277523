export class Medication {
    public generic: string;
    public brand: string;
    public generic_name: string;
    public brand_name: string;
    public dosage: string;
    public tabsDispensed: string;
    public instruction: string;
    public expiration: string;
    public id?: any;
    public is_s2: any = null;
    public is_injectable: any = null;
    public medicine_id: any = null;
    public is_deleted: boolean = false;
    public is_custom: boolean = false;
    public from_custom_table: boolean = false;

    constructor(
        generic: string,
        brand: string,
        dosage: string,
        tabsDispensed: string,
        instruction: string,
        expiration: any,
        id: any,
        is_s2?: any,
        is_injectable?: any,
        medicine_id?: any,
        is_deleted?: boolean,
        is_custom?: boolean,
        from_custom_table?: boolean
    ) {
        this.generic = generic;
        this.brand = brand;
        this.dosage = dosage;
        this.tabsDispensed = tabsDispensed;
        this.instruction = instruction;
        this.expiration = expiration;
        this.id = id;
        this.is_s2 = is_s2;
        this.is_injectable =is_injectable;
        this.medicine_id = medicine_id;
        this.is_deleted = is_deleted;
        this.is_custom = is_custom;
        this.from_custom_table = from_custom_table;
    }
}
