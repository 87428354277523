<!--FOR PRINTING  -->
<div *ngIf="(viewEvent && viewEvent.notes && viewEvent.notes.notes) ||
    iAmPatient()" class="to-print d-print-block">
    <table>
        <thead>
            <tr>
                <td>
                    <div class="header-space"></div>
                </td>
            </tr>
        </thead>


        <tbody>
            <tr>
                <td>
                    <div class="text-muted" *ngIf="viewEvent">
                        Schedule: <span class="font-weight-bold">{{displayTime(viewEvent.start)}}</span>
                    </div>
                    <div *ngIf="profile && profile.name">
                        Patient Name:
                        <strong>{{profile.name}}</strong>
                    </div>
                    <div *ngIf="user && user.first_name && user.last_name">
                        Therapist Name:
                        <strong>{{user.first_name}} {{user.last_name}}</strong>
                    </div>
                    <hr />
                    <div class="content">
                        <div class="section">
                            <section-progress [data]="viewEvent.notes"></section-progress>
                        </div>
                        <div class="section">
                            <section-physical-exam [eventId]="viewEvent.id"
                                [data]="viewEvent.physical_exam"
                                *ngIf="iAmDoctor() && !iAmPsychologist()"></section-physical-exam>
                        </div>
                        <div class="section">
                            <section-diagnosis [data]="viewEvent.notes"></section-diagnosis>
                        </div>
                        <div class="section">
                            <section-next-meeting [data]="viewEvent.notes"></section-next-meeting>
                        </div>
                        <div class="section">
                            <section-lab-request [eventId]="viewEvent.id"
                                [data]="viewEvent.notes"
                                *ngIf="iAmDoctor() && !iAmPsychologist()"></section-lab-request>
                        </div>
                        <div class="section">
                            <section-prescriptions [eventId]="viewEvent.id"
                                [data]="viewEvent.prescriptions">
                            </section-prescriptions>
                        </div>
                        <div class="section">
                            <section-medical-abstract [eventId]="viewEvent.id"
                                [data]="viewEvent.feedback"
                                (newData)="updateData('abstract', $event)"></section-medical-abstract>
                        </div>
                        <div class="section">
                            <section-medical-certificate
                                [eventId]="viewEvent.id"
                                [data]="viewEvent.notes"
                                (newData)="updateData('certificate', $event)"
                                *ngIf="iAmDoctor() && !iAmPsychologist()">
                            </section-medical-certificate>
                        </div>
                        <div class="section">
                            <section-admitting-order [eventId]="viewEvent.id"
                                [data]="viewEvent.notes"
                                *ngIf="iAmDoctor() && !iAmPsychologist()"></section-admitting-order>
                        </div>
                        <div class="section">
                            <section-patient-instructions
                                [eventId]="viewEvent.id"
                                [data]="viewEvent.notes">
                            </section-patient-instructions>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>


        <tfoot>
            <tr>
                <td>
                    <div class="footer-space"></div>
                </td>
            </tr>
        </tfoot>
    </table>

    <div class="headera">
        <img src="./assets/images/logo.png" class="logo">
    </div>
    <div class="footera"></div>
</div>
<div class="border rounded p-2 bg-light d-print-none">
    <div *ngIf="profile">
        <div class="row ">
            <div class="col-md-2 hide-mobile">
                <img src="{{profile.picture || defaultPicture }}"
                    class="profile-img rounded-circle my-2 mx-3"
                    alt="...">
            </div>
            <div class="col-md-10 col-12 mt-3">
                <h5 class="mb-0">
                    {{profile.name}}<span
                        *ngIf="isPsychiatrist(profile.doctorRole) && isPatient">,
                        MD</span>
                </h5>

                <div class="mb-0" *ngIf="isPatient &&
                    isPsychiatrist(profile.doctorRole)">
                    <strong class="profile-title bold">{{profile.title ||
                        'Psychiatrist'}}</strong>
                    <br>
                </div>
                <div *ngIf="isPatient && isPsychiatrist(profile.doctorRole)">
                    <div class="mb-0" *ngIf="profile.fellowOrDiplomate ==
                        'fellow'">
                        <strong class="profile-title">Fellow Philippine
                            Psychiatric Association</strong>
                        <br>
                    </div>
                    <div class="mb-0" *ngIf="profile.fellowOrDiplomate ==
                        'diplomate'">
                        <strong class="profile-title">Diplomate Philippine
                            Psychiatric Association</strong>
                        <br>
                    </div>
                    <div class="mb-0" *ngIf="profile.fellowOrDiplomate ==
                        'associate member'">
                        <strong class="profile-title">Associate Member</strong>
                        <br>
                    </div>
                    <div class="mb-0" *ngIf="profile.specialty != 'Undefined'">
                        <strong class="profile-title"> {{ profile.specialty }}</strong>
                        <br>
                    </div>
                </div>
                <div *ngIf="!isPsychiatrist(profile.doctorRole)">
                    <div class="mb-0">
                        <strong class="profile-title">Clinical Psychologist</strong>
                        <br>
                    </div>
                </div>
                <hr>
                <table *ngIf="isDoctorRole(profile.doctorRole) &&
                    medicalUserCanView()">
                    <tr>
                        <td style="vertical-align: top;"><strong>Clinic Address:</strong></td>
                        <td style="vertical-align: top;padding-left:5px">
                            {{ profile.streetAddress || 'No inputed address'}}
                            <br>
                            {{profile.state || 'No inputed address'}}
                        </td>
                    </tr>
                </table>
                <!-- <table>
                    <tr>
                        <td style="vertical-align: top;"><strong>Clinic Phone No:</strong></td>
                        <td style="vertical-align: top;padding-left:5px">{{profile.contact || '000-000-0000'}}</td>
                    </tr>
                </table> -->
            </div>
        </div>
        <!--  m-2 my-4 py-2 -->
        <div class="row">
            <div class="col-sm-12 mb-2">
                <tabset class="spaced">
                    <tab *ngIf="iAmDoctor()" heading="Appointments" id="tab1">
                        <ng-container *ngTemplateOutlet="appointments"></ng-container>
                    </tab>
                    <tab *ngIf="iAmDoctor()" heading="Patient Health Data
                        Sheet">
                        <ng-container *ngTemplateOutlet="infoSheet; context {
                            userId: profile.id}"></ng-container>
                    </tab>
                    <tab *ngIf="iAmDoctor()" heading="Medical Adherence">
                        <div class="col">
                            <app-mars></app-mars>
                        </div>
                    </tab>
                </tabset>
            </div>


        </div>
    </div>

    <div class="d-print-none d-none">
        <div class="text-muted font-weight-bold" *ngIf="viewEvent">{{displayTime(viewEvent.start)}}
            Notes</div>
        <div *ngIf="profile && profile.name">
            Patient Name:
            <strong>{{profile.name}}</strong>
        </div>
        <div *ngIf="user && user.first_name && user.last_name">
            Therapist Name:
            <strong>{{user.first_name}} {{user.last_name}}</strong>
        </div>
        <div *ngIf="viewEvent">

            <div *ngIf="(!!viewEvent && !!viewEvent.notes) || iAmPatient()"
                class="p-3 w-100">
                <div *ngFor="let row of toArray(viewEvent.notes)">
                    <h6 class="text-muted">{{titles[row.key]}}</h6>
                    <div *ngIf="row.key!=='Medications'">
                        <div *ngIf="row.key !== 'Labrequests' && row.key !==
                            'Medcert'">
                            <p [innerHTML]="row.value || '---'"></p>
                        </div>
                        <div *ngIf="row.key === 'Labrequests' || row.key ===
                            'Medcert'">
                            <div *ngFor="let v of row.value">
                                <h6>{{v.test}}</h6>
                                <div class="pl-3">
                                    <small>{{v.details}}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="row.key==='Medications'">
                        <ng-container *ngTemplateOutlet="medicationTable;
                            context: { item: row.value}"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #appointments>
        <div class="col">
            <div class="card" *ngIf="medicalUserCanView()">
                <div class="card-header">
                    <!-- <nav class="navbar navbar-light bg-light
                        justify-content-between p-0">

                        <form class="form-inline">
                            <span class="navbar-text mr-2">
                                Showing notes from:
                            </span>

                            <input class="form-control mr-sm-2" type="text"
                                style="width:210px" bsDaterangepicker
                                name="dateRange" [bsValue]="initialValue"
                                (bsValueChange)="onValueChange($event)"
                                aria-label="Search">
                        </form>
                    </nav> -->
                </div>
                <div class="card-body row">
                    <div class="col-2 px-0">
                        <div #log_date class="list-group" *ngIf="!loading">
                            <button *ngFor="let note of noteList; index as i;"
                                type="button"
                                class="log-date list-group-item
                                list-group-item-action text-center p-1"
                                [class.active]="selectedNoteIndex===i"
                                (click)="selectedNoteIndex=i;selectNote(note.id)">
                                <!-- {{ note.id }} -  -->
                                {{displayTime(note.start)}}
                            </button>
                        </div>

                        <div class="jumbotron text-center" *ngIf="loading">
                            <h4 class="display-4" style="font-size:35px;">We are pulling the records. Please wait.</h4>
                        </div>

                        <pagination
                            class="d-flex justify-content-center mt-2"
                            *ngIf="this.meta.total > this.meta.per_page"
                            [totalItems]="this.meta.total"
                            [itemsPerPage]="this.meta.per_page"
                            [maxSize]="3" 
                            (pageChanged)="pageChanged($event)"
                            [(ngModel)]="currentPage">        
                        </pagination>

                        <div class="border rounded p-2" *ngIf="noteList &&
                            noteList.length === 0">
                            No Results to Display.
                        </div>
                    </div>
                    <div class="col-10 pr-0">
                        <div class="d-flex justify-content-center rounded
                            calendar-card"
                            style="min-height:480px;height: auto">
                            <div class="overlay" *ngIf="loadingNote"
                                [ngClass]="{'show': calendarLoading}"></div>
                            <span *ngIf="!viewEvent && !loadingNote"
                                class="text-muted p-5">
                                Select a date to view notes
                            </span>
                            <!-- <span *ngIf="viewEvent && !viewEvent.notes && !loadingNote" class="text-muted p-5">
                            No notes for
                            <strong>{{displayTime(viewEvent.start)}}</strong>
                        </span> -->
                            <div *ngIf="(!!viewEvent && !loadingNote) ||
                                iAmPatient()" class="sections-container w-100">
                                <div class="ml-2" style="border-bottom: 1px solid #d2d0d0;">
                                    <h6 class="text-muted ">Consultation Date</h6>
                                    <pre>{{ displayTime(viewEvent.start_at.date) }}</pre>
                                </div>
                                <div class="ml-2" style="border-bottom: 1px solid #d2d0d0;">
                                    <h6 class="text-muted">Enable Add-On Purchase for this Appointment
                                        <span
                                        class="d-print-none badge badge-dark cta mr-2 ml-3"
                                        *ngIf="iAmDoctor()"
                                    >
                                        <fa-icon 
                                            [icon]="fa.faSave"
                                            ngbTooltip="Click to Save Add-on"
                                            (click)="submitAddonBtn()"
                                        ></fa-icon>
                                    </span> 
                                    </h6>
                                    
                                    <label class="mr-3">
                                        <input type="checkbox"  [(ngModel)]="request_addon_med_cert" 
                                        [disabled]="!viewEvent?.doctor?.request_medical_certificate">
                                        <span [ngClass]="{'text-muted': !viewEvent?.doctor?.request_medical_certificate}"> Medical Certificate</span> 
                                    </label>
                                    <label class="mr-3">
                                        <input type="checkbox" [(ngModel)]="request_addon_med_abstract"  
                                        [disabled]="!viewEvent?.doctor?.request_medical_abstract">
                                        <span [ngClass]="{'text-muted': !viewEvent?.doctor?.request_medical_abstract}"> Medical Abstract</span> 
                                    </label>
                                    <!-- <button class="btn btn-primary" (click)="submitAddonBtn()" [disabled]="saving">{{saving? 'Saving Add-On' :'Save Add-On'}} <i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button> -->
                                    
                                </div>
                                <div class="ml-2" style="border-bottom: 1px solid #d2d0d0;">
                                    <h6 class="text-muted ">Consultation Type</h6>
                                    <pre>{{ viewEvent.offline ? 'FACE-TO-FACE' : 'VIRTUAL' }}</pre>
                                </div>
                                <div class="ml-2" style="border-bottom: 1px solid #d2d0d0;">
                                    <h6 class="text-muted ">Appointment Status</h6>
                                    <pre>{{ displayAppointmentStatus(viewEvent.notes?.appointment_status) }}</pre>
                                </div>
                                <section-progress
                                    class="section"
                                    [data]="viewEvent.notes">
                                </section-progress>
                                <section-physical-exam
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.physical_exam"
                                    *ngIf="iAmDoctor() && !iAmPsychologist()">
                                </section-physical-exam>
                                <section-diagnosis
                                    class="section"
                                    [data]="viewEvent.notes">
                                </section-diagnosis>
                                <section-next-meeting
                                    class="section"
                                    [data]="viewEvent.notes">
                                </section-next-meeting>
                                <section-lab-request
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.notes"
                                    *ngIf="iAmDoctor() && !iAmPsychologist()">
                                </section-lab-request>
                                <section-prescriptions
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.prescriptions"
                                    *ngIf="iAmDoctor() && !iAmPsychologist()">
                                </section-prescriptions>
                                <section-medical-abstract
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.feedback"
                                    (newData)="updateData('abstract', $event)">
                                </section-medical-abstract>
                                <section-medical-certificate
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="(viewEvent.notes &&
                                    viewEvent.notes.notes) ?
                                    viewEvent.notes.notes : null"
                                    (newData)="updateData('certificate',
                                    $event)"
                                    *ngIf="iAmDoctor()"></section-medical-certificate>
                                <section-admitting-order
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.notes"
                                    *ngIf="iAmDoctor()">
                                </section-admitting-order>
                                <section-patient-instructions
                                    class="section"
                                    [eventId]="viewEvent.id"
                                    [data]="viewEvent.notes">
                                </section-patient-instructions>
                            </div>
                        </div>

                        <div class="action-buttons my-2" *ngIf="viewEvent &&
                            viewEvent.notes && !loadingNote">
                            <div class="btn-wrapper mb-2" *ngIf="iAmDoctor()">
                                <button ngbTooltip="Print Consulation Notes"
                                    class="btn btn-primary p-2"
                                    [disabled]="selectedNoteIndex === undefined
                                    || selectedNoteIndex === null"
                                    type="button" (click)="printPage()">
                                    <fa-icon [icon]="fa.faPrint"></fa-icon>
                                    Print Notes
                                </button>
                            </div>
                            <!-- <div class="btn-wrapper mb-2">
                                <button class="btn btn-primary" (click)="submitAddonBtn()" [disabled]="saving">{{saving? 'Saving Add-On' :'Save Add-On'}} <i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="card calendar-card" *ngIf="(iAmPatient()) &&
                !!calendarOptions">
                <div class="card-body">
                    <div class="overlay" [ngClass]="{'show': calendarLoading}"></div>
                    <app-calendar [options]="calendarOptions"></app-calendar>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- [{{viewEvent.prescriptions.customs | json}}] -->
    <ng-template #infoSheet let-id="userId">
        <div class="col">
            <div class="card" *ngIf="medicalUserCanView()">
                <div class="card-header">
                    <nav class="navbar navbar-light bg-light
                        justify-content-between p-0">
                        <h3>Health Information Sheet</h3>
                    </nav>
                </div>
                <div class="card-body">
                    <app-data-sheet [userId]="id" style="font-size: 95%"></app-data-sheet>
                </div>
            </div>
        </div>
    </ng-template>
