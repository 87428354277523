import { OnInit, Component, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
    MedicalPractitionerService,
    UserService,
    AvailabilityService,
    AppointmentService,
    ArchiveService,
    MentalHealthSheetService,
    NotesService,
    FeedbackService,
    isDoctorRole,
    iAmDoctor,
    iAmPatient,
    iAmPsychologist,
    canAccessConsultationNotes_LaboratoryRequest,
    canAccessConsultationNotes_Medication,
    canAccessConsultationNotes_Diagnosis,
    canAccessConsultationNotes_NextMeeting,
    consulationNotes_Label_MedicalAbstract,
    consulationNotes_Label_MedicalCertificate,
    consulationNotes_Label_AdmittingOrder,
    canAccessConsultationNotes_Diagnosis_CustomInput,
    canAccessConsultationNotes_Diagnosis_PsychiatristDropdown,
    iAmSecretary,
} from "../../../../../services";
import { snakeCase, capitalize } from "lodash";
import { LoaderService } from "../../../../../services/loader.service";
import moment from "moment";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { ToastrManager } from "ng6-toastr-notifications";
import { DOCTOR_MED_SHEET } from "../../../../feedback/doctor-med-sheet";
import { DOCTOR_MED_SHEET_NEW } from "../../../../feedback/doctor-med-sheet-new";
import { View } from "fullcalendar";
import { NoteContent } from "../../../../../shared/models";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-my-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class MyProfileSecComponent implements OnInit {
    location = "Psychiatrists > Profile";

    @ViewChild("log_date") log_date: ElementRef;

    profile: any;
    details: any[];
    calendarOptions: any;
    docMedSheet: any[];
    role: string;
    noteList: any;
    user: any;
    viewEvent: any;
    selectedNoteIndex: number;
    initialValue: Date[];
    dateRange: any;
    loading = false;
    archives: any[];
    feedbackData: any;
    feedbackRawData: any;
    medications: any;
    viewMentalSheet = false;
    viewMedicalAbstract = false;
    viewMedicalCertificate = false;
    viewPrescriptions = false;
    saveMedicalCertificate = false;
    sendingMedicalAbstract = false;
    sendingMedicalPrescription = false;
    isPatient: boolean = false;
    medCertRecomendation: string;
    currentView = "appointments";
    noteContent: NoteContent;
    calendarLoading = true;
    defaultPicture: any;
    loadingNote: boolean = false;
    fa = {
        faPrint,
    };

    isDoctorRole = isDoctorRole;
    iAmDoctor = iAmDoctor;
    iAmSecretary = iAmSecretary;
    iAmPatient = iAmPatient;
    iAmPsychologist = iAmPsychologist;
    canAccessConsultationNotes_LaboratoryRequest = canAccessConsultationNotes_LaboratoryRequest;
    canAccessConsultationNotes_Medication = canAccessConsultationNotes_Medication;
    canAccessConsultationNotes_Diagnosis = canAccessConsultationNotes_Diagnosis;
    canAccessConsultationNotes_NextMeeting = canAccessConsultationNotes_NextMeeting;
    consulationNotes_Label_MedicalAbstract = consulationNotes_Label_MedicalAbstract;
    consulationNotes_Label_MedicalCertificate = consulationNotes_Label_MedicalCertificate;
    consulationNotes_Label_AdmittingOrder = consulationNotes_Label_AdmittingOrder;
    canAccessConsultationNotes_Diagnosis_CustomInput = canAccessConsultationNotes_Diagnosis_CustomInput;
    canAccessConsultationNotes_Diagnosis_PsychiatristDropdown =
        canAccessConsultationNotes_Diagnosis_PsychiatristDropdown;

    titles = {
        Progress: "Progress",
        Diagnosis: "Diagnosis",
        // Labrequests: 'Laboratory Requests',
        Labrequestsnotes: "Lab Request Notes",
        Admittingorder: "Admitting Order",
        // Medcert: 'Medical Certificate',
        Medcertrecomendation: "Medical Certificate Recomendations",
        Nextmeeting: "Schedule for Next Meeting",
        // Medexpiration: 'Medical Certificate Expiration',
        // Medhassignature: 'Medical Certificate with Signature',
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private therapistService: MedicalPractitionerService,
        private loaderService: LoaderService,
        private noteService: NotesService,
        private userService: UserService,
        private availabilityService: AvailabilityService,
        private appointmentService: AppointmentService,
        private archiveService: ArchiveService,
        private feedbackService: FeedbackService,
        private toastr: ToastrManager
    ) {
        loaderService.loader.next(true);
        this.user = this.userService.getStoredCredentials();
        // console.log('this.user.roles[0]',this.user.roles)
        this.role = this.user.roles[0].name;

        this.noteContent = new NoteContent();

        if (iAmPatient()) {
            this.location = "Psychiatrists > Profile";
            this.isPatient = true;
            this.defaultPicture = "../../../../assets/images/doctor-placeholder.png";
        } else if (iAmSecretary()) {
            this.location = "Doctor Patients > Profile";
            this.isPatient = false;
            this.defaultPicture = "../../../../assets/images/users-placeholder.png";
        } else {
            this.location = "Profile";
        }

        this.initialValue = [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
    }

    ngOnInit() {
        this.route.params.subscribe(({ id }) => {
            if (iAmPatient()) {
                this.therapistService.show(id, { filter: "id;email" }).subscribe(this.mapProfile, (e) => {
                    // console.log(e);
                    this.loaderService.loader.next(false);
                    this.toastr.errorToastr("Couldnt retrieve doctor data.");
                    this.router.navigate(["me/psychiatrists"]);
                });
            } else if (iAmSecretary()) {
                this.userService.show(id).subscribe((v) => {
                    this.mapProfile(v);
                });
            }
        });

        this.initialValue = [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
    }

    medicalUserCanView() {
        return iAmSecretary();
    }

    generateSheet() {
        const medSheet = DOCTOR_MED_SHEET_NEW.map((val) => {
            let item;
            if (_.isString(val)) {
                item = {
                    label: val,
                    key: _.camelCase(val),
                    type: val === "hr" ? "hr" : "textarea",
                };
            }

            if (_.isObject(val)) {
                const obj = val as any;
                item = {
                    ...obj,
                    key: obj.key ? obj.key : _.camelCase(obj.label),
                };
            }
            return item;
        });

        this.docMedSheet = medSheet;
        this.feedbackData = {};
    }

    getCalendarMonthEntries(doctorId, dateStart?, dateEnd?) {
        let params = {
            with: "bookings",
            search: "assignable_id:" + doctorId,
            dateRange: "start_at:" + dateStart + ":>=|start_at:" + dateEnd + ":<=",
            orderBy: "events.start_at",
            sortedBy: "desc",
            limit: 0,
        };

        return this.availabilityService.index(params);
    }

    mapProfile = ({ data }) => {
        const doctorId = data.id;

        if (iAmPatient()) {
            this.profile = {
                name: `${data.first_name} ${data.last_name}`,
                streetAddress: data.general_information.streetAddress,
                title: data.title,
                email: data.email,
                contact: data.general_information.phone,
                address: capitalize(`${data.general_information.address_1} ${data.general_information.address_2}`),
                state: `${capitalize(data.general_information.city)}, ${capitalize(data.general_information.state)} ${
                    data.general_information.zipcode
                }`,
                events: data.events,
                id: doctorId,
                picture: data.picture,
                status: data.status,
                suffix: data.general_information.suffix,
                fellowOrDiplomate: data.general_information.fellowOrDiplomate,
                specialty: capitalize(`${data.general_information.specialty}`),
                doctorRole: data.general_information.doctorRole,
            };
        } else {
            this.profile = {
                name: `${data.first_name} ${data.last_name}`,
                streetAddress: data.streetAddress,
                title: data.title,
                email: data.email,
                contact: data.contact_mobile_no,
                address: capitalize(`${data.address_1} ${data.address_2}`),
                state: `${capitalize(data.city)}, ${capitalize(data.state)} ${data.zipcode}`,
                events: data.events,
                id: doctorId,
                picture: data.picture,
                status: data.status,
            };
        }

        this.calendarOptions = {
            // defaultView: 'listYear',
            header: {
                left: "prev,next",
                center: "title",
                right: "today",
            },
            events: (start, end, timezone, callback) => {
                // Role of current user
                switch (this.role) {
                    case "patient":
                    case "in-patient":
                        let startDateP = moment(start).subtract(1, "hours").format("YYYY-MM-DD");
                        let endDateP = moment(end).format("YYYY-MM-DD");
                        this.calendarLoading = true;
                        this.getCalendarMonthEntries(doctorId, startDateP, endDateP).subscribe((availabilities) => {
                            this.calendarLoading = false;
                            callback(this.mapEvents(availabilities.data));
                        });

                        break;
                    case "psychologist":
                    case "medical-practitioner":
                    case "doctor":
                        console.log("DOCTOR");
                        break;
                }
            },
            eventClick: (event, jsEvent, view) => {
                console.log(event, this.role);
                if (this.role == "patient" || this.role == "in-patient") {
                    if (event.status == "OPEN" && !event.start.isBefore(moment.now())) {
                        // this.toastr.successToastr('Opening your appointments calendar...', null, {showCloseButton: true})
                        this.router.navigate(["/me/appointments", { eventId: event.id }]);
                    } else if (event.status == "RESERVED") {
                        this.toastr.warningToastr("Schedule is reserved", null, { showCloseButton: true });
                    } else if (event.status == "BOOKED") {
                        this.toastr.warningToastr("Schedule is already booked", null, { showCloseButton: true });
                    } else if (event.start.isBefore(moment.now())) {
                        this.toastr.warningToastr("Schedule has already passed", null, { showCloseButton: true });
                    }
                } else if (this.medicalUserCanView()) {
                    // this.role === 'medical-practitioner' || this.role === 'psychologist') {

                    console.log("eveent", event);
                    this.viewEvent = event;
                }
            },
            loading: (isLoading) => this.loaderService.loader.next(isLoading),
            eventRender: (e, element) => {
                if (e.status == "OPEN") {
                    if (moment(e.start).isBefore(moment(), "d")) {
                        element.css("background-color", "#ababab");
                        element.css("border", "none");
                    } else {
                        element.find(".fc-content").css("cursor", "pointer");
                        element.find(".fc-content").css("color", "#fff");
                    }
                }
            },
        };
    };

    toArray = (obj) => Object.keys(obj).map((key) => ({ key: capitalize(key), value: obj[key] }));

    onValueChange = (v) => {
        // /events?&dateRange=start_at;2018-04-29|end_at;2018-06-10&limit=336
        // if (this.role === 'medical-practitioner') {

        this.initialValue = v;

        if (this.medicalUserCanView()) {
            const startDate = moment(v[0]).format("YYYY-MM-DD HH/mm/ss");
            const endDate = moment(v[1]).endOf("day").format("YYYY-MM-DD HH/mm/ss");

            this.loaderService.loader.next(true);

            // if (this.role === 'patient') {
            //     return o.status

            //     // return o.label;
            // } else {
            //     return !!o.notes ? 'Click to view notes' : 'No notes available';
            // }
            this.route.params.subscribe(({ id }) => {
                const params = {
                    // search: `assignable_id:${this.user.id};status:BOOKED`,
                    search: `status:BOOKED`,
                    criteria: "UserEventCriteria",
                    has: `bookings;bookingsWith:${id}`,
                    orderBy: "start_at",
                    sortedBy: "desc",
                    // with: 'feedback;opentok;notes.dsmnotes;notes.customDiagnosis;prescriptions.items.medicine.generic;prescriptions.customs',
                    doctorId: this.user.id,
                    filter: "id;start_at;end_at;created_at",
                };

                this.availabilityService.getDateRangeEvents(startDate, endDate, params).subscribe((response) => {
                    this.noteList = this.mapEvents(response.data);
                    this.loaderService.loader.next(false);

                    if (this.route.snapshot.queryParams.log && this.route.snapshot.queryParams.log == "recent") {
                        this.toastr.warningToastr("Finding recent log");
                        setTimeout(() => {
                            if (this.log_date.nativeElement.children.length) {
                                this.toastr.successToastr("Log found");
                                this.log_date.nativeElement.children[0].click();
                            }
                        }, 1000);
                    }
                });
            });
        }
    };

    mapEvents = (events) =>
        events
            // .filter(o => !!o.patient && o.patient.id === this.profile.id)
            .map((o) => ({
                id: o.id,
                start: moment(o.start_at.date),
                end: moment(o.end_at.date),
                notes: (() => {
                    if (o.notes && o.notes.notes && typeof o.notes.notes === "string") {
                        return { ...JSON.parse(o.notes.notes) };
                    }

                    if (o.notes && o.notes.notes && typeof o.notes.notes === "object") {
                        let d = o.notes.notes;
                        d.dsmnotes = o.notes.dsmnotes;
                        d.custom_diagnosis = o.notes.custom_diagnosis;
                        return d;
                    }
                    return {
                        diagnosis: "",
                        labRequests: [],
                        medCert: [],
                        medExpiration: "",
                        medHasSignature: false,
                        nextMeeting: "",
                        progress: "",
                        prescriptions: [],
                    };
                })(),
                title: this.getTitle(o),
                color: o.status === "BOOKED" ? "#649d71" : "#528cc9",
                sessionId: o.opentok ? o.opentok.session : null,
                status: o.status,
                prescriptions: o.prescriptions,
                feedback: o.feedback,
            }));

    getLink(id: string, index: number) {
        return this.archiveService.getDownloadLink(id).subscribe((url) => {
            this.archives[index].url = url;
        });
    }

    getTitle(o: any) {
        if (this.role === "patient" || this.role === "in-patient") {
            return o.status;

            // return o.label;
        } else {
            return !!o.notes ? "Click to view notes" : "No notes available";
        }
    }

    displayTime = (datetime) => {
        return moment(datetime).format("LL LT");
    };

    selectNote(eventId: number) {
        // this.selectedNoteIndex = i;

        // change this to loading via api call
        // this.viewEvent = this.noteList[i];

        const params = {
            search: `${eventId}`,
            searchFields: "id",
            limit: 0,
            with: "physicalExam;feedback;notes.dsmnotes;notes.customDiagnosis;prescriptions.items.medicine.generic;prescriptions.customs",
        };

        this.loadingNote = true;
        this.availabilityService.index(params).subscribe(
            (response) => {
                this.loadingNote = false;
                this.viewEvent = response.data[0];
            },
            (e) => {
                this.loadingNote = false;
                this.toastr.errorToastr("There was problem loading the notes");
            }
        );
    }

    fileName(file: any) {
        let date = new Date(file.createdAt * 1000);
        let r = Math.random().toString(36).substring(7);
        let format = `${date.getFullYear()}${date.getMonth() + 1}${date.getDay()}${r}`;
        // console.log('filename',date)//file.createdAt.format('YYYY_DD_MM'))
        return snakeCase(this.profile.name) + "_" + format + ".mp4";
    }

    LL = (data) => moment(data).format("LL");

    printPage() {
        // const date = this.noteList[this.selectedNoteIndex].start.format('YYYY_DD_MM');

        const date = moment(this.viewEvent.start_at.date).format("YYYY_DD_MM");
        const storedTitle = document.title;
        document.title = this.profile.name + "_" + date;
        (window as any).print();
        // document.title = storedTitle;
    }

    updateData = (type, value) => {
        if (type === "abstract") {
            this.viewEvent.feedback = { ...this.viewEvent.feedback, ...value };
        } else if (type === "certificate") {
            this.viewEvent.notes.notes = { ...this.viewEvent.notes.notes, ...value };
        }
    };

    isPsychiatrist(role: any) {
        if (role && role == "psychologist") {
            return false;
        }

        return true;
    }
}
