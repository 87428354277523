import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import moment from 'moment'
@Component({
  selector: 'app-time-fields',
  templateUrl: './time-fields.component.html',
  styleUrls: ['./time-fields.component.scss'],
})

export class TimeFieldsComponent implements OnInit, OnChanges {

  @Input() datePicked: any = null
  @Input() slotDetails
  @Input() isValidTime: boolean = false
  @Output() selectStartAt: EventEmitter<any> = new EventEmitter()
  @Output() selectEndAt: EventEmitter<any> = new EventEmitter()
  @Output() selectTime: EventEmitter<any> = new EventEmitter()

  selectedType = null

  public start_at = { hour: 0, minute: 0 };
  public end_at = { hour: 0, minute: 0 };

  public selectedDuration = 60
  timezone = 'Asia/Manila'

  selectedStartAt = { hour: 0, minute: 0 }
  selectedEndAt = null

  timeChanged = (type?) => {

    if (!type && this.slotDetails.start_at) {
      this.adjustEndAtBasedOnStartAndDuration()
    }

    // if (this.slot)
    // console.log({
    //     start_at: this.selectedStartAt,
    //     end_at: this.selectedEndAt
    // })
    this.selectTime.emit({
      start_at: this.selectedStartAt,
      end_at: this.selectedEndAt
    })
  }

  ngOnInit() {
    this.selectedStartAt = this.slotDetails.start_at
    this.timeChanged()
  }

  adjustEndAtBasedOnStartAndDuration = () => {
    if (this.datePicked) {
      const end_at = this.datePicked
        .clone()
        .hour(this.selectedStartAt.hour)
        .minute(this.selectedStartAt.minute)
        .add(this.slotDetails.duration, 'm')

      this.selectedEndAt = { hour: end_at.hour(), minute: end_at.minute() }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.slotDetails) {

      if (!changes.slotDetails.currentValue.start_at) {
        const date = moment().startOf('hour').add(60, 'm')
        const start_at = { hour: date.hour(), minute: 0 }
        this.selectedStartAt = start_at
        this.selectStartAt.emit(start_at)
      }

      if (changes.slotDetails.previousValue) {
        // console.log('---', changes.slotDetails)
        if (changes.slotDetails.previousValue.duration != changes.slotDetails.currentValue.duration) {
          this.timeChanged()
        }

      }
    }
  }

  constructor() { }
}
