import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPatient, iAmPsychologist, iAmPsychRole, } from '../../../../../../services';

@Component({
    selector: 'section-diagnosis',
    templateUrl: './diagnosis.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionDiagnosisComponent implements OnChanges {
    @Input() data: object

    iAmDoctor = iAmDoctor
    iAmPatient = iAmPatient
    iAmPsychologist = iAmPsychologist
    iAmPsychRole = iAmPsychRole

    title = 'Diagnosis'
    value = '<em class="text-muted">Not specified</em>'
    match = true
    dsmList = []
    customDsmList = []

    typeOf(value) {
        return typeof value
    }

    ngOnChanges(changes: SimpleChanges) {
        if (iAmPsychRole()) {
            if (
                changes.data && 
                changes.data.currentValue && 
                changes.data.currentValue.dsmnotes && 
                changes.data.currentValue.custom_diagnosis
            ) {
                this.dsmList = changes.data.currentValue.dsmnotes.concat(changes.data.currentValue.custom_diagnosis)
            }
            this.match = true
        } else if (
            !iAmPsychRole() && 
            changes.data && 
            changes.data.currentValue && 
            changes.data.currentValue.diagnosis && 
            typeof changes.data.currentValue.diagnosis === 'string'
        ) {
            if (changes.data.currentValue && changes.data.currentValue.diagnosis.length) {
                this.value = changes.data.currentValue.diagnosis
            }
        } else {
            if (changes.data.currentValue && changes.data.currentValue.notes && changes.data.currentValue.notes.diagnosis.length) {
                this.value = changes.data.currentValue.notes.diagnosis
            }
        }
    }

}
