<crud-header 
*ngIf="formType=='create'"
[settings]="settings" 
[action]="'create'"
></crud-header>

<crud-header 
*ngIf="formType=='edit'"
[settings]="settings" 
[action]="'edit'"
></crud-header>
<ngb-tabset>
    <ngb-tab title="Generic Name Information">
        <ng-template ngbTabContent>
            <form [formGroup]="rForm" novalidate  autocomplete="off"> 

                <div class="row">
                    <div class="form-group col-6">
                        <label for="name">Name <span class="required text-danger">*</span></label>
                        <input type="text" class="form-control" id="name" formControlName="name" autocomplete="off">
                        
                        <div class="small text-warning" ngxErrors="name" #errorName="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                <div *ngSwitchCase="'minlength'">
                                    {{ error.text }} {{ errorName.getError('minlength')?.requiredLength }}
                                </div>
                                <div *ngSwitchDefault>
                                    {{ error.text }}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 offset-3">
                        <crud-form-buttons
                            *ngIf="formType=='edit'"
                            [settings]="settings"
                            [action]="'edit'"
                            [rForm]="rForm"
                            (doSave)="doUpdate($event)"
                        ></crud-form-buttons>                      
                    </div>
                    <crud-form-buttons
                            *ngIf="formType=='create'"
                            [settings]="settings"
                            [action]="'create'"
                            [rForm]="rForm"
                            (doSave)="doSave($event)"
                        ></crud-form-buttons> 
                </div>

            </form>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
        