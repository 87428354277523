export const settings = {
    plural: 'Users',
    singular: 'User',


    crud: {
        create: {
            allow: true, // true/false
            url: '/admin/users/create'
        },
        read: {

        },
        update: {
            allow: true,
            url: '/admin/users/edit/'
        },
        delete: {
            allow: true
        },
        list: {
            allow: true,
            url: '/admin/users'
        }
    }
};
