<div class="p-2 bg-white med-list">
    <div class="overlay" [ngClass]="{'show': loading}"></div>
    <table class="table table-bordered">
        <thead>
            <tr>
                <th width="1%"></th>
                <th width="5px">#</th>
                <th width="120px">Generic</th>
                <th width="120px">Brand</th>
                <th width="120px">Dosage</th>
                <th width="170px">Quantity</th>
                <th>Instructions</th>
            </tr>
        </thead>

        <!-- {{ medications | json}} -->
        <tbody>
            <tr *ngFor="let medication of medications ; index as i" >
                <td *ngIf="!medication.is_deleted" class="pt-1">
                    <fa-icon
                        style="cursor: pointer;"
                        [icon]="fa.faTrashAlt"
                        (click)="removeMedication(i)"
                    ></fa-icon>
                </td>
                <td 
                    class="p-1 text-center"
                    [ngClass]="(!medication.id) ? 'custom-row' : ''"
                    *ngIf="!medication.is_deleted"
                >
                    {{i+1}}
                </td>
                <td 
                    class="p-1"
                    *ngIf="!medication.is_deleted"
                >
                <!-- [medicaion id: {{medication.id}}] -->
                    <input 
                        [(ngModel)]="medication.generic" 
                        [typeahead]="genericList" 
                        [typeaheadMinLength]="0" 
                        (typeaheadOnBlur)="typeaheadOnBlur($event, i)"
                        (typeaheadNoResults)="saveState()" 
                        [typeaheadOptionsLimit]="100" 
                        typeaheadOptionField="name"
                        [typeaheadScrollable]="true" 
                        [typeaheadOptionsInScrollableView]="10" 
                        (typeaheadOnSelect)="onSelectGeneric($event, i)"
                        class="form-control form-control-sm border-0" 
                        placeholder="Enter generic"
                    />

                    <div class="small" *ngIf="!medication.id">
                        <label 
                            style="display: inline-block;
                                    padding-right: 10px;
                                    white-space: nowrap;
                                    padding-left: 8px;
                                    cursor: pointer;
                                    "
                        >
                            <input 
                                type="checkbox" 
                                value="1" 
                                (change)="onChangeCheckbox('is_s2', i, $event)"
                                [(ngModel)]="medication.is_s2" 
                                style="vertical-align: middle;"
                                class="mr-1"
                            /> 
                            <span style="vertical-align: middle;">S2</span>
                        </label>
                    </div>
                </td>
                <td class="p-1" *ngIf="!medication.is_deleted">
                    <!-- [medication.brand: {{ medication.brand }}] -->
                    <input 
                        [(ngModel)]="medication.brand" 
                        [typeahead]="brandList" 
                        [typeaheadMinLength]="0"
                        (typeaheadNoResults)="saveState()" 
                        typeaheadOptionField="brand" 
                        (typeaheadOnSelect)="onSelectBrand($event, i)"
                        (keyup)="onSelectBrandCustom($event, i)"
                        class="form-control form-control-sm border-0" 
                        placeholder="Enter brand"
                        [disabled]="(!medication.generic) ? 'disabled': null"
                    >
                </td>
                <td class="p-1" *ngIf="!medication.is_deleted">
                    <input 
                        [(ngModel)]="medication.dosage" 
                        [typeahead]="dosageList" 
                        (typeaheadNoResults)="saveState()"
                        typeaheadOptionField="unit" 
                        [typeaheadMinLength]="0" 
                        (typeaheadOnSelect)="onSelectDosage($event, i)"
                        class="form-control form-control-sm border-0" 
                        placeholder="Enter dosage"
                        [disabled]="(!medication.brand) ? 'disabled': null"
                    >
                </td>
                <td class="p-1" *ngIf="!medication.is_deleted">
                    <input
                        type="number" 
                        [(ngModel)]="medication.tabsDispensed" 
                        class="form-control form-control-sm border-0"
                        placeholder="Enter number of tabs dispensed">
                </td>
                <td class="p-1 instructions" *ngIf="!medication.is_deleted">
                    <input 
                        [(ngModel)]="medication.instruction" 
                        [typeahead]="instructionlist" 
                        (typeaheadNoResults)="saveState()"
                        typeaheadOptionField="unit" 
                        [typeaheadMinLength]="0" 
                        (typeaheadOnSelect)="saveState()" 
                        class="form-control form-control-sm border-0"
                        placeholder="Enter Instructions"
                    />  
                </td>
            </tr>
        </tbody>
    </table>
    <div class="w-100 mb-3">
        <p *ngIf="!medications.length" class="text-secondary text-center mt-2 mb-0">
            No medications found.
            <br> You can add medication by clicking the button below.
        </p>
        <div class="small text-primary">
            <span (click)="addRow()" class=" btn btn-sm btn-primary">
                <!-- <i class="fa fa-plus"></i>  -->
                <fa-icon [icon]="faFileMedical"></fa-icon> Add Medication
            </span>

            <span (click)="pullLast()" class="ml-2 btn btn-sm btn-primary">
                <fa-icon [icon]="faFileImport"></fa-icon> Import from last consultation
            </span>
        </div>

        <!-- <div class="text-center p-2 text-primary">
            
        </div>

        <div class="text-center p-2 text-primary">
            
        </div> -->
    </div>
</div>
