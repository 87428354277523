<app-otsuka-survey-form [trigger]="OtsukaFormTrigger"></app-otsuka-survey-form>
<app-terms-agreement></app-terms-agreement>
<progressbar 
    [value]="100" 
    [striped]="true" 
    [animate]="true" 
    class="page-navigation-progress-bar" 
    *ngIf="showPageNavigationProgressBar"
    style="height: 5px;"
></progressbar>
<!-- *ngIf="showPageNavigationProgressBar" -->
<router-outlet></router-outlet>
