<div class="row">
  <div class="col-sm-6 text-center">
      <div 
        class="cta border rounded bg-light p-3"
        (click)="_selectType('virtual')"
        [ngClass]="{'bg-dark text-white' : selectedType=='virtual'}"
      >
        <fa-icon [icon]="faVideo"></fa-icon>
        <div class="text-small">Virtual</div>
      </div>
  </div>
  <div class="col-sm-6 text-center">
      <div 
        class="cta border rounded bg-light p-3"
        (click)="_selectType('face')"
        [ngClass]="{'bg-dark text-white' : selectedType=='face'}"
      >
        <fa-icon [icon]="faUsers"></fa-icon>
        <div class="text-small">Face-to-Face</div>
      </div>
  </div>
</div>