import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EventFeedbacksService extends AbstractService {

  endpoint = environment.serverUrl + 'feedbacks';

    constructor(http: HttpClient) {
        super(http);
    }

    getPatientFeedback() {
        return this.http.get(this.endpoint + `/patient`);
    }

    getFeedback(id: number) {
        return this.http.get(this.endpoint + `/${id}/patient`);
    }

    getDoctorFeedback() {
        return this.http.get(this.endpoint + `/medical-practitioner`);
    }

}
