export const DOCTOR_MED_SHEET_NEW = [
    { type: 'textarea', label: 'General Data'},
    { type: 'textarea', label: 'Chief Complaint'},
    { type: 'textarea', label: 'History of Present Illness'},
    { type: 'textarea', label: 'Course in the Ward/ Out Patient'},
    { type: 'textarea', label: 'Laboratory Examination Result'},
    { type: 'textarea', label: 'Psychological Examination Result'},
    { type: 'textarea', label: 'Diagnosis'},
    { type: 'textarea', label: 'Medication'},
    { type: 'textarea', label: 'Recommendation'},
];
