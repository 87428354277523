import { Component, Input, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from "../../../../../../services";
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { ToastrManager } from "ng6-toastr-notifications";

@Component({
    selector: "section-prescriptions",
    templateUrl: "./prescriptions.component.html",
    styleUrls: ["./styles.component.scss"],
})
export class ConsultationNotesSectionPrescriptionsComponent implements OnChanges {
    @Input() data: object;
    @Input() eventId;

    modalRef: BsModalRef;
    title = "Prescriptions";
    value = '<em class="text-muted">Not specified</em>';
    match = true;
    dsmList = [];
    customDsmList = [];
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    };

    iAmDoctor = iAmDoctor;
    iAmPsychologist = iAmPsychologist;

    constructor(
        private noteService: NotesService,
        private modalService: BsModalService,
        private toastr: ToastrManager
    ) {}

    downloadPDF = () => {
        this.noteService.previewPrescription({ event_id: this.eventId }).subscribe(
            (response) => downloadPDFFile(response),
            (e) => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    };

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
        this.modalRef.setClass("modal-lg");
    }

    typeOf(value) {
        return typeof value;
    }

    modalDecline = () => {
        this.modalRef.hide();
    };

    redirectToConsultationNote = () => {
        window.location.href = `/feedback/${this.eventId}?s=3`;
    };

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data &&
            changes.data.currentValue &&
            changes.data.currentValue.length &&
            changes.data.currentValue[0].items &&
            changes.data.currentValue[0].items.length
        ) {
            this.value = changes.data.currentValue[0].items;
        }
    }
}
