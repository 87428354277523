import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesComponent } from './messages.component';
import { MessagesRoutingModule } from './messages-routing.module';
import { CrudModule } from '../../../core/base/crud';
import { ListComponent } from './crud/list/list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MessagesService } from '../../../services/messages.service';
import { ViewComponent } from './crud/view/view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { HttpModule } from '@angular/http';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    MessagesRoutingModule,
    CrudModule,
    NgxDatatableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    HttpModule
  ],
  declarations: [
    MessagesComponent,
    ListComponent,
    ViewComponent
  ],
  providers: [
    MessagesService,
    BsModalService,
  ]
})
export class MessagesModule { }
