import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationEnd, NavigationStart } from '@angular/router';
import { Console } from 'console';
import { UserService } from './../../services/user.service'
@Component({
    selector: 'app-public',
    templateUrl: './public.component.html',
    styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {

    constructor(
        public activatedRoute: ActivatedRoute,
        public router: Router,
        private userService: UserService
    ) {
        this.router.events.subscribe((event: Event) => {
            
            // console.log(event)
            if (event instanceof NavigationStart) {
                // console.log(event)
                /*
                path: '',
            component: PhomeComponent
        },

        {
            path: 'doctors',
            component: DoctorsComponent,
            data: { title: extract('Doctors') },
        },
        {
            path: 'login',
            component: LoginComponent,
            data: { title: extract('Login'), noHeader: true },
        },
        {
            path: 'users/verify/:email/:token',
            loadChildren: './verify-email/verify-email.module#VerifyEmailModule',
            data: { title: extract('verify') },
        },
        {
            path: 'users/reset-password',
            component: ForgotPasswordComponent,
            data: { title: extract('Reset My password') },
        },
        {
            path: 'users/reset-password/:email/:token',
            component: ForgotPasswordComponent,
            data: { title: extract('Confirm My Token') },
        },  
        */
                
                // Hide loading indicator

                // let services = this.userService._srvcs.getValue();
                // if (services && services['_otsuka']) {
                //     console.log('here', services['_otsuka'].pop)
                //     this.OtsukaFormTrigger = false;
                //     this.OtsukaFormTrigger = services['_otsuka'].pop
                // }

                // console.log('NavigationEnd', event, this.userService._srvcs.getValue())
            }

        })
    }

    ngOnInit() {
        
    }

}
