import { Component, OnInit, Injector, TemplateRef } from "@angular/core";

// STEP 1: IMPORT THE necessary base component
import { ListComponent as CrudListComponent } from "../../../../../core/base/crud";

// STEP 2: IMPORT the service
import { UserService } from "../../../../../services/user.service";

// STEP 5: IMPORT settings
import { settings } from "../../settings";
import moment from "moment";
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: "app-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
})
export class ListComponent extends CrudListComponent {
    public columns: any;
    private selectedRow: any;

    constructor(injector: Injector, private modalService: BsModalService, service: UserService) {
        super(injector, modalService);
        this.setSettings(settings);
        this.service = service;
    }

    getEditLink(row: any) {
        return this.settings.crud.update.url + row.id;
    }

    formatDate(date: any) {
        return moment(date).format("LL");
    }

    deleteRow(row: any, templateRef: TemplateRef<any>): void {
        this.selectedRow = row;
        this.modalRef = this.modalService.show(templateRef);
    }

    confirm() {
        // UserService.
        // this.service.
        // console.log('confirm');
    }
}
