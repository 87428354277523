<div class="row login-container d-flex justify-content-center align-items-center bg-light m-0 p-0" style="height: 99vh">
    <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4" *ngIf="!registered">
        <div class="text-center m-3">
            <a [routerLink]="['/me']">
                <img src="./assets/images/logo.png" style="width:400px;">
            </a>
        </div>
        <div class="card border-0" *ngIf="!loading">
            <div class="card-body p-4">
                <h3 class="text-center">Patient Sign Up</h3>
                <hr />

                <form [formGroup]="registrationForm" (ngSubmit)="submitRegistration()">
                    <div class="form-group">
                        <label class="form-control-label">First Name</label>
                        <input type="text" class="form-control" formControlName="first_name">
                        <app-errors [control]="registrationForm.get('first_name')"></app-errors>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Middle Name</label>
                        <input type="text" class="form-control" formControlName="middle_name">
                        <app-errors [control]="registrationForm.get('middle_name')"></app-errors>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Last Name</label>
                        <input type="text" class="form-control" formControlName="last_name">
                        <app-errors [control]="registrationForm.get('last_name')"></app-errors>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Email</label>
                        <input type="text" class="form-control" formControlName="email" autocomplete="off">
                        <app-errors [control]="registrationForm.get('email')"></app-errors>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Password</label>
                        <input type="password" class="form-control" formControlName="password" autocomplete="off">
                        <app-errors [control]="registrationForm.get('password')"></app-errors>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Confirm Password</label>
                        <input type="password" class="form-control" formControlName="confirm_password">
                        <app-errors [control]="registrationForm.get('confirm_password')"></app-errors>
                    </div>


                    <p>
                        <input type="checkbox" formControlName="agree"> By clicking <strong>Agree and Continue</strong>,
                        you agree to RecoveryHub's
                        <a href="../../../../assets/docs/TermsAndConditions.pdf" target="_blank">
                            <u>Terms and Conditions</u>
                        </a> & the
                        <a href="../../../../assets/docs/PrivacyPolicy.pdf" target="_blank">
                            <u>Privacy Policy</u>
                        </a>
                    </p>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary btn-block" [disabled]="registrationForm.invalid">Agree
                            and Continue</button>
                    </div>

                    <div *ngIf="errorMessages">
                        <span class="form-text text-danger" *ngFor="let m of errorMessages">{{m}}</span>
                    </div>
                </form>
                
                <div>
                    <a routerLink="/login">
                        <i class="fa fa-long-arrow-left"></i> Back to Login
                    </a>
                </div>
                <div>
                    <a routerLink="/users/reset-password">
                        <i class="fa fa-long-arrow-left"></i> Reset Password
                    </a>
                </div>
            </div>
        </div>
        <div class="text-center p-5" *ngIf="loading" style="height:300px">
            <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
        </div>
    </div>

    <div class="align-self-center text-center" *ngIf="registered">
        <h3>Thank you for signing up with
            <a href="/"><img src="./assets/images/logo.png" style="width:200px;"></a>
        </h3>
        <p class="lead">
            We have sent you a verification email. Please click the link provided to complete your registration
            process.
        </p>

        <p class="lead">
            Click <a href="/login">here</a> to log in
        </p>
    </div>
</div>


<div class="colophon py-4 text-white">
    <div class="container">
        <div class="d-flex justify-content-between">
            <div class="copyright">
                <small>Copyright &copy; {{year|date:'y'}} Metro Psych Facility. All Rights Reserved.</small>
            </div>
            <div class="footer-links">
                <small><a class="text-white" href="../../../../assets/docs/TermsAndConditions.pdf" target="_blank"><u>Terms and Conditions</u></a></small> |
                <small><a class="text-white" href="../../../../assets/docs/PrivacyPolicy.pdf" target="_blank"><u>Privacy Policy</u></a></small>
            </div>
        </div>
    </div>
</div>