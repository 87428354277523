import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable()
export class AdminService extends AbstractService {
    endpoint = environment.serverUrl + 'admin';

    constructor(http: HttpClient) {
        super(http);
    }
 
    fees(params) {
        this.endpoint = environment.serverUrl + 'admin/fees';

        return this.index(params); 
    }

    listBookings(params) {
        this.endpoint += '/appointments';

        return this.index(params);
    }
}
