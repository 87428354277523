import { Component, OnInit, Injector } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { CreateComponent as CrudCreateComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { PatientService } from '../../../../../services/patient.service';

// STEP 4: IMPORT form
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { COMPANY_MODEL, ERRORS } from '../../company.model';

// STEP 5: IMPORT settings
import { settings } from '../../settings';

@Component({
    selector: 'app-create',
    templateUrl: './../edit/edit.component.html',
    styleUrls: ['./create.component.scss']
})
export class CreateComponent extends CrudCreateComponent implements OnInit {
    formType = 'create';

    errors = ERRORS;
    formGroup: FormGroup;

    constructor(injector: Injector) {
        super(injector);
        this.service = injector.get(PatientService);
        this.setSettings(settings);
    }

    ngOnInit() {
        this.rForm = this.formBuilder.group(COMPANY_MODEL);
    }

}
