import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MedicalPractitionerService extends AbstractService {

    endpoint = environment.serverUrl + 'medical-practitioners';

    myDoctors(params?) {
        // console.log(this.endpoint+'/my');
        let sq = this.buildQueryString(params);
        return this.http.get(this.endpoint+'/my' + sq)
            .map((result: any) => {
                if (result) {
                    return result;
                }
            });
        // my/doctors?filter=id&groupz
    }  
    
    // doctors(params?) {
    //     // console.log(this.endpoint+'/my');
    //     let sq = this.buildQueryString(params);
    //     return this.http.get(this.endpoint+'/medical-practitioners' + sq)
    //         .map((result: any) => {
    //             if (result) {
    //                 return result;
    //             }
    //         });
    //     // my/doctors?filter=id&groupz
    // } 

    getSearch(data: string, role: string) {
      
        let searchStr = data ? 'status:active;'+data : 'status:active';
        let uri = 'filter=id;email&search='+searchStr+'';

    
        if ( role ) {
            uri += '&therapist='+role;
        }
        
        return uri;
    }

    constructor(http: HttpClient) {
        super(http);
    }
}
