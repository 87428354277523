<div class="row mb-3">
    <div class="col-sm-12 text-center mb-2">
        Timezone: <strong>Philippine Timezone</strong>
        <br />
        <em class="small">(This time must be based on Philipine Timezone)</em>
    </div>
    <div class="col-sm-6">
        <div class="col-sm-12 text-small text-center">
            <span class="m-auto">Start time</span>
        </div>
        <div class="col-sm-12">
            <div class="d-flex">
                <ngb-timepicker
                    class="m-lg-0 m-md-auto m-auto"
                    [meridian]="true"
                    [minuteStep]="30"
                    [(ngModel)]="selectedStartAt"
                    (ngModelChange)="timeChanged()"
                ></ngb-timepicker>
                
                <!-- [disabled]="loading" -->
            </div>

            <!-- [{{isValidTime}}] -->
            <!-- <div>
                {{ selectedStartAt | json}}
            </div> -->
        </div>
    </div>
    <div class="col-sm-6">
        <div class="col-sm-12 text-small text-center">
            <span class="m-auto">End time</span>
        </div>
        <div class="col-sm-12">
            <div class="d-flex">
                <ngb-timepicker
                    class="m-lg-0 m-md-auto m-auto"
                    [meridian]="true"
                    [minuteStep]="30"
                    [(ngModel)]="selectedEndAt"
                    (ngModelChange)="timeChanged('end')"
                ></ngb-timepicker>
                
                <!-- [disabled]="loading" -->
            </div>
            <!-- <div>
                {{ selectedEndAt | json}}
            </div> -->
        </div>
    </div>
</div>