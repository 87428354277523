import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { patientName, avatar, getDayOfWeek, formattedPrice, is30Minutes, createMoment } from '../../../../../services/helpers'
import moment from 'moment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})

export class SummaryComponent implements OnInit, OnChanges {

  @Input() slotDetails
  @Input() datePicked
  @Input() actionMode
  @Input() isValidTime: boolean = true
  @Input() selectedEvent: any = null
  @Input() rateCharge: any = null

  public fa = {
    faCheckCircle, faExclamationCircle
  }
  faCheckCircle = faCheckCircle
  faExclamationCircle = faExclamationCircle
  getDayOfWeek = getDayOfWeek
  formattedPrice = formattedPrice
  avatar = avatar
  is30Minutes = is30Minutes

  timezone: string = '';

  getSlotTypeLabel = () => {
    if (this.slotDetails.type) {
      switch (this.slotDetails.type) {
        case 'virtual':
          return '<strong>Virtual</strong>'
        case 'face':
          return '<strong>Face-to-Face</strong>'
      }
    }

    return '<em class="text-muted">Select the type of consultation</em>'
  }

  getClinicLabel = () => {
    if (this.slotDetails.clinic_id) {
      return `<strong>${this.slotDetails.clinic_name}</strong>`
    }

    return '<em class="text-muted">Select a Clinic</em>'
  }

  getDurationLabel = () => {
    if (this.slotDetails.duration) {
      switch (this.slotDetails.duration) {
        case 60:
          return '<strong>1 Hour</strong>'
        case 30:
          return '<strong>30 Minutes</strong>'
      }
    }

    return '<em class="text-muted">Select the duration per consulation</em>'
  }

  getTimezoneLabel = () => {
    if (this.slotDetails.timezone) {
      return this.slotDetails.timezone
    }

    return '<em class="text-muted">Select the timezone you will be in during this schedule</em>'
  }

  getTimeLabel = () => {
    if (this.slotDetails.start_at && this.slotDetails.end_at) {
      const s = moment(this.datePicked).hour(this.slotDetails.start_at.hour).minute(this.slotDetails.start_at.minute).format('YYYY-MM-DD HH:mm');
      const e = moment(this.datePicked).hour(this.slotDetails.end_at.hour).minute(this.slotDetails.end_at.minute).format('YYYY-MM-DD HH:mm');
      const start = createMoment({date: s}).format('hh:mm a')
      const end = createMoment({date: e}).format('hh:mm a')
      this.timezone = createMoment({date: e}).format('zz')
      return `<strong>${start}-${end}</strong>`
    }

    return '<em class="text-muted">Set the time</em>'
  }

  getRateLabel = () => {
    if (!this.slotDetails || !this.slotDetails.start_at || !this.slotDetails.end_at) {
      return '<em class="text-muted">Set the time first!</em>'
    }

    const price = formattedPrice(
        this.rateCharge, 
        getDayOfWeek(moment(this.datePicked)),
        is30Minutes(moment(this.slotDetails.start_at), moment(this.slotDetails.end_at)),
        this.slotDetails.type
    );

    // if(price === null) {
    //   // return `<strong><a class="text-danger" href="/me/account/costs">Please set Clinic Rates</a></strong>`
    // }
    return price
  }

  getPatientName = () => {
    if(this.selectedEvent?.status == 'BOOKED'){
    let text = 'Booked by '
    return text.concat(patientName(this.selectedEvent.data.px))
  }
  }

  ngOnInit() { }


  ngOnChanges(changes: SimpleChanges): void { }

  constructor() { }
}
