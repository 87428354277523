import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from './abstract.service';

@Injectable()
export class CouponService extends AbstractService {
    endpoint = environment.serverUrl + 'shop/coupons/';

    constructor(http: HttpClient) {
        super(http);
      }
    addCoupon(code: string) {
        return this.http.post(this.endpoint + 'apply', { code })
        .map((result: any) => {
            return result;
          }, (err: any) => {
              return err;
          });
    }

    removeCoupon(code: string) {
        return this.http.post(this.endpoint + 'remove', { code });
    }

    // applyCoupon(code) {
    //     return this.http.post(this.endpoint + '/shop/coupons/apply', { code })
    //           .map(result => {
    //             return result
    //           },
    //           err => {
    //             return err
    //           })
    //   }
}
