import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule, Router } from '@angular/router';
import { HttpModule, Http, XHRBackend, ConnectionBackend, RequestOptions } from '@angular/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellComponent } from './shell/shell.component';
import { HeaderComponent } from './shell/header/header.component';
import { RouteReusableStrategy } from './route-reusable-strategy';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';
import { CheckAdminRoleGuard } from './authentication/guards/check-admin-role.guard';
import { CheckStaffRoleGuard } from './authentication/guards/check-staff-role.guard';
import { GuestOnlyGuard } from './authentication/guards/guest-only.guard';
import { I18nService } from './i18n.service';
import { HttpService } from './http/http.service';
import { HttpCacheService } from './http/http-cache.service';
import { SidebarComponent } from './shell/sidebar/sidebar.component';


// import { DashboardComponent } from './theme/dashboard/dashboard.component';
import { DashboardModule } from './theme/dashboard/dashboard.module';
// import { PublicThemeModule } from './theme/public-theme/public-theme.module';
import { PublicThemeComponent } from './theme/public-theme/public-theme.component';
import { IsPatientGuard } from './authentication/guards/is-patient.guard';
import { IsDoctorGuard } from './authentication/guards/is-doctor.guard';


export function createHttpService(backend: ConnectionBackend,
    defaultOptions: RequestOptions,
    httpCacheService: HttpCacheService,
) {
    return new HttpService(backend, defaultOptions, httpCacheService);
}

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        TranslateModule,
        NgbModule,
        RouterModule,

        DashboardModule,
    ],
    declarations: [
        HeaderComponent,
        ShellComponent,
        SidebarComponent,
        PublicThemeComponent,
    ],
    providers: [
        AuthenticationService,
        AuthenticationGuard,
        CheckAdminRoleGuard,
        CheckStaffRoleGuard,
        GuestOnlyGuard,
        IsPatientGuard,
        IsDoctorGuard,
        I18nService,
        HttpCacheService,
        {
            provide: Http,
            deps: [XHRBackend, RequestOptions, HttpCacheService],
            useFactory: createHttpService
        },
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        }
    ]
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        // Import guard
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
        }
    }

}
