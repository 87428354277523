import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PatientService } from '../../../services/patient.service';
import { CrudModule } from '../../../core/base/crud';
import { ListComponent, EditComponent, CreateComponent } from './crud';
import { CompaniesComponent } from './companies.component';
import { CompaniesRoutingModule } from './companies-routing.module';

@NgModule({
    imports: [
        CommonModule,
        NgxDatatableModule,
        NgxErrorsModule,
        NgbModule,
        HttpModule,
        CrudModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        CompaniesRoutingModule
    ],
    declarations: [
        ListComponent,
        EditComponent,
        CreateComponent,
        CompaniesComponent,
    ],
    exports: [],
    providers: [
        PatientService,
    ]
})
export class CompaniesModule { }
