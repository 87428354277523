import { Component, OnInit, ElementRef, Renderer2, NgZone, ViewChild, Injector } from "@angular/core";
import { AuthenticationService } from "../../authentication/authentication.service";
import {
    UserService,
    LoaderService,
    OpenTokService,
    openWindow,
    MentalHealthSheetService,
    avatar,
    activatedSpecialization,
} from "../../../services";
import { environment } from "../../../../environments/environment";
import { User } from "../../../shared/models";
import {
    faTachometerAlt,
    faCalendarAlt,
    faUserClock,
    faUsers,
    faUserCog,
    faSignOutAlt,
    faShoppingCart,
    faList,
    faMoneyBill,
    faQuestionCircle,
    faSearch,
    faLifeRing,
    faCapsules,
    faFilePrescription,
    faFile,
    faFileAlt,
    faPills,
    faTable,

} from "@fortawesome/free-solid-svg-icons";
import { Observable } from "rxjs";

import { NgbModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
    @ViewChild("sinchLogin") sinchLogin: ElementRef;
    @ViewChild("mentalNotify") mentalNotify: ElementRef;

    isPatient: boolean;
    isSecretary: boolean;
    isDoctor: boolean;
    displayName: string;
    profilePicture: string;
    showModal = "false";
    wasDeclined = false;
    isLoading = false;
    public isMenuCollapsed = true;
    isPatientFilesCollapsed = true;
    isDoctorFeesCollapsed = true;
    user;
    fa = {
        tachometer: faTachometerAlt,
        calendar: faCalendarAlt,
        userClock: faUserClock,
        users: faUsers,
        userCog: faUserCog,
        signOut: faSignOutAlt,
        faShoppingCart,
        faList,
        faMoneyBill,
        faQuestionCircle,
        faSearch,
        faLifeRing,
        faCapsules,
        faFilePrescription,
        faFile,
        faFileAlt,
        faPills,
        faTable
    };

    public modalService: any;
    public modal: any;

    activatedSpecialization = activatedSpecialization;

    constructor(
        private authService: AuthenticationService,
        private elRef: ElementRef,
        private renderer: Renderer2,
        public userService: UserService,
        private dataService: MentalHealthSheetService,
        public otService: OpenTokService,
        public loaderService: LoaderService,
        injector: Injector
    ) {
        this.modalService = injector.get(NgbModal);

        this.isPatient = this.userService.checkRole(["patient", "in-patient"]);
        this.isDoctor = this.userService.checkRole(["doctor", "medical-practitioner"]);
        this.isSecretary = this.userService.checkRole(['secretary']);
        // this.isPatient = !!userService.getStoredCredentials().roles.find(({ id }) => id === 3);
        this.userService.userData.asObservable().subscribe((data) => {
            // console.log('data', data)
            this.displayName = data;
        });

        this.userService.avatars.asObservable().subscribe((avatars) => {
            // console.log('avatars', avatars)
            this.profilePicture = avatars[300].url;
            // this.displayName = data
        });

        // this.userService.profilePicture.asObservable().subscribe(
        //     data => this.profilePicture = data
        //     );
        // console.log('avatar(300)', avatar(300))
        this.profilePicture = avatar(300);
        const user = this.userService.getStoredCredentials();

        if (this.isPatient) {
            this.dataService.get(user.id).subscribe((data) => {
                // console.log(data.isMentalDataComplete);
                if (this.isPatient && !data.isMentalDataComplete) {
                    this.modal = this.modalService.open(this.mentalNotify, {
                        ariaLabelledBy: "modal-basic-title",
                        size: "custom-valign",
                        windowClass: "custom-modal-class",
                    });
                }
            });
        }
    }

    openWindow = (url, target = null) => {
        openWindow(url, target);
    };

    ngOnInit() {
        this.renderer.addClass(this.elRef.nativeElement, "wild");
        const creds: User = this.userService.getStoredCredentials();
        this.displayName = creds.fullName;
        this.userService.userData.next(creds.fullName);
        // this.otService.connecting.asObservable().subscribe(v => this.showModal = v);
        // this.otService.wasDeclined.asObservable().subscribe(v => this.wasDeclined = v);
        // this.loaderService.loader.asObservable().subscribe(v => this.isLoading = v);
        // this.userService.show(this.userService.currentUser.id).subscribe(
        //     (result: any) => {
        //     this.user = new Observable(result.data);
        //     let pictureUrl = '../../../../assets/images/users-placeholder.png';

        //     if (this.isPatient) {
        //         pictureUrl = '../../../../assets/images/users-placeholder.png';
        //     } else {
        //         pictureUrl = '../../../../assets/images/doctor-placeholder.png';
        //     }

        //     if (result.data['picture']) {
        //         pictureUrl = result.data['picture'];
        //     }
        //     this.userService.setPicture(pictureUrl);
        //     return result;
        // },
        // (err: any) => {
        //     console.log(err);
        // })
    }

    hangup() {
        // this.otService.cancelCall();
    }

    logout() {
        this.authService.logout().subscribe(() => {
            window.location.href = environment.BASE_URL + "login";
        });
    }
}
