<crud-header 
  [settings]="settings"
  [action]="'list'"
  (refreshList)="refreshList()"
></crud-header>
<div>
  <div class="row">

    <div class="form-group col-sm-12 col-md-9"></div>
    <div class="form-group col-sm-12 col-md-3">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Search" (keyup)="onKeySearch($event)" value="{{search}}">
          <div class="input-group-btn">
            <button class="btn btn-primary" (click)="searchFunc()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>

    </div>
  </div>
  <ngx-datatable
      [rows]="rows"
      [columns]="columns"
      [columnMode]="'force'"
      [footerHeight]="30"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='handlePager($event)'
      (sort)="handleSort($event)"
      [loadingIndicator]="loading"

      class="small material"
  >

    <ngx-datatable-column name="ID" props="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Name" props="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Generic Name" props="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.generic_name }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Unit" props="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Dosage" props="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Price" props="id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span (click)="sort($event)">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ ( value <= 0 ? 'Not Set' : value ) }}
          </ng-template>
      </ngx-datatable-column>

    <ngx-datatable-column name="Status" props="id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>

        <ng-template let-row="row" let-value="row.is_active" ngx-datatable-cell-template>
          <span class="badge badge-pill badge-default" 
            *ngIf="value!==''"
            [class.badge-success]="value=='1'"
            [class.badge-danger]="value=='0'"
            [class.badge-warning]="value=='suspended' || value=='banned'"
          >{{ value =='1' ? 'active' : 'not active'  | uppercase }}</span>
          <span class="badge badge-pill badge-warning" *ngIf="value===''">NOT SET</span>
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Actions" sortable="false" prop="id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          {{column.name}}
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <a *ngIf="settings.crud.update.allow" [routerLink]="[getEditLink(row)]"><i class="fa fa-pencil text-warning"></i></a>
        <a *ngIf="settings.crud.delete.allow" href="javascript:void(0)" (click)="deleteRow(row, deleteModal)"><i class="fa fa-trash text-danger"></i></a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>

<ng-template #deleteModal>
    <div class="modal-body text-center">
      <p>Do you want to delete selected row?</p>
      <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
    </div>
</ng-template>