<crud-header [settings]="settings" [action]="'list'" (refreshList)="refreshList()"></crud-header>
<div>

    <ngx-datatable [rows]="rows" [columns]="columns" [columnMode]="'force'" [footerHeight]="30" [rowHeight]="'auto'"
        [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)='handlePager($event)'
        (sort)="handleSort($event)" [loadingIndicator]="loading" class="small material">
        <ngx-datatable-column name="ID" props="id" maxWidth="40">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort($event)">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ value }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Name" props="name">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{row.first_name}} {{row.last_name}}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Roles" props="roles">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span *ngFor="let role of value.data" class="" style="margin-right:2px">
                    {{role.name }}
                </span>
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Status" props="status">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <span class="" *ngIf="value!=''" [class.text-success]="value=='active'" [class.text-danger]="value=='inactive'"
                    [class.text-warning]="value=='suspended' || value=='banned'">{{
                    value | uppercase }}</span>

                <span class="badge badge-pill badge-warning" *ngIf="value==''">NOT SET</span>
            </ng-template>
        </ngx-datatable-column>


        <ngx-datatable-column name="Date Joined" props="date_joined">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <span (click)="sort()">{{column.name}}</span>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                {{ formatDate(row.date_joined) }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Actions" sortable="false" prop="id" maxWidth="120">
            <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
                <div class="text-center">
                    {{column.name}}
                </div>
            </ng-template>
            <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                <div class="text-center">
                    <a class="btn btn-link btn-sm m-0" *ngIf="settings.crud.update.allow" [routerLink]="settings.crud.update.url + row.id"
                        role="button">
                        <i class="fa fa-pencil text-warning"></i>
                    </a>

                    <a class="btn btn-link btn-sm m-0" *ngIf="settings.crud.delete.allow" href="javascript:void(0)"
                        (click)="onDeleteRow(row, deleteModal)" role="button">
                        <i class="fa fa-trash text-danger"></i>
                    </a>
                </div>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>



<ng-template #deleteModal>
        <div class="modal-body text-center">
          <p>Do you want to delete selected row?</p>
          <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
          <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
        </div>
</ng-template>
