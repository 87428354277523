import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AbstractService } from "../abstract.service";
import { formatDate, downloadPDFFile, doctorName } from "../helpers";
import { isArray } from "lodash";

@Injectable()
export class DeliverMyMedicineService extends AbstractService {
    endpoint = environment.serverUrl + "send-prescriptions";
    filesEndpoint = environment.serverUrl + "files";

    headers = {
        "Access-Control-Allow-Origin": "*",
        Accept: environment.API_HEADER_ACCEPT_v2,
    };

    constructor(public http: HttpClient) {
        super(http);
    }

    loadPrescriptionList() {
        const url = this.endpoint + "/lists";
        return this.http.get<{ data: ListResponse[] }>(url, { headers: { ...this.headers } }).pipe(
            map(({ data: values }) => values.map((data) => new Consultation(data))),

            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    console.error(err.message);
                }

                return of([]);
            })
        );
    }

    loadFiles(type: string, roles?: string) {
        const url = this.filesEndpoint + "/" + type;
        return this.http
            .get(url, {
                headers: { ...this.headers },
                params: roles ? { roles } : {},
            })
            .pipe(
                map((v) => (isArray(v) ? v : Object.values(v))),
                map((arr) => {
                    return arr.map((obj: any) => {
                        const consultation: Consultation = {
                            // created_at: obj.start_at.date,
                            consultationDate: formatDate(obj.start_at.date),
                            doctor: doctorName(obj.doctor),
                            id: obj.notes.event_id,
                            meta: obj,
                        };
                        return consultation;
                    });
                })
            );
    }

    async viewPrescription(event_id: number) {
        const headers = { ...this.headers };

        await this.http
            .post(
                this.endpoint + "/view",
                { event_id },
                {
                    headers,
                    responseType: "blob",
                }
            )
            .pipe(tap((res) => downloadPDFFile(res)))
            .toPromise();
    }

    async viewFile(event_id: number, type: string) {
        const headers = { ...this.headers };

        await this.http
            .post(
                this.filesEndpoint + "/view",
                { event_id, type },
                {
                    headers,
                    responseType: "blob",
                }
            )
            .pipe(tap((res) => downloadPDFFile(res)))
            .toPromise();
    }

    async sendMedicationForm(body: MedicationForm) {
        const headers = { ...this.headers };

        return this.http.post(this.endpoint + "/send", body, { headers }).toPromise();
    }

    async getMedications(consultationId: number) {
        let result;

        const headers = { ...this.headers };
        result = await this.http.get(this.endpoint + "/" + consultationId, { headers }).toPromise();

        return new ConsultationDetails(result.data);
    }

    getPharmacies(): Observable<Pharmacy[]> {
        return of([
            {
                id: 1,
                name: "Mediclick",
            },
        ]);
    }

    getScores() {
        const headers = { ...this.headers };
        return this.http.post(this.endpoint + "/otsuka-mars/scores", { patient_id: 305 }, { headers });
    }
}

interface ListResponse {
    id: number;
    start_at: string;
    end_at: string;
    dr: string;
    follow_up: string;
    med_expiration: string;
    is_sent_to_pharmacy: number[];
}

export interface Pharmacy {
    id: number;
    name: string;
}

export interface MedicationForm {
    event_id: string;
    pharmacy_id: string;
    contact_person: string;
    mobile_number: string;
    address: string;
    city: string;
    landmark: string;
    items: { name: string; quantity: string }[];
}

export class Consultation {
    id: number;
    doctor: string;
    prescriptionExpiry?: string;
    consultationDate: string;
    nextConsultation?: string;
    sentList?: number[];

    meta?: any;

    constructor(value: ListResponse) {
        this.id = value.id;
        this.doctor = value.dr;
        this.prescriptionExpiry = formatDate(value.med_expiration);
        this.sentList = value.is_sent_to_pharmacy;

        this.consultationDate = formatDate(value.start_at);
        this.nextConsultation = value.follow_up;
    }
}

export class ConsultationDetails {
    id: number;
    doctor: string;
    created_at: string;
    patient: string;
    constructor(value: any) {
        this.id = value.id;
        this.doctor = value.doctor;
        this.patient = value.patient;
        this.created_at = formatDate(value.created_at);
    }
}
