export const settings = {
    plural: 'Roles',
    singular: 'role',
    
  
    crud: {
      create: {
        allow: true, // true/false
        url: '/admin/roles/create'
      },
      read:{
  
      },
      update:{
        allow: true,
        url: '/admin/roles/edit/'
      },
      delete:{
        allow: true
      },
      list: {
        allow: true,
        url: '/admin/roles'
      }
    }
  };