export const settings = {
    plural: 'Orders',
    singular: 'Order',
  
    crud: {
      list: {
        allow: true,
        url: '/admin/orders'
      },
      view: {
        allow: true,
        url: '/admin/orders/view/'
      },
      update:{
        allow: true,
        url: '/admin/orders/edit/'
      },
      create:{
        allow: false,
        url: '/admin/orders/create/'
      }
    }
  };