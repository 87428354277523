import { Component, OnInit, Input, Output, Injector, EventEmitter } from '@angular/core';
import { CrudComponent } from '../crud.component';

@Component({
    selector: 'crud-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends CrudComponent implements OnInit {

    @Output() refreshList: EventEmitter<any> = new EventEmitter();
    @Input() settings: any;
    @Input() action: any;
    @Input() title = '';
    @Output() id = '';

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => this.id = params.id);
    }


    doRefreshList() {
        this.refreshList.emit();
    }

}
