import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewEncapsulation, SimpleChanges, OnChanges } from '@angular/core';
import { Medication } from '../../models';
import { FormBuilder } from '@angular/forms';
import { instructions, injectableInstructions } from './medications';
import { NotesService } from '../../../services';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { lowerCase } from 'lodash';
import { faTrashAlt, faFileImport, faPills, faBookMedical, faFileMedical } from '@fortawesome/free-solid-svg-icons';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'app-medication-table',
    templateUrl: './medication-table.html',
    styleUrls: ['./medication-table.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MedicationTableComponent implements OnChanges {
    @Input() medications: Medication[];
    @Input() medicationsCustom = [];
    @Input() editable = true;
    @Output() onSubmit = new EventEmitter<Medication>();
    @Output() stateSaver = new EventEmitter<Medication[]>();
    @Input() event: any

    medList: any[];
    brandList: any[];
    genericList: any[];
    dosageList: any[];
    instructionlist: any[];
    loading = true;

    fa = {
        faTrashAlt
    }

    faFileImport = faFileImport
    faPills = faPills
    faBookMedical = faBookMedical
    faFileMedical = faFileMedical

    constructor(
        private fb: FormBuilder, 
        private noteService: NotesService,
        public toastr: ToastrManager,
    ) {

        this.brandList = [];
        this.dosageList = [];
        this.loading = true;
        this.noteService.getMedicineList()
            .subscribe((data: any) => {
                this.medList = data.list;
                data.generics.push({
                    id:0,
                    name: 'Add medication',
                    medicines: [],
                })
                this.genericList = data.generics.filter(o => o.medicines.length > 0);
                this.loading = false;
            });
    }

    typeaheadOnBlur(event: any, index: number): void {
        this.onSelectGeneric(event, index);
    }

    addRow() {
        this.onSubmit.emit(new Medication('', '', '', '', '', '', '', 0, 0));
        this.medications.push(new Medication(
            '',
            '',
            '',
            '',
            '',
            null,
            null,
            '',
            null,
            null,     
            false,
            null,
            null
        ))
        this.saveState()
    }

    uniqFilter(model: any) {
        return (v, i, a) => a.findIndex(item => item[model] === v[model]) === i;
    }

    onSelectGeneric(event: TypeaheadMatch, index: number) {
        const item = event.item;
        this.brandList = this.medList
            .filter(o => o.genericName === item.name)
            .filter(this.uniqFilter('brand'));

        this.medications[index].generic_name = item.genericName;
        this.saveState()
    }

    onSelectBrand(event: TypeaheadMatch, index: number) {
        const item = event.item;
        // this.dosageList = item.dosageList;
        this.dosageList = item.raw.dosagesFormated;
        this.medications[index].medicine_id = item.medId;
        this.medications[index].is_custom = false;

        this.medications[index].brand_name = item.brand;

        this.instructionlist = lowerCase(item.remarks) === 'injectable' ? injectableInstructions : instructions;
        this.saveState()
    }

    onSelectBrandCustom = (event, index) => {
        this.medications[index].is_custom = false;
        if (this.medications[index].medicine_id) {
            this.medications[index].is_custom = true;
        }
        this.medications[index].medicine_id = null
        this.medications[index].brand = event.target.value;
        
    }

    onSelectDosage(event: TypeaheadMatch, index: number) {
        const item = event.item;
        this.medications[index].dosage = item;
        this.saveState()
    }

    saveState() {
        this.stateSaver.emit(this.medications);
    }

    removeMedication = (index) => {
        this.medications[index].is_deleted = true;
        // this.medications.splice(index,1)
    }

    onChangeCheckbox = (type, index, e) => {
        this.medications[index][type] = e.target.checked ? 1 : 0
    }

    pullLast = () => {
        if (this.event) {
            this.noteService.getMedicinesFromLastConsultation(this.event.id)
            .subscribe(res => {
                if (!res.length) {
                    this.toastr.warningToastr('You do not have previous prescription records with this patient');
                } else {

                    // Clear list
                
                    /*
                    1 generic: string,
                    2 brand: string,
                    3 dosage: string,
                    4 tabsDispensed: string,
                    5 instruction: string,
                    6 expiration: any,
                    7 id: any,
                    8 is_s2?: any,
                    9 is_injectable?: any,
                    10 medicine_id?: any,
                    11 is_deleted?: boolean,
                    12 is_custom?: boolean,
                    13 from_custom_table?: boolean
                    */
                    res.forEach((row, i) => {
                        this.onSubmit.emit(new Medication(
                            row.generic_name,    // 1
                            row.brand_name,     // 2 
                            row.dosage,         // 3   
                            row.qty_tabs_dispensed,       // 4
                            row.instructions,    // 5 
                            row.expiration,               // 6 
                            null,               // 7 
                            row.is_s2,          // 8 
                            row.is_injectable,                   // 9
                            (row.medicine) ? row.medicine.id : null,                // 10
                            false,                // 11
                            true               // 12 
                                            // 13
                        ));

                        this.medications.push(new Medication(
                            row.generic_name,
                            row.brand_name,
                            row.dosage,
                            row.qty_tabs_dispensed,
                            row.instructions,
                            null,
                            row.id,
                            row.is_s2,
                            row.is_injectable,
                            (row.medicine) ? row.medicine.id : null,     
                            false,
                            row.is_custom,
                            row.from_custom_table
                        ))
                    })

                    this.saveState()
                    
                    this.toastr.successToastr('Previous record of prescription has been added');
                }
            })
        }
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.medications && changes.medications.currentValue) {
            changes.medications.currentValue.forEach((row, i) => {
                row['generic'] = row.generic_name
                row['brand'] = row.brand_name

                if (!row.generic && row.medicine && row.medicine.generic_name) {
                    row['generic'] = row.medicine.generic_name
                    row['generic_name'] = row.medicine.generic_name
                }

                if (!row.brand && row.medicine && row.medicine.name) {
                    row['brand'] = row.medicine.name
                    row['brand_name'] = row.medicine.name
                }

                if (!row.tabsDispensed && row.qty_tabs_dispensed) {
                    row['tabsDispensed'] = row.qty_tabs_dispensed
                }

                if (!row.instruction && row.instructions) {
                    row['instruction'] = row.instructions
                }
            })

        }


        if (changes.medicationsCustom && changes.medicationsCustom.currentValue) {
            changes.medicationsCustom.currentValue.forEach((row, i) => {
                let ok = true;
                this.medications.forEach((medRow, medRowI) => {
                    if (row.generic_name == medRow.generic &&
                        row.brand_name == medRow.brand &&
                        row.dosage == medRow.dosage &&
                        row.quantity == medRow.tabsDispensed &&
                        row.instructions == medRow.instruction
                    ) {
                        ok = false
                    }
                })
               
                if (ok) {
                    // console.log('row', row)
                    this.medications.push(new Medication(
                        row.generic_name,
                        row.brand_name,
                        row.dosage,
                        row.quantity,
                        row.instructions,
                        null,
                        row.id,
                        row.is_s2,
                        row.is_injectable,
                        null,
                        false,
                        row.is_custom,
                        row.from_custom_table
                    ))
                }
                
            });

        }
    }

}
