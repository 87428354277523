import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MedicinesService extends AbstractService{

  endpoint = environment.serverUrl + 'medicine-inventory';

    constructor(http: HttpClient) {
        super(http);
    }

    getSearch(data: string) {
      
      let uri = 'search='+data+'&searchFields=name:like;remarks:like;unit:like&searchJoin=or';
      
      return uri;
      // return this.http.get(this.endpoint + uri)
      //       .map(result => result, error => error);
    }
    

}
