import { Component, OnInit, Injector } from '@angular/core';

import { BaseCrudFormComponent } from '../base-crud-form/base-crud-form.component';
import { Router } from '@angular/router';


@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.scss']
})
export class CreateComponent extends BaseCrudFormComponent {

    router: Router;

    constructor(injector: Injector) {
        super(injector);
        this.router = injector.get(Router);

    }

}
