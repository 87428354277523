import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "../../../services";

@Component({
    selector: "app-doctors",
    templateUrl: "./doctors.component.html",
    styleUrls: ["./../phome/phome.component.scss"],
})
export class DoctorsComponent {
    doctor: string;

    user = {
        id: null,
    };
    constructor(private userService: UserService, private route: ActivatedRoute) {
        this.user = this.userService.getStoredCredentials();
    }

    get imgSrc() {
        return `./assets/images/${this.doctor}.jpg`;
    }
}
