import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PatientService extends AbstractService {
    endpoint = environment.serverUrl + 'patients';

    myPatients(params?) {
        // console.log(this.endpoint+'/my');
        let sq = this.buildQueryString(params);
        return this.http.get(this.endpoint+'/my' + sq)
            .map((result: any) => {
                if (result) {
                    return result;
                }
            });
        // my/doctors?filter=id&groupz
    } 
    
    getSearch(data: string) {
      
        let uri = 'search='+data+'&searchFields=email:like;first_name:like;last_name:like&searchJoin=or';
        
        return uri;
    }

    constructor(http: HttpClient) {
        super(http);
    }
}
