<nav class="navbar navbar-expand-md navbar-light fixed-top bg-light d-print-none">
    <a class="navbar-brand" [routerLink]="['/me']">
        <img src="./assets/images/logo.png" class="logo" style="max-width: none;">
    </a>
    <button class="navbar-toggler d-print-none" type="button" data-toggle="collapse" data-target="#navbarNav"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
</nav>

<nav id="navbarNav" class="collapse navbar-dark bg-dark mobile-nav">
    <div class="row sidebar-sticky">
        <ul class="nav flex-column w-100">
            <li class="nav-item" *ngIf="isPatient || isDoctor">
                <a [routerLink]="['/me/appointments']" [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.calendar"></fa-icon>
                    </span> My Appointments</a>
            </li>

            <li class="nav-item" *ngIf="isSecretary">
                <a [routerLink]="['/me/secretary/appointments']" [routerLinkActiveOptions]="{ exact: true }"
                    routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.calendar"></fa-icon>
                    </span> Doctor Appointments</a>
            </li>

            <li class="nav-item" *ngIf="!isSecretary">
                <a [routerLink]="['/me']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
                    class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.tachometer"></fa-icon>
                    </span> Instructions</a>
            </li>
            <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                <a [routerLink]="['/me/availability']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.userClock"></fa-icon>
                    </span> Clinic Hours</a>
            </li>

            <li class="nav-item" *ngIf="isSecretary">
                <a [routerLink]="['/me/secretary/availability']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.userClock"></fa-icon>
                    </span> Doctor Clinic Hours</a>
            </li>

            <li class="nav-item" *ngIf="isPatient">
                <!-- <a  [routerLink]="['/doctors']" routerLinkActive="active" data-toggle="collapse" class="nav-link" (click)="isMenuCollapsed = !isMenuCollapsed"></a> -->
                <a routerLinkActive="active" data-toggle="collapse" class="nav-link"
                    (click)="isMenuCollapsed = !isMenuCollapsed">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faSearch"></fa-icon>
                    </span> Find Doctors
                </a>
                <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
                    <ul class="navbar-nav nav-second" id="sub2">
                        <!-- <li class="nav-item">
                            <a [routerLink]="['/doctors/find']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.faLifeRing"></fa-icon>
                                </span> Help me choose</a>
                        </li> -->
                        <!--


                        <li class="nav-item">
                            <a [routerLink]="['/doctors/gynecologist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Gynecologists</a>
                        </li>

                        <li class="nav-item">
                            <a [routerLink]="['/doctors/opthalmologist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Opthalmologists</a>
                        </li>
                        -->
                        <!-- <li class="nav-item">
                            <a [routerLink]="['/doctors/dermatologist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Dermatologists</a>
                        </li> -->
                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('ent')">
                            <a [routerLink]="['/doctors/ent']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> ENT</a>
                        </li> -->

                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('family-physician')">
                            <a [routerLink]="['/doctors/family-physician']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Family Physician</a>
                        </li> -->

                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('general-practitioner')">
                            <a [routerLink]="['/doctors/general-practitioner']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> General Practitioner</a>
                        </li> -->

                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('internist')">
                            <a [routerLink]="['/doctors/internist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Internists</a>
                        </li> -->
                        <li class="nav-item">
                            <a [routerLink]="['/doctors/neurologist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Neurologists</a>
                        </li>

                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('pediatrician')">
                            <a [routerLink]="['/doctors/pediatrician']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Pediatrician</a>
                        </li> -->

                        <li class="nav-item" *ngIf="isPatient && activatedSpecialization('psychiatrist')">
                            <a [routerLink]="['/doctors/psychiatrist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Psychiatrists</a>
                        </li>
                        <li class="nav-item" *ngIf="isPatient && activatedSpecialization('psychologist')">
                            <a [routerLink]="['/doctors/psychologist']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Clinical Psychologists</a>
                        </li>
                        <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('surgeon')">
                            <a [routerLink]="['/doctors/surgeon']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Surgeons</a>
                        </li> -->
                        <!--
                        <li class="nav-item">
                            <a [routerLink]="['/doctors/physiatrists']" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.users"></fa-icon>
                                </span> Physiatrists</a>
                        </li>
                        -->
                    </ul>
                </div>
            </li>
            <!-- <li class="nav-item" *ngIf="isPatient">
                <a [routerLink]="['/me/prescriptions']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <i class="fa fa-file"></i>
                    </span> My Prescriptions</a>
            </li> -->
            <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                <a [routerLink]="['/me/patients']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.users"></fa-icon>
                    </span> My Patients</a>
            </li>

            <li class="nav-item" *ngIf="isSecretary">
                <a [routerLink]="['/me/secretary/patients']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.users"></fa-icon>
                    </span> Doctor Patients</a>
            </li>

            <li class="nav-item" *ngIf="!isSecretary">
                <a [routerLink]="['/me/account']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.userCog"></fa-icon>
                    </span> My Account</a>
            </li>



            <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                <a [routerLink]="['/me/fees']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faMoneyBill"></fa-icon>
                    </span> Fees</a>
            </li>


            <li class="nav-item" *ngIf="isPatient">
                <a [routerLink]="['/me/appointments/payment']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faShoppingCart"></fa-icon>
                    </span> My Cart</a>
            </li>
            <li class="nav-item" *ngIf="isPatient">
                <a [routerLink]="['/purchase/orders']" routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faList"></fa-icon>
                    </span> My Orders</a>
            </li>


            <li class="nav-item" *ngIf="isPatient">
                <a routerLinkActive="active" data-toggle="collapse" class="nav-link"
                    (click)="isPatientFilesCollapsed = !isPatientFilesCollapsed">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faFilePrescription"></fa-icon>
                    </span> My Files
                </a>
                <div [ngbCollapse]="isPatientFilesCollapsed" class="collapse navbar-collapse">
                    <ul class="navbar-nav nav-second" id="sub2">
                        <li class="nav-item" >
                            <a [routerLink]="['/me/files/prescriptions']" routerLinkActive="active" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.faFilePrescription"></fa-icon>
                                </span> Prescriptions</a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="['/me/files/lab-request']" routerLinkActive="active" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                                </span> Laboratory Requests</a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="['/me/files/med-certs']" routerLinkActive="active" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                                </span> Medical Certificates</a>
                        </li>
                        <li class="nav-item" >
                            <a [routerLink]="['/me/other']" routerLinkActive="active" class="nav-link">
                                <span class="nav-icon">
                                    <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                                </span> Other files</a>
                        </li>
                    </ul>
                </div>
            </li>

            <li class="nav-item" *ngIf="isPatient">
                <a href="https://www.lifeportpharmacy.ph/" target="_blank" class="nav-link" title="Lifeport Pharmacy">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faPills"></fa-icon>
                    </span> Buy Medicine Online</a>
            </li>

            <li class="nav-item" *ngIf="isPatient">
                <a (click)="openWindow('https://www.evernote.com/shard/s325/client/snv?noteGuid=063cd80a-05f4-41c6-a34f-d57dddf25f33&noteKey=e71b8bfe7cb08068&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs325%2Fsh%2F063cd80a-05f4-41c6-a34f-d57dddf25f33%2Fe71b8bfe7cb08068&title=Frequently%2BAsked%2BQuestions', '_blank')"
                    routerLinkActive="active" class="nav-link">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.faQuestionCircle"></fa-icon>
                    </span> FAQ</a>
            </li>

            <li class="nav-item cta-logout">
                <a class="nav-link" style="cursor: pointer" (click)="logout()">
                    <span class="nav-icon">
                        <fa-icon [icon]="fa.signOut"></fa-icon>
                    </span> Logout</a>
            </li>
        </ul>
    </div>
</nav>

<div class="container-fluid">
    <div class="row flex-nowrap">
        <nav id="nav-bar" class=" col-md-2 d-none d-print-none d-md-block bg-inverse sidebar">
            <div class="row sidebar-sticky">
                <ul class="nav flex-column w-100">
                    <li class="nav-item pt-3 text-center mb-3">
                        <figure class="figure text-center m-auto">
                            <div style="background-image: url({{profilePicture}});"
                                class="figure-img img-fluid rounded-circle w-50" alt="..."></div>
                            <figcaption class="figure-caption text-light">{{displayName}}</figcaption>
                        </figure>
                    </li>
                    <li class="nav-item" *ngIf="isPatient || isDoctor">
                        <a [routerLink]="['/me/appointments']" [routerLinkActiveOptions]="{ exact: true }"
                            routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.calendar"></fa-icon>
                            </span> My Appointments</a>
                    </li>

                    <li class="nav-item" *ngIf="isSecretary">
                        <a [routerLink]="['/me/secretary/appointments']" [routerLinkActiveOptions]="{ exact: true }"
                            routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.calendar"></fa-icon>
                            </span> Doctor Appointments</a>
                    </li>
                    <li class="nav-item" *ngIf="!isSecretary">
                        <a [routerLink]="['/me']" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active"
                            class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.tachometer"></fa-icon>
                            </span> Instructions</a>
                    </li>
                    <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                        <a [routerLink]="['/me/availability']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.userClock"></fa-icon>
                            </span> Clinic Hours</a>
                    </li>
                    <li class="nav-item" *ngIf="isSecretary">
                        <a [routerLink]="['/me/secretary/availability']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.userClock"></fa-icon>
                            </span> Doctor Clinic Hours</a>
                    </li>
                    <li class="nav-item" *ngIf="isPatient">
                        <!-- <a  [routerLink]="['/doctors']" routerLinkActive="active" data-toggle="collapse" class="nav-link" (click)="isMenuCollapsed = !isMenuCollapsed"></a> -->
                        <a routerLinkActive="active" data-toggle="collapse" class="nav-link"
                            (click)="isMenuCollapsed = !isMenuCollapsed">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faSearch"></fa-icon>
                            </span> Find Doctors
                        </a>
                        <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
                            <ul class="navbar-nav nav-second" id="sub2">
                                <!-- <li class="nav-item" *ngIf="isPatient">
                                    <a [routerLink]="['/doctors/find']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faLifeRing"></fa-icon>
                                        </span> Help me choose</a>
                                </li> -->
                                <!--


                                <li class="nav-item" *ngIf="isPatient">
                                    <a [routerLink]="['/doctors/gynecologist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Gynecologists</a>
                                </li>
                                <li class="nav-item" *ngIf="isPatient">
                                    <a [routerLink]="['/doctors/opthalmologist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Opthalmologists</a>
                                </li>
                                -->

                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('dermatologist')">
                                    <a [routerLink]="['/doctors/dermatologist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Dermatologists</a>
                                </li> -->
                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('ent')">
                                    <a [routerLink]="['/doctors/ent']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> ENT</a>
                                </li> -->

                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('family-physician')">
                                    <a [routerLink]="['/doctors/family-physician']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Family Physician</a>
                                </li> -->

                                <!-- <li class="nav-item"
                                    *ngIf="isPatient && activatedSpecialization('general-practitioner')">
                                    <a [routerLink]="['/doctors/general-practitioner']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> General Practitioner</a>
                                </li> -->

                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('internist')">
                                    <a [routerLink]="['/doctors/internist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Internists</a>
                                </li> -->
                                <li class="nav-item" *ngIf="isPatient && activatedSpecialization('neurologist')">
                                    <a [routerLink]="['/doctors/neurologist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Neurologists</a>
                                </li>

                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('pediatrician')">
                                    <a [routerLink]="['/doctors/pediatrician']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Pediatrician</a>
                                </li> -->

                                <li class="nav-item" *ngIf="isPatient && activatedSpecialization('psychiatrist')">
                                    <a [routerLink]="['/doctors/psychiatrist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Psychiatrists</a>
                                </li>
                                <li class="nav-item" *ngIf="isPatient && activatedSpecialization('psychologist')">
                                    <a [routerLink]="['/doctors/psychologist']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Clinical Psychologists</a>
                                </li>
                                <!-- <li class="nav-item" *ngIf="isPatient && activatedSpecialization('surgeon')">
                                    <a [routerLink]="['/doctors/surgeon']" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.users"></fa-icon>
                                        </span> Surgeons</a>
                                </li> -->
                            </ul>
                        </div>
                    </li>

                    <!-- <li class="nav-item" *ngIf="isPatient">
                        <a [routerLink]="['/me/prescriptions']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <i class="fa fa-file"></i>
                            </span> My Prescriptions</a>
                    </li> -->
                    <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                        <a [routerLink]="['/me/patients']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.users"></fa-icon>
                            </span> My Patients</a>
                    </li>
                    <li class="nav-item" *ngIf="isSecretary">
                        <a [routerLink]="['/me/secretary/patients']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.users"></fa-icon>
                            </span> Doctor Patients</a>
                    </li>
                    <li class="nav-item" *ngIf="!isSecretary">
                        <a [routerLink]="['/me/account']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.userCog"></fa-icon>
                            </span> My Account</a>
                    </li>

                    <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                        <a routerLinkActive="active" data-toggle="collapse" class="nav-link"
                            (click)="isDoctorFeesCollapsed = !isDoctorFeesCollapsed">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faMoneyBill"></fa-icon>
                            </span> Fees
                        </a>
                        <div [ngbCollapse]="isDoctorFeesCollapsed" class="collapse navbar-collapse">
                            <ul class="navbar-nav nav-second" id="sub2">
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/fees']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faTable"></fa-icon>
                                        </span> Consultations</a>
                                </li>
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/addons']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faTable"></fa-icon>
                                        </span> Add-ons</a>
                                </li>
                            </ul>
                        </div>
                    </li>


                    <li class="nav-item" *ngIf="!isPatient && !isSecretary">
                        <a (click)="openWindow('https://www.evernote.com/l/AUVNbAk3s_VMqIYCWXDbX6UwVuyPoV9EGmE', '_blank')"
                            routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faQuestionCircle"></fa-icon>
                            </span> FAQ</a>
                    </li>


                    <li class="nav-item" *ngIf="isPatient">
                        <a [routerLink]="['/me/appointments/payment']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faShoppingCart"></fa-icon>
                            </span> My Cart</a>
                    </li>
                    <li class="nav-item" *ngIf="isPatient">
                        <a [routerLink]="['/purchase/orders']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faList"></fa-icon>
                            </span> My Orders</a>
                    </li>

                    <!-- <li class="nav-item" *ngIf="isPatient">
                        <a [routerLink]="['/me/files/prescriptions']" routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faCapsules"></fa-icon>
                            </span> Deliver My Medicines</a>
                    </li> -->
                    <li class="nav-item" *ngIf="isPatient">
                        <!-- <a  [routerLink]="['/doctors']" routerLinkActive="active" data-toggle="collapse" class="nav-link" (click)="isMenuCollapsed = !isMenuCollapsed"></a> -->
                        <a routerLinkActive="active" data-toggle="collapse" class="nav-link"
                            (click)="isPatientFilesCollapsed = !isPatientFilesCollapsed">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                            </span> My Files
                        </a>
                        <div [ngbCollapse]="isPatientFilesCollapsed" class="collapse navbar-collapse">
                            <ul class="navbar-nav nav-second" id="sub2">
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/files/prescriptions']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faFilePrescription"></fa-icon>
                                        </span> Prescriptions</a>
                                </li>
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/files/lab-request']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faFile"></fa-icon>
                                        </span> Laboratory Requests</a>
                                </li>
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/files/med-certs']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                                        </span> Medical Certificates</a>
                                </li>
                                <li class="nav-item" >
                                    <a [routerLink]="['/me/files/other']" routerLinkActive="active" class="nav-link">
                                        <span class="nav-icon">
                                            <fa-icon [icon]="fa.faFileAlt"></fa-icon>
                                        </span> Other Files</a>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="nav-item" *ngIf="isPatient">
                        <a href="https://www.lifeportpharmacy.ph/" target="_blank" class="nav-link" title="Lifeport Pharmacy">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faPills"></fa-icon>
                            </span> Buy Medicine Online</a>
                    </li>

                    <!-- <li class="nav-item" *ngIf="isPatient">
                        <a href="https://lifeportpharmacy.ph" target="_blank" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faQuestionCircle"></fa-icon>
                            </span> Lifeport Pharmacy</a>
                    </li> -->

                    <li class="nav-item" *ngIf="isPatient">
                        <a (click)="openWindow('https://www.evernote.com/shard/s325/client/snv?noteGuid=063cd80a-05f4-41c6-a34f-d57dddf25f33&noteKey=e71b8bfe7cb08068&sn=https%3A%2F%2Fwww.evernote.com%2Fshard%2Fs325%2Fsh%2F063cd80a-05f4-41c6-a34f-d57dddf25f33%2Fe71b8bfe7cb08068&title=Frequently%2BAsked%2BQuestions', '_blank')"
                            routerLinkActive="active" class="nav-link">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.faQuestionCircle"></fa-icon>
                            </span> FAQ</a>
                    </li>

                    <li class="nav-item cta-logout">
                        <a class="nav-link" style="cursor: pointer" (click)="logout()">
                            <span class="nav-icon">
                                <fa-icon [icon]="fa.signOut"></fa-icon>
                            </span> Logout</a>
                    </li>
                </ul>
            </div>
        </nav>
        <main class="col-sm-12 col-md-9 ml-sm-auto col-lg-10 pt-3 px-4">
            <app-overlay class="w-100" [class.d-none]="!isLoading"></app-overlay>
            <router-outlet></router-outlet>
            <footer class="small d-print-none">
                <ul>
                    <li>Contact Us: <a href="tel:(6332) 344-2142">+63 (32) 344-2142</a></li>
                    <li>Email: <a href="#" class="cryptedmail" data-part1="info" data-part_2="recoveryhub"
                            data-partthree="ph"
                            onclick="window.open('mailto:' + this.dataset.part1 + '@' + this.dataset.part_2 + '.' + this.dataset.partthree, '_blank'); return false;"></a>
                    </li>
                </ul>
            </footer>
        </main>
    </div>
</div>

<div class="overlay d-flex align-items-center justify-content-center" *ngIf="showModal !== 'false'">
    <div class="d-flex align-items-center justify-content-center flex-column text-light"
        *ngIf="showModal === 'incoming'">
        <h3 *ngIf="showModal === 'incoming'">Incoming call</h3>
        <h3 *ngIf="showModal === 'connecting' || showModal === 'requesting'">Connecting call</h3>
        <small *ngIf="showModal === 'incoming'">from {{otService.caller.name}}</small>
        <div class="p-5" *ngIf="showModal === 'incoming'">
            <button type="button" class="btn btn-primary mr-5" (click)="otService.answer()">Answer</button>
            <button type="button" class="btn btn-light" (click)="otService.decline()">Decline</button>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-column text-light"
        *ngIf="showModal === 'requesting'">
        <h3>{{wasDeclined ? 'Caller Declined':'Connecting call...'}}</h3>
        <small *ngIf="showModal === 'requesting'">ringing {{otService.receiver.name}}</small>
        <div class="p-5">
            <button type="button" class="btn btn-light" (click)="hangup()">{{wasDeclined ? 'Close':'Cancel'}}</button>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-column text-light"
        *ngIf="showModal === 'userInSession' || showModal === 'userNotConnected'">
        <h3>Connection failed.</h3>
        <small *ngIf="showModal === 'userNotConnected'">User is not online</small>
        <small *ngIf="showModal === 'userInSession'">User is currently in another call</small>
        <div class="p-5">
            <button type="button" class="btn btn-light"
                (click)="otService.connecting.next('false');otService.audioStop();">Close</button>
        </div>
    </div>

    <div class="d-flex align-items-center justify-content-center flex-column text-light"
        *ngIf="showModal === 'creatingSession' || showModal === 'createdSession'">
        <h3>Connection established</h3>
        <small>{{showModal==='createdSession'? 'succesfully created session':'creating session...'}}</small>
        <div class="p-5" *ngIf="showModal === 'createdSession'">
            <button type="button" class="btn btn-light" (click)="otService.connecting.next('false')">Connect to
                session</button>
        </div>
    </div>
</div>

<ng-template #mentalNotify let-c="close" let-d="dismiss">
    <div class="modal-body text-center">
        <img src="{{profilePicture}}" class="figure-img img-fluid rounded-circle w-50" alt="...">

        <p style="font-size: 18px;font-weight: 600;margin: 30px;">Complete your profile and answer your health data
            sheet</p>
        <p><em>We updated the health data sheet recently. If you are seeing this again, please review the health data
                sheet for a required field that you may have missed.</em></p>

        <a class="btn btn-primary" (click)="d()" [routerLink]="['/me/account/questionnaire']">Continue</a>
    </div>

</ng-template>
