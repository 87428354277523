import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ClinicService extends AbstractService {
  endpoint = `${environment.serverUrl}clinics/`;


  getByDoctorId(drId, params = {}) {
    return this.http.get(
      `${this.endpoint}doctor/${drId}${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  show(clinicId) {
    return this.http.get(
      `${this.endpoint}${clinicId}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  update(id: any, params: any) {
    return this.http.put(
      `${this.endpoint}${id}`,
      params,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  store(data: any) {
    return this.http.post(
      this.endpoint,
      data,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  remove(id) {
    return this.http.delete(
      `${this.endpoint}${id}`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  attachImage = (clinicId, file: File) => {
    const endpoint = `${this.endpoint}${clinicId}/image`
    const formData: FormData = new FormData();
    formData.append('clinic_image', file, file.name)

    return this.http.post(
      endpoint,
      formData,
      {
        reportProgress: true,
        responseType: 'json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': environment.API_HEADER_ACCEPT_v2,
        }
      })
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
