import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Logger } from '../../logger.service';
import { UserService } from '../../../services/user.service';

const log = new Logger('CheckRoleGuard');

@Injectable()
export class CheckAdminRoleGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // console.log('is Admin? ', this.userService.checkRole(['admin']));
        if (this.userService.checkRole(['admin']) || this.userService.checkRole(['rxfort']) ) {
            return true;
        } else {
            log.debug('You are not ADMIN, redirecting to "me"...');
            this.router.navigate(['/me'], { replaceUrl: true });
            return false;
        }
    }
}
