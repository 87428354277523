import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'section-lab-request',
    templateUrl: './lab-request.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionLabRequestComponent implements OnChanges {
    @Input() data: object
    @Input() eventId

    title = 'Laboratory Requests'
    value = '<em class="text-muted">Not specified</em>'
    match = true
    dsmList = []
    customDsmList = []
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private noteService: NotesService
    ) {}

    downloadPDF = () => {
        this.noteService.genReport('laboratory', this.eventId)
        .subscribe(
            response => downloadPDFFile(response),
            e => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    }

    typeOf(value) {
        return typeof value;
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log('lab', this.value[0], this.value[0].length)
        if (changes.data 
            && changes.data.currentValue
            && changes.data.currentValue.notes
            && changes.data.currentValue.notes.labRequests 
            && changes.data.currentValue.notes.labRequests.length 
            && changes.data.currentValue.notes.labRequests[0].length !== 0
        ) {
            this.value = changes.data.currentValue.notes.labRequests
            // console.log('lab', this.value[0], this.value[0].length)
        }
    }

}
