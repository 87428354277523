<div *ngIf="match">
    <h6 class="text-muted">
        {{title}} 
        <span
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"
            ></fa-icon>
        </span> 
        <span 
            class="d-print-none badge badge-dark cta mr-2"
            (click)="openModal(formModal)"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faPencilAlt"
                ngbTooltip="Click to edit"
            ></fa-icon>
        </span>
        <span 
            class="d-print-none badge badge-dark cta mr-2"
            (click)="openModal(emailModal)"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faPaperPlane"
                ngbTooltip="Email to patient"
            ></fa-icon>
        </span>
    </h6>
    <pre *ngIf="value" [innerHTML]="value"></pre>
    <pre *ngIf="!value"><em class="text-muted">Not specified</em></pre>
</div>


<ng-template #formModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ title }}
        </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <section-form-medical-certificate 
            [feedbackData]="value"
            [eventId]="eventId"
            (updatedData)="updatedData($event)"
            (modalDecline)="modalDecline()"
            (selectNote)="selectNote"
        ></section-form-medical-certificate>
    </div>
</ng-template>


<ng-template #emailModal>
    <div class="modal-body text-center">
        <section-medical-certificate-email 
            (modalDecline)="modalDecline()"
            [eventId]="eventId"
            [title]="title"
        ></section-medical-certificate-email>
    </div>
</ng-template>
