import { Component, Injector, OnInit, TemplateRef } from '@angular/core';

import { CrudComponent } from '../crud.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';


// import { ModalConfirmComponent } from './../../../modal/modal-confirm.component';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent extends CrudComponent implements ListComponentInterface, OnInit {
    // public modalComponent = ModalConfirmComponent;
    // public modalOptions: any;
    // public modalActive;
    public service: any;
    public rows: Array<any> = [];
    public modalRef: BsModalRef;
    public page = {
        size: 20, // The number of elements in the page
        totalElements: 0, // The total number of elements
        totalPages: 0, // The total number of pages
        pageNumber: 0, // The current page number
    };

    public queryState = {};

    constructor(injector: Injector, modalService?: BsModalService) {
        super(injector);
    }

    ngOnInit() {
        this.getList();
    }

    getList(e?: any) {
        this.loadingStatus(true);
        this.service.index(e)
            .subscribe(
                (result: any) => {
                    this.rows = result['data'];
                    this.setPager(result['meta']['pagination']);
                    this.loadingStatus(false);
                },
                (error: any) => {
                    console.log('ERROR', error);
                },
            );
    }

    setPager(pageInfo: any) {
        this.page.size = pageInfo['per_page'];
        this.page.totalElements = pageInfo['total'];
        this.page.totalPages = pageInfo['total_pages'];
        this.page.pageNumber = pageInfo['current_page'] - 1;
    }

    onDeleteRow(row: any): void {
        // this.modalActive = this.modalService.open(this.modalComponent, { size: 'sm', container: 'nb-layout' });

        // this.modalActive.componentInstance.modalHeader = 'Delete';
        // this.modalActive.componentInstance.modalContent = 'Are you sure you want to delete this record?'
    }

    getEditLink(...args: any[]) {
        return 'OVERRIDE THIS';
    }

    handlePager(e: any) {
        console.log('handlePager', e)
        this.getList(e);
    }

    handleSort(e: any) {
        console.log(e);
    }


    refreshList() {
        this.getList();
    }

}

export interface ListComponentInterface {
    // modalComponent;
    service: any;
    rows: any;
    page: any;
    // Deinfe the logic to pull the list to be displayed in the table

    getEditLink: Function;
    refreshList: Function;
}
