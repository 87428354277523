import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class PrescriptionItemService extends AbstractService {

    endpoint = environment.serverUrl + 'prescription-items';

    constructor(http: HttpClient) {
        super(http);
    }


    addToCart(id, quantity) {
        console.log(id, this.endpoint + '/add-to-cart')
        return this.http.post(this.endpoint + '/add-to-cart', {
            id,
            quantity
        })
            .map(
                (response: any) => {
                    console.log(response);
                    return response;
                },
                (err: any) => {
                    console.log(err);
                    return err;
                });
    }
}
