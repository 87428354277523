import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, } from '../../../../../../../services';
import { UserService, FeedbackService, } from '../../../../../../../services';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'section-form-medical-abstract',
    templateUrl: './form-medical-abstract.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionFormMedicalAbstractComponent implements OnChanges {
    @Input() eventId
    @Input() feedbackData: object
    @Output() updatedData = new EventEmitter
    @Output() modalDecline = new  EventEmitter()

    match = true
    value = []

    sections = {
        chief_complaint: 'Chief Complaint',
        course_in_the_ward_out_patient: 'Course in the Ward/Out Patient',
        diagnosis: 'Diagnosis',
        general_data: 'General Data',
        history_of_present_illness: 'History of Present Illness',
        laboratory_examination_result: 'Laboratory Examination Result',
        medication: 'Medication',
        psychological_examination_result: 'Psychological Examination Result',
        recommendation: 'Recommendation',
    }

    Object = Object;
    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private userService: UserService,
        private feedbackService: FeedbackService,
        private toastr: ToastrManager
    ) {}

    formatIndex = (str) => {
        // str = str.toLowerCase().replace(/ /g,"_").replace(/\//, '')
        str = str.toLowerCase().replace(/ /g,"_").replace(/\//, '')
        return str
    }

    getValue = (index) => {
        if (this.feedbackData && this.feedbackData[this.formatIndex(index)]) {
            return this.feedbackData[this.formatIndex(index)]
        }
        return ''
    }
    
    onSubmit = (val) => {
        const user = this.userService.getStoredCredentials();
        this.feedbackService.saveDoctorFeedback(this.eventId, user.id, val)
            .subscribe(data => {
                this.updatedData.emit(data['data'])
                this.modalDecline.emit()
                this.toastr.successToastr('Medical Sheet saved successfully.')
            }, e => {
                this.toastr.errorToastr(e.error.message || 'Something went wrong.');
            });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.docMedSheet && changes.docMedSheet.currentValue && changes.docMedSheet.currentValue) {
            this.value = changes.docMedSheet.currentValue
        }

        if (changes.feedbackData && changes.feedbackData.currentValue && changes.feedbackData.currentValue) {
            this.value = changes.feedbackData.currentValue
        }
    }

}
