<div *ngIf="match"> 
    <form 
        #simpleForm="ngForm" 
        (ngSubmit)="onSubmit(simpleForm.value)"
        id="medicalAbstractForm"
    >
        <div class="row mb-2">
            <div class="col-sm-12">
                <textarea 
                    [ngModel]="value"
                    class="form-control m-0" 
                    id="medCertRecomendation" 
                    rows="5" 
                    name="medCertRecomendation"
                ></textarea>
            </div>
        </div>
        
        <div class="row">
            <div class="col text-right">
                <button class="btn btn-primary btn-sm" >Save<i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
            </div>
        </div>
    </form>
</div>
