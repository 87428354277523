<!-- <div class="bg-light p-2 m-0">
    <a class="navbar-brand" [routerLink]="['/me']">
        <img src="./assets/images/logo.png" style="width:300px;">
    </a>
</div> -->
    <div class="pt-5 ml-6">
        <div class="form-check form-check-inline font-weight-bold">
            *Appointment Status:
        </div>
        <label class="mr-3">
            <input type="radio"  [(ngModel)]="appointmentStatus" [value]="1" [checked]="event?.notes?.appointment_status==1"> Completed
        </label>
        <label class="mr-3">
            <input type="radio" [(ngModel)]="appointmentStatus" [value]="2" [checked]="event?.notes?.appointment_status==2"> Rescheduled
        </label>
        <label class="mr-3">
            <input type="radio" [(ngModel)]="appointmentStatus" [value]="3" [checked]="event?.notes?.appointment_status==3"> No Show
        </label>
      </div>

      <div class="pt-1 ml-6">
        <div class="form-check form-check-inline font-weight-bold ml-1">
            Allow Patients to Purchase Add-Ons for this Appointment:
        </div>
        <label class="mr-3">
            <input type="checkbox"  [(ngModel)]="request_addon_med_cert"
            [disabled]="!event?.doctor?.request_medical_certificate">
            <span [ngClass]="{'text-muted': !event?.doctor?.request_medical_certificate}"> Medical Certificate</span> 
        </label>
        <label class="mr-3">
            <input type="checkbox" [(ngModel)]="request_addon_med_abstract"  
            [disabled]="!event?.doctor?.request_medical_abstract">
            <span [ngClass]="{'text-muted': !event?.doctor?.request_medical_abstract}"> Medical Abstract</span> 
        </label>
      </div>

    <div class="pt-2">
        <select class="form-control" [(ngModel)]="tab" (change)="tabChange($event.target.value)">
            <option [value]="0">Progress</option>
            <option [value]="9" *ngIf="canAccessConsultationNotes_PhysicalExam()">Virtual Physical Exam</option>
            <option [value]="1" *ngIf="canAccessConsultationNotes_Diagnosis()">Diagnosis</option>
            <option [value]="4" *ngIf="canAccessConsultationNotes_NextMeeting()">Next Meeting</option>
            <option [value]="2" *ngIf="canAccessConsultationNotes_LaboratoryRequest()">Laboratory Request</option>
            <option [value]="3" *ngIf="canAccessConsultationNotes_Medication()">Medication</option>
            <option [value]="5">{{ consulationNotes_Label_MedicalAbstract()}}</option>
            <option [value]="6">{{ consulationNotes_Label_MedicalCertificate() }}</option>
            <option [value]="7">{{ consulationNotes_Label_AdmittingOrder() }}</option>
            <option [value]="8">Patient Instructions</option>
            <!-- <option [value]="8" *ngIf="!iAmPsychiatrist() && !iAmPsychologist()">Patient Instructions</option> -->            
        </select>
    </div>
    <div class="my-2">
        <div class="py-2" *ngIf="tab==='0'">
            <!-- [{{noteContent.progress | json}}] -->
            <!-- <ngx-editor 
                [(ngModel)]="noteContent.progress" 
                (ngModelChange)="callSave()"
            ></ngx-editor> -->
            <textarea 
                [(ngModel)]="noteContent.progress" 
                (ngModelChange)="callSave()"
                rows="10"
                style="width: 100%;
                    border: 1px solid #cecece;
                    border-radius: 11px;
                    padding: 10px 15px;"
            ></textarea>
        </div>
        <div class="py-2" *ngIf="tab==='9'">
            <app-physical-exam [eventId]="event.id" [physicalExam]="event.physical_exam" (onPEFormDirty)="onPEFormDirty($event)"></app-physical-exam>
        </div>
        <div class="py-2" *ngIf="tab==='1'">
            <h3>Diagnosis</h3>
            <table class="small" *ngIf="canAccessConsultationNotes_Diagnosis_PsychiatristDropdown()">
                <tr *ngFor="let entry of dsm; let i = index">
                    <td style="padding-right: 10px;vertical-align: top;">
                        <a (click)="removeDSMRow(i)">
                            <fa-icon 
                                [icon]="fa.trashIcon" 
                                style="font-size:20px;cursor: pointer;"
                            ></fa-icon>
                        </a>
                    </td>
                    <td class="dsm-drop">
                        <!-- {{ i }} - {{ lvl | json }} -->
                        <span *ngFor="let lvl of entry.lvl; let lvlIndex = index" style="float:left">
                            <span 
                                (click)="removeSelectedDSM_2(i, lvl.id)" 
                                class="btn btn-primary btn-sm"
                                style="cursor: pointer;word-break: break-all;min-height: 31px;max-width: 500px;white-space: normal;text-align: left;"
                            >
                                {{ lvl.name }}
                            </span>&nbsp;
                        </span>
                        <!-- [{{dsmCurrentList.length }}] [{{selectedDSMIndex }}] [{{i}}] -->
                        <!-- [{{entry | json}}] -->
                        <app-dsm5-dropdown 
                            *ngIf="((dsmCurrentList.length && selectedDSMIndex === i) 
                                    || (selectedDSMIndex === i && dsmCurrentList.length && !entry.lvl['id']))
                                    " 
                            [list]="dsmCurrentList" 
                            [loading]="dsmLoading" 
                            [displayOthers]="dsmDisplayOthers"
                            [defaultValue]="dsmDefaultVaue"
                            (selectedValue)="dsmSelectedValue_2(i, $event)"
                            style="float:left"
                            class="dsm-drop"
                        ></app-dsm5-dropdown>

                        <span 
                            *ngIf="selectedDSMIndex != i && !entry.lvl.length"
                            (click)="selectDSMRow(i)"
                            style="cursor: pointer;"
                        >
                            Click here to add a diagnosis
                        </span>

                        <span *ngIf="entry.lvl.length && !entry.lvl[0].id || !canDropdownDSM()">
                            <div class="clearfix"></div>
                            <input 
                                class="pull-left"
                                type="text" 
                                maxlength="254" 
                                (keyup)="customDsm(i, $event)"  
                                placeholder="Enter diagnosis"
                                style="height: 31px;border: 1px solid #d4d4d4;border-radius: 4px;padding: 0 10px;margin-top:2px;"
                            />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td style="padding-right: 10px">
                        
                    </td>
                    <td>
                        <a 
                            (click)="addDSMRow()" 
                            style="cursor: pointer;
                                display: inline-block;
                                line-height: 10px;
                                margin-top: 5px;"
                        >
                            <fa-icon 
                                [icon]="fa.plusIcon" 
                                style="font-size:18px;"
                                
                            ></fa-icon> <span style="vertical-align: text-top;">Add Another Diagnosis</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <!-- <td *ngFor="let dsm of selectedDSM5">
                        <span (click)="removeSelectedDSM(dsm.id)" class="btn btn-primary btn-sm">
                            {{ dsm.label }}
                        </span>&nbsp;
                    </td>
                    <td>
                        <app-dsm5-dropdown 
                            *ngIf="dsmCurrentList.length" 
                            [list]="dsmCurrentList" 
                            [loading]="dsmLoading" 
                            (selectedValue)="dsmSelectedValue($event)"
                        ></app-dsm5-dropdown>
                    </td> -->
                </tr>
            </table>
            <div *ngIf="canAccessConsultationNotes_Diagnosis_CustomInput()">
                    <textarea 
                    [(ngModel)]="noteContent.diagnosis" 
                    (ngModelChange)="callSave()"
                    rows="10"
                    style="width: 100%;
                        border: 1px solid #cecece;
                        border-radius: 11px;
                        padding: 10px 15px;"
                ></textarea>
                <!-- <hr />
                <ngx-editor 
                    [ngModel]="noteContent.diagnosis" 
                    (ngModelChange)="callSave()"
                ></ngx-editor> -->
            </div>
        </div>
        <div class="py-2" *ngIf="tab==='2'">
            <ng-container *ngTemplateOutlet="LabRequests; context: { item: labRequests}"></ng-container>
            <h6>Notesa</h6>
            <textarea placeholder="Add Notes/Remarks" class="form-control" [(ngModel)]="labRequestNotes" cols="2" rows="3"></textarea>
        </div>
        <div class="py-2" *ngIf="tab==='3'">
            <app-medication-table
                [event]="event"
                [medications]="medications" 
                [medicationsCustom]="medicationsCustom" 
                (onSubmit)="addMedication($event)" 
                (stateSaver)="callSaveMeds($event)"
            ></app-medication-table>
            
            <h6>Notes</h6>
            <textarea placeholder="Add Notes/Remarks" class="form-control" [(ngModel)]="medNotes" cols="2" rows="3"></textarea>
            <h6>Prescription Expiration Date <span class="small text-danger">(Required)</span></h6>
            <input [(ngModel)]="medExpiration" required bsDatepicker class="form-control form-control-sm my-2" placeholder="Enter expiration date"/>
            <div _ngcontent-c9="" class="form-group col-12">
                <input _ngcontent-c9="" [(ngModel)]="medHasSignature" class="ng-untouched ng-pristine ng-valid" id="med_has_signature" type="checkbox"> Attach Signature
            </div>
        </div>
        <div *ngIf="tab==='4'">
            <app-next-meeting (stateSaver)="updateNextMeeting($event)" (selectedType)="selectedNextMeetingType($event)" (selectedClinic)="selectedNextMeetingClinic($event)" [event]="event"></app-next-meeting>
        </div>
        <div class="py-2" *ngIf="tab==='5'">
            <div>
                <!-- <app-feedback-form [showHeader]="false"></app-feedback-form> -->
                <form action="">
                    <h6>{{ consulationNotes_Label_MedicalAbstract() }}</h6>
                    <div *ngIf="consulationNotes_MedicalAbstractMultipleFields()">
                        <div class="row mb-2" *ngFor="let item of docMedSheet; first as first">
                            <ng-template [ngIf]="item.type === 'hr' || first">
                                <div class="col-12">
                                    <hr>
                                </div>
                            </ng-template>
                            <div class="col" *ngIf="iAmDoctor()">
                                <ng-template [ngIf]="item.type === 'textarea'">
                                    <div class="co">
                                        <!-- <div *ngIf="item.type=='General Data'">
                                            0<label for="item.key" *ngIf="iAmPsychologist()">-Summary Evaluation</label>
                                            <label for="item.key" *ngIf="!iAmPsychologist()">-- General Data</label>
                                        </div> -->
                                        <!-- <div *ngIf="item.type!='General Data'"> -->
                                        <label for="item.key" [innerHTML]="item.label"></label>
                                        <textarea class="form-control" rows="2" [name]="item.key" [(ngModel)]="feedbackData[item.key]"></textarea>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!consulationNotes_MedicalAbstractMultipleFields()">
                        <div class="co">
                            <textarea 
                                class="form-control" 
                                rows="5" 
                                name="generalData" 
                                [(ngModel)]="feedbackData['generalData']"
                            >
                            </textarea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-right">
                            <hr>
                            <button class="btn btn-primary" (click)="submitClickBtn()" [disabled]="saving">{{saving? 'Saving' :'Save'}} <i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="py-2" *ngIf="tab==='6'">
            <!-- <ng-container *ngTemplateOutlet="medCert"></ng-container> -->
            <h6>Notes</h6>
            <textarea placeholder="Recomendations" class="form-control" [(ngModel)]="medCertRecomendation" cols="2" rows="3"></textarea>
        </div>
        <div class="p-2" *ngIf="tab==='7'">
            <h6>{{ (role && role === 'medical-practitioner' ? 'Admitting Orders' : 'Referral Letter') }}</h6>
            <textarea placeholder="Add Notes/Remarks" class="form-control" [(ngModel)]="admittingOrder" cols="2" rows="3"></textarea>
        </div>
        <div class="py-2" *ngIf="tab==='8'">
            <!-- <ng-container *ngTemplateOutlet="medCert"></ng-container> -->
            <h6>Patient Instructions</h6>
            <textarea placeholder="" class="form-control" [(ngModel)]="noteContent.patientInstructions" cols="2" rows="10"></textarea>
        </div>
        <div class="text-right actions">

            <button *ngIf="tab!=='4' && tab!=='5' && tab!=='9'" class="btn btn-primary mr-4" (click)="onSave()">
                {{ savingNotes ? 'Saving' : 'Save' }} <i *ngIf="savingNotes" class="fa fa-spinner fa-spin"></i>
            </button>

            <!-- <button 
                *ngIf="tab==='9'" 
                class="btn btn-primary mr-4" 
                (click)="onSavePhysicalExam()"
                [disabled]="!PhysicalExamFormDirty"
            >
                {{ savingNotes ? 'Saving  Physical Eam' : 'Save Physical Eam' }} <i *ngIf="savingNotes" class="fa fa-spinner fa-spin"></i>
            </button> -->

            <button class="btn btn-primary" *ngIf="tab==='3'" (click)="previewPrescription()" [disabled]="!onSaveChanges">
                Preview & Print Medication <i *ngIf="sendingMedicationPreview" class="fa fa-spinner fa-spin"></i>
            </button>
            <button *ngIf="tab==='3'" class="btn btn-primary mr-4" (click)="onSendPrescription()" [disabled]="!onSaveChanges">
                {{ sendingMedication ? 'Sending Prescription' : 'Send Prescription' }} <i *ngIf="sendingMedication" class="fa fa-spinner fa-spin"></i>
            </button>

            <!-- <button *ngIf="tab==='3'" class="btn btn-primary mr-4" (click)="onUpdateFlag()" [disabled]="!sendingFlagUpdate">
                Deliver To LifePort <i *ngIf="sendingFlagUpdateLoader" class="fa fa-spinner fa-spin"></i>
            </button> -->

            <button *ngIf="tab==='2'" class="btn btn-primary mr-4" (click)="viewLabReq()">
                Preview & Print Laboratory Request<i *ngIf="sendingLabPreview" class="fa fa-spinner fa-spin"></i>
            </button> 
            <button *ngIf="tab==='2'" class="btn btn-primary mr-4" (click)="onSendLaboratoryRequest()">
                {{ sendingLabRequest ? 'Sending Laboratory Request' : 'Send Laboratory Request'}} <i *ngIf="sendingLabRequest" class="fa fa-spinner fa-spin"></i>
            </button>

            <button *ngIf="tab==='5' && (iAmDoctor())" class="btn btn-primary mr-4 new-update" (click)="viewFeedback()">
                Preview & Print {{ consulationNotes_Label_MedicalAbstract() }}<i *ngIf="sendingMeidicalAbstractPreview" class="fa fa-spinner fa-spin"></i>
            </button>
            <button *ngIf="tab==='5' && (iAmDoctor())" class="btn btn-primary mr-4 new-update" (click)="onSendMedicalAbstract()">
                {{ sendingMedicalAbstract ? 'Sending' : 'Send'}} {{ consulationNotes_Label_MedicalAbstract() }} <i *ngIf="sendingMedicalAbstract" class="fa fa-spinner fa-spin"></i>
            </button>

            <button *ngIf="tab==='6'" class="btn btn-primary mr-4" (click)="viewCert()">
                {{ (role && role === 'medical-practitioner' ? consulationNotes_Label_MedicalCertificate() + ' Preview' : 'Preview & Print ' + consulationNotes_Label_MedicalCertificate()) }} <i *ngIf="sendingCertificatePreview" class="fa fa-spinner fa-spin"></i>
            </button>
            <button *ngIf="tab==='6'" class="btn btn-primary mr-4" (click)="onSendCertificate()">
                {{ sendingCertificate ? 'Sending' : 'Send'}} {{ consulationNotes_Label_MedicalCertificate() }} <i *ngIf="sendingCertificate" class="fa fa-spinner fa-spin"></i>
            </button>

            <button *ngIf="tab==='7'" class="btn btn-primary mr-4" (click)="viewAdmitOrder()">
                Preview & Print {{ consulationNotes_Label_AdmittingOrder() }}<i *ngIf="SendingAdmittingOrderPreview" class="fa fa-spinner fa-spin"></i>
            </button>
            <button *ngIf="tab==='7'" class="btn btn-primary mr-4" (click)="onSendAdmittingOrder()">
                {{ sendingAdmittingOrder ? 'Sending' : 'Send'}} {{ consulationNotes_Label_AdmittingOrder() }}<i *ngIf="sendingAdmittingOrder" class="fa fa-spinner fa-spin"></i>
            </button>

            <button *ngIf="tab==='8'" class="btn btn-primary mr-4" (click)="viewPatientInstruction()">
                Preview & Print Patient Instruction<i *ngIf="SendingPatientInstrctionsPreview" class="fa fa-spinner fa-spin"></i>
            </button>
            
            <button *ngIf="tab==='8'" class="btn btn-primary mr-4" (click)="onSendPatientInstruction()">
                Send Patient Instruction <i *ngIf="sendingPatientInstrctions" class="fa fa-spinner fa-spin"></i>
            </button>
        </div>

        <div class="mt-3 font-italic" *ngIf="tab==='2' || tab==='3' || tab==='5' || tab==='6' || tab==='7' || tab==='8'"> 
            *Open and print your PDF file using Adobe Acrobat Reader. Click Preview & Print.
        </div>
    </div>

        <ng-template #LabRequests let-labrequests="item">
        <div class="p-2 bg-white">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th width="5px">#</th>
                        <th width="120px">Request</th>
                        <th width="120px">Type</th>
                        <th width="120px">Diagnostic Test</th>
    
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lr of labRequests ; index as i">
                        <td class="p-1 text-center">{{i+1}}</td>
                        <td class="p-1">
                            <input 
                                [(ngModel)]="lr.diagnosticRequest" 
                                [typeahead]="labTests" 
                                [typeaheadMinLength]="0"
                                (typeaheadNoResults)="saveLabRequests()" 
                                [typeaheadOptionsLimit]="100" 
                                typeaheadOptionField="name"
                                [typeaheadScrollable]="true" 
                                [typeaheadOptionsInScrollableView]="10" 
                                (typeaheadOnSelect)="onSelectLabTest($event, i)"
                                class="form-control form-control-sm border-0" 
                                placeholder="Select Diagnostic Request"
                            >
                        </td>
                        <td class="p-1">
                            <input 
                                [(ngModel)]="lr.diagnosticType" 
                                [typeahead]="requestTypesFiltered" 
                                [typeaheadMinLength]="0"
                                (typeaheadNoResults)="saveLabRequests()" 
                                typeaheadOptionField="name" 
                                (typeaheadOnSelect)="onSelectType($event, i, 'labRequests')"
                                class="form-control form-control-sm border-0" 
                                placeholder="Enter Type"
                            >
                        </td>
                        <td class="p-1">
                            <input 
                                [(ngModel)]="lr.diagnosticTest" 
                                class="form-control form-control-sm border-0"
                                placeholder="Enter Test">
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-100">
                <p *ngIf="!labRequests.length" class="text-secondary text-center mt-2 mb-0">
                    No Lab requests found.
                    <br> You can add lab requests by clicking the button below.
                </p>
                <div class="text-center p-2 text-primary">
                    <span (click)="labRequests.push({})" style="cursor: pointer;">
                        <i class="fa fa-plus"></i> Add Lab Request
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    
    <ng-template #medCert>
        <div class="p-2 bg-white">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th width="5px">#</th>
                        <th width="120px">Request</th>
                        <th width="120px">Type</th>
                        <th width="120px">Diagnostic Test</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let lr of medCerts; index as i">
                        <td class="p-1 text-center">{{i+1}}</td>
                        <td class="p-1">
                            <input 
                                [(ngModel)]="lr.diagnosticRequest" 
                                [typeahead]="labTests" 
                                [typeaheadMinLength]="0"
                                (typeaheadNoResults)="saveLabRequests()" 
                                [typeaheadOptionsLimit]="100" 
                                typeaheadOptionField="name"
                                [typeaheadScrollable]="true" 
                                [typeaheadOptionsInScrollableView]="10" 
                                (typeaheadOnSelect)="onSelectLabTest($event, i)"
                                class="form-control form-control-sm border-0" 
                                placeholder="Select Diagnostic Request"
                            >
                        </td>
                        <td class="p-1">
                            <input [(ngModel)]="lr.diagnosticType" [typeahead]="requestTypesFiltered" [typeaheadMinLength]="0"
                                (typeaheadNoResults)="saveLabRequests()" typeaheadOptionField="display" (typeaheadOnSelect)="onSelectType($event, i, 'medCerts')"
                                class="form-control form-control-sm border-0" placeholder="Enter Type">
                        </td>
                        <td class="p-1">
                            <input [(ngModel)]="lr.diagnosticTest" class="form-control form-control-sm border-0"
                                placeholder="Enter Test">
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="w-100">
                <p *ngIf="!medCerts.length" class="text-secondary text-center mt-2 mb-0">
                    No medical Certificate found.
                    <br> You can add medical Certificate by clicking the button below.
                </p>
                <div class="text-center p-2 text-primary">
                    <span (click)="medCerts.push({})" style="cursor: pointer;">
                        <i class="fa fa-plus"></i> Add Lab Request
                    </span>
                </div>
            </div>
        </div>
    </ng-template>
    