import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { extract } from '../../../core/i18n.service';

import { ListComponent, EditComponent, CreateComponent } from './crud';

const routes: Routes = [
  {
    path: '',
    data: { title: extract('Generic Names') },
    children: [
        {
          path: '',
          component: ListComponent,
        },
        {
          path: 'edit/:id',
          component: EditComponent,
          data: { title: extract('Edit Generic Name')  },
        },
        {
          path: 'create',
          component: CreateComponent,
          data: { title: extract('Create Generic Name')  },
        },
      ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GenericNamesRoutingModule { }
