import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Logger } from '../../../core/logger.service';
import { I18nService } from '../../../core/i18n.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';

import { UserService } from '../../../services/user.service';
import { LoginContext } from '../../../core/authentication/login-context.interface';
import { User } from '../../../shared/models/user.model';
import { ToastrManager } from 'ng6-toastr-notifications';


const log = new Logger('Login');

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    version: string = environment.version;
    error: any = false;
    loginForm: FormGroup;
    isLoading = false;
    year = new Date();

    emailVerified = true;

    constructor(private router: Router,
        public activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private i18nService: I18nService,
        private authenticationService: AuthenticationService,
        private toastr: ToastrManager,
        private userService: UserService
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.userService.emailVerified.asObservable().subscribe(
            verified => this.emailVerified = verified
        );
    }

    login(login: LoginContext) {
        this.isLoading = true;
        this.error = false;

        this.userService.authenticate(login)
            .subscribe(
                (user: User) => {
                    this.userService.userData.subscribe(a => console.log(a))
                    this.userService.userData.next(user.fullName);
                    this.userService.updateStoredCredentials(user);

                    // // IF role is staff, redirecto to /me
                    // // IF role is admin, redirecto to /admin
                    user.roles.forEach((role: any) => {
                        if (role.name === 'admin') {
                            window.location.href = environment.BASE_URL + 'admin';
                        }
                        else if (role.name === 'secretary') {
                            window.location.href = environment.BASE_URL + 'me/secretary/appointments';
                        
                        } else {
                            window.location.href = environment.BASE_URL + 'me/appointments';
                        }
                    });
                },
                (error: any) => {
                    this.error = error;
                    this.isLoading = false;


                    if (error.status == 500) {
                        this.toastr.warningToastr(
                            '<div style="width:200px">We are having some technical difficulties while logging you in. This is usually an issue on our end. <br/><br/>Please try again later or you may report this to <a href="mailto:info@recoveryhub.ph">info@recoveryhub.ph</a></div>',
                            'Warning', 
                            {
                                toastTimeout: 60000,
                                enableHTML: true,
                                dismiss: 'controlled',
                                animate: 'slideFromTop',
                                position: 'top-center',
                                showCloseButton: true,
                                newestOnTop: true,
                            })
                    }
                }
            );
    }

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    private createForm() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            remember: true
        });
    }

}
