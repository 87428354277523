import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '../../core/i18n.service';
import { GuestOnlyGuard } from '../../core/authentication/guards/guest-only.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PublicComponent } from './public.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PhomeComponent } from './phome/phome.component';
import { DoctorsComponent } from './doctors/doctors.components';

const routes: Routes = [{
    path: '',
    component: PublicComponent,
    children: [
        {
            path: '',
            component: PhomeComponent,
            data: { title: extract('Online Consultation Philippines') },
        },
        {
            path: 'register',
            component: RegisterComponent,
            data: { title: extract('Patient Sign Up') },
            canActivate: [GuestOnlyGuard],
        },
        {
            path: 'doctors',
            component: DoctorsComponent,
            data: { title: extract('Our Psychiatrists') },
        },
        {
            path: 'login',
            component: LoginComponent,
            data: { title: extract('Login'), noHeader: true },
            canActivate: [GuestOnlyGuard],
        },
        {
            path: 'users/verify/:email/:token',
            loadChildren: './verify-email/verify-email.module#VerifyEmailModule',
            data: { title: extract('verify') },
            canActivate: [GuestOnlyGuard],
        },
        {
            path: 'users/reset-password',
            component: ForgotPasswordComponent,
            data: { title: extract('Reset My password') },
            canActivate: [GuestOnlyGuard],
        },
        {
            path: 'users/reset-password/:email/:token',
            component: ForgotPasswordComponent,
            data: { title: extract('Confirm My Token') },
            canActivate: [GuestOnlyGuard],
        },

    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [
        GuestOnlyGuard,
    ]
})
export class PublicRoutingModule { }
