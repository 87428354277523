
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';


export const MESSAGES_MODEL = {
  'name' : [null, Validators.compose([Validators.required])],
  'email' : [null, Validators.compose([Validators.required])],
  'phone' : [null, Validators.compose([Validators.required])],
  'message' : [null, Validators.compose([Validators.required])]
}

export const ERRORS = [
  { name: 'required', text: 'This field is required', rules: ['touched', 'dirty'] },
  { name: 'minlength', text: 'Minimum number of characters ', rules: ['touched', 'dirty'] }
];

export const ROLES_MODEL = {
  'roles': FormArray,
};