import { Component, OnInit, Input, OnChanges, SimpleChanges, Injector } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { faCartArrowDown, faCartPlus } from '@fortawesome/free-solid-svg-icons';
import { 
    CartService, label_MedicalCertificate, 
    label_MedicalAbstract,
} from '../../../../../../services';

@Component({
    selector: 'app-appointment-list-addons-dr-services',
    templateUrl: './dr-services.component.html',
    styleUrls: ['./dr-services.component.scss'],
})
export class DrServicesComponent implements OnInit, OnChanges {

    @Input() doctor: any
    @Input() action: any
    @Input() event: any

    toastr = null
    loading = false
    appointment = null

    drAddonsList = []

    bookingData = null
    ctaAddToCartBtnDisplay = {
        'medical_certificate': false,
        'abstract_report': false,
    }
    // eventData = null 
    label_MedicalAbstract  = label_MedicalAbstract 
    label_MedicalCertificate = label_MedicalCertificate
    fa = {
        faCartArrowDown, faCartPlus
    }

    constructor(
        injector: Injector,
        private cartService: CartService,
    ) {
        this.toastr = injector.get(ToastrManager);
    }

    onClickMedCert(e) {
        console.log(this.appointment, this)
        this.appointment.add_ons.memedical_certificatedcert = e.target.checked
    }

    getPrice = (type = null) => {
        // console.log(this.doctor.services)
        if (this.doctor && type) {
            let A = null
            if (type == 'abstract_report') {
                A = this.doctor.services ? this.doctor.services.abstract_report : this.doctor.addon_price_abstract_report
            } else if (type == 'medical_certificate') {
                A = this.doctor.services ? this.doctor.services.medical_certificate : this.doctor.addon_price_med_cert
            }

            switch(typeof A) {
                case 'number':
                    return `Php${A.toFixed(2)}`
                case 'string':
                    return `Php${A}`
                default:
                    return '<em class="text-muted">N/A</em>'
            }
        }
    }


    ctaAddToCart = (type = null) => {
        this.ctaAddToCartBtnDisplay[type] = false

        if (typeof this.event.doctor.services[type] === 'undefined' || typeof this.appointment.addons[type] === 'undefined') {
            return '<span class="btn btn-secondary badge text-bg-primary p-1">N/A</span>'
        }

        if (this.appointment && this.appointment.addons) {
            // patient <= doctor's view
            if (this.action == 'patient') {
                if (this.event.doctor.services[type] !== null) {
                    if (this.appointment.addons[type]) {
                        return '<span class="btn btn-success badge text-bg-primary p-1 text-uppercase">Purchased</span>' 
                    } else {
                        return '<span class="btn btn-primary badge text-bg-primary p-1 text-uppercase">Not Purchased</span>' 
                    }

                } else {
                    return '<span class="btn btn-secondary badge text-bg-primary p-1">N/A</span>'
                }

            } else { // PX is viewing this time
                if (this.event.doctor.services[type] !== null) {
                    if (this.appointment.addons[type]) {
                        return '<span class="btn btn-success badge text-bg-primary p-1 text-uppercase">Purchased</span>'
                    } else {
                        this.ctaAddToCartBtnDisplay[type] = true
                        return ''
                    }

                } else {
                    return '<span class="btn btn-secondary badge text-bg-primary p-1">N/A</span>'
                }
            }
        }

        return ''
    }

    actionAddToCart = (type) => {
        let add_ons = {}

        add_ons[type] = true
        return this.cartService.addAddonToCart(this.event.eventId, {add_ons, addon_only: true})

        .subscribe(
            (result) => {
                // // StorageService.storage(this.storageType).setItem('paymentMethod', this.paymentMethod);
                // this.modal.close(this.eventDetailsTemplate);
                // this.resetModal();
                // this.loadCart();
                // this.removeCoupon();
                this.toastr.successToastr(
                    'An add-on service was added to you cart. Go to <a href=\"/me/appointments/payment\" target=\"_blank\" style=\"font-weight:bold\">My Cart</a> to proceed to payment', 
                    null, 
                    {
                        enableHTML: true,
                        closeButton: true,
                        timeOut: 100000,
                        disableTimeOut: true
                    });
                // this.loading = false;
                // this.route.navigate(['me/appointments/payment']);
            },
            (error) => {
                this.loading = false;
                console.log(error)
                if (error.error.code == 400) {
                    if (error.error.message == 'event_time_passed') {
                        // this.toastr.errorToastr('You selected a past schedule');
                    }
                }

            }

        );
    }

    onClickMedAbstract(e) {
        console.log(this.appointment, this)
        this.appointment.add_ons.abstract_report = e.target.checked
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.event.currentValue) {
            if (changes.event.currentValue) {
                if (changes.event.currentValue.booking) {
                    this.appointment = changes.event.currentValue.booking
                }
            }

        }
    }

    ngOnInit(): void {

    }

}
