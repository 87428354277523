import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { OrderTransactionService } from '../../../../../services/order-transaction.service';

// import { GenericNamesService } from '../../../../../services/generic-names.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { ORDERS_MODEL, ORDERS_STATUS } from '../../orders.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent implements OnInit {
  public formType = 'edit';

  formRoles: FormGroup;
  formItem: FormGroup;
  // errors = ERRORS;
  genericList: any[];
  status: any;

  private roles: Array<any> = [];

  public vcr: any;
  private order: any;

  constructor(
    injector: Injector,
    vcr: ViewContainerRef
  ) {
    super(injector);
    this.service = injector.get(OrderTransactionService);
    this.setSettings(settings);
    this.vcr = vcr;

    this.formRoles = this.formBuilder.group({
      'roles': this.formBuilder.array([])
    });

    this.formItem = this.formBuilder.group(ORDERS_MODEL);

    this.status = ORDERS_STATUS;
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group(ORDERS_MODEL, { updateOn: 'blur' })
    this._load({mode: 'complete'}).subscribe((result: any) => {
      this.order = result.data;
      // console.log(this.order);
      this.rForm.get('notes').setValue(this.order.notes);
      this.rForm.get('received_by').setValue(this.order.received_by);
      this.rForm.get('status').setValue(this.order.status);
      this.rForm.get('date_received').setValue(this.order.date_received);
    });
  }

  // onRoleCheckboxChange(roleId: string, isChecked: boolean) {
  //   const rolesFormArray = <FormArray>this.formRoles.controls.roles;

  //   if (isChecked) {
  //       rolesFormArray.push(new FormControl(roleId));
  //       console.log('test', roleId);
  //   } else {
  //       const index = rolesFormArray.controls.findIndex(x => x.value === roleId);
  //       rolesFormArray.removeAt(index);
  //   }
  // }
  // checkdate()
  // {
  //   console.log(this.rForm.get('received_by').value);
  //   if ( this.rForm.get('received_by').value ){
  //     // console.log("date", new Date());
  //     let now = new Date('d-m-Y H:i:s');
  //     console.log("now", now);
  //     this.rForm.get('date_received').setValue(now);
  //   }
  // }
 

}
