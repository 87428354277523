import { Component, OnInit, Input, EventEmitter, Output, Injector } from '@angular/core';
import { CrudComponent } from '../crud.component';
import { Router, RouterLink } from '@angular/router';


@Component({
    selector: 'crud-form-buttons',
    templateUrl: './form-buttons.component.html',
    styleUrls: ['./form-buttons.component.scss']
})
export class FormButtonsComponent extends CrudComponent {
    @Output() refreshList: EventEmitter<any> = new EventEmitter();

    @Output() doSave: EventEmitter<any> = new EventEmitter();
    @Output() doDelete: EventEmitter<any> = new EventEmitter();
    @Input() settings: any;
    @Input() action: any;
    @Input() rForm: any = false;
    @Input() delete: any = false;

    isValid: false;

    constructor(injector: Injector) {
        super(injector);
    }

    triggerSave(action?: any) {
        this.doSave.emit({ action });
    }

    triggerDelete(action?: any) {
        this.doDelete.emit({ action });
    }
}
