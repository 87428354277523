export class StorageService {

    public static storage(type: StorageType): Storage {
        let store: Storage;

        switch (type) {
            case StorageType.SESSION:
                store = sessionStorage;
                break;
            case StorageType.LOCAL:
            default:
                store = localStorage;
                break;
        }

        return store as Storage;
    }


}

export enum StorageType {
    SESSION,
    LOCAL
}
