<div class="phome">
    <div class="container">
        <nav class="navbar navbar-expand-md bg-white fixed-top text-uppercase clearfix">
            <div class="container">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="./assets/images/logo.png" class="brand-logo">
                </a>
                <button class="navbar-toggler navbar-toggler-right text-uppercase btn-primary text-white rounded"
                    type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">

                    <i class="fa fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item mx-0 mx-lg-1">
                            <!-- <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center"
                                href="#what-is-recovery-hub">
                                What is RecoveryHub
                            </a> -->
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center" routerLink="/doctors"
                                id="who-is">
                                Our Psychiatrists
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center" href="https://blog.recoveryhub.ph/">
                                Blog
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-center text-dark mb-1" href="#how-do-we-recover">
                                Get In Touch
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-center text-dark mb-1"
                                href="/assets/docs/RecoveryHub_Doctor_SignupForm-MOA.pdf">
                                Apply as Doctor
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-center text-dark mb-1"
                                href="http://www.lifeportpharmacy.ph/">
                                Lifeport
                            </a>
                        </li>

                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link btn-primary btn-green mx-3 rounded" href="/login"
                                *ngIf="!user.id">Login</a>
                            <a class="nav-link btn-primary btn-green mx-3 rounded" href="/me" *ngIf="user.id">My App</a>
                        </li>

                        <!-- <li class="nav-item btn-links" style="font-size:10px !important;line-height: 1px;padding: 0 10px;">
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
    </div>
    <div class="container-fluid head-space">
    </div>
    <header class="container-flue=id my-5">
        <!-- <div class="mb-5">
            <img class="w-100" src="./assets/images/banners/recoveryhub_banner.jpg">
        </div> -->

    </header>

    <section class="portfolio" id="what-is-recovery-hub">
        <div class="container">

            <div class="row">
                <div class="col-md-6 header-text-wrap">
                    <div class="header-text">
                        <h3>Book an online appointment with a Doctor</h3>
                        <p><a href="/register" class="rounded btn-green lg-btn">Patient Sign Up</a></p>
                        <!-- <p><a href="/assets/docs/RecoveryHub_Doctor_SignupForm-MOA.pdf" class="rounded btn-green lg-btn">Doctor Sign Up</a></p> -->
                    </div>
                </div>
                <div class="col-md-6">



                    <div class="wrap-home-slider">


                        <div id="homeBanner" class="carousel slide" data-ride="carousel" data-interval="false">

                            <ol class="carousel-indicators">
                                <li data-target="#homeBanner" data-slide-to="0" class="active"></li>
                                <li data-target="#homeBanner" data-slide-to="1"></li>
                                <li data-target="#homeBanner" data-slide-to="2"></li>
                            </ol>

                            <div class="carousel-inner">

                                <div class="carousel-item active">
                                    <img class="d-block w-100" src="./assets/images/home-img/banner1.png"
                                        alt="Online Mental Health Consultation">
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./assets/images/home-img/banner2.png"
                                        alt="Mental Health Philippines">
                                </div>

                                <div class="carousel-item">
                                    <img class="d-block w-100" src="./assets/images/home-img/banner3.png"
                                        alt="Online Consultation Philippines">
                                </div>



                            </div>

                            <!-- SLIDE BUTTONS -->
                            <a class="carousel-control-prev" data-target="#homeBanner" role="button" data-slide="prev">
                                <span class="slider-icon" aria-hidden="true">
                                    <i class="fa fa-angle-left"></i>
                                </span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" data-target="#homeBanner" role="button" data-slide="next">
                                <span class="slider-icon" aria-hidden="true">
                                    <i class="fa fa-angle-right"></i>
                                </span>
                                <span class="sr-only">Next</span>
                            </a>

                        </div>



                    </div>


                </div>
            </div>
        </div>

        <div class="program">
            <div class="container">
    
                <div class="row">
                    <div class="col-md-6">
                        <h2 class="text-center text-uppercase text-white mb-0">Subsidized recovery for students and teachers</h2>
                    </div>
                    <div class="col-md-6">
                        <p class="text-center">
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe4pdSgvQcopkwhEqpICoNLgsBZjCn4dPEh7FCobImjhs5k9A/viewform" class="btn btn-primary text-white lg-btn" target="_blank" rel="noopener noreferrer">APPLY TODAY</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="what-is-recovery-bg" id="jump-link1">
            <div class="container">
                <h2 class="text-center text-dark mb-0">WHAT IS RECOVERYHUB?</h2>
                <br>
                <!-- <hr class="star-dark mb-5"> -->
                <div class="row">
                    <div class="col-md-12 lead">
                        <h3 class="text-center">Consult with a Psychiatrist or Psychologist Online</h3>
                        <br>
                        <p class="text-justify">
                            Have you ever felt or thought that life is not as it should be? Are you striving or just
                            surviving? You might be experiencing pain, sadness, guilt, depression, anxiety, or social
                            isolation. These challenging feelings are often so hard to deal with on your own.
                        </p>

                        <p class="text-justify">
                            Let's talk. Ask. Seek answers. It is never too late to seek professional help. Early
                            intervention will help to speed up recovery.
                        </p>

                        <p class="text-justify">
                            RecoveryHub is a platform for online mental health consultation and treatment. We are a team
                            of Filipino licensed psychiatrists and clinical psychologists ready to help you. </p>

                        <p class="text-justify">
                            With the convenience, safety, and security measures in place, our doctors perform
                            psychiatric evaluations, prescribe medications, write up medical certifications and
                            abstracts, and provide talk therapy through video calls. </p>

                        <p class="text-justify">
                            Recovery is just a click away. Book an appointment with any of RecoveryHub's doctors.
                        </p>
                        <!-- <p class="text-center">
                    <a class="btn btn-warning text-white">READ MORE</a>
                </p> -->
                    </div>
                </div>

            </div>
        </div>
    </section>

    <!-- <div class="container">
        <div class="row block-cater">
            <h4 class="text-center text-dark mb-0">We also cater to these fields of specializations:</h4>

            <ul>
                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp1.png" alt="">
                    <p>Dermotology</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp2.png" alt="">
                    <p>Surgery</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp3.png" alt="">
                    <p>Internal Medicine</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp4.png" alt="">
                    <p>Family Medicine</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp5.png" alt="">
                    <p>Neurology</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp6.png" alt="">
                    <p>ENT</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp7.png" alt="">
                    <p>Opthalmology</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp8.png" alt="">
                    <p>Pediatrics</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp91.png" alt="">
                    <p>OBGYN</p>
                </li>

                <li>
                    <img class="d-block w-100" src="./assets/images/home-img/sp10.png" alt="">
                    <p>Rehab Medicine</p>
                </li>


            </ul>
        </div>
    </div> -->

    <div class="steps2-con">
        <div class="container">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-8">
                    <div id="homeBanner2" class="carousel slide" data-ride="carousel" data-interval="false">

                        <!-- <ol class="carousel-indicators">
                        <li data-target="#homeBanner2" data-slide-to="0" class="active"></li>
                        <li data-target="#homeBanner2" data-slide-to="1"></li>
                        <li data-target="#homeBanner2" data-slide-to="2"></li>
                    </ol> -->

                        <div class="carousel-inner">

                            <div class="carousel-item active">
                                <p class="head-slide-item">Booking An Appointment</p>
                                <img class="d-block w-100" src="./assets/images/home-img/call.jpg"
                                    alt="Book an Appointment with a Psychiatrist or Psychologist">
                            </div>

                            <div class="carousel-item">
                                <p class="head-slide-item">Calling A Doctor</p>
                                <img class="d-block w-100" src="./assets/images/home-img/online.jpg"
                                    alt="Talk with a Psychiatrist or Psychologist">
                            </div>

                            <div class="carousel-item">
                                <p class="head-slide-item">With RecoveryHub, if necessary, your doctor can email you the
                                    following after the call:</p>
                                <img class="d-block w-100" src="./assets/images/home-img/prescription.jpg"
                                    alt="E Prescriptions, Med Certs, Lab requests will be emailed">
                            </div>



                        </div>

                        <!-- SLIDE BUTTONS -->
                        <a class="carousel-control-prev" data-target="#homeBanner2" role="button" data-slide="prev">
                            <span class="slider-icon" aria-hidden="true">
                                <i class="fa fa-angle-left"></i>
                            </span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" data-target="#homeBanner2" role="button" data-slide="next">
                            <span class="slider-icon" aria-hidden="true">
                                <i class="fa fa-angle-right"></i>
                            </span>
                            <span class="sr-only">Next</span>
                        </a>

                    </div>
                </div>

                <div class="col-md-2">
                </div>


            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-5">
                <h4 class="text-center text-dark" style="margin-bottom:20px;margin-top:20px">PARTNERS</h4>
                <div class="partners">
                    <img class="partner-img" src="./assets/images/otsuka.png" alt="Otsuka Pharmaceutical" />
                    <img class="partner-img" src="./assets/images/lifeport.png" alt="Lifeport" />
                    <img class="partner-img maxicare" src="./assets/images/maxicare.png" alt="Maxicare" />
                    <img class="partner-img" src="./assets/images/metro-psych.png" alt="Metro Psych Facility" />
                    <img class="partner-img hipre" src="./assets/images/hipre-logo.png" alt="HiPrecision Diagnostics" />
                    <img class="partner-img bettersteps" src="./assets/images/better-steps.webp" alt="Better steps" />
                </div>
            </div>
        </div>
    </div>

    <div class="what-is-recovery-bg">
        <div class="testimonials row">
            <div class="col-md-12">

                <h4 class="text-center text-dark" style="margin-bottom:20px">TESTIMONIALS</h4>

                <div class="wrap-testimonial-slider">



                    <div id="testimonials" class="carousel slide" data-ride="carousel" data-interval="false">

                        <ol class="carousel-indicators">
                            <li data-target="#testimonials" data-slide-to="0" class="active"></li>
                            <li data-target="#testimonials" data-slide-to="1"></li>
                        </ol>

                        <div class="carousel-inner">

                            <div class="carousel-item active">
                                <ul class="list-testimonial">
                                    <li class="">
                                        <p class="testimonial-desc green testimonial-text-container">
                                            Thanks to Recovery Hub that I got to connect with my excellent doctor!
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            AY
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>

                                    <li class="">
                                        <p class="testimonial-desc blue testimonial-text-container">
                                            The appointment with the doctor went very well. Happy to know about this
                                            service and how easy to book appointments. No long wait, no lining up in the
                                            hallway, no traffic to endure.
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            CS
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>

                                    <li class="">
                                        <p class="testimonial-desc orange testimonial-text-container">
                                            I just want to commend your support team for immediately responding to my
                                            request late last night to reschedule my appointment today.
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            JJG
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <div class="carousel-item">
                                <ul class="list-testimonial">
                                    <li class="">
                                        <p class="testimonial-desc green testimonial-text-container">
                                            Great! The video and voice are clear! Its convenient to have a session here.
                                            Thank God for your platform.
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            AC
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>

                                    <li class="">
                                        <p class="testimonial-desc blue testimonial-text-container">
                                            It was a great session because I was able to talk about my concerns and Dr.
                                            Elaine listened to me. It was nice to have an initial view on what I'm
                                            dealing with and i'm looking forward to another session.
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            MM
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>

                                    <li class="">
                                        <p class="testimonial-desc orange testimonial-text-container">
                                            I love Doc Bernardo. Very nice and honest with her patients. I've always
                                            been comfortable talking to her.
                                            <img class="" src="./assets/images/home-img/sp10.png" alt="">
                                        </p>
                                        <p class="testimonial-name">
                                            LB
                                            <!-- <span>Kindergarten Teacher</span> -->
                                        </p>
                                    </li>
                                </ul>
                            </div>

                            <!-- <div class="carousel-item">
                                <img class="d-block w-100" src="./assets/images/home-img/banner2.png" alt="Second slide">
                            </div>

                            <div class="carousel-item">
                                <img class="d-block w-100" src="./assets/images/home-img/banner3.png" alt="Third slide">
                            </div> -->



                        </div>

                        <!-- SLIDE BUTTONS -->
                        <!-- <a class="carousel-control-prev" data-target="#testimonials" role="button" data-slide="prev">
                            <span class="slider-icon" aria-hidden="true">
                                <i class="fa fa-angle-left"></i>
                            </span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" data-target="#testimonials" role="button" data-slide="next">
                            <span class="slider-icon" aria-hidden="true">
                                <i class="fa fa-angle-right"></i>
                            </span>
                            <span class="sr-only">Next</span>
                        </a> -->

                    </div>




                </div>
            </div>
        </div>
    </div>

    <!-- Infographic -->
    <!-- <div class="mb-5">
        <img src="./assets/images/infographic.jpg" style="width:100%" />
    </div> -->

    <!-- How do we recover -->
    <section id="how-do-we-recover">
        <div class="container">

            <div class="row">
                <div class="col-md-6">
                    <h2 class="text-center text-uppercase text-dark mb-0">Book an Appointment with a Psychiatrist or
                        Psychologist</h2>
                    <!-- <h2 class="text-center text-uppercase text-secondary mb-0 together">BOOK AN APPOINTMENT WITH A DOCTOR</h2> -->
                </div>
                <div class="col-md-6">
                    <!-- <h2 class="text-center text-uppercase text-dark ">BOOK AN APPOINTMENT WITH A DOCTOR</h2> -->
                    <p class="text-center">
                        <a href="http://app.recoveryhub.ph/register" class="btn btn-primary text-white lg-btn">SIGN
                            UP</a>
                    </p>
                </div>
            </div>
        </div>
    </section>

    <!-- Contact us -->
    <section id="contact-us" class="my-5">
        <div class="container">
            <h2 class="text-center text-uppercase text-dark mb-3">CONTACT US</h2>
            <div class="row">
                <div class="col-lg-8 mx-auto">
                    <!-- To configure the contact form email address, go to mail/contact_me.php and update the email address in the PHP file on line 19. -->
                    <!-- The form should work on most web servers, but if the form is not working you may need to configure your web server differently. -->
                    <form [formGroup]="contactForm" enctype="text/plain" mail>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <input class="form-control form-control-lg underBorder" formControlName="name"
                                    type="text" placeholder="Name" required="required">
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <input class="form-control form-control-lg underBorder" formControlName="from"
                                    type="email" placeholder="Email Address" required="required">
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <!-- <label>Phone Number</label> -->
                                <input class="form-control form-control-lg underBorder" formControlName="phone"
                                    type="tel" placeholder="Phone Number" required="required">
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Message</label>
                                <textarea class="form-control form-control-lg text-area-bg" formControlName="message"
                                    rows="5" required="required"></textarea>
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <br>
                        <div class="form-group text-center">
                            <button type="button" class="btn btn-primary btn-xl lg-btn" id="sendMessageButton"
                                (click)="onSubmit()">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>

    <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes) -->
    <div class="scroll-to-top d-lg-none position-fixed ">
        <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top">
            <i class="fa fa-chevron-up"></i>
        </a>
    </div>

    <footer class="footer text-center container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-md-4 mb-5 mb-lg-0">
                    <h4 class="text-uppercase mb-4 ">Quick links</h4>
                    <p class="mb-0">
                        <a class="text-white as-link" (click)="onClick(0)">
                            Home
                        </a><br />
                        <a class="text-white" href="/login">
                            Log-in
                        </a><br />
                        <a class="text-white" href="/register">
                            Patient Sign-up
                        </a><br />
                        <a class="text-white" href="/assets/docs/RecoveryHub_Doctor_SignupForm-MOA.pdf">
                            Doctor Sign-up
                        </a><br />
                        <a class="text-white as-link" (click)="onClick(550)">
                            What is RecoveryHub
                        </a><br />
                        <a class="text-white" href="/doctors">
                            Our Psychiatrists
                        </a><br />
                        <a class="text-white as-link" href="https://blog.recoveryhub.ph/">
                            Blog
                        </a><br />
                    </p>
                </div>
                <div class="col-md-4 mb-5 mb-lg-0">
                    <h4 class="text-uppercase mb-4 ">Location</h4>
                    <p class="lead mb-0">Metro Psych Facility <br>
                        Upper Malibu Beach Street <br>
                        Subangdaku, Mandaue City <br>
                        Cebu, Philippines
                    </p>
                </div>
                <div class="col-md-4 mb-5 mb-lg-0">
                    <h4 class="text-uppercase mb-4">Contact Us</h4>

                    <a class="text-white" href="tel:(6332) 344-2142" target="_blank">
                        <i class="fa fa-phone m-auto"></i> +63 (32) 344-2142
                    </a>
                    <br>
                    <a href="#" class="cryptedmail text-white" data-part1="info" data-part_2="recoveryhub"
                        data-partthree="ph"
                        onclick="window.open('mailto:' + this.dataset.part1 + '@' + this.dataset.part_2 + '.' + this.dataset.partthree, '_blank'); return false;">
                    </a> <br />
                    <div class="d-flex justify-content-center align-items-center p-2" style="height:50px">
                        <a class="text-white mr-3" style="font-size:1.5em;"
                            href="https://www.facebook.com/recoveryhubph">
                            <i class="fa fa-facebook m-auto mr-3"></i>
                        </a>
                        <div>
                            |
                        </div>
                        <a class="text-white ml-3 mr-3" style="font-size:1.5em;"
                            href="https://www.instagram.com/recoveryhubph">
                            <i class="fa fa-instagram m-auto ml-3"></i>
                        </a>
                        <div>
                            |
                        </div>
                        <a class="text-white ml-3" style="font-size:1.5em;"
                            href="https://www.youtube.com/channel/UC0qJmW6NqTilC-ZIE_HzRxg">
                            <i class="fa fa-youtube m-auto ml-3"></i>
                        </a>
                    </div>


                </div>
            </div>
        </div>
    </footer>

    <div class="colophon py-4 text-white">
        <div class="container">
            <div class="d-flex justify-content-between align-items-center">
                <ul class="list-inline mb-0">

                    <li class="list-inline-item m-3">
                        <div class="copyright">
                            <small>Copyright &copy; {{year | date:'y'}} | RecoveryHub</small>
                        </div>
                    </li>
                </ul>

                <div class="footer-links">
                    <small><a class="text-white" href="../../../../assets/docs/TermsAndConditions.pdf"
                            target="_blank">Terms and Conditions</a></small> |
                    <small><a class="text-white" href="../../../../assets/docs/PrivacyPolicy.pdf"
                            target="_blank">Privacy Policy</a></small>

                </div>
            </div>
        </div>
    </div>

</div>
