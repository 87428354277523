<crud-header 
  [settings]="settings"
  [action]="'list'"
  (refreshList)="refreshList()"
></crud-header>

<div>
  <ngx-datatable
      [rows]="rows"
      [columns]="columns"
      [columnMode]="'force'"
      [footerHeight]="30"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='handlePager($event)'
      (sort)="handleSort($event)"
      [loadingIndicator]="loading"

      class="small material"
  >

    <ngx-datatable-column name="ID" props="id" maxWidth="60">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ value }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Transaction Number" props="transaction_id">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.transaction_id }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Notes" props="notes">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          {{ row.notes }}
        </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Status" props="status">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span (click)="sort($event)">{{column.name}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            <span class="badge badge-pill badge-default" 
            *ngIf="value!==''"
            [class.badge-warning]="value=='processing'"
            [class.badge-danger]="value=='pending'"
            [class.badge-success]="value=='shipped' || value=='delivered'"
          >{{ row.status  | uppercase }}</span>
        </ng-template>
    </ngx-datatable-column>


    <ngx-datatable-column name="Action" sortable="false" prop="action">
      <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          {{column.name}}
      </ng-template>
      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <a *ngIf="settings.crud.update.allow" [routerLink]="[getEditLink(row)]"><i class="fa fa-pencil text-warning"></i></a>
        <a *ngIf="settings.crud.view.allow" [routerLink]="[getViewLink(row)]"><i class="fa fa-eye text-warning"></i></a>
      </ng-template>
    </ngx-datatable-column>

  </ngx-datatable>
</div>
