import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'

@Component({
  selector: 'app-time-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
})

export class DurationComponent implements OnInit, OnChanges {

  @Input() slotDetails
  @Output() selectDuration: EventEmitter<any> = new EventEmitter()
  @Output() panelToOpen: EventEmitter<any> = new EventEmitter()
  
  selectedDuration = null
  
  _selectDuration = (duration) => {
    this.selectedDuration = duration
    this.selectDuration.emit(duration)
    this.panelToOpen.emit('panel-step-3')
  }



  ngOnInit() { }
  

  ngOnChanges(changes: SimpleChanges): void { 
    if (changes.slotDetails) {
      // console.log('duration', changes.slotDetails.currentValue.duration, this.slotDetails, changes.slotDetails)
      const duration = changes.slotDetails.currentValue.duration
      if (duration != this.selectedDuration) {
        this._selectDuration(duration)
      }

    }
  }

  constructor() {}
}
