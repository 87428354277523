<div *ngIf="match">
    <h6 class="text-muted">
        {{title}} 
        <span 
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor()"
        >
            <fa-icon 
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"
            ></fa-icon>
        </span> 
    </h6>
    <pre [innerHTML]="value"></pre>
</div>
