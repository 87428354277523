<crud-header *ngIf="formType=='create'" [settings]="settings" [action]="'create'">
</crud-header>

<crud-header *ngIf="formType=='edit'" [settings]="settings" [action]="'edit'"></crud-header>

<ngb-tabset>
    <ngb-tab title="Personal Information">
        <ng-template ngbTabContent>
            <form [formGroup]="rForm" novalidate autocomplete="off">
                <div class="row">
                    <div class="form-group col-6">
                        <label for="email">Email
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="email" formControlName="email" autocomplete="off">

                        <div class="small text-warning" ngxErrors="email" #errorEmail="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorEmail.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-6">
                        <label for="passwordaa">Password</label>

                        <input type="password" class="form-control" id="password" formControlName="password" autocomplete="user-password">

                        <div class="small text-warning" ngxErrors="password" #errorPassword="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorPassword.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="first_name">First Name
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="first_name" formControlName="first_name">

                        <div class="small text-warning" ngxErrors="first_name" #errorFirstName="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorFirstName.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-6">
                        <label for="last_name">Last Name
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="last_name" formControlName="last_name">
                        <div class="small text-warning" ngxErrors="last_name" #errorLastName="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorLastName.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-6">
                        <label for="phone">Phone
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="phone" formControlName="phone">
                        <div class="small text-warning" ngxErrors="phone" #errorPhone="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorPhone.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-12">
                        <label for="address_1">Address
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="address_1" formControlName="address_1">
                        <div class="small text-warning" ngxErrors="address_1" #errorAddress1="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorAddress1.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-6">
                        <label for="city">City
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="city" formControlName="city">
                        <div class="small text-warning" ngxErrors="city" #errorCity="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorCity.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-6">
                        <label for="state">State/Region
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="state" formControlName="state">
                        <div class="small text-warning" ngxErrors="state" #errorState="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorState.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-6">
                        <label for="zipcode">Zip Code
                            <span class="required text-danger">*</span>
                        </label>
                        <input type="text" class="form-control" id="zipcode" formControlName="zipcode">
                        <div class="small text-warning" ngxErrors="zipcode" #errorZipcode="ngxErrors">
                            <div *ngFor="let error of errors" [ngxError]="error.name" [when]="error.rules">
                                <div [ngSwitch]="error.name">
                                    <div *ngSwitchCase="'minlength'">
                                        {{ error.text }} {{ errorZipcode.getError('minlength')?.requiredLength }}
                                    </div>
                                    <div *ngSwitchDefault>
                                        {{ error.text }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <crud-form-buttons *ngIf="formType=='edit'" [settings]="settings" [action]="'edit'" [rForm]="rForm" (doSave)="doUpdate($event)"></crud-form-buttons>

                <crud-form-buttons *ngIf="formType=='create'" [settings]="settings" [action]="'create'" [rForm]="rForm" (doSave)="doSave($event)"></crud-form-buttons>

            </form>


        </ng-template>
    </ngb-tab>
    <!-- <ngb-tab>
        <ng-template ngbTabTitle>Roles &amp; Status</ng-template>
        <ng-template ngbTabContent>
            <div *ngIf="formType!='edit'">
                <p class="text-center">Updating the user's roles and status is only available in EDIT mode.</p>

            </div>
            <div *ngIf="formType=='edit'">
                <form [formGroup]="formRoles">
                    <div class="form-check" *ngFor="let role of roles">
                        <label class="form-check-label">
                            <input type="checkbox" [checked]="isChecked(role.id)" (change)="onRoleCheckboxChange(role.id, $event.target.checked)"> {{ role.name }}
                        </label>
                    </div>

                    <crud-form-buttons *ngIf="formType=='edit'" [settings]="settings" [action]="'edit'" [rForm]="formRoles" (doSave)="doUpdateRoles($event)"></crud-form-buttons>

                    <h2>Status</h2>
                    <select>
                        <option value="active">Active</option>
                        <option value="active">Active</option>
                    </select>
                </form>
            </div>
        </ng-template>
    </ngb-tab> -->
</ngb-tabset>
