    <!-- <button type="button" class="btn btn-outline-primary" (click)="log(data)">log data</button> -->
    <!-- SECTION -->

    <div class="bg-white border rounded my-2 p-5" *ngIf="stepper===0">
        <div class="p-auto">
            <p class="">
                <span class="p-2 btn-sm" style="cursor: pointer;" (click)="data['agreeToCollectionAndProcessing'] = !data['agreeToCollectionAndProcessing']">
                    <fa-icon class="text-muted" style="font-size:1.2em" [icon]="data['agreeToCollectionAndProcessing'] ? fa.checkboxTrue : fa.checkboxFalse"></fa-icon>
                </span><span class="required"></span>I freely, voluntarily, and unequivocally consent to the collection and processing of my
                sensitive personal information specifically collected under this Patient Mental Health Information
                Sheet.
            </p>
            <small class="text-danger pl-5" *ngIf="!data['agreeToCollectionAndProcessing'] && requiredFields">This field is required</small>
        </div>
    </div>

    <ng-container *ngFor="let section of sections; index as i; first as first">
        <div class="border border-danger m-2 p-2" *ngIf="first && requiredFields">Please fill all required fields</div>
        <div class="bg-white border rounded my-2 p-3" *ngIf="stepper===(i+1) || showAll">
            <!-- <hr *ngIf="!first"> -->

            <!-- HEADER -->
            <div>
                <h6>
                    <div class="d-flex">
                        <h5 class="mr-auto font-weight-bold"> {{i+1}}. {{section}} <span *ngIf="(i+1) > 2">( Optional )</span></h5>
                        <!-- <fa-icon class="text-muted" [icon]="sectionIcon(section)"></fa-icon> -->
                    </div>
                </h6>
            </div>

            <!-- ROW -->
            <ng-container *ngFor="let groupNo of sectionMap[cc(section)].groups">

                <div class="mt-3">
                    <strong>{{sectionMap[cc(section)].groupNames[groupNo]}}</strong>
                </div>
                <hr class="mt-2">
                <div class="row" *ngFor="let item of getItems(cc(section), groupNo)">

                    <!-- COLUMN -->
                    <div class="col-4 text-right" *ngIf="item.showLabel">
                        <label *ngIf="canDisplayLabel(item) && item.type !== 'note'" [innerHTML]="item.label" [ngClass]="item.required ? 'required' : ''"></label>
                    </div>
                    <div [ngClass]="item.showLabel ? 'col-md-4 col-8' :'col'">
                        <ng-template [ngIf]="canDisplayItem(item)">
                            <div class="form-group" [ngSwitch]="item.type">
                                <div *ngSwitchCase="'note'" class="font-italic" >
                                    {{item.label}}
                                </div>
                                <!-- TEXT -->
                                <p *ngIf="isPatient && (item.type == 'text' || item.type == 'number')">
                                    <input
                                        *ngSwitchCase="'text'"
                                        type="text"
                                        class="form-control"
                                        [id]="item.key"
                                        [ngClass]="!data[item.sectionKey][item.key] && item.required && requiredFields ? 'is-invalid' : ''"
                                        [disabled]="!isPatient || item.disabled"
                                        [(ngModel)]="data[item.sectionKey][item.key]"
                                        [placeholder]="item.placeholder || ''"
                                    >
                                    <input
                                        *ngSwitchCase="'number'"
                                        type="number"
                                        class="form-control"
                                        [id]="item.key"
                                        [ngClass]="!data[item.sectionKey][item.key] && item.required && requiredFields ? 'is-invalid' : ''"
                                        [disabled]="!isPatient || item.disabled"
                                        [(ngModel)]="data[item.sectionKey][item.key]"
                                        [placeholder]="item.placeholder || ''"
                                    >
                                </p>


                                <!-- AGE -->
                                <ng-template [ngSwitchCase]="'age'">
                                    <div *ngIf="typeOf(data['age']) != 'undefined' && data['age']">{{ data['age'] }}y.o.</div>
                                    <em class="small" *ngIf="isPatieant" >Your age will be determined by your Birth Date</em>
                                </ng-template>

                                <!-- RADIO -->
                                <ng-template [ngSwitchCase]="'radio'">
                                    <ng-container *ngFor="let option of item.options">
                                        <div (click)="data[item.sectionKey][item.key] = option.value" class="p-auto"
                                            *ngIf="isPatient">
                                            <fa-icon class="text-muted" [icon]="data[item.sectionKey][item.key] === option.value ? fa.checked : fa.unchecked"></fa-icon>
                                            {{option.label}}
                                        </div>
                                        <div class="p-auto" *ngIf="!isPatient">
                                            <fa-icon class="text-muted" [icon]="data[item.sectionKey][item.key] === option.value ? fa.checked : fa.unchecked"></fa-icon>
                                            {{option.label}}
                                        </div>
                                    </ng-container>
                                </ng-template>

                                <!-- CHECKBOX -->
                                <div *ngSwitchCase="'checkbox'">
                                    <ng-container *ngFor="let option of item.options">
                                        <div (click)="onCheckboxClick(item, option.value)" class="p-auto" style="cursor: pointer;"
                                            *ngIf="isPatient">
                                            <fa-icon class="text-muted" [icon]="getIcon(item, option.value)"></fa-icon>
                                            {{option.label}}
                                        </div>
                                        <div *ngIf="!isPatient" class="p-auto">
                                            <fa-icon class="text-muted" [icon]="getIcon(item, option.value)"></fa-icon>
                                            {{option.label}}
                                        </div>
                                    </ng-container>
                                </div>

                                <!-- DATEPICKER -->
                                <div *ngIf="isPatient">
                                    <div *ngSwitchCase="'datepicker'">
                                        <input
                                            type="text"
                                            class="form-control"
                                            bsDatepicker
                                            [bsValue]="data['date_of_birth']"
                                            [id]="item.key"
                                            [ngClass]="!data[item.sectionKey][item.key] && item.required && requiredFields ? 'is-invalid' : ''"
                                            [disabled]="!isPatient"
                                            [(ngModel)]="data['date_of_birth']"
                                            [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY' }"

                                            readonly
                                        >
                                        <!-- (bsValueChange)="onValueChange($event)" -->
                                        <!-- <em class="small text-danger" *ngIf="isPatient" >Please set your correct birth date</em> -->
                                    </div>
                                </div>

                                <!-- CHECKBOX - DETAILED-->
                                <div *ngSwitchCase="'checkbox-detailed'">
                                    <ng-container *ngFor="let option of item.options">
                                        <div *ngIf="isPatient">

                                            <span (click)="onCheckboxClick(item, option.value)" class="p-auto" style="cursor: pointer;">
                                                <fa-icon class="text-muted" [icon]="getIcon(item, option.value)"></fa-icon>
                                            </span>
                                            {{option.label}}
                                            <input type="text" class="form-control mb-2 ml-4" *ngIf="isActive(item, option.value)"
                                                [(ngModel)]="data[item.sectionKey][option.value]">

                                        </div>
                                        <div *ngIf="!isPatient">

                                            <span class="p-auto">
                                                <fa-icon class="text-muted" [icon]="getIcon(item, option.value)"></fa-icon>
                                            </span>
                                            {{option.label}}
                                            <input type="text" class="form-control mb-2 ml-4" *ngIf="isActive(item, option.value)"
                                                [(ngModel)]="data[item.sectionKey][option.value]">

                                        </div>
                                    </ng-container>
                                </div>

                                <!-- SELECT -->
                                <p *ngIf="isPatient">
                                    <select *ngSwitchCase="'select'" class="form-control" [(ngModel)]="data[item.sectionKey][item.key]"
                                        [ngClass]="!data[item.sectionKey][item.key] && item.required && requiredFields ? 'is-invalid' : ''"
                                        [disabled]="!isPatient">
                                        <option [value]="option.value" *ngFor="let option of item.options">{{option.label}}</option>
                                    </select>
                                </p>
                                <!-- TEXTAREA -->
                                <p *ngIf="isPatient">
                                    <textarea
                                        *ngSwitchCase="'textarea'"
                                        class="form-control"
                                        [placeholder]="item.placeholder || ''"
                                        [ngClass]="!data[item.sectionKey][item.key] && item.required && requiredFields ? 'is-invalid' : ''"
                                        [disabled]="!isPatient"
                                        [(ngModel)]="data[item.sectionKey][item.key]"
                                    ></textarea>
                                </p>
                                <!-- TABLE -->
                                <ng-template [ngSwitchCase]="'table'">
                                    <ng-container [ngSwitch]="item.tableType">
                                        <ng-template [ngSwitchCase]="'familyRelationship'">
                                            <ng-container *ngTemplateOutlet="familyRelationship; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'legalInvolvement'">
                                            <ng-container *ngTemplateOutlet="legalInvolvement; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'historyOfPresentIllness'">
                                            <ng-container *ngTemplateOutlet="historyOfPresentIllness; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'patientPsychHistory'">
                                            <ng-container *ngTemplateOutlet="patientPsychHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'pastMedicalHistory'">
                                            <ng-container *ngTemplateOutlet="pastMedicalHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'famPsychHistory'">
                                            <ng-container *ngTemplateOutlet="famPsychHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'famMedicalHistory'">
                                            <ng-container *ngTemplateOutlet="famMedicalHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'abnormalDevelopmentalHistory'">
                                            <ng-container *ngTemplateOutlet="abnormalDevelopmentalHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                        <ng-template [ngSwitchCase]="'substanceUseOrAddictionHistory'">
                                            <ng-container *ngTemplateOutlet="substanceUseOrAddictionHistory; context: { item: item}"></ng-container>
                                        </ng-template>
                                    </ng-container>
                                </ng-template>

                                <div *ngSwitchDefault>
                                    <p *ngIf="isPatient">
                                        couldnt find template for {{item.type}}
                                    </p>

                                    <p *ngIf="!isPatient && item.key == 'dateOfBirth'">
                                        <span *ngIf="typeOf(data['age']) != 'undefined'">{{ data[item.sectionKey][item.key] | date: 'longDate'}}</span>
                                        <span *ngIf="typeOf(data['age']) == 'undefined'"><em>Not Specified</em></span>
                                    </p>
                                    <p *ngIf="!isPatient && item.key != 'dateOfBirth'" [innerHTML]="data[item.sectionKey][item.key]" style="white-space:break-spaces"></p>
                                </div>
                                <small class="text-danger" *ngIf="!data[item.sectionKey][item.key] && item.required && requiredFields">This field is required</small>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div *ngIf="groupNo === 4 && sectionMap[cc(section)].groupNames[groupNo] === 'Contact information'" class="disclaimer">
                    <span>
                        In case the patient is a minor, contact details entered should be of the parents.
                    </span>
                </div>

                <div *ngIf="groupNo === 5 && sectionMap[cc(section)].groupNames[groupNo] === 'Contact Person In Case of Emergency'" class="disclaimer">
                    <span>
                        Details entered should be different from the Contact Information section and should be next of kin.
                    </span>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <!-- <div class="bg-white rounded border p-3">
        <div class="row">
            <div class="col px-2"> -->
                <!-- <h4> Section {{stepper}} of {{sections.length}}</h4> -->
                <!-- <div>
                    Jump to section:
                    <select [ngModel]="stepper" (change)="jumpToPage()" [(ngModel)]="jumpTo">
                        <ng-container *ngFor="let p of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]">
                            <option  *ngIf="isPatient && p >= 0" [value]="p">{{p}}</option>
                        </ng-container>
                    </select> |
                    <span class="p-2 btn-sm" style="cursor: pointer;" (click)="showAll = !showAll">
                        <fa-icon class="text-muted" style="font-size:1.2em" [icon]="showAll ? fa.checkboxTrue : fa.checkboxFalse"></fa-icon>
                    </span> Show all sections
                </div> -->
            <!-- </div> -->
            <!-- <div class="col text-right" *ngIf="stepper <= sections.length">
                <button style="margin-bottom:10px" class="btn btn-secondary btn-lg px-3 ml-1" [class.disabled]="loading || stepper === 0" (click)="stepDown()"
                    [disabled]="loading || stepper === 0">
                    Back to previous
                </button>
                <button
                    *ngIf="isPatient"
                    style="margin-bottom:10px"
                    class="btn btn-primary btn-lg px-3 ml-1"
                    [class.disabled]="loading"
                    (click)="saveData();"
                    [disabled]="loading"
                >
                    {{loading ? 'Saving changes': stepper===18 ? 'Save':'Go to next section' }}
                    <span *ngIf="loading">
                        <i class="fa fa-spinner fa-spin"></i>
                    </span>
                </button>
                <button *ngIf="!isPatient && stepper!==18" style="margin-bottom:10px" class="btn btn-primary btn-lg px-3 ml-1" [class.disabled]="loading" (click)="saveData();" [disabled]="loading">
                    {{loading ? 'Saving changes': stepper===18 ? 'Save':'Go to next section' }}
                    <span *ngIf="loading">
                        <i class="fa fa-spinner fa-spin"></i>
                    </span>
                </button>
            </div> -->
        <!-- </div>
    </div> -->

<ng-template #familyRelationship let-item="item">
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Relationship</th>
                    <th>Primary Caregiver</th>
                    <th>Name <br>
                        <small>First Name Last Name</small></th>
                    <th>Age</th>
                    <th>Educational Attainment</th>
                    <th>Home Address</th>
                    <th>Occupation</th>
                    <th>Emotional Relationship</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">
                    <td class="p-1">
                        <p *ngIf="isPatient">
                        <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['relationship']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['relationship'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['primaryCaregiver']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['primaryCaregiver'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['name']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['name'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['age']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['age'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['educationalAttainment']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['educationalAttainment'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['homeAddress']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['homeAddress'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['occupation']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['occupation'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['emotionalRelationship']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['emotionalRelationship'] }}</p>
                    </td>


                </tr>
            </tbody>
        </table>
    </div>
    <div class="text-center">
        <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})" *ngIf="isPatient">Add
            row</button>
    </div>
</ng-template>

<ng-template #legalInvolvement let-item="item">
    <div>
        <button class="border-0 bg-white" (click)="data[item.sectionKey]['noLegalInvolveMent'] = !data[item.sectionKey]['noLegalInvolveMent']"
            [disabled]="!isPatient">
            <fa-icon class="text-muted" [icon]="!data[item.sectionKey]['noLegalInvolveMent'] ? fa.checkboxFalse : fa.checkboxTrue"></fa-icon>
            NONE
        </button>
    </div>
    <div class="table-responsive" *ngIf="!data[item.sectionKey]['noLegalInvolveMent']">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width:15%;">Involvement</th>
                    <th style="width:15%;">Type of case <br> <small>(Civil / Criminal / Drug Related)</small></th>
                    <th>Details</th>
                    <th style="width:15%;">Case Status</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select class="form-control form-control-sm border-0" [(ngModel)]="row[0]" [disabled]="!isPatient">
                                <option value="complainant">Complainant</option>
                                <optgroup label="Defendant">
                                    <option value="def-accused">Accused</option>
                                    <option value="def-prosecuted">Prosecuted</option>
                                    <option value="def-convicted">Convicted</option>
                                    <option value="def-acquited">Acquitted</option>
                                </optgroup>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row[0] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row[1]">
                        </p>
                        <p *ngIf="!isPatient">{{ row[1] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row[2]">
                        </p>
                        <p *ngIf="!isPatient">{{ row[2] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0" [(ngModel)]="row[3]">
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                                <option value="archived">Archived</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row[3] }}</p>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button type="button" [disabled]="!isPatient" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                *ngIf="isPatient">Add
                row</button>
        </div>
    </div>
</ng-template>

<ng-template #historyOfPresentIllness let-item="item">
    <div class="table-responsive" *ngIf="!item.hidden">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width:15%;">Onset <br> <small>(yr/mo/wk prior to consult)</small></th>
                    <th style="width:15%;">Symptom / Condition</th>
                    <th>Specific Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row[0]">
                        </p>
                        <p *ngIf="!isPatient">{{ row[0] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row[1]">
                        </p>
                        <p *ngIf="!isPatient">{{ row[1] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row[2]">
                        </p>
                        <p *ngIf="!isPatient">{{ row[2] }}</p>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                *ngIf="isPatient">Add
                row</button>
        </div>
    </div>
</ng-template>

<ng-template #patientPsychHistory let-item="item">
    <p>
        <strong>B. Patient Psychiatric History</strong>
        <button class="ml-5 border-0 bg-white" [disabled]="!isPatient" (click)="data[item.sectionKey]['noPatientPsychHistory'] = !data[item.sectionKey]['noPatientPsychHistory']">
            <fa-icon class="text-muted" [icon]="!data[item.sectionKey]['noPatientPsychHistory'] ? fa.checkboxFalse : fa.checkboxTrue"></fa-icon>
            NONE
        </button>
    </p>
    <div class="table-responsive" *ngIf="!data[item.sectionKey]['noPatientPsychHistory']">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th rowspan="2" style="width: 5%">Date <br> <small>mm/yy</small></th>
                    <th rowspan="2">Diagnosis/Symptom <br> <small>e.g violence, sexual abuse</small></th>
                    <th rowspan="2">Treatment Setting <br> <small>in-patient/ out-patient</small></th>
                    <th rowspan="2">Health Facility/Hospital</th>
                    <th colspan="2">Intervention</th>
                    <th colspan="3">Outcomes</th>
                </tr>
                <tr>
                    <th style="width: 110px">Type</th>
                    <th>Details</th>
                    <th style="width: 110px">Symptoms</th>
                    <th style="width: 110px">Functionality</th>
                    <th>Adverse Drug Event <br> <small>specify drug side effect</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">
                    <td class="p-1"><input type="text" [disabled]="!isPatient" class="border-0 px-1" [(ngModel)]="row['date']"></td>
                    <td class="p-1"><input type="text" [disabled]="!isPatient" class="border-0 px-1" [(ngModel)]="row['diagnosisOrSymptom']"></td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0 w-100 no-focus"
                                [(ngModel)]="row['treatmentSetting']">
                                <option value="IN">In-Patient</option>
                                <option value="OUT">Out-Patient</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['treatmentSetting'] }}</p>
                    </td>
                    <td class="p-1"><input type="text" [disabled]="!isPatient" class="border-0 px-1" [(ngModel)]="row['healthFacilityOrHospital']"></td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0 w-100 no-focus"
                                [(ngModel)]="row['interventionType']">
                                <option value="P">Pharma</option>
                                <option value="NP">Non-Pharma</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['interventionType'] }}</p>
                    </td>

                    <td class="p-1"><input type="text" [disabled]="!isPatient" class="border-0 px-1" [(ngModel)]="row['interventionDetails']"></td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0 w-100 no-focus"
                                [(ngModel)]="row['outcomeSymptoms']">
                                <option value="C">Countinuing</option>
                                <option value="PR">Partial Response</option>
                                <option value="R">Remission</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['outcomeSymptoms'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0 w-100" [(ngModel)]="row['outcomeFunctionality']">
                                <option value="F">Full</option>
                                <option value="P">Partial</option>
                                <option value="NF">Non-Functional</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['outcomeFunctionality'] }}</p>
                    </td>

                    <td class="p-1"><input type="text" [disabled]="!isPatient" class="border-0 px-1" [(ngModel)]="row['outcomeAdverseDrugEvent']"></td>
                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                *ngIf="isPatient">Add
                row</button>
        </div>
    </div>
</ng-template>

<ng-template #pastMedicalHistory let-item="item">
    <p>
        <strong>A. Past Medical History</strong>
        <button class="ml-5 border-0 bg-white" [disabled]="!isPatient" (click)="data[item.sectionKey]['noPastMedicalHistory'] = !data[item.sectionKey]['noPastMedicalHistory']">
            <fa-icon class="text-muted" [icon]="!data[item.sectionKey]['noPastMedicalHistory'] ? fa.checkboxFalse : fa.checkboxTrue"></fa-icon>
            NONE
        </button>
    </p>
    <div class="table-responsive" *ngIf="!data[item.sectionKey]['noPastMedicalHistory']">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width: 5%">Date <br> <small>mm/yy</small></th>
                    <th>Diagnosis</th>
                    <th>Treatment / Intervention</th>
                    <th>Hospital / Health Facility</th>
                    <th style="width: 10%">Outcome</th>
                    <th>Adverse Events <br> <small>(specify adverse event & possible cause)</small></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['date']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['date'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['diagnosis']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['diagnosis'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['treatmentOrIntervention']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['treatmentOrIntervention'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['hospitalOrFacility']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['hospitalOrFacility'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0" [(ngModel)]="row['outcome']">
                                <option value="R">Recovered</option>
                                <option value="NR">Not Recovered</option>
                                <option value="WC">With complications</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['outcome'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['adverseEvents']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['adverseEvents'] }}</p>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                *ngIf="isPatient">Add
                row</button>
        </div>
    </div>
</ng-template>

<ng-template #famPsychHistory let-item="item">
    <p>
        <strong>B. Family Psychiatric History</strong>
        <button class="ml-5 border-0 bg-white" [disabled]="!isPatient" (click)="data[item.sectionKey]['noFamilyPsychHistory'] = !data[item.sectionKey]['noFamilyPsychHistory']">
            <fa-icon class="text-muted" [icon]="!data[item.sectionKey]['noFamilyPsychHistory'] ? fa.checkboxFalse : fa.checkboxTrue"></fa-icon>
            NONE
        </button>
    </p>
    <ng-container *ngTemplateOutlet="famPsychAndMedHistory; context: { item: item, hidden: data[item.sectionKey]['noFamilyPsychHistory'] }"></ng-container>
</ng-template>

<ng-template #famMedicalHistory let-item="item">
    <p>
        <strong>A. Family Medical History</strong>
        <button class="ml-5 border-0 bg-white" [disabled]="!isPatient" (click)="data[item.sectionKey]['noFamMedHistory'] = !data[item.sectionKey]['noFamMedHistory']">
            <fa-icon class="text-muted" [icon]="!data[item.sectionKey]['noFamMedHistory'] ? fa.checkboxFalse : fa.checkboxTrue"></fa-icon>
            NONE
        </button>
    </p>
    <ng-container *ngTemplateOutlet="famPsychAndMedHistory; context: { item: item, hidden:data[item.sectionKey]['noFamMedHistory'] }"></ng-container>
</ng-template>

<ng-template #famPsychAndMedHistory let-item="item" let-hidden="hidden">
    <div class="table-responsive" *ngIf="!hidden">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width:20%">Illness</th>
                    <th style="width:15%"><small>Paternal / Maternal / Sibling</small></th>
                    <th>Details</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of data[item.sectionKey][item.key]">
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['illness']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['illness'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <select [disabled]="!isPatient" class="form-control form-control-sm border-0" [(ngModel)]="row['history']">
                                <option value="P">Paternal</option>
                                <option value="M">Maternal</option>
                                <option value="S">Sibling</option>
                            </select>
                        </p>
                        <p *ngIf="!isPatient">{{ row['history'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['details']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['details'] }}</p>
                    </td>

                </tr>
            </tbody>
        </table>
        <div class="text-center">
            <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                *ngIf="isPatient">Add
                row</button>
        </div>
    </div>
</ng-template>


<ng-template #abnormalDevelopmentalHistory let-item="item">
    <div class="w-75 m-auto">
        <div class="table-responsive" *ngIf="data[item.sectionKey]['noFamMedHistory']">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th style="width:15%" rowspan="2">Developmental Stage</th>
                        <th style="width:15%" colspan="5">
                            Domains <br>
                            <small>SE - Socio Emotional</small><br>
                            <small>L - Language</small><br>
                            <small>C - Cognitive</small><br>
                            <small>M - Motor</small><br>
                            <small>Sh - Self help</small>
                        </th>
                        <th rowspan="2">Remarks / Details</th>
                    </tr>
                    <tr>
                        <th style="width:5%">SE</th>
                        <th style="width:5%">L</th>
                        <th style="width:5%">C</th>
                        <th style="width:5%">M</th>
                        <th style="width:5%">SH</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of data[item.sectionKey][item.key]">




                        <td class="p-1">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                                [(ngModel)]="row['stage']">
                        </td>
                        <td class="px-auto py-1">
                            <div style="cursor: pointer;" (click)="row['SE'] = !row['SE']">
                                <fa-icon class="text-muted" [icon]="isTicked(row,'SE')"></fa-icon>
                            </div>
                        </td>
                        <td class="px-auto py-1">
                            <div style="cursor: pointer;" (click)="row['L'] = !row['L']">
                                <fa-icon class="text-muted" [icon]="isTicked(row,'L')"></fa-icon>
                            </div>
                        </td>
                        <td class="px-auto py-1">
                            <div style="cursor: pointer;" (click)="row['C'] = !row['C']">
                                <fa-icon class="text-muted" [icon]="isTicked(row,'C')"></fa-icon>
                            </div>
                        </td>
                        <td class="px-auto py-1">
                            <div style="cursor: pointer;" (click)="row['M'] = !row['M']">
                                <fa-icon class="text-muted" [icon]="isTicked(row,'M')"></fa-icon>
                            </div>
                        </td>
                        <td class="px-auto py-1">
                            <div style="cursor: pointer;" (click)="row['SH'] = !row['SH']">
                                <fa-icon class="text-muted" [icon]="isTicked(row,'SH')"></fa-icon>
                            </div>
                        </td>
                        <td class="p-1"><input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                                [(ngModel)]="row['details']"></td>
                    </tr>
                </tbody>
            </table>
            <div class="text-center">
                <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key].push({})"
                    *ngIf="isPatient">Add
                    row</button>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #substanceUseOrAddictionHistory let-item="item">
    <div class="table-responsive" *ngIf="!item.hidden">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th style="width:15%" rowspan="2">Substance</th>
                    <th colspan="3">Date <br><small>MMM/YYYY</small></th>
                    <th rowspan="2">Frequency<br><small>specify units</small></th>
                    <th rowspan="2">Amount<br><small>specify units</small></th>
                    <th colspan="5">
                        Associated Dysfunctional Behavior *
                    </th>
                    <th rowspan="2">Remarks</th>
                </tr>
                <tr>
                    <th style="width:8%"><small>Start</small></th>
                    <th style="width:8%"><small>Stop</small></th>
                    <th style="width:5%"><small>Ongoing</small></th>
                    <th style="width:5%"><span title="Occupational and Role Functioning">ORF</span></th>
                    <th style="width:5%"><span title="Social Functioning and Relationship">SFR</span></th>
                    <th style="width:5%"><span title="Self-Care and Risk Taking Behavior">SRB</span></th>
                    <th style="width:5%"><span title="Violence and Aggression">SVA</span></th>
                    <th style="width:5%"><span title="Other medical or psychiatric Illness">OMPI</span></th>
                </tr>
            </thead>
            <tbody *ngIf="data[item.sectionKey][item.key] ? data[item.sectionKey][item.key] : data[item.sectionKey][item.key] = []">
                <tr *ngFor="let row of data[item.sectionKey][item.key]">

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['substance']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['substance'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['start']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['start'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['stop']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['stop'] }}</p>
                    </td>


                    <td class="p-1 px-auto">
                        <div style="cursor: pointer;" (click)="row['ongoing'] = !row['ongoing']" class="text-center"
                            *ngIf="isPatient">
                            <fa-icon class="text-muted" [icon]="isTicked(row,'ongoing')"></fa-icon>
                        </div>
                        <div style="cursor: pointer;" class="text-center" *ngIf="!isPatient">
                            <fa-icon class="text-muted" [icon]="isTicked(row,'ongoing')"></fa-icon>
                        </div>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['frequency']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['frequency'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['amount']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['amount'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['orf']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['orf'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['sfr']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['sfr'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['srb']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['srb'] }}</p>
                    </td>

                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['sva']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['sva'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['ompi']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['ompi'] }}</p>
                    </td>
                    <td class="p-1">
                        <p *ngIf="isPatient">
                            <input type="text" [disabled]="!isPatient" class="form-control form-control-sm border-0"
                            [(ngModel)]="row['remarks']">
                        </p>
                        <p *ngIf="!isPatient">{{ row['remarks'] }}</p>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
    <div class="text-center">
        <button type="button" class="btn btn-primary btn-sm" (click)="data[item.sectionKey][item.key] ? data[item.sectionKey][item.key].push({}) : data[item.sectionKey][item.key] = []" *ngIf="isPatient">Add
            row</button>
    </div>
    <div>
        *<strong>Associated Dysfunctional Behavior</strong> <br>
        <small>
            <strong>ORF</strong>: Occupational and Role Functioning<br>
            <strong>SFR</strong>: Social Functioning and Relationship<br>
            <strong>SRB</strong>: Self-Care and Risk Taking Behavior<br>
            <strong>SVA</strong>: Violenceand Aggression<br>
            <strong>OMPI</strong>: Other medical or psychiatric Illness
        </small>
    </div>
</ng-template>
