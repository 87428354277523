<form [formGroup]="PEForm" novalidate  autocomplete="off">
    <!-- {{ PEForm.controls | json }} -->
    <accordion [isAnimated]="true" [closeOthers]="true">
        <accordion-group class="accordion-group" *ngFor="let section of SECTIONS" #accGroup>
            <div accordion-heading>               
                <fa-icon [icon]="accGroup?.isOpen? faArrowCircleUp: faArrowCircleDown" class="pull-right"></fa-icon>
                <div>
                    <div class="title">
                        {{section.header.title}}  
                        <fa-icon [icon]="faPencilAlt" class="text-warning" *ngIf="PEForm.controls[section.key].value" ></fa-icon>
                        <span *ngIf="PEForm.controls[section.key].dirty" class="badge bg-danger ml-2">Draft</span>
                    </div>
                    <div class="description" [innerHTML]="section.header.description"></div>
                    
                </div>
                
            </div>
            
            <textarea rows="3" class="form-control" [formControlName]="section.key"> </textarea>
        </accordion-group>
    </accordion>

    <div class="mt-2 text-right">
        <button 
            class="btn btn-primary mr-2" 
            (click)="doSave()"
            [disabled]="!formDirty"
        >
            {{ savingForm ? 'Saving  Physical Exam' : 'Save Physical Exam' }} 
            <i *ngIf="savingForm" class="fa fa-spinner fa-spin"></i>
        </button>
        
        <button 
            class="btn btn-danger" 
            (click)="openModal(template)"
            [disabled]="!formDirty"
        >
            Cancel {{ formDirty ? 'Changes' : ''}}
        </button>
    </div>
    
</form>


<ng-template #template>
    <div class="modal-body text-center">
        <p>Are you sure you want to cancel your changes to the Physical Exam?</p>
        <button type="button" class="btn btn-default mr-2" (click)="confirm()" >Yes</button>
        <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
    </div>
</ng-template>