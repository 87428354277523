import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { RoleRoutingModule } from './role-routing.module';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { RoleService } from '../../../services/role.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CrudModule } from '../../../core/base/crud';

import { ListComponent, EditComponent, CreateComponent } from './crud';
import { RolesComponent } from './roles.component';


@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    CrudModule,
    RoleRoutingModule
  ],
  declarations: [
    RolesComponent, ListComponent, EditComponent, CreateComponent
  ],
  providers:[
    RoleService,
  ]
})
export class RolesModule { }
