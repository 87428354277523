import { Injectable } from '@angular/core';
import { AbstractService } from './../abstract.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SearchService extends AbstractService {
  endpoint = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http);
  }

  searchDoctors(params) {
    return this.http.get(
      `${environment.serverUrlV2}/doctors/search${this.buildQueryString(params)}`,
      {
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      })
    .map((result: any) => {
      return result;
    }, (err: any) => {
      console.log(err)
        return err;
    });
  }
}
