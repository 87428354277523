import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Logger } from '../../logger.service';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../authentication.service';

const log = new Logger('CheckStaffeGuard');

@Injectable()
export class CheckStaffRoleGuard implements CanActivate {
    constructor(
        private router: Router,
        private userService: UserService,
        private authentication: AuthenticationService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const isAuthenticated = this.authentication.isAuthenticated();
        // THIS IS BETTER TO REVISE USER SERVICE CHECKROLE TO CHECK NOT ADMIN 
        const isStaff = this.userService.checkRole([
            'patient', 
            'in-patient', 
            'doctor',
            'secretary'
        ]);

        if (!isAuthenticated) {
            this.router.navigate(['/login'], { replaceUrl: true });
        } else {
            if (isStaff) {
                return true;
            } else {
                log.debug('You are not Staff, redirecting to "me"...');
                this.router.navigate(['/login'], { replaceUrl: true });
                return false;
            }
        }

    }
}
