<div *ngIf="match">
    <h6 class="text-muted">
        {{title}}
        <span 
            class="d-print-none badge badge-dark cta mr-2 ml-3"
            *ngIf="iAmDoctor() && !iAmPsychologist()"
        >
            <fa-icon 
                [icon]="fa.faDownload"
                ngbTooltip="Click to download PDF"
                (click)="downloadPDF()"
            ></fa-icon>
        </span> 
    </h6>

    <table>
        <tr>
            <td class="label">Eyes</td>
            <td class="value" [innerHTML]="getValue('eyes')"></td>
        </tr>
        <tr>
            <td class="label">Ears, Nose, Throat, Mouth</td>
            <td class="value" [innerHTML]="getValue('ent')"></td>
        </tr>

        <tr>
            <td class="label">Neck</td>
            <td class="value" [innerHTML]="getValue('neck')"></td>
        </tr>

        <tr>
            <td class="label">Respiratory</td>
            <td class="value" [innerHTML]="getValue('respiratory')"></td>
        </tr>

        <tr>
            <td class="label">Cardiovascular</td>
            <td class="value" [innerHTML]="getValue('cardiovascular')"></td>
        </tr>

        <tr>
            <td class="label">Constitutional</td>
            <td class="value" [innerHTML]="getValue('constitutional')"></td>
        </tr>

        <tr>
            <td class="label">Chest</td>
            <td class="value" [innerHTML]="getValue('chest')"></td>
        </tr>

        <tr>
            <td class="label">Musculoskeletal</td>
            <td class="value" [innerHTML]="getValue('musculoskeletal')"></td>
        </tr>

        <tr>
            <td class="label">Skin</td>
            <td class="value" [innerHTML]="getValue('skin')"></td>
        </tr>

        <tr>
            <td class="label">Neurologic</td>
            <td class="value" [innerHTML]="getValue('neurologic')"></td>
        </tr>

        <tr>
            <td class="label">Psychiatric</td>
            <td class="value" [innerHTML]="getValue('psychiatric')"></td>
        </tr>

        <tr>
            <td class="label">Other Pertinent Findings</td>
            <td class="value" [innerHTML]="getValue('other_findings')"></td>
        </tr>
    </table>
</div>
