import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrudModule } from '../../../core/base/crud';
import { ListComponent } from './crud/list/list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EventFeedbacksService } from '../../../services/event-feedbacks.service';
import { ViewComponent } from './crud/view/view.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { HttpModule } from '@angular/http';
import { FeedbacksComponent } from './feedbacks.component';
import { FeedbacksRoutingModule } from './feedbacks-routing.module';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    CrudModule,
    NgxDatatableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    HttpModule,
    FeedbacksRoutingModule
  ],
  declarations: [
    ListComponent,
    ViewComponent,
    FeedbacksComponent
  ],
  providers: [
    EventFeedbacksService,
    BsModalService,
  ]
})
export class FeedbacksModule { }
