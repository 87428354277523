import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class DoctorAppointmentService extends AbstractService {

    endpoint = environment.serverUrl + 'user/events/appointments';

    constructor(http: HttpClient) {
        super(http);
    }

    getBookedEvents() {
        // return this.http
    }
}
