<crud-header 
[settings]="settings"
[action]="'list'"
(refreshList)="refreshList()"
></crud-header>
<div>
    <ngx-datatable
      [rows]="rows"
      [columns]="columns"
      [columnMode]="'force'"
      [footerHeight]="30"
      [rowHeight]="'auto'"
      [externalPaging]="true"
      [count]="page.totalElements"
      [offset]="page.pageNumber"
      [limit]="page.size"
      (page)='handlePager($event)'
      (sort)="handleSort($event)"
      [loadingIndicator]="loading"

      class="small material"
    >
      <ngx-datatable-column name="ID">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span (click)="sort($event)">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ value }}
          </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Name" props="name">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
              {{ value }}
          </ng-template>
      </ngx-datatable-column>

     <!--  <ngx-datatable-column name="Guard Name" props="guard_name">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            <span (click)="sort()">{{column.name}}</span>
          </ng-template>
          <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
            {{ row.guard_name }}
          </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column name="Actions" sortable="false" prop="id">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
            {{column.name}}
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <a *ngIf="settings.crud.update.allow" [routerLink]="[getEditLink(row)]"><i class="fa fa-pencil text-warning"></i></a>
          <a *ngIf="settings.crud.delete.allow" href="javascript:void(0)" (click)="onDeleteRow(row)"><i class="fa fa-trash text-danger"></i></a>
        </ng-template>
      </ngx-datatable-column> -->

    </ngx-datatable>
  </div>