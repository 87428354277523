export const settings = {
    plural: 'Medicines',
    singular: 'Medicine',
    
  
    crud: {
      create: {
        allow: true, // true/false
        url: '/admin/medicines/create'
      },
      read:{
  
      },
      update:{
        allow: true,
        url: '/admin/medicines/edit/'
      },
      delete:{
        allow: true
      },
      list: {
        allow: true,
        url: '/admin/medicines'
      }
    }
  };