import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { AbstractService } from "./abstract.service";
import { HttpClient } from "@angular/common/http";
import { Moment } from "moment";
import moment from "moment";
import "moment-timezone";

@Injectable()
export class AppointmentService extends AbstractService {
    endpoint = environment.serverUrl + "user/appointments";

    constructor(http: HttpClient) {
        super(http);
    }

    patientAppointments(from: Moment, to: Moment) {
        const startString = moment(from).format("YYYY-MM-DD");
        const endString = moment(to).format("YYYY-MM-DD");
        const days = moment(to).diff(moment(from), "d", true);
        const str = "events.start_at:" + startString + ":>=|events.start_at:" + endString + ":<=";
        const url = `${this.endpoint}?dateRange=${str}&limit=${days * 8}`;

        var params = {
            dateRange: str,
            // limit:
        };

        return this.index(params);

        // return this.http.get(url)
        // .map((response: any) => response.data.map((o) => {
        //     o.event.bookingId = o.id;
        //     return o.event;
        // }
        // ));
    }

    cancelAppointment(appointmentId) {
        return this.http.post(this.endpoint + "/cancel/" + appointmentId, {}).map(
            (result: any) => {
                return result;
            },
            (err: any) => {
                return err;
            }
        );
    }

    // private createMoment = date => moment(date.date, 'YYYY-MM-DD HH:mm:ss').tz(date.timezone).tz(moment.tz.guess());
}
