import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { TermsAgreementService } from './service'
import { UserService } from '../../services'


@Component({
    selector: 'app-terms-agreement',
    templateUrl: './termsagreement.component.html',
    styleUrls: ['./termsagreement.component.scss']
})
export class TermsAgreementComponent {
    @Input() trigger: any
    @ViewChild('thankYou') mentalNotify: ElementRef;
    @ViewChild('autoShownModal', { static: false }) autoShownModal?: ModalDirective;

    modalRef?: BsModalRef;

    public isModalShown = false
    public loadingTerm = false
    public processingAnswer = false
    private termId = null 
    public body = ''


    questions = [];

    constructor(
        public service: TermsAgreementService,
        private userService: UserService
    ) {

        userService._tc
            .subscribe(
                terms => {
                    if (terms) {
                        let term = terms.shift()
                        if (
                            term
                        ) {
                            this.termId = term.tid
                            this.loadingTerm = true
                            service.read(term.tid)
                            .subscribe(result => {
                                this.body = result['body']
                                this.loadingTerm = false
                                this.showModal()
                            },
                            error => {
                                console.log(error)
                                this.loadingTerm = false
                            }) 
                            // this.OtsukaFormTrigger = services['_otsuka'].pop
                            // this.bookingId = services['_otsuka'].bid
                            // if (!this.isModalShown) {
                            //     this.getQuestions()
                            //     this.showModal()
                            // }
                        } else {
                            // this.hideModal()
                        }
                    }
                }
            )
    }

    showModal(): void {
        // TRIGGER modal only if there is a flag from backend
        this.isModalShown = true;
    }

    hideModal(): void {
        this.autoShownModal?.hide();
        // this.OtsukaTrigger.emit(false)
    }

    onHidden(): void {
        this.isModalShown = false;
    }


   

    iAccept =  ()  => {
        let user = this.userService.getStoredCredentials()
        
        this.processingAnswer = true
        this.service.iAccept(user.id, this.termId)
        .subscribe(
            response => {
                if (!response.data.length) {
                    this.hideModal()
                }

                this.processingAnswer = false
                let tc = this.userService._tc.getValue()
                tc = response.data
                this.userService._tc.next(tc)
                
            },
            error => {
                console.log(error)
                this.processingAnswer = false
            }
        )
    }
}
