<div ngbDropdown container="body" *ngIf="slotDetails.type == 'face'">
  <div *ngIf="clinicList.length">
    <button type="button" class="btn btn-outline-primary btn-sm" ngbDropdownToggle>Select your clinic</button>
    <div ngbDropdownMenu>
      <button ngbDropdownItem *ngFor="let clinic of clinicList" (click)="_selectClinic(clinic.id, clinic.name)">
        {{clinic.name}}
      </button>

      <!-- <button ngbDropdownItem (click)="_selectClinic(1, 'Edit')">Edit</button>
    <button ngbDropdownItem (click)="_selectClinic(2, 'Duplicate')">Duplicate</button>
    <button ngbDropdownItem (click)="_selectClinic(3, 'Move')">Move</button>
    <div class="dropdown-divider"></div>
    <button ngbDropdownItem (click)="_selectClinic(4, 'Delete')">Delete</button> -->
    </div>
  </div>

  <div class="alert alert-warning small mb-0" *ngIf="!clinicList.length">
    No clinic found. Doctor must register a clinic.
  </div>
</div>
