import { RouterModule, Routes, CanActivate } from '@angular/router';
import { NgModule } from '@angular/core';

import { Route } from '../../core/route.service';
import { extract } from '../../core/i18n.service';

import { CheckAdminRoleGuard } from '../../core/authentication/guards/check-admin-role.guard';
import { AdminComponent } from './admin.component';

const routes: Routes = Route.withShell([
    {
        path: 'admin',
        data: { title: extract('Admin') },
        component: AdminComponent,
        children: [
            {
                path: '',
                loadChildren: './home/home.module#HomeModule',
                data: { title: extract('Admin') },
                // pathMatch: 'full'
            },
            {
                path: 'users',
                loadChildren: './users/users.module#UsersModule',
                data: { title: extract('Admin :: Users') },
                // pathMatch: 'full'
            },

            {
                path: 'roles',
                loadChildren: './roles/roles.module#RolesModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Roles') },
            },

            {
                path: 'medical-practitioners',
                loadChildren: './medical-practitioners/medical-practitioners.module#MedicalPractitionerModule',
                data: { title: extract('Admin :: Doctors') },
                // pathMatch: 'full',
            },

            {
                path: 'patients',
                loadChildren: './patients/patients.module#PatientsModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Patient') },
            },

            {
                path: 'staff',
                loadChildren: './staffs/staffs.module#StaffsModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Staff') },
            },

            {
                path: 'medicines',
                loadChildren: './medicines/medicines.module#MedicinesModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Medicines') },
            },

            {
                path: 'coupons',
                loadChildren: './coupons/coupons.module#CouponsModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Coupons') },
            },


            {
                path: 'disbursements',
                loadChildren: './disbursement/disbursement.module#DisbursementModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Doctor Fees') },
            },

            {
                path: 'messages',
                loadChildren: './messages/messages.module#MessagesModule',
                data: { title: extract('Admin :: Messages') },
            },

            {
                path: 'orders',
                loadChildren: './orders/orders.module#OrdersModule',
                data: { title: extract('Admin :: Orders') },
            },

            {
                path: 'feedbacks',
                loadChildren: './feedbacks/feedbacks.module#FeedbacksModule',
                data: { title: extract('Admin :: Feedbacks') },
            },

            {
                path: 'generic-names',
                loadChildren: './generic-names/generic-names.module#GenericNamesModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Generic Names') },
            },

            {
                path: 'companies',
                loadChildren: './companies/companies.module#CompaniesModule',
                // pathMatch: 'full',
                data: { title: extract('Admin :: Companies') },
            },
        ],
        canActivate: [
            CheckAdminRoleGuard,
        ]
    },
]);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AdminRoutingModule {
}
