import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { UserService } from '../../../services/user.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class GuestOnlyGuard implements CanActivate {
    constructor(
        private authService: AuthenticationService,
        private userService: UserService,
        public router: Router
    ) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // IF Guest
        if (!this.authService.isAuthenticated()) {
            return true;
        } else {
            // REDIRECT to their appropriate landing page
            if (this.userService.checkRole(['admin'])) {
                this.router.navigate(['admin']);
                return true
            } else if (this.userService.checkRole(['staff', 'doctor', 'patient', 'in-patient',])) {
                this.router.navigate(['me']);
                return true
            }

            return false;
        }
    }
}
