
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';


export const GENERIC_NAMES_MODEL = {
  'name' : [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
}

export const ERRORS = [
  { name: 'required', text: 'This field is required', rules: ['touched', 'dirty'] },
  { name: 'minlength', text: 'Minimum number of characters ', rules: ['touched', 'dirty'] }
];

export const ROLES_MODEL = {
  'roles': FormArray,
};