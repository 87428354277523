import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class PingService {

    pingStream: Subject<number> = new Subject<number>();
    ping= 0;
    url = 'https://cors-test.appspot.com/test';
    // url: string = "'http://boticajohn-admin.dev/api/ping";

    constructor() {
        // Observable.interval(2000)
        //   .subscribe((data) => {
        //     let timeStart: number = performance.now();

        //     this._http.get(this.url)
        //       .subscribe((data) => {
        //         let timeEnd: number = performance.now();

        //         let ping: number = timeEnd - timeStart;
        //         this.ping = ping;
        //         this.pingStream.next(ping);
        //       },
        //       err => {
        //         this.ping = 0;
        //         this.pingStream.next(this.ping);
        //       });
        //   },(err) => { console.log( err)});
    }

}
