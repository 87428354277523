import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';

import { UserService } from '../../services/user.service';
import { Credentials } from './credentials.interface';
import { LoginContext } from './login-context.interface';
import { User } from '../../shared/models';

const credentialsKey = 'credentials';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {

    private currentUser: User;

    constructor(private userService: UserService) {
        this.currentUser = userService.currentUser;
        userService.userData.next(this.currentUser.fullName);
    }

    login(login: LoginContext): Observable<User> {
        return this.userService.authenticate(login);
    }

    logout(): Observable<void> {
        return of(this.userService.clearStoredCredentials());
    }

    isAuthenticated(): boolean {
        return !!this.currentUser.token;
    }

    get user(): User {
        return this.currentUser;
    }

    private setCredentials(credentials?: User, remember?: boolean) {
        return this.userService.updateStoredCredentials(credentials);
    }
}
