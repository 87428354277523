<ngb-tabset>
    <ngb-tab title="Message Details">
        <ng-template ngbTabContent>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mx-auto">
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Name :</label> 
                                <input [readonly]="true" type="text" class="form-control form-control-lg underBorder" [(ngModel)]="name" />
                            </div>
                        </div>
                        
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Email Address :</label> 
                                <a class="form-control form-control-lg underBorder email" [attr.href]="href" target="_top">{{ from }}</a>
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Phone Number :</label> 
                                <input [readonly]="true" type="text" class="form-control form-control-lg underBorder" [(ngModel)]="phone" />
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Message :</label> 
                                <textarea [readonly]="true" class="form-control form-control-lg underBorder" [(ngModel)]="message"
                                    rows="5"></textarea>
                            </div>
                        </div>

                    </div>
                </div>
            </div>            
        </ng-template>
    </ngb-tab>
</ngb-tabset>