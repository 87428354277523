import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { AvailabilityModule } from './availability/availability.module';
import { SecretaryModule } from './secretary/secretary.module';
// import { AvailabilityComponent } from './availability/availability.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import {
    AvailabilityService, MedicalPractitionerService, AppointmentService, ArchiveService,
    MentalHealthSheetService, EventPrescriptionService, CartService, UserService, BillingInformationService, OtsukaMARSService, SecretaryService
} from '../../services';
import { CalendarComponent } from '../../shared/components/calendar.component';
import { MyListingComponent } from './listing/listing.component';
import { MyProfileComponent } from './listing/profile/profile.component';
import { MyListComponent } from './listing/list/list.component';
import { FaqComponent } from './faq/faq.component';
import { SharedModule } from '../../shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NotPatientGuard } from '../../core/authentication/guards/not-patient.guard';
import { IsSecretaryGuard } from '../../core/authentication/guards/is-secretary.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CouponService } from '../../services';

// import { MyPsychologistComponent } from './psychologist/psychologist.component';
// import { PsychologistListComponent } from './psychologist/list/list.component';
// import { PsychologistProfileComponent } from './psychologist/profile/profile.component';

import { ConsultationNotesSectionDiagnosisComponent } from './listing/profile/sections/diagnosis/diagnosis.component'
import { ConsultationNotesSectionProgressComponent } from './listing/profile/sections/progress/progress.component'
import { ConsultationNotesSectionPatientInstructionsComponent } from './listing/profile/sections/patient-instructions/patient-instructions.component'
import { ConsultationNotesSectionNextMeetingComponent } from './listing/profile/sections/next-meeting/next-meeting.component'
import { ConsultationNotesSectionLabRequestComponent } from './listing/profile/sections/lab-request/lab-request.component'
import { ConsultationNotesSectionMedicalAbstractComponent } from './listing/profile/sections/medical-abstract/medical-abstract.component'
import { ConsultationNotesSectionMedicalCertificateComponent } from './listing/profile/sections/medical-certificate/medical-certificate.component'
import { ConsultationNotesSectionAdmittingOrderComponent } from './listing/profile/sections/admitting-order/admitting-order.component'
import { ConsultationNotesSectionPrescriptionsComponent } from './listing/profile/sections/prescriptions/prescriptions.component'
import { ConsultationNotesSectionFormMedicalAbstractComponent } from './listing/profile/sections/medical-abstract/form-medical-abstract/form-medical-abstract.component'
import { ConsultationNotesSectionMedicalAbstractEmailComponent } from './listing/profile/sections/medical-abstract/email-to-patient/email-to-patient.component'
import { ConsultationNotesSectionFormMedicalCertificateComponent } from './listing/profile/sections/medical-certificate/form-medical-certificate/form-medical-certificate.component'
import { ConsultationNotesSectionMedicalCertificateEmailComponent } from './listing/profile/sections/medical-certificate/email-to-patient/email-to-patient.component'
import { ConsultationNotesSectionPrescriptionEmailComponent } from './listing/profile/sections/prescriptions/email-to-patient/email-to-patient.component'
import { ConsultationNotesSectionPhysicalExamSectionProgressComponent } from './listing/profile/sections/physical-exam/physical-exam.component'
import { MARSComponent } from './listing/profile/sections/mars/mars.component';

// import { MyPrescriptionComponent } from './my-prescription/my-prescription.component';
import { ServeFormModule } from './my-prescription/serve-form/serve-form.module';
import { TransactionModule } from './my-prescription/transaction/transaction.module';

import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    listPlugin,
    interactionPlugin
]);

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgbModule,
        FormsModule,
        AvailabilityModule,
        SecretaryModule,
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        FontAwesomeModule,
        TabsModule,
        SharedModule,
        BsDatepickerModule,
        ServeFormModule,
        TransactionModule,
        FullCalendarModule,
    ],
    declarations: [
        DashboardComponent,
        // AvailabilityComponent,
        MyListingComponent,
        MyProfileComponent,
        FaqComponent,
        MyListComponent,
        MARSComponent,
        // MyPsychologistComponent,
        // PsychologistListComponent,
        // PsychologistProfileComponent,

        // MyPrescriptionComponent,

        ConsultationNotesSectionProgressComponent,
        ConsultationNotesSectionDiagnosisComponent,
        ConsultationNotesSectionPatientInstructionsComponent,
        ConsultationNotesSectionNextMeetingComponent,
        ConsultationNotesSectionLabRequestComponent,
        ConsultationNotesSectionAdmittingOrderComponent,
        ConsultationNotesSectionPrescriptionsComponent,

        ConsultationNotesSectionMedicalAbstractComponent,
        ConsultationNotesSectionFormMedicalAbstractComponent,
        ConsultationNotesSectionMedicalAbstractEmailComponent,

        ConsultationNotesSectionMedicalCertificateComponent,
        ConsultationNotesSectionFormMedicalCertificateComponent,
        ConsultationNotesSectionMedicalCertificateEmailComponent,
        ConsultationNotesSectionPrescriptionEmailComponent,
        ConsultationNotesSectionPhysicalExamSectionProgressComponent,
    ],
    providers: [
        BillingInformationService,
        NotPatientGuard,
        IsSecretaryGuard,
        AvailabilityService,
        SecretaryService,
        MedicalPractitionerService,
        AppointmentService,
        ArchiveService,
        MentalHealthSheetService,
        EventPrescriptionService,
        CartService,
        CouponService,
        UserService,
        OtsukaMARSService
    ]
})
export class DashboardModule { }
