import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, } from '../../../../../../../services';
import { NotesService, } from '../../../../../../../services';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'section-form-medical-certificate',
    templateUrl: './form-medical-certificate.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionFormMedicalCertificateComponent implements OnChanges {
    @Input() eventId
    @Input() feedbackData: object
    @Output() updatedData = new EventEmitter
    @Output() modalDecline = new  EventEmitter()
    @Input('ngFormOptions')

    match = true
    value = []

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private noteService: NotesService,
        private toastr: ToastrManager
    ) {}

    onSubmit = (val) => {
        this.noteService.saveNote({
            event_id: this.eventId,
            notes: {
                medCertRecomendation: val['medCertRecomendation']
            }
        })
            .subscribe(data => {
                this.updatedData.emit(data['data'].notes)
                this.modalDecline.emit()
                this.toastr.successToastr('Medical Sheet saved successfully.')
            }, e => {
                this.toastr.errorToastr(e.error.message || 'Something went wrong.');
            });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.feedbackData && changes.feedbackData.currentValue && changes.feedbackData.currentValue) {
            this.value = changes.feedbackData.currentValue
        }
    }

}
