import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../core/authentication/authentication.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private authService: AuthenticationService;
    private router: Router;

    constructor(private injector: Injector) {
        setTimeout(() => {
            this.authService = injector.get(AuthenticationService);
            this.router = injector.get(Router);
        });
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authReq = this.authenticateRequest(req);
        return next.handle(authReq)
            .do(
                () => {
                },
                (error: any) => {
                    
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            if (error.url.match(/(\/api\/me)/)) {
                                return   
                            }
                            
                            this.router.navigate(['/login']);
                        }
                    }
                },
            );
    }

    authenticateRequest(req: HttpRequest<any>) {
        let headers = req.headers

        if (!req.headers.get('Accept')) {
            headers = req.headers.set('Accept', environment.API_HEADER_ACCEPT);
        }

        // PATCH
        if (!this.authService) {
            this.authService = this.injector.get(AuthenticationService);
        }

        if (this.authService.isAuthenticated()) {
            headers = headers.set('Authorization', 'Bearer ' + this.authService.user.token)
        }

        let content_type = 'application/json';

        if (req.url.indexOf('picture') > 0 
            || req.url.indexOf('signature') > 0
            || req.url.match(/clinics\/[\d]*\/image/)
        ) {
            content_type = 'application/x-www-form-urlencoded';
            const nheaders = headers.set('enctype', 'multipart/form-data');
                // .set('Content-type', content_type);
            return req.clone({
                headers: nheaders
            });
        }
        
        headers = headers.set('Access-Control-Allow-Origin', '*');
        headers = headers.set('Access-Control-Allow-Methods', 'HEAD, GET, POST, PUT, PATCH, DELETE, OPTIONS');
        headers = headers.set('Access-Control-Allow-Headers', 'Content-Type');

        const authReq = req.clone({
            headers: headers.set('Content-type', content_type)
        });

        return authReq;
    }
}
