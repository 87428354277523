<div class="row">
    <div class="col">
        <span *ngIf="action==='list'" class="h3">{{ settings.plural }}</span>
        <span *ngIf="action==='edit'" class="h3">
            {{ title || 'Edit '+settings.singular+' '+ (id?id:'') }}
        </span>
        <span *ngIf="action==='create'" class="h3">
            {{ title || 'Create '+settings.singular+' '+ (id?id:'') }}
        </span>
    </div>
    <div class="col-3 text-right">
        <div *ngIf="action==='list'">
            <a class="btn btn-primary rounded-circle" *ngIf="settings.crud.create.allow" [routerLink]="settings.crud.create.url" >
                <i class="fa fa-plus" aria-hidden="true"></i>
            </a>
            <a class="btn btn-secondary rounded-circle" (click)="doRefreshList">
                <i class="fa fa-refresh" aria-hidden="true"></i>
            </a>
        </div>
        <div *ngIf="action==='edit'">
            <a class="btn btn-secondary rounded-circle" [routerLink]="settings.crud.list.url">
                <i class="fa fa-list" aria-hidden="true"></i>
            </a>
        </div>
    </div>
</div>
<hr>
