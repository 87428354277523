import { Component, OnInit, Injector } from '@angular/core';
import { BaseCrudFormComponent } from '../base-crud-form/base-crud-form.component';
import { Router, Params } from '@angular/router';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class EditComponent extends BaseCrudFormComponent {

    constructor(injector: Injector) {
        super(injector);

        this.activatedRoute.params.subscribe((params: Params) => {
            this.objectId = params['id'];
        });
    }

    _load(params?: any) {
        return this.service.show(this.objectId, params);
    }
}
