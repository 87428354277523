<!--FOR PRINTING  -->
<div class="border rounded p-2 bg-light d-print-none">
    <div *ngIf="profile">
        <!--  m-2 my-4 py-2 -->
        <div class="row">
            <div class="col-sm-12 mb-2">
                <tabset class="spaced">
                    <!-- <tab *ngIf="iAmSecretary()" heading="Appointments" id="tab1">
                        <ng-container *ngTemplateOutlet="appointments"></ng-container>
                    </tab> -->
                    <tab *ngIf="iAmSecretary()" heading="Patient Health Data
                        Sheet">
                        <ng-container *ngTemplateOutlet="infoSheet; context {
                            userId: profile.id}"></ng-container>
                    </tab>
                    <!-- <tab *ngIf="iAmSecretary()" heading="Medical Adherence">
                        <div class="col">
                            <app-mars></app-mars>
                        </div>
                    </tab> -->
                </tabset>
            </div>


        </div>
    </div>

    <!-- [{{viewEvent.prescriptions.customs | json}}] -->
    <ng-template #infoSheet let-id="userId">
        <div class="col">
            <div class="card" *ngIf="medicalUserCanView()">
                <div class="card-header">
                    <nav class="navbar navbar-light bg-light
                        justify-content-between p-0">
                        <h3>Health Information Sheet</h3>
                    </nav>
                </div>
                <div class="card-body">
                    <app-data-sheet-sec [userId]="id" style="font-size: 95%"></app-data-sheet-sec>
                </div>
            </div>
        </div>
    </ng-template>
