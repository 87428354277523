import { Role } from './role.model';

export class User {
    public id: number;
    public email: string;
    public firstName: string;
    public lastName: string;
    public permissions: any;
    public roles: Role[];
    public token: string;
    public suffix: string;
    public prefix: string;
    public avatar;

    constructor(
        id?: number,
        email?: string,
        first_name?: string,
        last_name?: string,
        permissions?: string,
        roles?: Role[],
        token?: string,
        suffix?: string,
        prefix?: string,
        avatar?
    ) {
        this.create(id, email, first_name, last_name, permissions, roles, token, suffix, prefix, avatar);
    }

    public create(
        id?: number,
        email?: string,
        firstName?: string,
        lastName?: string,
        permissions?: string,
        roles?: Role[],
        token?: string,
        suffix?: string,
        prefix?: string,
        avatar?
    ) {
        this.id = id;
        this.email = email;
        this.firstName = firstName;
        this.lastName = lastName;
        this.permissions = permissions;
        this.roles = roles;
        this.token = token;
        this.suffix = suffix;
        this.prefix = prefix;
        this.avatar = avatar;
    }

    public createFromStorage(obj: any) {
        const { id, email, firstName, lastName, permissions, roles, token, suffix, prefix, avatar } = obj;
        this.create(id,
            email,
            firstName,
            lastName,
            permissions,
            roles,
            token,
            suffix,
            prefix,
            avatar,
        );
    }

    public createFromAuth(obj: any) {
        const { id, email, first_name, last_name, permissions, roles, token, suffix, prefix, avatar } = obj;

        this.create(
            id,
            email,
            first_name,
            last_name,
            permissions.data,
            roles.data.map(r => new Role(r.id, r.name)),
            token,
            suffix,
            prefix,
            avatar
        );
    }

    public get fullName(): string {
        return `${this.prefix ? this.prefix : ''} ${this.firstName} ${this.lastName} ${this.suffix ? this.suffix : ''}`;
    }
}
