import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreateComponent } from './crud/create/create.component';
import { EditComponent } from './crud/edit/edit.component';
import { ListComponent } from './crud/list/list.component';
import { MedicinesRoutingModule } from './medicines-routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpModule } from '@angular/http';
import { CrudModule } from '../../../core/base/crud';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MedicinesComponent } from './medicines.component';
import { MedicinesService } from '../../../services/medicines.service';
import { GenericNamesService } from '../../../services/generic-names.service';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    MedicinesRoutingModule,
    NgxDatatableModule,
    NgxErrorsModule,
    NgbModule,
    HttpModule,
    CrudModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CreateComponent, 
    EditComponent, 
    ListComponent,
    MedicinesComponent
  ],
  providers: [
    MedicinesService,
    GenericNamesService,
    BsModalService,
  ]
})
export class MedicinesModule { }
