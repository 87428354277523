export * from './user.service'
export * from './role.service'
export * from './appointment.service'
export * from './doctor-appointment.service'
export * from './availability.service'
export * from './open-tok.service'
export * from './medical-practitioner.service'
export * from './patient.service'
export * from './permission.service'
export * from './ping.service'
export * from './notes.service'
export * from './storage.service'
export * from './feedback.service'
export * from './coupon.service'
export * from './mental-health-sheet.service'
export * from './archive.service'
export * from './loader.service'
export * from './prescription-flag-update.service'
export * from './event-prescription.service'
export * from './prescription-transaction.service'
export * from './rate-charge.service'
export * from './cart.service'
export * from './order-transaction.service'
export * from './order.service'
export * from './diagnosis-dsm5.service'
export * from './admin.service'
export * from './cart-order.service'
export * from './cart-item.service'
export * from './staff.service'
export * from './prescription-item.service'
export * from './helpers'
export * from './billing-information.service'
export * from './insurance.service'
export * from './physical-exam.service'
export * from './v2/search.service'
export * from './v2/doctor.service'
export * from './v2/deliver-my-medicines.service'
export * from './v2/patient-file.service'
export * from './v2/otsuka-mars.service'
export * from './clinic.service'
export * from './secretary.service'

export function openWindow(url, target = '_blank') {
    window.open(url, target)
}
