<div class="bg-light p-2 m-0 fixed-top">
    <a class="navbar-brand" [routerLink]="['/me']">
        <img src="./assets/images/logo.png" style="width:300px;">
    </a>
    <!-- [{{supportedCodecs | json}}] -->
</div>

<!-- [
<app-video-pre-test></app-video-pre-test>
] -->
<div class="container-fluid">
    <div *ngIf="showHUD">
        <div class="row" style="height: inherit;">
            <div class="col-md-9 col-sm-12" style="position: relative;">
                <div id="btn-controls" [ngClass]="{'ok': inSession}">
                    <div class="controls-wrapper" [ngClass]="{'vertical-center': !inSession}">
                        <div class="small text-center">

                            <div *ngIf="!initialized" class="text-danger">
                                Initializing...
                            </div>
                            
                            <div *ngIf="subscriberVideoDisableWarning" class="text-warning">
                                <strong>We are detecting unstable network. Video quality may be affected.</strong>
                            </div>

                            <div *ngIf="subscriberVideoDisabled" class="text-warning">
                                <strong>We temporarily disabled video streaming until the network stabilizes. Please hang tight.</strong>
                            </div>

                            <div *ngIf="subscriberVideoDisabledExplicitely" class="text-warning">
                                <strong>{{ otherParty().name }} disabled {{ otherParty().sex == 'male'? 'his' : 'her' }} camera</strong>
                            </div>
                            
                            <div *ngIf="initialized && !publisherReady && !publisherMediaDenied">
                                <div *ngIf="!publisherAudioReady" class="text-danger loading-ellipsea">Detecting microphone</div>
                                <div *ngIf="!publisherVideoReady" class="text-danger loading-ellipsea">Detecting camera</div>
                            </div>

                            <div *ngIf="publisherReady && publisherMediaDenied">
                                <div *ngIf="!publisherAudioReady" class="text-danger">Unable to detect your microphone</div>
                                <div *ngIf="!publisherVideoReady" class="text-danger">Unable to detect your camera</div>
                            </div>

                            <div *ngIf="publisherReady && !inSession">
                                <div class="text-warning">
                                    <h6>
                                        Press on the green dial-in button to start your video call
                                    </h6>
                                </div>
                            </div>

                            <div *ngIf="publisherMediaDenied">
                                <div class="text-danger">
                                    <h6>
                                        Recoveryhub is denied access from your audio and video sources.
                                        <br/><br/>
                                        Please check your browser's settings or check if another application is using your hardware audio and video sources.
                                    </h6>
                                </div>
                            </div>

    <!--                        
                            <div *ngIf="!publisherDeviceOk" class="text-danger">Enable your 
                                <div *ngIf="!publisherAudioReady" class="text-danger">microphone</div>
                                video camera 
                                to proceed
                            </div> -->

                            <div *ngIf="sessionReconnecting" class="text-danger">Disconnected from session. Attempting to reconnect.</div>
                            


                            <!-- <div *ngIf="!subscriberReady">
                                Waiting for <strong>{{ otherParty().name }}</strong> log in
                            </div> -->
                            
                            <div *ngIf="initialized && publisherReady && !subscriber && event" class="we-are-waiting">
                                <div class="{{(inSession && !subscriber) ? '' : 'text-warning'}}">
                                    We are waiting for <strong>{{ otherParty().name }}</strong> to dial in
                                </div>
                            </div>

                            <div *ngIf="initialized && publisherReady && !inSession && subscriber" class="">
                                <div class="text-warning">
                                    <strong>{{ otherParty().name }}</strong> is waiting for you to dial in
                                </div>
                            </div>

                            <div 
                                id="video-reminder" 
                                class="rounded text-left p-3 pb-3 border border-warning w-75 my-3 mx-auto"
                                *ngIf="publisherReady && !inSession"
                            >
                                Reminder: <br/>
                                Per consultation, the video call should only be opened on one device. Multiple devices simultaneously opening the same call will result in audio issues.
                            </div>

                            <div *ngIf="(inSession && subscriber && !subscriberSpeaker)">
                                <div class="text-warning">
                                    Press the speaker button to hear <strong>{{ otherParty().name }}</strong>
                                </div>
                            </div>
                        </div>

                        <div class="row m-4 rounded hud text-center" *ngIf="showHUD" style="display:block;">
                            
                            <!-- [ngClass]="{'r0eady': subscriber && !subscriberSpeaker}" -->
                            <button 
                                type="button" 
                                class="btn ml-auto videoBtn subscriberSpeakerBtn btn-circle mr-2
                                    {{
                                        (inSession && subscriber && !subscriberSpeaker)? 'ready' : ''
                                    }}
                                "
                                
                                [disabled]="!subscriber" 
                                (click)="toggleSubscriberSpeaker()"
                                placement="bottom" 
                                [ngbTooltip]="otherPartySoundTooltip"
                            >
                                <fa-icon [icon]="subscriberSpeaker ? fa.faVolumeUp : fa.faVolumeMute" class="icon"></fa-icon>   
                                <!-- <strong>
                                    <span *ngIf="publisherEnding">Ending call;... </span>
                                    <span *ngIf="!publisherEnding">End call</span>
                                </strong> -->
                            </button>

                            <button 
                                type="button" class="btn videoBtn mr-2 btn-circle"
                                [class.disabled]="!publisherReady || !publisherAudioReady" 
                                [disabled]="!publisherReady|| !publisherAudioReady" 
                                (click)="mute(!muted)"
                                placement="bottom" [ngbTooltip]="micTooltip"
                            >
                                <fa-icon [icon]="muted ? fa.faMicrophoneSlash : fa.faMicrophone" class="icon"></fa-icon>     
                            </button>
                            <!-- <button 
                                type="button" 
                                class="btn {{subscriberSpeaker ? 'btn-primary':'btn-danger'}} videoBtn btn-circle mr-1"
                                *ngIf="subscriber" 
                                (click)="toggleSubscriberSpeaker()"
                            >
                                Other party is 
                                <span *ngIf="subscriberSpeaker">audible</span>
                                <span *ngIf="!subscriberSpeaker">muted</span>
                            </button> -->

                            <button 
                                type="button" class="btn videoBtn btn-circle mr-2"
                                [class.disabled]="!publisherReady  || !publisherVideoReady" 
                                [disabled]="!publisherReady || !publisherVideoReady" 
                                (click)="toggleVideo()"
                                placement="bottom" 
                                [ngbTooltip]="camTooltip"
                                tooltipClass="button-tooltip-class"
                            >
                                <fa-icon [icon]="publishVideo ? fa.faVideo : fa.faVideoSlash" class="icon"></fa-icon>     
                            </button>

                            <button type="button" class="btn videoBtn mr-auto btn-circle end-call"
                                *ngIf="inSession"
                                (click)="disconnect()"
                                placement="bottom" 
                                [ngbTooltip]="endCallTooltip"
                            >
                                <fa-icon [icon]="fa.faPhoneAlt" class="icon" transform="rotate-135"></fa-icon>
                                <!-- <strong>
                                    <span *ngIf="publisherEnding">Ending call;... </span>
                                    <span *ngIf="!publisherEnding">End call</span>
                                </strong> -->
                            </button>
                
                            <!-- [{{!initialized }} {{ !publisherReady }} {{ !publisherDeviceOk }} {{ sessionReconnecting}}] -->

                            <button type="button" class="btn videoBtn mr-auto btn-circle dial-in"
                                [ngClass]="{'ready': publisherReady && !inSession}"
                                *ngIf="!inSession"
                                [disabled]="!initialized || !publisherReady || !publisherDeviceOk || sessionReconnecting"
                                (click)="dialIn()"
                                placement="bottom" 
                                [ngbTooltip]="dialInTooltip"
                            >
                            
                            <!-- publishVideo ? fa.faVideo : fa.faVideoSlashl -->
                                <fa-icon [icon]="(!initialized || !publisherReady || !publisherDeviceOk || sessionReconnecting) ? fa.faPhoneSlash : fa.faPhoneAlt" class="icon"></fa-icon>     
                                <!-- <fa-icon [icon]="fa.faPhoneAlt" class="icon"></fa-icon> -->
                                
                                <!-- <strong>
                                    <span *ngIf="publisherDialing">Dialing... </span>
                                    <span *ngIf="!publisherDialing">Dial In</span>
                                </strong> -->
                            </button>
                        </div>
                    </div>
                </div>
                <div class="publisherDiv bg-secondary rounded" #publisherDiv></div>
                <div class="subscriberDiv bg-primary rounded subscriberVideoPlayer" #subscriberDiv></div>
            </div>
            <div class="col-md-3 col-sm-12">
                <!-- <app-medications></app-medications> -->
                <!-- <hr> -->
                <app-chat [session]="OTSession" [receiver]="patientName?.name" compHeight="(!isPatient) ? doctor : patient"></app-chat>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="!showHUD">
        <div class="col">
            <h5 class="text-center">Verifying your request</h5>
            <br/><br/>
            <h5 class="text-center" *ngIf="!error">We are having problem validating your request. Please go back to your  <a href="/me/appointments">appointments calendar</a> and start the video call again.</h5>
        </div>
    </div>
</div>


<ng-template #otherPartySoundTooltip>{{(subscriberSpeaker? 'Mute' : 'Unmute')}} Speaker</ng-template>
<ng-template #micTooltip>{{(muted? 'Unmute' : 'Mute')}} Mic</ng-template>
<ng-template #camTooltip>Turn {{(publishVideo? 'off' : 'on')}} Camera</ng-template>
<ng-template #dialInTooltip>{{(publisherDialing? 'Dialing...' : 'Dial-In')}}</ng-template>
<ng-template #endCallTooltip>{{(publisherEnding? 'Ending call...' : 'End Call')}}</ng-template>