import { Component, Input, Output, OnChanges, SimpleChanges,EventEmitter  } from '@angular/core';
import { NotesService, } from '../../../../../../../services';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'section-medical-certificate-email',
    templateUrl: './email-to-patient.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionMedicalCertificateEmailComponent implements OnChanges {
    @Output() modalDecline = new  EventEmitter()
    @Input() eventId
    @Input() title

    constructor(
        private noteService: NotesService,
        private toastr: ToastrManager
    ) {}

    confirm() {
        this.noteService.sendEmail('certificate', this.eventId)
            .subscribe(
                response => {
                    this.toastr.successToastr('Email sent successfully.')
                    this.decline()
                },
                e => this.toastr.errorToastr('Couldnt retrieve data for Medical Abstract.')
            );
    }


    decline = () => {
        this.modalDecline.emit()
    }
    

    ngOnChanges(changes: SimpleChanges) {

    }

}
