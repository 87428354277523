<crud-header 
*ngIf="formType=='create'"
[settings]="settings" 
[action]="'create'"
></crud-header>

<crud-header 
*ngIf="formType=='edit'"
[settings]="settings" 
[action]="'edit'"
></crud-header>
<ngb-tabset>
    <ngb-tab title="Edit transaction">
        <ng-template ngbTabContent>
            <form [formGroup]="rForm" novalidate  autocomplete="off"> 

                

                    <div class="row">
                        <div class="form-group col-6">
                            <label for="name">Notes </label>
                            <input type="text" class="form-control" id="name" formControlName="notes" autocomplete="off">
                            
                            
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-6">
                            <label for="name">Courier </label>
                            <input type="text" class="form-control" id="name" formControlName="received_by" autocomplete="off">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-6">
                            <label for="name">Date Shipped </label>
                            <input type="date" class="form-control" id="name" formControlName="date_received" autocomplete="off">
                            <!-- <select class="form-control" id="name" formControlName="status">
                                <option *ngFor="let stats of status">{{ stats }}</option>
                            </select> -->
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-6">
                            <label for="name">Status </label>
                            <!-- <input type="text" class="form-control" id="name" formControlName="status" autocomplete="off"> -->
                            <select class="form-control" id="name" formControlName="status">
                                <option *ngFor="let stats of status">{{ stats }}</option>
                            </select>
                        </div>
                    </div>

               

                
                    <div class="row">
                        <div class="form-group col-12 offset-3">
                            <crud-form-buttons
                                *ngIf="formType=='edit'"
                                [settings]="settings"
                                [action]="'edit'"
                                [rForm]="rForm"
                                (doSave)="doUpdate($event)"
                            ></crud-form-buttons>                      
                        </div>
                    </div>
                

            </form>
        </ng-template>
    </ngb-tab>
</ngb-tabset>
        