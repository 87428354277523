import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpResponse,
    HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import 'rxjs/add/operator/do';

import { environment } from '../../environments/environment';

@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {
    private router: Router;

    constructor(injector: Injector) {
        this.router = injector.get(Router);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(evt => {
            // console.log(req)
            // console.log(next)
            // console.log(evt, evt instanceof HttpResponse)
            if (evt instanceof HttpResponse) {
                // console.log('---> status:', evt.status);
                // console.log('---> filter:', req.params.get('filter'));
            }
        }, (error: any) => {
            if (error instanceof HttpErrorResponse) {
                // console.log('error.status', error)
                if (error.status === 401) {
                    // CLEAR STORAGE
                    sessionStorage.removeItem('credentials');
                    localStorage.removeItem('credentials');

                    if (error.url.match(/(\/api\/me)/)) {
                        return   
                    }
                    
                    if (window.location.pathname !== '/login') {
                        this.router.navigate(['/login']);
                        // window.location.href = environment.BASE_URL + 'login' + '?' + new Date().getMilliseconds();
                    }

                    // return Observable.throw(next);
                    return next.handle(req);
                } else {
                    return Observable.throw(error);
                }
            }
        });

    }
}
