import { Component, OnInit, ElementRef, ViewChild, NgZone } from "@angular/core";
// import { OpenTokService } from '../../services/open-tok.service';
import { UserService } from "../../services/user.service";
import * as OT from "@opentok/client";
import { NotesService } from "../../services/notes.service";
import { AvailabilityService, ArchiveService, OpenTokService } from "../../services";
import { Observable, timer } from "rxjs";
import { NoteContent, Medication } from "../../shared/models";
import * as _ from "lodash";
import moment from "moment";
import { ToastrManager } from "ng6-toastr-notifications";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { environment } from "./../../../environments/environment";
import {
    faMicrophone,
    faMicrophoneSlash,
    faVideo,
    faVideoSlash,
    faPhoneAlt,
    faHeadphones,
    faHeadphonesAlt,
    faVolumeMute,
    faVolumeUp,
    faPhoneSlash,
    faVolumeOff,
    faShareSquare,
    faBan,
    faComment,
} from "@fortawesome/free-solid-svg-icons";
import { DeviceDetectorService } from "ngx-device-detector";
import { eventRangeToEventFootprint } from "fullcalendar";

// export class CustomOption extends ToastOptions {
//     animate = 'flyRight'; // you can override any options available
//     newestOnTop = false;
//     showCloseButton = true;
//   }

@Component({
    selector: "app-video-call-v2",
    templateUrl: "./video-call-v2.component.html",
    styleUrls: ["./video-call-v2.component.scss"],
    // providers: [
    //     {provide: ToastOptions, useClass: CustomOption},
    // ]
})
export class VideoCallV2Component implements OnInit {
    @ViewChild("screenDiv") screenDiv: ElementRef;
    @ViewChild("publisherDiv") publisherDiv: ElementRef;
    @ViewChild("subscriberDiv") subscriberDiv: ElementRef;
    @ViewChild("chatBox") chatBox: ElementRef;

    fa = {
        faMicrophone,
        faMicrophoneSlash,
        faVideo,
        faVideoSlash,
        faPhoneAlt,
        faHeadphones,
        faHeadphonesAlt,
        faVolumeMute,
        faVolumeUp,
        faPhoneSlash,
        faVolumeOff,
        faShareSquare,
        faBan,
        faComment
    };

    streamOptions: OT.PublisherProperties;

    event = null;
    showHUD = false;
    inSession: boolean;
    publishVideo: boolean;
    user: any;
    isPatient: boolean;
    eventId: number;
    timer: any = 0;
    watchChange: any;
    patientName: any;
    editorConfig: any;
    medications: Medication[];
    debouncedCallSave: Function;
    debouncedCallSaveMeds: Function;
    callerName: string;
    minDate: Date;
    currentEventData: any;
    almostUp: boolean;
    callTimeLimit = 60;
    showWarningOn = 45;
    medCertRecomendations: any;
    backgroundBlur : boolean;
    backgroundImage: boolean;
    shareScreen : boolean;
    screenSharePublisher;

    OTSession = null;
    sessionRead = false;

    onStatBlur : boolean;
    blurSupport : boolean;

    /** CONFERENCE ROOM */
    initialized = false;
    token = null;

    /** SESSION */
    sessionReconnecting: boolean = false;
    sessionConnected: boolean = false;
    sessionDisconnected: boolean = false;
    sessionId = null;
    authorized: boolean = true;

    /** PUBLISHER */
    publisher: OT.Publisher;
    publisherReady = false;
    publisherDialing = false;
    publisherEnding = false;
    publisherSpeaker = false;
    publisherDeviceOk = false;
    publisherEnded = false;
    publisherAudioReady = false;
    publisherVideoReady = false;
    publisherInitTimeout: any;
    publisherMediaDenied = false;

    muted = false;

    /** SUBSCRIBER */
    subscriber: OT.Subscriber;
    subscriberReady = false; //
    subscriberDialing = false;
    subscriberEnding = false;
    subscriberSpeaker = false;
    subscriberVideoDisableWarning = false;
    subscriberVideoDisabled = false;
    subscriberVideoDisabledExplicitely = false;

    audio: any;

    supportedCodecs: any;

    constructor(
        public OTService: OpenTokService,
        private userService: UserService,
        private noteService: NotesService,
        private availabilityService: AvailabilityService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrManager,
        private ngZone: NgZone,
        private deviceService: DeviceDetectorService
    ) {
        this.router.events.subscribe((val) => {
            /*whatever*/

            if (val instanceof NavigationEnd) {
                // console.log(val)
            }
        });
    }

    ngOnInit() {
        const s = JSON.parse(localStorage.getItem("otSession"));
        const user = this.userService.getStoredCredentials();

        if (s && user) {
            const { session } = s;
            this.sessionId = session;
        } else {
            this.authorized = false;
            return;
        }

        this.audio = new Audio("../../../assets/audio/ringback.wav");
        this.audio.loop = true;

        if (!user.id) {
            // redirect to login
            this.router.navigate(["/login"]);
        }

        this.isPatient = !!user.roles.find(({ id }) => id === 3);
        this.callerName = localStorage.getItem("callername");
        // const ticker = Observable.timer(0, 1000);
        // ticker.subscribe(seconds => {
        //     this.timer = seconds;
        //     this.almostUp = this.timer / 60 >= this.showWarningOn;
        // });

        this.publishVideo = true;
        this.streamOptions = {
            insertMode: "append",
            // frameRate: 7, //  30, 15, 7, and 1
            resolution: "320x240", // "1280x720", "640x480", and "320x240"
            width: "100%" as any,
            height: "100%" as any,
            audioBitrate: 128000,
            disableAudioProcessing: false,
        };

        // Call backend to get new token everytime.

        const { callType, eventId } = JSON.parse(localStorage.getItem("sessionEventInfo"));

        this.patientName = JSON.parse(localStorage.getItem("patient"));
        this.eventId = eventId;

        this.availabilityService
            .show(this.eventId, {
                with: "bookings.patient",
            })
            .subscribe((response) => {
                this.event = response.data;
                const event = response.data;
                const duration = moment(event.end_at.date).diff(moment(event.start_at.date), "m");
                this.callTimeLimit = duration;
                this.showWarningOn = duration - 15;
            });

        if (!this.isPatient) {
            this.noteService.getNotes().subscribe((v) => console.log("noteList list", v));
        }

        this.initializeOT();
    }

    generateToken() {
        return new Promise((resolve) => {
            const { eventId } = JSON.parse(localStorage.getItem("sessionEventInfo"));
            if (eventId) {
                this.OTService.generateToken_2(eventId).subscribe(
                    (res) => {
                        let { token } = res["data"];
                        this.token = token;
                        this.authorized = true;
                        resolve(token);
                    },
                    (error) => {
                        this.authorized = false;
                        console.log("error", error);
                    }
                );
            }
        });
    }

    messageBtn()
    {
        // document.getElementById("messageBox").classList.add("showMessageBox")
        // document.getElementById("messageBox").classList.remove("chat")

        this.chatBox.nativeElement.classList.add("showMessageBox")
        this.chatBox.nativeElement.classList.remove("chat")

        this.publisherDiv.nativeElement.classList.add("hidden")
        this.subscriberDiv.nativeElement.classList.add("hidden")
        this.screenDiv.nativeElement.classList.add("hidden")
        
        document.getElementById("otherParty").classList.add("hidden")
    }

    closeChat() {
        // document.getElementById("messageBox").classList.add("hidden")
        // document.getElementById("messageBox").classList.remove("showMessageBox")
        this.chatBox.nativeElement.classList.add("hidden")
        this.chatBox.nativeElement.classList.remove("showMessageBox")

        this.publisherDiv.nativeElement.classList.remove("hidden")
        this.subscriberDiv.nativeElement.classList.remove("hidden")
        this.screenDiv.nativeElement.classList.remove("hidden")
        document.getElementById("otherParty").classList.remove("hidden")


    }

    
    onBtnBlur() {
        
        if (OT.hasMediaProcessorSupport()) {

            this.publisher.applyVideoFilter({
                type: 'backgroundBlur',
                blurStrength: 'high'
            });
            this.onStatBlur = true
            document.getElementById("btnOnBlur").classList.toggle("hidden");
            document.getElementById("btnOffBlur").classList.toggle("hidden");

        }
    }

    offBtnBlur() {
        
        if (OT.hasMediaProcessorSupport()) {
            this.publisher.clearVideoFilter()
            this.onStatBlur = false
            document.getElementById("btnOnBlur").classList.toggle("hidden");
            document.getElementById("btnOffBlur").classList.toggle("hidden");
        }
    }

    showMessage(message)
    {   
        if(!message.isSender)
        {
            if(window.innerWidth <= 500)
            {
                this.toastr.infoToastr(message.senderName + " has sent you a message", null, {
                    positionClass: "toast-top-center",
                    showCloseButton: true,
                    newestOnTop: true,
                });
            }
        }
    }

    screenShare() {
        OT.checkScreenSharingCapability(response => {
            if (!response.supported || response.extensionRegistered === false) {
            alert("Screen sharing not supported");
            } else if (response.extensionInstalled === false) {
            alert("Browser requires extension");
            } else {
                this.screenSharePublisher = OT.initPublisher(
                    this.screenDiv.nativeElement,
                    {
                      insertMode: "append",
                      width: "100%",
                      height: "100%",
                      videoSource: "screen",
                      publishAudio: true
                    },
                    function (error) {

                        if(error) {
                            document.getElementById('startScreenShareBtn').removeAttribute("disabled");
                            document.getElementById("subscriberId").classList.remove("formatSubscriberVideo");
                            document.getElementById("publisherId").classList.remove("formatPublisherVideo");
                            document.getElementById("screenId").classList.remove("screenShareVideo");
                            document.getElementById("startScreenShareBtn").classList.toggle("hidden");
                            document.getElementById("stopScreenShareBtn").classList.toggle("hidden");
                        }

                    }
                  );
                    this.OTSession.publish(this.screenSharePublisher)

                    this.screenSharePublisher.on("streamDestroyed", function (event) {
                        if(event.reason === 'mediaStopped'){
                            document.getElementById('startScreenShareBtn').removeAttribute("disabled");
                            document.getElementById("startScreenShareBtn").classList.toggle("hidden");
                            document.getElementById("stopScreenShareBtn").classList.toggle("hidden");
                            document.getElementById("subscriberId").classList.remove("formatSubscriberVideo");
                            document.getElementById("publisherId").classList.remove("formatPublisherVideo");
                            document.getElementById("screenId").classList.remove("screenShareVideo");
                    
                        }
                    });

                    this.shareScreen = true
                    document.getElementById("startScreenShareBtn").classList.toggle("hidden");
                    document.getElementById("stopScreenShareBtn").classList.toggle("hidden");
                    this.publisherDiv.nativeElement.classList.add("formatPublisherVideo")
                    this.subscriberDiv.nativeElement.classList.add("formatSubscriberVideo")
                    this.screenDiv.nativeElement.classList.add("screenShareVideo")

            }
        });
    }

    
    stopScreenShare() {
        this.screenSharePublisher.destroy()
        this.shareScreen = false;
        document.getElementById("startScreenShareBtn").classList.toggle("hidden");
        document.getElementById("stopScreenShareBtn").classList.toggle("hidden");
        // document.getElementById("subscriberId").classList.remove("formatSubscriberVideo");
        // document.getElementById("publisherId").classList.remove("formatPublisherVideo");
        // document.getElementById("screenId").classList.remove("screenShareVideo");

        this.publisherDiv.nativeElement.classList.remove("formatPublisherVideo")
        this.subscriberDiv.nativeElement.classList.remove("formatSubscriberVideo")
        this.screenDiv.nativeElement.classList.remove("screenShareVideo")

    }




    disconnect = async () => {
        this.publisherEnded = true;
        this.publisherEnding = true;
        this.publisher.destroy();
        this.inSession = false;
        this.publisher = null;

        // await this.createPublisher()
        this.publisherEnding = false;
        if (this.publisher) {
            this.OTSession.unpublish(this.publisher);
            this.publisher = null;
        }
        if (this.subscriber) {
            this.OTSession.unsubscribe(this.subscriber);
            this.subscriber = null;
        }
        this.OTSession.disconnect();
        this.audioStop();
        this.OTSession.destroy();

        // window.open('/feedback/' + sessionEventInfo.eventId, '_blank');
        this.router.navigate(["feedback", this.eventId]);
        // }
        // this.router.navigate(['medications']);

        // window.close();
    };

    toggleSubscriberSpeaker() {
        this.subscriberSpeaker = !this.subscriberSpeaker;
        this.subscriber.setAudioVolume(this.subscriberSpeaker ? 100 : 0);
    }

    mute(stat) {
        this.muted = typeof stat == "boolean" ? stat : !this.muted;

        this.muted = stat;
        this.publisher.publishAudio(!this.muted);
        // this.subscriber.setAudioVolume((this.muted) ? 0 : 100)

        // this.subscriber.subscribeToAudio(this.muted);
    }

    dialIn = async () => {
        if (this.publisherDialing) {
            return;
        }

        if (!this.OTSession) {
            await this.initializeOT();
        }

        if (!this.publisher) {
            await this.createPublisher();
        }

        this.sessionReconnecting = false;
        this.sessionConnected = false;
        this.sessionDisconnected = false;

        this.publisherDialing = true;

        this.OTSession.unpublish(this.publisher);
        this.audioStop();

        this.toastr.infoToastr("We are now connecting you to the call.", null, { showCloseButton: true });
        this.OTSession.publish(this.publisher, (error) => {
            if (error) {
                // this.publisherDialing = false;
                this.inSession = false;
                this.audioStop();
                this.errorHandler(error);
            } else {
                this.toastr.successToastr("Congratulations! You have joined the call.", null, {
                    showCloseButton: true,
                });
                this.inSession = true;
                this.publisherDialing = false;
                this.startTimer();
                this.audioStop();
                this.publisher.publishAudio(true);
                // this.audio.
                this.log({
                    name: "PUBLISHER: DIALED-IN-V2",
                });
            }
        });
    };

    initializeOT = async () => {
        if (!(await OT.checkSystemRequirements())) {
            // alert('Not tcompatible. Disregard this. CLick ok lang.')
        } else {
            // alert('compatible. Disregard this. CLick ok lang. ')
        }

        this.blurSupport = OT.hasMediaProcessorSupport();

        try {
            this.supportedCodecs = await OT.getSupportedCodecs();
            // console.log('supportedCodecs', this.supportedCodecs);
            if (
                this.supportedCodecs.videoEncoders.indexOf("H264") < 0 &&
                this.supportedCodecs.videoDecoders.indexOf("H264") < 0
            ) {
                // The client does not support encoding or decoding H264.
                // Let's recommend using a different browser.
            }
        } catch (err) {
            console.log(err);
        }

        await this.generateToken();

        const { session } = JSON.parse(localStorage.getItem("otSession"));

        this.OTSession = OT.initSession(environment.OT_KEY, session);

        this.showHUD = true;

        this.toastr.infoToastr("Please wait while we prepare your conference room...", null, {
            positionClass: "toast-top-center",
            showCloseButton: true,
            newestOnTop: true,
            // dismiss: 'auto',
        });

        this.OTSession.connect(this.token, (error) => {
            if (error) {
                console.log("session error");
                this.errorHandler(error);
            } else {
                this.initialized = true;
                //publisherDeviceOk
                //this.toastr.successToastr('Your conference room is ready. Checking your device.', null, {
                this.toastr.successToastr("Your conference room is ready", null, {
                    positionClass: "toast-top-full-width",
                    showCloseButton: true,
                    newestOnTop: true,
                    // dismiss: 'auto',
                });

                // TRIGGER LOG
                this.log({
                    name: "READY-V2",
                });

                this.sessionRead = true;
                this.attachOTSessionEvents();
                this.createPublisher();
            }
        });
    };

    handleScreenShare(streamType) {
          if (streamType === "screen") {
            this.shareScreen = true;
            this.publisherDiv.nativeElement.classList.add("formatPublisherVideo")
            this.subscriberDiv.nativeElement.classList.add("formatSubscriberVideo")
            this.screenDiv.nativeElement.classList.add("screenShareVideo")

          }
      }

    attachOTSessionEvents = async () => {
        this.OTSession.on("streamDestroyed", (event) => {
            console.log("attachOTSessionEvents", event)
            console.log("Stream stopped. Reason: " + event.reason);
            this.audioStop();

            if(event.stream.videoType == 'camera') {
                this.toastr.warningToastr(this.otherParty().name + " left the call", null, { showCloseButton: true });
            }

            else if(event.stream.videoType == 'screen') {
                this.toastr.warningToastr(this.otherParty().name + " stop sharing his/her screen", null, { showCloseButton: true });
                this.publisherDiv.nativeElement.classList.remove("formatPublisherVideo")
                this.subscriberDiv.nativeElement.classList.remove("formatSubscriberVideo")
                this.screenDiv.nativeElement.classList.remove("screenShareVideo")
                this.shareScreen = false;
    
            }
            this.log({
                name: "SESSION: streamDestroyed V2",
                event,
            });
        });

        this.OTSession.on("streamCreated", async (event) => {
            console.log("streamcreated", event);
            // other party has entered
            this.subscriberReady = true;
            this.log({
                name: "SESSION: streamCreated V2",
                event,
            });

            await this.actionSubscriberConnected(event);

            const streamContainer = event.stream.videoType === "screen" ? this.screenDiv.nativeElement : this.subscriberDiv.nativeElement;
            // Initialize the DOM for the subsriber (the other party)
            this.subscriber = await this.OTSession.subscribe(
                event.stream,
                streamContainer,
                {
                    ...this.streamOptions,
                    resolution: "640x480",
                    testNetwork: true,
                    audioVolume: 100,
                } as OT.SubscriberProperties,
                this.errorHandler
            );


            this.handleScreenShare(event.stream.videoType)

            if (this.subscriber) {
                // this.subscriber.setStyle('audioBlockedDisplayMode', 'off')
                // this.subscriber.setStyle('audioLevelDisplayMode', 'on')
                this.subscriber.setStyle("buttonDisplayMode", "off");

                this.subscriber.subscribeToAudio(true); // audio on
                this.subscriber.setAudioVolume(100);

                this.subscriber.on("audioBlocked", function (event) {
                    // OT.unblockAudio()
                    console.log("subscriber: audioBlocked", event);
                    this.log({
                        name: "SUBSCRIBER: audioBlocked V2",
                        event,
                    });
                });

                this.subscriber.on(
                    "audioUnblocked",
                    function (event) {
                        console.log("subscriber: audioUnblocked", event);
                        this.log({
                            name: "SUBSCRIBER: audioUnblocked V2",
                            event,
                        });
                    },
                    this
                );

                this.subscriber.on(
                    "videoDisabled",
                    function (event) {
                        // this.ngZone.run( () => {
                        //     this.sessionConnected = false;
                        //     this.sessionReconnecting = true;
                        //     this.sessionDisconnected = false;
                        //  });
                        if (event.reason == "quality") {
                            this.ngZone.run(() => {
                                this.subscriberVideoDisableWarning = false;
                                this.subscriberVideoDisabled = true;
                                this.subscriberVideoDisabledExplicitely = false;
                            });
                            // this.toastr.warningToastr('Video has been temporarily disabled due to a detected unstable internet connection. Video will resume automatically once it has stabilized.', 'Warning');
                        } else if (event.reason == "codecNotSupported") {
                            this.toastr.warningToastr(
                                "Sorry but your browser currently does not support our video codec. Please try switching to Google Chrome or Firefox.",
                                "Warning"
                            );
                        } else if (event.reason == "publishVideo") {
                            // this.toastr.warningToastr('The other party disabled his video.', 'Warning');
                            this.ngZone.run(() => {
                                this.subscriberVideoDisableWarning = false;
                                this.subscriberVideoDisabled = false;
                                this.subscriberVideoDisabledExplicitely = true;
                            });
                        }
                        // console.log('subscriber: videoDisabled', event)
                        // this.log({
                        //     name: 'SUBSCRIBER: videoDisabled',
                        //     event,
                        // });
                    },
                    this
                );

                this.subscriber.on(
                    "videoDisableWarning",
                    function (event) {
                        this.ngZone.run(() => {
                            this.subscriberVideoDisableWarning = true;
                        });

                        //this.toastr.warningToastr('We are detecting unstable internet connection. Video quality may be affected.', 'Warning');
                        console.log("subscriber: videoDisableWarning", event);
                        // this.log({
                        //     name: 'SUBSCRIBER: videoDisabledWarning',
                        //     event,
                        // });
                    },
                    this
                );

                this.subscriber.on(
                    "videoDisableWarningLifted",
                    function (event) {
                        this.ngZone.run(() => {
                            this.subscriberVideoDisableWarning = false;
                        });
                        console.log("subscriber: videoDisableWarningLifted", event);
                        // this.log({
                        //     name: 'SUBSCRIBER: videoDisableWarningLifted',
                        //     event,
                        // });
                    },
                    this
                );

                this.subscriber.on(
                    "videoEnabled",
                    function (event) {
                        this.ngZone.run(() => {
                            this.subscriberVideoDisableWarning = false;
                            this.subscriberVideoDisabled = false;
                            this.subscriberVideoDisabledExplicitely = false;
                        });
                        // this.subscriber.subscribeToVideo(true);
                        // console.log('subscriber: videoEnabled', event)
                    },
                    this
                );

                // this.log({
                //     name: 'SUBSCRIBER: videoEnabled',
                //     event,
                // });
            }

            // this.mute(false)
            // this.subscriber.subscribeToAudio(true);
        });

        this.OTSession.on("streamPropertyChanged", async (event) => {
            console.log("session: streamPropertyChanged", event);

            if (event.changedProperty == "hasAudio") {
                // alert('other party muted')
                this.publisherSpeaker = event.newValue;
                // this.subscriberSpeaker = event.newValue;
                // console.log(this.publisher, this.publisherSpeaker)
            }

            if (event.changedProperty == "hasVideo") {
            }

            if (event.reason == "s")
                this.log({
                    name: "SESSION: streamPropertyChanged V2",
                    event,
                });
        });

        this.OTSession.on("connectionDestroyed", async (event) => {
            // console.log('Session: connectionDestroyed', event)
            this.log({
                name: "SESSION: connectionDestroyed V2",
                event,
            });
        });

        this.OTSession.on("sessionConnected", async (event) => {
            console.log("Session: sessionConnected", event);
            this.ngZone.run(() => {
                this.sessionConnected = true;
                this.sessionReconnecting = false;
                this.sessionDisconnected = false;
            });

            this.log({
                name: "SESSION: sessionConnected V2",
                event,
            });
        });

        this.OTSession.on("sessionDisconnected", async (event) => {
            event.preventDefault();
            this.ngZone.run(() => {
                this.sessionConnected = false;
                this.sessionReconnecting = false;
                this.sessionDisconnected = true;
                this.inSession = false;
            });

            this.log({
                name: "SESSION: sessionDisconnected V2",
                event,
            });

            // console.log('Session: sessionDisconnected', event)
        });

        this.OTSession.on("sessionReconnected", async (event) => {
            console.log("Session: sessionReconnected", event);
            this.ngZone.run(() => {
                this.sessionConnected = true;
                this.sessionReconnecting = false;
                this.sessionDisconnected = false;
            });

            // if (this.publisherDialing) {
            //     this.dialIn()
            // }

            this.log({
                name: "SESSION: sessionReconnected V2",
                event,
            });

            this.toastr.successToastr("Reconnection successful!", null, { toastLife: 5000, showCloseButton: true });
            event.preventDefault();
        });

        this.OTSession.on(
            "sessionReconnecting",
            function (event) {
                this.ngZone.run(() => {
                    this.sessionConnected = false;
                    this.sessionReconnecting = true;
                    this.sessionDisconnected = false;
                });

                this.log({
                    name: "SESSION: sessionRecconecting V2",
                    event,
                });
                console.log("Session: sessionReconnecting", event);
            },
            this
        );

        this.OTSession.on("signal", async (event) => {
            // console.log('Session: signal', event)
        });

        // this.OTSession.publishAudio()
    };

    attachPublisherEvents = async () => {
        if (!this.publisher) return;

        this.publisher.on("streamCreated", async (event) => {
            this.audioStop();
            console.log("Publisher: The publisher started streaming.");

            this.log({
                name: "PUBLISHER: streamCreated V2",
                event,
            });
        });

        this.publisher.on("streamDestroyed", async (event) => {
            this.ngZone.run(() => {
                this.inSession = false;
                this.createPublisher();
            });
            this.audioStop();

            if (!this.publisherEnded) {
                this.initializeOT();
            }

            this.log({
                name: "PUBLISHER: streamDestroyed V2",
                event,
            });

            console.log("Publisher: streamDestroyed", event);
        });

        // this.publisher.on('audioLevelUpdated', async(event) => {
        //     console.log('Publisher: audioLevelUpdated', event)
        // });
        this.publisher.on("accessAllowed", async (event) => {
            console.log("Publisher: accessAllowed", event);
            this.log({
                name: "PUBLISHER: accessAllowed V2",
                event,
            });
        });

        this.publisher.on("accessDenied", async (event) => {
            console.log("Publisher: accessDenied", event);
            this.log({
                name: "PUBLISHER: accessDenied V2",
                event,
            });
        });

        this.publisher.on("accessDialogClosed", async (event) => {
            console.log("Publisher: accessDialogClosed", event);
            this.log({
                name: "PUBLISHER: accessDialogClosed V2",
                event,
            });
        });

        this.publisher.on("accessDialogOpened", async (event) => {
            console.log("Publisher: accessDialogOpened", event);
            this.log({
                name: "PUBLISHER: accessDialogOpened V2",
                event,
            });
        });

        this.publisher.on("destroyed", async (event) => {
            console.log("Publisher: destroyed", event);
            // this.ngZone.run( () => {
            //     this.publisherDiv.nativeElement.remove
            // });

            if (event["reason"] == "mediaStopped") {
                this.publisherMediaDenied = true;
            }

            this.log({
                name: "PUBLISHER: destroyed V2",
                event,
            });
        });

        // console.log(this.publisher.getImgData(),this.publisher.getAudioSource());
        // if (!this.publisher.getImgData() || !this.publisher.getAudioSource()) {
        //     this.ngZone.run( () => {
        //         this.publisherDeviceOk = false;
        //     });
        // }

        if (this.publisher.getAudioSource()) {
            this.ngZone.run(() => {
                this.publisherDeviceOk = true;
                this.publisherAudioReady = true;
            });
        }

        if (this.publisher.getImgData()) {
            this.ngZone.run(() => {
                this.publisherVideoReady = true;
            });
        } else {
            this.ngZone.run(() => {
                this.publisherVideoReady = false;
                this.toggleVideo(false);
            });
        }

        /*
        audioLevelUpdated
        destroyed
        mediaStopped
        videoDimensionsChanged
        videoElementCreated
        */
    };

    createPublisher = async () => {
        console.log("creating pulisher object");

        this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, this.streamOptions, (error) => {
            if (error) {
                // console.log(this.publisher.getAudioSource() , this.publisher.getImgData())
                this.ngZone.run(() => {
                    this.publisherReady = false;
                });
                console.log("publisher error", error);

                if (error.name == "OT_USER_MEDIA_ACCESS_DENIED") {
                    this.publisherMediaDenied = true;
                }

                this.errorHandler(error);
                // The client cannot publish.
                // You may want to notify the user.
            } else {
                this.audioStop();
                console.log("Publisher initialized.");
                this.attachPublisherEvents();

                this.ngZone.run(() => {
                    this.publisherReady = true;
                });

                // call backend to notify user is online
                // apply websocket
            }
        });

        // this.publisher.setStyle('audioLevelDisplayMode', 'auto');
        this.publisher.setStyle("buttonDisplayMode", "off");
        this.publisher.setStyle("nameDisplayMode", "off");
        // this.publisher.setStyle('archiveStatusDisplayMode', 'auto');

        this.inSession = false;

        // if (!this.publisher.getAudioSource() || this.publisher.getImgData()) {
        //     this.publisherReady = false;
        // }
    };

    otherParty() {
        let obj = this.event;
        let info = null;
        // If patient is logged in
        if (this.isPatient) {
            info = obj.doctor.general_information;
            info.name = "";
            // console.log('info', info.doctorRole)
            if (info.doctorRole && (info.doctorRole == "medical-practitioner" || info.doctorRole == "doctor")) {
                info.name += "Dr. ";
            }
            info.name += `${info.first_name} ${info.last_name}`;
        } else {
            info = this.event.bookings.patient.general_information;
            info.name = `${info.first_name} ${info.last_name}`;
        }

        return info;
    }

    toggleVideo(flag = null) {
        this.publishVideo = flag ? flag : !this.publishVideo;
        this.publisher.publishVideo(this.publishVideo);
    }

    startTimer = () => {
        const ticker = timer(0, 1000);
        ticker.subscribe((seconds) => {
            this.ngZone.run(() => {
                this.timer = seconds;
                this.almostUp = this.timer / 60 >= this.showWarningOn;
            });
        });
    };

    actionSubscriberConnected = async (event) => {

        // IF iam in not in the chat room and the other party is inside,
        // sound the ring
        // console.log(this.subscriber, this.publisher, this.inSession, this.audio.paused)
        // Else if Video Type == screen or share screen

        if(event.stream.videoType == 'camera'){
            if (!this.inSession && !this.subscriber) {
                this.toastr.successToastr("The other party is now in the call. Please dial in to commence.", null, {
                    toastLife: 5000,
                    showCloseButton: true,
                });

                // console.log('this.audio.paused', this.audio.paused, this.audio);

                // Update view to show the other party is online

                if (this.audio.paused) {
                    // console.log('playing ring')
                    // this.audio.play()
                }
            } else {
                this.audioStop();
                this.toastr.successToastr("The other party is now in the call.", null, { showCloseButton: true });
            }
        }

        else if(event.stream.videoType == 'screen') {
            this.toastr.successToastr(this.otherParty().name + " sharing his/her screen", null, { showCloseButton: true });
        }
    };

    errorHandler = (error) => {
        console.log("errorHandler", error);
        if (error) {
            let message = "";
            let title = null;
            let config = null;

            switch (error.name) {
                // OT_CHROME_MICROPHONE_ACQUISITION_ERROR

                case "OT_HARDWARE_UNAVAILABLE":
                    message = error.message;
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_CONNECT_FAILED":
                case "OT_NOT_CONNECTED":
                    message = "You are not connected to the internet. Please Check your network connection.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_INVALID_HTTP_STATUS":
                case "OT_TIMEOUT":
                    message =
                        "We are having problem connecting you to the session. Please check your internet connection.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_CANCEL":
                    message = "There was a problem initializing your session. Press Dial In again or reload this page.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    this.publisherDialing = false;
                    this.inSession = false;
                    break;

                case "OT_USER_MEDIA_ACCESS_DENIED":
                    this.publisherDeviceOk = false;
                    message = "Please allow your browser to access your camera and mic.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_SOCKET_CLOSE_FALLBACK_CODE":
                    message = "There seems to a problem with your token. Please reload this window.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_STREAM_DESTROYED":
                    message =
                        "Your session closed. This could be the result of unstable internet connection. Please reload this page.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_NOT_SUPPORTED":
                    message = "Your browser is currently not supported.";
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    break;

                case "OT_AUTHENTICATION_ERROR":
                case "OT_SOCKET_CLOSE_TIMEOUT":
                    message = "We are having a problem verifying your session. Kindly reload this page...";
                    title = "Oops.. we encountered a hickup.";
                    config = {
                        toastLife: 5000,
                        showCloseButton: true,
                        positionClass: "toast-bottom-full-width",
                    };
                    break;

                default:
                    console.log("defalt error", error);
                    message = error.name;
                    title = "Oops.. we encountered a hickup.";
                    config = { toastLife: 15000, showCloseButton: true };
                    // send error to backend

                    break;
            }

            this.log(error);

            this.toastr.warningToastr(message, title, config);
        }
    };

    log(error) {
        const user = this.userService.getStoredCredentials();
        let userId = null;
        if (user) {
            userId = user.id;
        }

        const log = {
            event_id: this.eventId,
            user_id: userId,
            name: error.name,
            detail: {
                error: error,
                browser: this.deviceService.getDeviceInfo(),
                isDesktop: this.deviceService.isDesktop(),
                isMobile: this.deviceService.isMobile(),
                isTablet: this.deviceService.isTablet(),
            },
            event_at: moment.now(),
        };

        this.OTService.log(log);
    }

    pad(num: any) {
        return ("0" + num).slice(-2);
    }

    hhmmss(secs: any) {
        let minutes = Math.floor(secs / 60);
        secs = secs % 60;
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return this.pad(hours) + ":" + this.pad(minutes) + ":" + this.pad(secs);
    }

    audioStop = async () => {
        console.log("stopping ring");
        this.audio.pause();
        this.audio.currentTime = 0;
    };
}
