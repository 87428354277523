export const settings = {
    plural: 'Generic Names',
    singular: 'Generic Name',
    
  
    crud: {
      create: {
        allow: true, // true/false
        url: '/admin/generic-names/create'
      },
      read:{
  
      },
      update:{
        allow: true,
        url: '/admin/generic-names/edit/'
      },
      delete:{
        allow: true
      },
      list: {
        allow: true,
        url: '/admin/generic-names'
      }
    }
  };