<ngb-tabset>
    <ngb-tab title="Order Details">
        <ng-template ngbTabContent>
            <div class="container" *ngIf="transaction" >
                
                <!-- cart transaction details -->
                <div class="row">
                    <div class="col-lg-8 mx-auto">

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Order Number :</label> 
                                {{ transaction.order.id }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Transaction Number :</label> 
                                {{ transaction.transaction_id }}
                            </div>
                        </div>
                        <!-- <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Description :</label> 
                                {{ transaction.detail }}
                            </div>
                        </div> -->
                        
                        
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Date Created :</label> 
                                {{ transaction.order.created_at | date : "MMM d yyyy"}}
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 mx-auto">
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Status :</label> 
                                {{ transaction.order.statusCode }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Gateway :</label> 
                                {{ transaction.gateway }}
                            </div>
                        </div>
                        
                        
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Notes :</label> 
                                {{ transaction.notes }}
                            </div>
                        </div>
                    </div>

                    

                </div>
                <!-- <div *ngIf="prescriptionItems.data.length > 0"> -->
                <table class="table table-reponsive">
                    <thead>
                        <tr>
                            <th>SKU</th>
                            <th>Details</th>
                            <th>Total Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of transaction.order.items">

                            <td>{{ item.sku }}</td>
                            <td *ngIf="item.product_type == 'appointment'">
                                <p> 
                                    Doctor : {{ item.doctor.first_name }} {{ item.doctor.last_name }}<br>
                                    Status : {{ item.status }}<br>
                                    Start : {{ item.start_at.date | date : "MMM d yyyy hh:mm"}}<br>
                                    End : {{ item.end_at.date | date : "MMM d yyyy hh:mm"}}
                                </p>
                            </td>
                            <td *ngIf="item.product_type != 'appointment'">
                                <p> 
                                    Medicine Name : {{ item.medicine.name }}
                                    Dosage : {{ item.medicine.dosage }}
                                </p>
                            </td>
                            <td>{{ item.price }}</td>
                        </tr>

                        <tr *ngIf="transaction.order.items.length <= 0">
                            <td colspan="8"><p class="text-center"><i>No Items Found</i></p></td>
                        </tr>
                    </tbody>
                </table>

                <!-- billing details -->
                <div class="row" *ngIf="transaction.detail && transaction.detail.billingInfo">
                    <div class="col-lg-12 mx-auto">
                    <p><strong>Billing Info</strong></p>

                    </div>
                    <div class="col-lg-8 mx-auto">
                        

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Name :</label> 
                                {{ transaction.detail.billingInfo.firstName }} {{ transaction.detail.billingInfo.lastName }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Address :</label> 
                                {{ transaction.detail.billingInfo.address }} <br>
                                {{ transaction.detail.billingInfo.address2 }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Tel No. :</label> 
                                {{ transaction.detail.billingInfo.telno }}
                            </div>
                        </div>

                        

                    </div>

                    <div class="col-lg-4 mx-auto">
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>City :</label> 
                                {{ transaction.detail.billingInfo.city }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>State :</label> 
                                {{ transaction.detail.billingInfo.state }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Zip Code :</label> 
                                {{ transaction.detail.billingInfo.zip }}
                            </div>
                        </div>

                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Country :</label> 
                                {{ transaction.detail.billingInfo.country }}
                            </div>
                        </div>
                    </div>


                </div>

            </div>            
        </ng-template>
    </ngb-tab>

</ngb-tabset>