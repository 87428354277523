<div 
    *ngIf="isModalShown" 
    [config]="{ 
        show: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false
     }" 
    (onHidden)="onHidden()" 
    bsModal 
    #autoShownModal="bs-modal"
    class="modal fade" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="dialog-auto-name"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div *ngIf="processingAnswer" class="answer-processing-overlay">
                <table style="height:100%;min-height: 100px; width:100%;">
                    <tbody>
                        <tr>
                            <td class="align-middle text-center">
                                <div>Submitting answer selection...</div>
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="modal-header">
                <h4 id="dialog-auto-name" class="modal-title pull-left">Terms And Conditions</h4>
                <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button> -->
            </div>
            <div class="modal-body" style="position: relative;">
                <table style="min-height: 100px; width:100%;" *ngIf="loadingTerm">
                    <tbody>
                        <tr>
                            <td class="align-middle text-center">
                                <div>Loading Terms and Conditions</div>
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </td>
                        </tr>
                    </tbody>                    
                </table>
                <div class="table" *ngIf="!loadingTerm" [innerHTML]="body"></div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" (click)="iAccept()">I Accept</button>
            </div>
        </div>
    </div>
</div>

