<div class="p-2">
    <div *ngIf="action=='list'">

        {{ settings.plural }}
        <div size="medium" class="text-right">
            <a *ngIf="settings.crud.create.allow" class="cursor-pointer" [routerLink]="[settings.crud.create.url]"><i
                    class="fa fa-plus"></i></a>
            <a class="cursor-pointer" (click)="doRefreshList()"><i class="fa fa-refresh"></i></a>
        </div>
    </div>

    <div *ngIf="action=='edit'">
        <div size="medium" class="col-6 text-center well text-left">
            <!-- custom button for appointment -->
            <a *ngIf="delete" class="cursor-pointer btn btn-tn btn-warning btn-space-left " (click)="!rForm.invalid && triggerDelete('close')"
                [class.disabled]="rForm.invalid">Delete Appointment</a>
        </div>
        <div size="medium" class="col-6 text-center well ">
            <!-- USES rForm -->
            <a *ngIf="rForm" class="cursor-pointer btn btn-tn btn-primary btn-space-left " (click)="!rForm.invalid && triggerSave('close')"
                [class.disabled]="rForm.invalid">Update &amp; Close</a>

            <!-- DOES NOT use rForm -->
            <a *ngIf="!rForm" class="cursor-pointer btn btn-tn btn-primary btn-space-left " (click)="triggerSave('close')">Update
                &amp; Close</a>

            <!-- <a class="cursor-pointer btn btn-tn btn-primary btn-space-left " (click)="triggerSave('continue')">Save &amp; Continue</a> -->
            <a class="btn btn-tn btn-danger btn-space-left " [routerLink]="[settings.crud.list.url]">Cancel</a>
        </div>
    </div>

    <div *ngIf="action=='create'">
        <div size="medium" class="text-right ">
            <!-- USES rForm -->
            <a *ngIf="rForm" class="cursor-pointer btn btn-tn btn-primary btn-space-left " [class.disabled]="rForm.invalid"
                (click)="!rForm.invalid && triggerSave('close')">Save &amp; Close</a>

            <!-- DOES NOT use rForm -->
            <a *ngIf="!rForm" class="cursor-pointer btn btn-tn btn-primary btn-space-left " (click)="triggerSave('close')">Save
                &amp; Close</a>

            <!-- <a class="cursor-pointer btn btn-tn btn-primary btn-space-left " (click)="triggerSave('continue')">Save &amp; Continue</a> -->
            <a class="btn btn-tn btn-danger btn-space-left " [routerLink]="[settings.crud.list.url]">Cancel</a>
        </div>
    </div>
</div>
