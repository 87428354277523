import { Injectable } from '@angular/core';
import { AbstractService } from './../abstract.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DoctorService extends AbstractService {
  endpoint = environment.serverUrl;

  constructor(http: HttpClient) {
    super(http);
  }

  info(id) {
    return this.http.post(
      `${environment.serverUrlV2}/doctor/info`, 
      { id },
      {
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      }
    ).map((result: any) => {
      return result;
    }, (err: any) => {
      console.log(err)
        return err;
    });
  }

  calendar(id, start, end) {
    return this.http.post(
      `${environment.serverUrlV2}/doctor/calendar`, 
      { id, start, end },
      {
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Accept': environment.API_HEADER_ACCEPT_v2
        }
      }
    ).map((result: any) => {
      return result;
    }, (err: any) => {
      console.log(err)
        return err;
    });
  }
}
