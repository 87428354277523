import { camelCase, isString, isArray, isObject } from "lodash";

export const SECTIONS = [
    "General Information",
    "Reason For Consultation",
    "History of Present Illness ",
    "Past Psychiatric And Medical Illnesses ",
    "Substance use / Addiction History",
    "Family History of Medical Illnesses and Psychiatric",
    "Family Information ",
    "Developmental History ",
    "Academic and Occupational Performance",
    "Sexual and OB-GYNE History ",
    "Social History",
    "Religious or Spiritual History",
    "Legal History",
    "Military History",
    "Other Relevant History",
    "Financial Status and Living Arrangement",
    "Other Data",
    "Source of Referral",
];

export const SECTION_KEYS = [
    "agreeToCollectionAndProcessing",
    "generalInformation",
    "reasonForConsultation",
    "presentHistoryOfIllness",
    "pastPsychiatricAndMedicalIllnesses",
    "substanceUseAddictionHistory",
    "familyHistoryOfMedicalIllnessesAndPsychiatric",
    "familyInformation",
    "developmentalHistory",
    "academicAndOccupationalPerformance",
    "sexualAndObGyneHistory",
    "socialHistory",
    "religiousOrSpiritualHistory",
    "legalHistory",
    "militaryHistory",
    "otherRelevantHistory",
    "financialStatusAndLivingArrangement",
    "otherData",
    "sourceOfReferral",
];

export interface DataItem {
    label?: string;
    key?: string;
    value?: any;
    section?: string;
    sectionNo?: number;
    type?: string;
    options?: { value: string; label: string }[];
    parent?: string;
    requireParent?: boolean | string;
    placeholder?: string;
    sectionKey?: string;
    group?: number;
    groupName?: string;
    keyPrefix?: string;
    showLabel?: boolean;
    tableType?: string;
    initValue?: {};
    required?: boolean;
}

export class DataItem {
    label?: string;
    key?: string;
    section?: string;
    type?: string;
    options?: { value: string; label: string }[];
    sectionNo?: number;
    parent?: string;
    value?: any;
    requireParent?: boolean | string;
    placeholder?: string;
    sectionKey?: string;
    group?: number;
    showLabel?: boolean;
    groupName?: string;
    tableType?: string;
    initValue?: {};
    required?: boolean;
    disabled?: boolean;

    constructor(o: DataItem) {
        this.type = o.type || "text";
        const keyPrefix = o.keyPrefix || "";
        const key = o.key || o.label;
        this.key = camelCase(keyPrefix + key);
        this.section = SECTIONS[o.sectionNo - 1];
        this.sectionKey = camelCase(this.section);
        this.label = o.label;
        this.group = o.group;
        this.groupName = o.groupName;
        this.options = o.options;
        this.parent = o.parent;
        this.sectionNo = o.sectionNo;
        this.requireParent = o.requireParent;
        this.placeholder = o.placeholder;
        this.showLabel = o.showLabel || !!this.label;
        this.tableType = o.tableType || "default";
        this.initValue = o.initValue;
        this.required = o.required || false;
        this.disabled = o.disabled || false;
    }
}

export const ITEMS = [
    // section 1
    new DataItem({ label: "First Name", type: "text", sectionNo: 1, group: 1, disabled: true }),
    new DataItem({ label: "Middle Name", type: "text", sectionNo: 1, group: 1, disabled: true }),
    new DataItem({ label: "Last Name", type: "text", sectionNo: 1, group: 1, disabled: true }),
    new DataItem({
        label: "Request to change the patient name for this by emailing info@recoveryhub.ph",
        type: "note",
        sectionNo: 1,
        group: 1,
        required: false,
    }),
    new DataItem({
        group: 1,
        sectionNo: 1,
        label: "Gender",
        key: "sex",
        type: "select",
        options: [
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
        ],
        required: true,
    }),
    new DataItem({
        group: 1,
        sectionNo: 1,
        label: "Civil Status",
        type: "select",
        options: [
            { value: "Single", label: "Single" },
            { value: "Married", label: "Married" },
            { value: "Live-In", label: "Live-In" },
            { value: "Widowed", label: "Widowed" },
            { value: "Separated", label: "Separated" },
        ],
        required: true,
    }),

    // new DataItem({ label: 'Date of Birth', sectionNo: 1, group: 1, required: true }),
    new DataItem({
        label: "Date of Birth",
        sectionNo: 1,
        group: 1,
        required: true,
        type: "datepicker",
    }),
    new DataItem({ label: "Age", sectionNo: 1, group: 1, required: false, type: "age" }),
    new DataItem({ label: "Place of Birth", sectionNo: 1, group: 1, required: true }),
    new DataItem({ label: "Religion", sectionNo: 1, group: 1, required: false }),
    new DataItem({ label: "Nationality", sectionNo: 1, group: 1, required: false }),
    new DataItem({ label: "Race", sectionNo: 1, group: 1, required: false }),
    new DataItem({ label: "Ethnicity", sectionNo: 1, group: 1, required: false }),

    new DataItem({
        label: "No. & Street",
        sectionNo: 1,
        keyPrefix: "temp",
        groupName: "Current Address",
        group: 2,
        required: true,
    }),
    new DataItem({ label: "Barangay", sectionNo: 1, keyPrefix: "temp", group: 2, required: true }),
    new DataItem({ label: "City/Municipality", sectionNo: 1, keyPrefix: "temp", group: 2, required: true }),
    new DataItem({ label: "Province", sectionNo: 1, keyPrefix: "temp", group: 2, required: true }),
    new DataItem({ label: "ZipCode", key: "tempZipCode", sectionNo: 1, group: 2, required: true }),

    new DataItem({
        label: "No. & Street",
        sectionNo: 1,
        keyPrefix: "perm",
        group: 3,
        groupName: "Permanent Address",
        required: true,
    }),
    new DataItem({ label: "Barangay", sectionNo: 1, keyPrefix: "perm", group: 3, required: true }),
    new DataItem({ label: "City/Municipality", sectionNo: 1, keyPrefix: "perm", group: 3, required: true }),
    new DataItem({ label: "Province", sectionNo: 1, keyPrefix: "perm", group: 3, required: true }),
    new DataItem({ label: "ZipCode", key: "permZipCode", sectionNo: 1, group: 3, required: true }),

    new DataItem({
        label: "Landline Number",
        sectionNo: 1,
        group: 4,
        groupName: "Contact information",
        required: true,
        type: "number",
    }),
    new DataItem({ label: "Mobile Number", sectionNo: 1, group: 4, required: true, type: "number" }),
    new DataItem({ label: "Email Address", sectionNo: 1, group: 4, required: true }),

    new DataItem({
        label: "First Name",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: true,
    }),
    new DataItem({
        label: "Last Name",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: true,
    }),
    new DataItem({
        label: "Middle Name",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: false,
    }),
    new DataItem({
        label: "LandLine",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: false,
        type: "number",
    }),
    new DataItem({
        label: "Mobile No",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: true,
        type: "number",
    }),
    new DataItem({
        label: "Address",
        groupName: "Contact Person In Case of Emergency",
        keyPrefix: "contact",
        sectionNo: 1,
        group: 5,
        required: false,
    }),

    new DataItem({
        label: "Highest Educational Attainment",
        sectionNo: 1,
        group: 6,
        groupName: "Other Information",
        required: true,
    }),
    new DataItem({ label: "Occupation", sectionNo: 1, group: 6, required: true }),
    new DataItem({ label: "Company", sectionNo: 1, group: 6, required: true }),
    new DataItem({ label: "Company Address and Contact Details", sectionNo: 1, group: 6, required: false }),
    new DataItem({ label: "Height", sectionNo: 1, group: 6, required: true }),
    new DataItem({ label: "Weight", sectionNo: 1, group: 6, required: true }),

    // section 2
    new DataItem({ label: "Reason for Consultation", group: 1, sectionNo: 2, type: "textarea", required: true }),

    // section 11 >> 3
    // section 11
    new DataItem({
        sectionNo: 3,
        key: "historyOfPresentIllness",
        group: 1,
        type: "table",
        tableType: "historyOfPresentIllness",
        initValue: [{}],
    }),

    // // section 4
    // new DataItem({
    //     type: 'radio',
    //     key: 'financialSituation',
    //     label: 'Financial Situation',
    //     sectionNo: 4,
    //     group: 1,
    //     options: [
    //         { value: 'independent', label: 'Independent' },
    //         { value: 'dependent', label: 'Dependent' },
    //     ],
    // }),
    // new DataItem({
    //     label: 'Monthly Income',
    //     sectionNo: 4,
    //     type: 'text',
    //     parent: 'financialSituation',
    //     requireParent: 'independent',
    //     group: 1,
    // }),
    // new DataItem({
    //     label: 'Dependent to',
    //     key: 'dependentValue',
    //     sectionNo: 4,
    //     type: 'checkbox',
    //     parent: 'financialSituation',
    //     requireParent: 'dependent',
    //     options: [
    //         { value: 'parents', label: 'Parents' },
    //         { value: 'siblings', label: 'Siblings' },
    //         { value: 'relatives', label: 'Relatives' },
    //         { value: 'other', label: 'Others' },
    //     ],
    //     group: 1,
    // }),
    // SECTION 12 >> 4

    new DataItem({
        sectionNo: 4,
        key: "pastMedicalHistory",
        group: 1,
        type: "table",
        tableType: "pastMedicalHistory",
        initValue: [
            {
                adverseEvents: "",
                date: "",
                diagnosis: "",
                hospitalOrFacility: "",
                outcome: "",
                treatmentOrIntervention: "",
            },
        ],
    }),

    new DataItem({
        sectionNo: 4,
        key: "patientPsychHistory",
        group: 1,
        type: "table",
        tableType: "patientPsychHistory",
        initValue: [
            {
                date: "",
                diagnosisOrSymptom: "",
                healthFacilityOrHospital: "",
                interventionDetails: "",
                interventionType: "",
                outcomeAdverseDrugEvent: "",
                outcomeFunctionality: "",
                outcomeSymptoms: "",
                treatmentSetting: "",
            },
        ],
    }),

    // section 15 >> 11
    // from section 11, change to section 5
    new DataItem({
        sectionNo: 5,
        key: "substanceUseOrAddictionHistory",
        group: 1,
        type: "table",
        tableType: "substanceUseOrAddictionHistory",
        initValue: [
            { substance: "Nicotine" },
            { substance: "Caffeine" },
            { substance: "Alcohol" },
            { substance: "Methamphetamine" },
            { substance: "Cannabinoid" },
            { substance: "Inhalants" },
            { substance: "Prescription Drugs" },
        ],
    }),

    // section 13 >> 18 >> 5
    // from section 5, change to section 6
    new DataItem({
        sectionNo: 6,
        key: "famMedicalHistory",
        group: 1,
        type: "table",
        tableType: "famMedicalHistory",
        initValue: [
            {
                details: "",
                history: "",
                illness: "",
            },
        ],
    }),

    new DataItem({
        sectionNo: 6,
        key: "famPsychHistory",
        group: 1,
        type: "table",
        tableType: "famPsychHistory",
        initValue: [
            {
                details: "",
                history: "",
                illness: "",
            },
        ],
    }),

    // // section 3 >> 6
    // new DataGroup(['Father', 'Mother', 'Spouse'], familyInfo, 3, true),
    // from section 6, change to section 7
    new DataItem({
        sectionNo: 7,
        key: "familyRelationship",
        group: 1,
        type: "table",
        tableType: "familyRelationship",
        initValue: [
            {
                relationship: "",
                primaryCaregiver: "",
                name: "",
                age: "",
                educationalAttainment: "",
                homeAddress: "",
                occupation: "",
                emotionalRelationship: "",
            },
        ],
    }),
    new DataItem({ label: "Birth Rank", sectionNo: 7, group: 2, required: false }),
    new DataItem({ label: "No. of Siblings", sectionNo: 7, group: 2, required: false }),
    new DataItem({ label: "No. of Supportive Siblings", sectionNo: 7, group: 2, required: false }),
    new DataItem({ label: "No. of Children", sectionNo: 7, group: 2, required: false }),
    new DataItem({ label: "No. of Supportive Children", sectionNo: 7, group: 2, required: false }),
    new DataItem({ label: "Other Remarks", sectionNo: 7, group: 2, type: "textarea" }),

    // section 14 >> 7
    // from section 7, change to section 8
    new DataItem({
        sectionNo: 7,
        key: "developmentalHistory",
        group: 1,
        type: "radio",
        label: "Developmental History",
        options: [
            { value: "noinfo", label: "No information" },
            { value: "normal", label: "Normal" },
            { value: "abnormal", label: "Abnormal" },
        ],
    }),

    new DataItem({
        sectionNo: 8,
        key: "abnormalDevelopmentalHistory",
        group: 2,
        type: "checkbox",
        tableType: "abnormalDevelopmentalHistory",
        parent: "developmentalHistory",
        requireParent: "abnormal",
        label: "Abnormal Development History",
        options: [
            { value: "Infancy", label: "Infancy" },
            { value: "Pre-School", label: "Pre-School" },
            { value: "School Age", label: "School Age" },
            { value: "Adolescence", label: "Adolescence" },
        ],
    }),

    new DataItem({
        sectionNo: 8,
        key: "otherSignificantEvents",
        group: 1,
        type: "textarea",
        label: "Other Significant Events",
    }),

    new DataItem({
        sectionNo: 8,
        key: "significantPrenatalOrPerinatalEvent",
        group: 1,
        type: "textarea",
        label: "Significant prenatal / perinatal event",
        placeholder: "Fill this out if patient is female",
    }),

    // SECTION 5>> 18
    // from section 18, change to section 9
    new DataItem({
        type: "radio",
        key: "academicPerformance",
        sectionNo: 9,
        group: 1,
        label: "School",
        options: [
            { value: "p", label: "Poor" },
            { value: "a", label: "Average" },
            { value: "aa", label: "Above Average" },
        ],
    }),
    new DataItem({
        label: "Remarks / Details",
        key: "academicPerformanceRemarks",
        sectionNo: 9,
        group: 1,
    }),
    new DataItem({
        type: "radio",
        key: "occupationalPerformance",
        sectionNo: 9,
        group: 2,
        label: "Work",
        options: [
            { value: "p", label: "Poor" },
            { value: "a", label: "Average" },
            { value: "aa", label: "Above Average" },
        ],
    }),
    new DataItem({
        label: "Remarks / Details",
        key: "occupationalPerformanceRemarks",
        sectionNo: 9,
        group: 2,
    }),

    // SECTION 16 >> 8
    // from section 8, change to section 10
    new DataItem({
        sectionNo: 10,
        key: "genderPreference",
        group: 1,
        type: "radio",
        label: "Gender Preference",
        options: [
            { value: "homo", label: "Homosexual" },
            { value: "hetero", label: "Heterosexual" },
            { value: "bi", label: "Bisexual" },
            { value: "other", label: "Others" },
        ],
    }),

    new DataItem({
        label: "Please Specify",
        key: "genderPreferenceOthers",
        sectionNo: 10,
        group: 1,
        parent: "genderPreference",
        requireParent: "other",
    }),
    
    new DataItem({
        sectionNo: 10,
        label: "Courtship Experience",
        group: 1,
        type: "radio",
        options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    }),
    new DataItem({
        sectionNo: 10,
        key: "courtshipExperienceYesValue",
        group: 1,
        type: "radio",
        parent: "courtshipExperience",
        requireParent: "yes",
        label: "Details",
        options: [
            { value: "p", label: "Positive" },
            { value: "n", label: "Negative" },
        ],
    }),

    new DataItem({
        sectionNo: 10,
        key: "courtshipExperienceYesDetail",
        parent: "courtshipExperienceYesValue",
        requireParent: "p",
        group: 1,
        label: "Details",
        type: "textarea",
    }),

    new DataItem({
        sectionNo: 10,
        key: "courtshipExperienceYesDetail",
        parent: "courtshipExperienceYesValue",
        requireParent: "n",
        group: 1,
        type: "textarea",
        label: "Details",
    }),

    new DataItem({
        sectionNo: 10,
        key: "sexualExperience",
        label: "Sexual Experience",
        group: 2,
        type: "checkbox",
        options: [
            { value: "m", label: "Masturbation" },
            { value: "s", label: "Sexual Contact" },
            { value: "o", label: "Others" },
        ],
    }),

    new DataItem({
        sectionNo: 10,
        key: "sexualExpDetails",
        label: "Details",
        group: 2,
        type: "textarea",
    }),

    new DataItem({
        sectionNo: 10,
        label: "Age of First Menstruation",
        group: 3,
        type: "text",
    }),
    new DataItem({
        sectionNo: 10,
        label: "<b>OB Score</b>    G",
        key: "obScoreG",
        group: 3,
        type: "text",
    }),
    new DataItem({
        key: "obScoreP",
        sectionNo: 10,
        label: "P",
        group: 3,
        type: "text",
    }),

    new DataItem({
        sectionNo: 10,
        label: "Menstrual Cycle",
        group: 3,
        type: "radio",
        options: [
            { value: "n", label: "Normal" },
            { value: "a", label: "abnormal" },
        ],
    }),

    new DataItem({
        sectionNo: 10,
        label: "Gynecological illnesses",
        group: 3,
        type: "textarea",
    }),

    new DataItem({
        sectionNo: 10,
        label: "Menopause, Age of Onset",
        group: 3,
        type: "text",
    }),

    new DataItem({
        sectionNo: 10,
        label: "Other significant sexuality Experience",
        placeholder: "(i.e. trauma, paraphilia, sexual dysfunction, transgender, etc.)",
        group: 4,
        type: "textarea",
    }),

    new DataItem({
        sectionNo: 10,
        label: "HIV tested",
        group: 5,
        type: "radio",
        options: [
            { value: "not tested", label: "Not Tested" },
            { value: "positive", label: "Positive" },
            { value: "negative", label: "Negative" },
        ],
    }),

    new DataItem({
        sectionNo: 10,
        key: "otherStds",
        label: "Other STD's, Please specify",
        group: 6,
        type: "text",
    }),

    new DataItem({
        sectionNo: 10,
        key: "otherStdStatus",
        group: 6,
        type: "radio",
        showLabel: true,
        options: [
            { value: "not tested", label: "Not Tested" },
            { value: "positive", label: "Positive" },
            { value: "negative", label: "Negative" },
        ],
    }),

    new DataItem({
        sectionNo: 10,
        label: "Significant Urologic History",
        group: 7,
        type: "textarea",
    }),

    // SECTION 6 >> 10
    // from section 10, change to section 11
    new DataItem({
        // label: 'Please Specify',
        type: "checkbox",
        key: "friends",
        sectionNo: 11,
        group: 1,
        label: "With who does the patient make friends with",
        options: [
            { value: "anyone", label: "Anyone" },
            { value: "choosy", label: "Those he/she wants" },
            { value: "none", label: "No one" },
            { value: "other", label: "Others" },
        ],
    }),
    new DataItem({
        label: "Remarks / Details",
        key: "friendsOthers",
        sectionNo: 11,
        group: 1,
        parent: "friends",
        requireParent: "other",
    }),

    new DataItem({
        type: "checkbox",
        key: "leisureFreeTimeSpent",
        sectionNo: 11,
        group: 2,
        label: "How is the leisure/free time spent?",
        options: [
            { value: "alone", label: "Alone" },
            { value: "family", label: "With family" },
            { value: "friends", label: "With friends" },
            { value: "other", label: "Others" },
        ],
    }),
    new DataItem({
        label: "Remarks / Details",
        key: "leisureFreeTimeSpentOthers",
        sectionNo: 11,
        group: 2,
        parent: "leisureFreeTimeSpent",
        requireParent: "other",
    }),

    new DataItem({
        label: "Specify Activities",
        type: "textarea",
        key: "activities",
        sectionNo: 11,
        group: 2,
    }),

    new DataItem({
        type: "checkbox",
        key: "otherSocialSupport",
        sectionNo: 11,
        group: 3,
        label: "Other Social Support",
        options: [
            { value: "relatives", label: "Relatives" },
            { value: "friends", label: "Friends" },
            { value: "neighbors", label: "Neighbors" },
            { value: "church", label: "Church" },
            { value: "other", label: "Others" },
        ],
    }),
    new DataItem({
        label: "Remarks / Details",
        key: "otherSocialSupportOthers",
        sectionNo: 11,
        group: 3,
        parent: "otherSocialSupport",
        requireParent: "other",
    }),

    // section 7 >> 15
    // from section 15, change to section 12
    new DataItem({
        label: "Details",
        type: "textarea",
        key: "details",
        sectionNo: 12,
        group: 5,
    }),

    // section 9 >> 16
    // from section 16, change to 13
    new DataItem({
        sectionNo: 13,
        key: "legalInvolvement",
        group: 1,
        type: "table",
        tableType: "legalInvolvement",
        initValue: [{}],
    }),

    // section 8 >> 12
    // from section 12, change to section 14
    new DataItem({
        type: "checkbox",
        key: "attended",
        sectionNo: 14,
        group: 1,
        label: "Attended",
        options: [
            { value: "cat", label: "Citizen's Army Training(CAT)" },
            { value: "rotc", label: "Reserve Officer's Training Corps (ROTC) " },
            { value: "military", label: "Military Training" },
            { value: "other", label: "Other" },
        ],
    }),
    new DataItem({
        label: "Please Specify",
        key: "attendedOther",
        sectionNo: 14,
        group: 1,
        parent: "attended",
        requireParent: "other",
    }),
    new DataItem({
        type: "checkbox",
        key: "served",
        sectionNo: 14,
        group: 2,
        label: "Served",
        options: [
            { value: "army", label: "Army" },
            { value: "airforce", label: "Airforce" },
            { value: "navy", label: "Navy" },
            { value: "other", label: "Other" },
        ],
    }),
    new DataItem({
        label: "Please Specify",
        key: "servedOther",
        sectionNo: 14,
        group: 2,
        parent: "served",
        requireParent: "other",
    }),
    new DataItem({
        type: "checkbox",
        key: "status",
        sectionNo: 14,
        group: 3,
        label: "Status",
        options: [
            { value: "active", label: "Active" },
            { value: "dismissed", label: "Dismissed" },
            { value: "retired", label: "Retired" },
            { value: "other", label: "Other" },
        ],
    }),
    new DataItem({
        label: "Please Specify",
        key: "statusOther",
        sectionNo: 14,
        group: 3,
        parent: "status",
        requireParent: "other",
    }),
    new DataItem({
        type: "radio",
        key: "combatExperience",
        sectionNo: 14,
        group: 4,
        label: "Combat Experience",
        options: [
            { value: "yes", label: "Yes" },
            { value: "no", label: "No" },
        ],
    }),
    new DataItem({
        label: "Details",
        key: "combatExperienceDetails",
        sectionNo: 14,
        type: "textarea",
        group: 4,
        parent: "combatExperience",
        requireParent: "yes",
    }),

    // SECTION 10 >> 17
    // from section 17, change to section 15
    new DataItem({
        sectionNo: 15,
        key: "otherRelevantHistoryDetails",
        group: 1,
        type: "textarea",
        label: "Details",
    }),

    // // section 4 >> 9
    // from section 9, change to section 16
    new DataItem({
        type: "radio",
        key: "financialSituation",
        label: "Financial Situation",
        sectionNo: 16,
        group: 1,
        options: [
            { value: "independent", label: "Independent" },
            { value: "dependent", label: "Dependent" },
        ],
    }),
    new DataItem({
        label: "Monthly Income",
        sectionNo: 16,
        type: "text",
        parent: "financialSituation",
        requireParent: "independent",
        group: 1,
    }),
    new DataItem({
        label: "Dependent to",
        key: "dependentValue",
        sectionNo: 16,
        type: "checkbox",
        parent: "financialSituation",
        requireParent: "dependent",
        options: [
            { value: "parents", label: "Parents" },
            { value: "siblings", label: "Siblings" },
            { value: "relatives", label: "Relatives" },
            { value: "other", label: "Others" },
        ],
        group: 1,
    }),
    new DataItem({
        label: "Please specify other dependency",
        key: "financialSituationDependentOther",
        sectionNo: 16,
        type: "text",
        parent: "dependentValue",
        requireParent: "other",
        group: 1,
    }),

    new DataItem({
        type: "radio",
        key: "sourceOfIncome",
        sectionNo: 16,
        group: 2,
        label: "Source of Income",
        options: [
            { value: "employed", label: "Employed" },
            { value: "selfemployed", label: "Self-employed" },
            { value: "unemployed", label: "Unemployed" },
            { value: "other", label: "Other" },
        ],
    }),
    new DataItem({
        label: "Occupation",
        key: "sourceOfIncomeEmployedOccupation",
        sectionNo: 16,
        type: "text",
        parent: "sourceOfIncome",
        requireParent: "employed",
        group: 2,
    }),
    new DataItem({
        label: "Occupation",
        key: "sourceOfIncomeOther",
        sectionNo: 16,
        type: "text",
        parent: "sourceOfIncome",
        requireParent: "other",
        group: 2,
    }),
    new DataItem({
        label: "Unemployment reason",
        key: "sourceOfIncomeUnemployedReason",
        sectionNo: 16,
        type: "checkbox",
        parent: "sourceOfIncome",
        requireParent: "unemployed",
        options: [
            { value: "student", label: "Student" },
            { value: "householdWork", label: "Household Work" },
            { value: "none", label: "None" },
            { value: "other", label: "Others" },
        ],
        group: 2,
    }),

    new DataItem({
        label: "Occupation",
        key: "sourceOfIncomeOther",
        sectionNo: 16,
        type: "text",
        parent: "sourceOfIncomeUnemployedReason",
        requireParent: "other",
        group: 2,
    }),

    new DataItem({
        type: "radio",
        key: "livingArrangement",
        sectionNo: 16,
        group: 3,
        label: "Living Arrangement",
        options: [
            { value: "unrestrained", label: "Not Physically Restrained" },
            { value: "restrained", label: "Physically Restrained" },
            { value: "vagrant", label: "Vagrant/Homeless" },
        ],
    }),
    new DataItem({
        label: "Please Specify",
        type: "radio",
        key: "livingArrangementUnrestrained",
        sectionNo: 16,
        group: 3,
        parent: "livingArrangement",
        requireParent: "unrestrained",
        options: [
            { value: "nuclear", label: "Living with Nuclear family" },
            { value: "extended", label: "Living with Extended family" },
            { value: "compound", label: "Living in a compound with relatives" },
            { value: "alone", label: "Living alone" },
            { value: "halfway", label: "Halfway home" },
        ],
    }),
    new DataItem({
        label: "Physically Restrained",
        type: "radio",
        key: "livingArrangementRestrained",
        sectionNo: 16,
        group: 3,
        parent: "livingArrangement",
        requireParent: "restrained",
        options: [
            { value: "home", label: "At home" },
            { value: "jail", label: "In jail" },
            { value: "custodial", label: "Custodial Care" },
            { value: "homecare", label: "Homecare" },
        ],
    }),
    new DataItem({
        // label: 'Please Specify',
        type: "radio",
        key: "estimatedFamilyIncome",
        sectionNo: 16,
        group: 4,
        label: "Estimated Family Monthly Income",
        options: [
            { value: "q1", label: "Q1 < 3,461" },
            { value: "q2", label: "Q2 3,462 - 9, 308" },
            { value: "q3", label: "Q3 9,309 - 15,063" },
            { value: "q4", label: "Q4 15,064 - 38,065" },
            { value: "q5", label: "Q5 > 38,065" },
        ],
    }),

    // SECTION 17 >> 13
    // from section 13, change to section 17
    new DataItem({
        sectionNo: 17,
        label: "Treatment Setting",
        group: 1,
        type: "radio",
        options: [
            { value: "outpatient", label: "Outpatient" },
            { value: "inpatient", label: "Inpatient" },
        ],
    }),
    new DataItem({
        sectionNo: 17,
        label: "Payment",
        group: 2,
        type: "radio",
        options: [
            { value: "pay", label: "Pay" },
            { value: "charity", label: "Charity" },
        ],
    }),

    new DataItem({
        sectionNo: 17,
        label: "Type of consult",
        group: 3,
        type: "radio",
        options: [
            { value: "voluntary", label: "Voluntary" },
            { value: "involuntary", label: "Involuntary with family consent" },
            { value: "others", label: "Others" },
        ],
    }),

    new DataItem({
        sectionNo: 17,
        group: 4,
        type: "radio",
        label: "Source of Information",
        key: "sourceOfInformation",
        options: [
            { value: "patient", label: "Patient" },
            { value: "others", label: "Others" },
        ],
    }),

    new DataItem({
        sectionNo: 17,
        group: 4,
        type: "text",
        key: "sourceInfoName",
        label: "Name",
        parent: "sourceOfInformation",
        requireParent: "patient",
    }),

    new DataItem({
        sectionNo: 17,
        group: 4,
        type: "text",
        key: "sourceInfoRelationship",
        parent: "sourceOfInformation",
        requireParent: "patient",
        label: "Relationship",
    }),

    new DataItem({
        sectionNo: 17,
        group: 4,
        type: "text",
        key: "sourceInfoName",
        label: "Name",
        parent: "sourceOfInformation",
        requireParent: "others",
    }),

    new DataItem({
        sectionNo: 17,
        group: 4,
        type: "text",
        key: "sourceInfoRelationship",
        parent: "sourceOfInformation",
        requireParent: "others",
        label: "Relationship",
    }),

    // section 18 >> 14
    // from section 14, change to section 18
    new DataItem({
        sectionNo: 18,
        key: "otherData",
        group: 1,
        showLabel: true,
        type: "checkbox-detailed",
        options: [
            { value: "0", label: "Private Clinic" },
            { value: "1", label: "Private Hospital" },
            { value: "2", label: "Government Hospital" },
            { value: "3", label: "LGU" },
            { value: "4", label: "School" },
            { value: "5", label: "Court" },
            { value: "6", label: "Emergency Room" },
            { value: "7", label: "Others" },
        ],
    }),
];
