import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NoContentComponent } from './pages/no-content/no-content.component';

const routes: Routes = [
    // Fallback when no prior route is matched
    {
        path: '**',
        component: NoContentComponent,
        // redirectTo: '',
        // pathMatch: 'full'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { enableTracing: false, anchorScrolling: 'enabled' })],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }
