import { Component, OnInit, Injector, TemplateRef, ViewEncapsulation } from '@angular/core';

import { ListComponent as CrudListComponent } from '../../../../../core/base/crud';

import { OrderTransactionService } from '../../../../../services/order-transaction.service';

import { settings } from '../../settings';

import { BsModalService } from 'ngx-bootstrap/modal';

import { isNull } from 'util';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent extends CrudListComponent implements OnInit {

  public columns: any;
  selectedRow: any;

  constructor(injector: Injector, private modalService: BsModalService) {
    super(injector);
    this.setSettings(settings);
    this.service = injector.get(OrderTransactionService);
  }

  ngOnInit() {
      this.getList(`orderBy=created_at&sortedBy=desc&groupBy=transaction_id`);
  }

  getViewLink(row: any) {
    return this.settings.crud.view.url + row.id;
  }

  getEditLink(row: any) {
    return this.settings.crud.update.url + row.id;
  }
}
