export const SECTIONS = [
    {
        key: 'constitutional',
        model: {
            'constitutional': [null],
        },
        header: {
            title: 'Constitutional',
            description: `
                <ul>
                    <li>Vital Signs (heart rate and respiratory rate; if available, temperature, blood pressure, weight)</li>
                    <li>General appearance (ill, well appearing, (un) comfortable, fatigued, attentive, distracted, disheveled, unkept)</li>
                </ul>
                `,
        }
    },

    {
        key: 'eyes',
        model: {
            'eyes': [null],
        },
        header: {
            title: 'Eyes',
            description: `
                <ul>
                    <li>Appearance of conjunctiva and lids (lid drop, crusting / exudate, conjunctival injection)</li>
                    <li>Appearance of pupils (equal, round, extra ocular eye movements)</li>
                    <li>Assessment of vision (seeing double)</li>
                </ul>
                `,
        }
    },

    {
        key: 'ent',
        model: {
            'ent': [null],
        },
        header: {
            title: 'Ears, Nose, Mouth & Throat',
            description: `
                <ul>
                    <li>External appearance of the ears and nose (scars, lesions, masses)</li>
                    <li>Assessment of hearing (able to hear, asks to repeat questions)</li>
                    <li>Inspection of lips, mouth, teeth and gums (color, condition of mucosa)</li>
                    <li>Gross inspection of throat (tonsillar enlargement, exudate)</li>
                    <li>Appearance of face (symmetric, appropriate movement of mouth, no drooling or labial flattening, ability to raise
                    eyebrow, frown / smile, close eyes, show upper lower teeth, puff out cheeks)</li>
                    <li>Pain or tenderness when patient palpates sinuses or ears</li>
                </ul>
                `,
        }
    },
    
    {
        key: 'neck',
        model: {
            'neck': [null],
        },
        header: {
            title: 'Neck',
            description: `
                <ul>
                    <li>External appearance of the neck (overall appearance, symmetry, tracheal position, gross evidence of
                        lymphadenopathy, jugular venous distention)</li>
                    <li>Gross movement (degrees of flexion anterior, posterior and laterally)</li>
                </ul>
                `,
        }
    },

    {
        key: 'respiratory',
        model: {
            'respiratory': [null],
        },
        header: {
            title: 'Respiratory',
            description: `
                <ul>
                    <li>Assessment of respiratory effort (intercostal retractions, see of accessory muscles, diaphragmatic movement,
                        pursed lip breathing, speaking in full sentences of limited due to shortness of breath)</li>
                    <li>Audible wheezing</li>
                    <li>Presence and nature of cough (frequent, occasional, wet, dry, coarse)</li>
                    <li>Determine Roth Score</li>
                </ul>
                `,
        }
    },

    {
        key: 'cardiovascular',
        model: {
            'cardiovascular': [null],
        },
        header: {
            title: 'Cardiovascular',
            description: `
                <ul>
                    <li>Presence and nature of edema in extremities (pitting, weeping)</li>
                    <li>Capillary refill
                    Temperature of extremities per patient / other measure</li>
                </ul>
                `,
        }
    },

    {
        key: 'chest',
        model: {
            'chest': [null],
        },
        header: {
            title: 'Chest',
            description: `
                <ul>
                    <li>Inspection of thee breasts (symmetry, nipple discharge)</li>
                    <li>Chest wall or costochondral tenderness with self palpation</li>
                </ul>
                `,
        }
    },

    {
        key: 'musculoskeletal',
        model: {
            'musculoskeletal': [null],
        },
        header: {
            title: 'Musculoskeletal',
            description: `
                <ul>
                    <li>Examination of gait and station (stands with / without use of arms to push off chair; steady gait, broad /
                        narrowed based)</li>
                    <li>Inspection of digits and nails (capillary refill, clubbing, cyanosis, inflammatory conditions, petechiae, pallor)</li>
                    <li>Extremity exam may include
                        <ul>
                            <li>Alignment, symmetry, defects, tenderness on self-palpation</li>
                            <li>Range of motion, pain contracture</li>
                            <li>Muscle strength and tone ( flaccid, cogwheel, spastic), atrophy, abnormal movements</li>
                            <li>Presence and nature of edema, temperature</li>
                            <li>Self-assessment using Ottawa ankle and knee rules</li>
                        </ul>
                    </li>
                </ul>
                `,
        }
    },

    {
        key: 'skin',
        model: {
            'skin': [null],
        },
        header: {
            title: 'Skin',
            description: `
                <ul>
                    <li>Rashes, lesions, ulcers, cracking, fissures, mottling, petechiae</li>
                    <li>Cyanosis, diaphoresis</li>
                </ul>
                `,
        }
    },

    {
        key: 'neurologic',
        model: {
            'neurologic': [null],
        },
        header: {
            title: 'Neurologic',
            description: `
                <ul>
                    <li>Dermatomal distribution of numbness or pain</li>
                    <li>Examination of sensation (by touch or pin)</li>
                </ul>
                `,
        }
    },

    {
        key: 'psychiatric',
        model: {
            'psychiatric': [null],
        },
        header: {
            title: 'Phsyciatric',
            description: `
                <ul>
                    <li>Orientation to time, place, and person</li>
                    <li>Recent and remote memory</li>
                    <li>Mood and affect</li>
                    <li>Pressured Speech</li>
                    <li>Mood lability (crying, laughing)</li>
                </ul>
                `,
        }
    },

    {
        key: 'other_findings',
        model: {
            'other_findings': [null],
        },
        header: {
            title: 'Other Pertinent Findings',
            description: ``,
        }
    }
]