import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';



@Component({
    selector: 'section-progress',
    templateUrl: './progress.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionProgressComponent implements OnChanges {
    @Input() data: object

    title = 'Progress';
    value = '<em class="text-muted">Not specified</em>';
    match = true

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue && 
            changes.data.currentValue.notes && 
            typeof changes.data.currentValue.notes.progress === 'string'
        ) {
            if (changes.data.currentValue.notes.progress.length) {
                this.value = changes.data.currentValue.notes.progress
            }
        }
    }

}
