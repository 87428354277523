
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';


export const ORDERS_MODEL = {
  'notes' : [null, Validators.compose([Validators.required])],
  // 'status' : [null, Validators.compose([Validators.required])]
  'status' : [null],
  'received_by' : [null],
  'date_received' : [null]
};

export const ORDERS_STATUS = [
  'pending',
  'processing',
  'shipped',
  'delivered'
];