import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Routes, RouterModule } from '@angular/router'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { SharedModule } from '../../shared/shared.module'
import { FeedbackFormComponent } from './feedback.component'
import { FeedbackService, AvailabilityService, PhysicalExamService } from '../../services'
import { MedicationsComponent } from '../video-call/medications/medications.component'
import { PhysicalExamComponent } from './physical-exam/physical-exam.component'
import { NgxEditorModule } from 'ngx-editor'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { TimepickerModule } from 'ngx-bootstrap/timepicker'
import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { DSM5Component } from './../../components/dsm5-dropdown/dsm5-dropdown.component'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
    {
        path: 'feedback/:eventId',
        component: FeedbackFormComponent,
    },
]

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        AccordionModule.forRoot(),
        ModalModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        SharedModule,
        NgxEditorModule,
        TabsModule,
        BsDatepickerModule,
        TimepickerModule,
        TypeaheadModule,
        TooltipModule,
        FontAwesomeModule,
        NgbRatingModule
    ],
    declarations: [
        FeedbackFormComponent,
        MedicationsComponent,
        DSM5Component,
        PhysicalExamComponent,
    ],
    providers: [
        FeedbackService,
        AvailabilityService,
        PhysicalExamService,
    ],
    exports: [
        FeedbackFormComponent,
        DSM5Component,
        PhysicalExamComponent,
    ]
})
export class FeedbackFormModule { }
