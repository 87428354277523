import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppointmentService, UserService, AvailabilityService, MedicalPractitionerService, PatientService, SecretaryService, avatar } from '../../../../../services';
import { LoaderService } from '../../../../../services/loader.service';
import { faSortAlphaUp, faSortAlphaDown, faSortNumericUp, faSortNumericDown } from '@fortawesome/free-solid-svg-icons';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

enum SortType {
    Alphabetical,
    MostRecent,
}

@Component({
    selector: 'app-my-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class MyListSecComponent implements OnInit {
    public location: string;

    myList: any[];
    displayList: any[];
    searchTerm: string;
    sortClass: any;
    isPatient: any;

    sortType = new BehaviorSubject<SortType>(SortType.Alphabetical);
    sortAsc = new BehaviorSubject<boolean>(true);
    userType: any;
    role: any;
    defaultPicture: any;

    // Pagination
    pagedList: any[];
    currentList: any[];
    perPage: number;
    currentPage: number;
    total: number;
    totalPages;

    orderBy = 'last_name';
    sortedBy = 'asc';

    filterMyTherapists = false;
    filterMyTherapistsLoading = false;

    avatar = avatar
    
    constructor(
        private appointmentService: AppointmentService,
        private loaderService: LoaderService,
        private userService: UserService,
        private availabilityService: AvailabilityService,
        private medicalPractitionerService: MedicalPractitionerService,
        private patientService: PatientService,
        private secretaryService: SecretaryService,
        private route: Router
    ) {
        // console.log('here')
         
        // loaderService.loader.next(true);
        // // route.navigate(['/doctors/psychiatrist'])
        // // window.location.href = '/doctors/psychiatrist'
        // // http://localhost:4200/me/psychiatrists 
        // return

        this.role = this.userService.getStoredCredentials().roles[0].name;
        // console.log("route", route);
        switch (this.role) {
            case 'secretary':
                this.location = 'Doctor Patients';
                this.isPatient = false;
                this.defaultPicture = '../../../../assets/images/users-placeholder.png';
                break;
            case 'patient':
            case 'in-patient':
                this.location = 'Psychiatrists';
                this.isPatient = true;
                this.defaultPicture = '../../../../assets/images/doctor-placeholder.png';
                break;
        }
    }

    ngOnInit() {
        this.pagedList = new Array();
        this.perPage = 15;
        this.currentPage = 1;

        this.getList();
    }

    getList() {
        if (this.role === 'patient' || this.role === 'in-patient') {
            this.getAppointments();
        } else if (this.role === 'secretary') {
            this.getPatients();
        }
    }

    getAppointments() {
        let params = {
            filter: 'id;email',
            search: 'status:active',
            limit: this.perPage,
            orderBy: this.orderBy,
            page: this.currentPage,
            therapist:'medical-practitioner',
            sortedBy: (this.sortAsc.getValue()) ? 'asc' : 'desc',
        };

        if (this.filterMyTherapists) {
            params['myTherapists'] = this.userService.getStoredCredentials().id
        }

        if (this.searchTerm && this.searchTerm.length) {
            params['search'] = 'status:active;' + this.searchTerm;
        }

        this.filterMyTherapistsLoading = true;
        console.log('params', params)

        this.medicalPractitionerService.index(params) //this.appointmentService.buildQueryString(params))
            .subscribe(({ data, meta }) => {
                let d = data
                    .filter(o => 'event' in o && 'doctor' in o.event)
                    .map(o => o.event.doctor)
                    .filter((v, i, a) => a.findIndex(doctor => doctor.id === v.id) === i)
                    .map(dr => {
                        return {
                            ...dr,
                            name: `${dr.first_name} ${dr.last_name}`
                        };
                    });

                this.total = meta.pagination.total;
                this.totalPages = meta.pagination.total_pages;
                this.myList = data;

                // this.displayList = this.myList;
                this.displayList = data; // this.myList;
                this.setCurrentList();
                this.sortType.asObservable().subscribe(v => this.sortList(v, this.sortAsc.getValue()));
                this.sortAsc.asObservable().subscribe(v => this.sortList(this.sortType.getValue(), v));

                this.filterMyTherapistsLoading = false;

                this.loaderService.loader.next(false);
            });
    }

    getPatients() {
        let params = {
            with: 'bookings.patient',
            // search: 'status:active',
            limit: this.perPage,
            orderBy: this.orderBy,
            page: this.currentPage,
            sortedBy: (this.sortAsc.getValue()) ? 'asc' : 'desc',
        };

        if (this.searchTerm && this.searchTerm.length) {
            params['search'] = this.searchTerm;
        }

        this.secretaryService.getPatients(params)
            .subscribe((res) => {
                const { data, meta } = res;

                this.total = meta.total;
                this.totalPages = meta.total_pages;
                this.myList = data;

                this.displayList = data; // this.myList;
                this.setCurrentList();
                this.sortType.asObservable().subscribe(v => this.sortList(v, this.sortAsc.getValue()));
                this.sortAsc.asObservable().subscribe(v => this.sortList(this.sortType.getValue(), v));
                
                this.loaderService.loader.next(false);
            });
    }

    doFilterMyTherapists() {
        this.filterMyTherapists = !this.filterMyTherapists;
        this.getAppointments();
    }

    startSearch() {
        // let term = this.searchTerm;
        this.currentPage = 1;
        this.getList();
    }

    setSort() {
        this.sortAsc.next(!this.sortAsc.getValue())
        this.getList();
    }

    sortAlphabetical() {
        this.orderBy = 'last_name';
        this.getList();
    }

    sortMostRecent() {
        this.orderBy = 'created_at';
        this.getList();
    }


    searchList() {
        const term = this.searchTerm;
        this.displayList = this.myList.filter(therapist => {
            let valid;
            Object.keys(therapist).forEach(key => {
                const obj = therapist[key];
                if (typeof obj === 'string') {
                    valid = true;
                }
            });

            return valid;
        });

        this.setCurrentList();
    }

    sortList(sortType: SortType, sortAsc: boolean) {
        switch (sortType) {
            case SortType.Alphabetical:
                this.displayList = this.myList.sort((AA, BB) => {
                    const a = AA['name'];
                    const b = BB['name'];
                    if (a === b) {
                        return 0;
                    }
                    if (typeof a === typeof b) {
                        return a < b ? -1 : 1;
                    }
                    return typeof a < typeof b ? -1 : 1;
                });
                this.sortClass = sortAsc ? faSortAlphaUp : faSortAlphaDown;
                break;
            case SortType.MostRecent:
                // console.log('Most recent sort');
                this.sortClass = sortAsc ? faSortNumericUp : faSortNumericDown;
                break;
        }

        if (!sortAsc) {
            this.displayList = this.displayList.reverse();
        }

        this.setCurrentList();
    }

    pageChanged(page): void {
        this.currentPage = page.page;
        this.getList();

        // const startItem = (event.page - 1) * event.itemsPerPage;
        // const endItem = event.page * event.itemsPerPage;
        // this.currentList = this.pagedList.slice(startItem, endItem);
    }

    setCurrentList(): void {
        // this.currentPage = 1;
        this.pagedList = this.displayList;
        this.currentList = this.pagedList.slice(0, this.perPage);
    }

    isPsychiatrist(role:any) {


        // console.log(this.isPatient);
        if (role !== undefined && role.doctorRole == 'psychologist') {
            return false;
        }

        return true;
    }

    onKeySearch(event: any) 
    {
        if ( event.which === 13 ) {
            this.startSearch();
        }
        this.searchTerm = event.target.value;
    }

}
