<div *ngIf="match"> 
    <form #simpleForm="ngForm" (ngSubmit)="onSubmit(simpleForm.value)" action="" id="medicalAbstractForm">
  
        <div *ngIf="iAmDoctor() && !iAmPsychologist()">
            <div class="row mb-2">
                <div class="col-sm-6" *ngFor="let key of Object.keys(sections);">
                    <label 
                        for="item.key" 
                        [innerHTML]="sections[key]" 
                        class="mb-0"
                    ></label>
                    <textarea 
                        [ngModel]="getValue(key)"
                        class="form-control m-0" 
                        [id]="key" 
                        rows="2" 
                        [name]="key"
                    ></textarea>
                </div>
            </div>
            

        </div>
        <div *ngIf="iAmPsychologist()">
            <textarea 
                [ngModel]="getValue('General Data')"
                class="form-control m-0" 
                id="general_data" 
                rows="2" 
                name="general_data"
                [value]="value.general_data"
            ></textarea>
        </div>

        <div class="row">
            <div class="col text-right">
                <button class="btn btn-primary btn-sm" >Save<i *ngIf="saving" class="fa fa-spinner fa-spin"></i></button>
            </div>
        </div>
    </form>
</div>
