<div *ngIf="match">
    <h6 class="text-muted">{{title}}</h6>


    <!-- 
        ONLY pysch roles can use DSM5.
        the rest uses plain text
    -->
    <div *ngIf="iAmPsychRole()">
        <div *ngFor="let dsm of  dsmList; let i = index">
            <pre>Diagnosis #{{i+1}}: 
                <span *ngIf="dsm.levels">
                    <span *ngFor="let d of dsm.levels; let dsmIndex = index">
                        <strong>{{d.name}}</strong>{{dsmIndex < dsm.levels.length -1 ? ' > ' : ''}}
                    </span> 
                </span>
                <span *ngIf="!dsm.levels">
                    <strong>{{dsm.name}}</strong>
                </span>
            </pre>
            <!-- [{{ viewEvent.notes.dsmnotes.length }}] [{{i+1}}] -->
        </div>

            <!-- <div *ngIf="value.viewEvent.notes.custom_diagnosis && value.viewEvent.notes.custom_diagnosis.length">
                Diagnosis #{{value.viewEvent.notes.dsmnotes.length+1}}:
                <strong>{{value.viewEvent.notes.custom_diagnosis[0].name }}</strong>
            </div> -->

        <!-- <div *ngIf="!(value.viewEvent && value.viewEvent.notes)"> -->
        <div *ngIf="!dsmList.length && !customDsmList.length">
            <pre [innerHTML]="value"></pre>
        </div>
    </div>


    <pre *ngIf="!iAmPsychRole()" [innerHTML]="value"></pre>
</div>

