import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class OrderService extends AbstractService {

    endpoint = environment.serverUrl + 'cart/orders';

    constructor(http: HttpClient) {
        super(http);
    }


    getItem(query){
        
        let sq = this.buildQueryString(query);

        return this.http.get(this.endpoint + sq);
    }

    processOrder(params) {
        return this.http.post(this.endpoint + 'status', params)
          .map((result: any) => {
              return result;
          }, (err: any) => {
              return err;
          });
    }
    
}
