import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'section-admitting-order',
    templateUrl: './admitting-order.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionAdmittingOrderComponent implements OnChanges {
    @Input() data: object
    @Input() eventId

    title = 'Admitting Order';
    value = '<em class="text-muted">Not specified</em>'
    match = true
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private noteService: NotesService
    ) {}

    downloadPDF = () => {
        this.noteService.genReport('admitting', this.eventId)
        .subscribe(
            response => downloadPDFFile(response),
            e => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue &&
            changes.data.currentValue.notes && 
            typeof changes.data.currentValue.notes.admittingOrder === 'string'
        ) {
            if (changes.data.currentValue.notes.admittingOrder.length) {
                this.value = changes.data.currentValue.notes.admittingOrder
            }

            if (iAmDoctor()) {
                this.title = (!iAmPsychologist()) ? 'Admitting Order' : 'Referral Letter'
            }
        }
    }

}
