import { RouterModule, Routes, CanActivate } from "@angular/router";
import { NgModule } from "@angular/core";

import { Route } from "../../core/route-dashboard.service";
import { extract } from "../../core/i18n.service";

import { CheckStaffRoleGuard } from "../../core/authentication/guards/check-staff-role.guard";
import { AvailabilityComponent } from "./availability/availability.component";
import { AvailabilitySecComponent } from './secretary/availability/availability.component';
import { MyPrescriptionComponent } from "./my-prescription/my-prescription.component";
import { MyListingComponent } from "./listing/listing.component";
import { MyListComponent } from "./listing/list/list.component";
import { MyProfileComponent } from "./listing/profile/profile.component";

import { FaqComponent } from "./faq/faq.component";
import { DoctorsModule } from "./doctors/doctors.module";
// import { MyPsychologistComponent } from './psychologist/psychologist.component';
// import { PsychologistListComponent } from './psychologist/list/list.component';
// import { PsychologistProfileComponent } from './psychologist/profile/profile.component';

import { NotPatientGuard } from "../../core/authentication/guards/not-patient.guard";
import { ListSecComponent } from './secretary/list/list.component';
import { MyListingSecComponent } from './secretary/listing/listing.component';
import { MyProfileSecComponent } from './secretary/listing/profile/profile.component';
import { MyListSecComponent } from './secretary/listing/list/list.component';
import { IsSecretaryGuard } from '../../core/authentication/guards/is-secretary.guard';
import { DataSheetSecComponent } from './secretary/listing/data-sheet/data-sheet.component';

const routes: Routes = Route.withShell(
    [
        {
            path: "me",
            data: { title: extract("Dashboard") },
            children: [
                {
                    path: "",
                    loadChildren: "./dhome/dhome.module#DhomeModule",
                    data: { title: extract("Dashboard") },
                },
                {
                    path: "account",
                    loadChildren: "./my-account/my-account.module#MyAccountModule",
                    data: { title: extract("Dashboard :: My Account") },
                },
                {
                    path: "appointments",
                    loadChildren: "./appointments/appointments.module#AppointmentsModule",
                    data: { title: extract("Dashboard :: Appointments") },
                },
                {
                    path: "availability",
                    component: AvailabilityComponent,
                    canActivate: [NotPatientGuard],
                },

                {
                    path: "secretary/availability",
                    component: AvailabilitySecComponent,
                    canActivate: [IsSecretaryGuard],
                },

                {
                    path: "secretary/appointments",
                    component: ListSecComponent,
                    data: { title: extract("Dashboard :: Doctor Appointments") },
                    canActivate: [IsSecretaryGuard],
                },
                {
                    path: "prescriptions",
                    loadChildren: "./my-prescription/my-prescription.module#MyPrescriptionModule",
                    data: { title: extract("Dashboard :: My Prescriptions") },
                },

                // {
                //   path: 'psychiatrists',
                //   component: MyListingComponent,
                //   data: {title: 'Psychiatrists'},
                //   children: [
                //       {
                //           path: '',
                //           component: MyListComponent,
                //       },
                //       {
                //         path: ':id',
                //         component: MyProfileComponent,
                //       },
                //   ]
                // },
                // {
                //   path: 'psychologist',
                //   component: MyPsychologistComponent,
                //   data: {title: 'Psychologist'},
                //   children: [
                //       {
                //           path: '',
                //           component: PsychologistListComponent,
                //       },
                //       {
                //         path: ':id',
                //         component: PsychologistProfileComponent,
                //       },
                //   ]
                // },
                {
                    path: "patients",
                    component: MyListingComponent,
                    data: { title: "My Patients" },
                    children: [
                        {
                            path: "",
                            component: MyListComponent,
                        },
                        {
                            path: ":id",
                            component: MyProfileComponent,
                        },
                    ],
                },

                {
                    path: "secretary/patients",
                    component: MyListingSecComponent,
                    data: { title: "Doctor Patients" },
                    children: [
                        {
                            path: "",
                            component: MyListSecComponent,
                            canActivate: [IsSecretaryGuard],
                        },
                        {
                            path: ":id",
                            component: MyProfileSecComponent,
                            canActivate: [NotPatientGuard],
                        },
                    ],
                },

                {
                    path: "fees",
                    loadChildren: "./fees/fees.module#FeesModule",
                    data: { title: extract("Dashboard :: Fees") },
                },

                {
                    path: "addons",
                    loadChildren: "./fees-addons/fees-addons.module#FeesAddonsModule",
                    data: { title: extract("Dashboard :: Fees") },
                },
                {
                    path: "files",
                    data: { title: "My Patients" },
                    loadChildren: "./deliver-my-medicines/deliver-my-medicines.module#DeliverMyMedicinesModule",
                },
            ],
            canActivate: [CheckStaffRoleGuard],
        },
        {
            path: "purchase",
            loadChildren: "./purchase/purchase.module#PurchaseModule",
        },
        {
            path: "doctors",
            loadChildren: "./doctors/doctors.module#DoctorsModule",
            // children: [
            //   {
            //       path: '',

            //       data: { title: extract('Dashboard')  },
            //   },
            //   {
            //     path: ':role',
            //     loadChildren: './doctors/doctors.module#DoctorsModule',
            //     data: { title: extract('Dashboard')  },
            // },
            // ],
        },
    ],
    "dashboard"
);

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
