import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';

@Injectable()
export class RateChargeService extends AbstractService {

    endpoint = environment.serverUrl + 'rate-charges';

}
