import { OnInit, Component } from '@angular/core';

enum SortType {
    Alphabetical,
    MostRecent,
}

@Component({
    selector: 'app-my-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss']
})
export class MyListingComponent implements OnInit {
    location: string;
    constructor() {}
    ngOnInit() {}

    componentAdded(component: any) {
        this.location = component.location;
    }
}
