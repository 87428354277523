import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core'
import { faVideo, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-consultation-type',
  templateUrl: './consultation-type.component.html',
  styleUrls: ['./consultation-type.component.scss'],
})

export class ConsultationTypeComponent implements OnInit, OnChanges {

  @Input() slotDetails
  @Output() selectType: EventEmitter<any> = new EventEmitter()
  selectedType = null
  faVideo = faVideo
  faUsers = faUsers
  
  _selectType = (type) => {
    this.selectedType = type
    this.selectType.emit(type)
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slotDetails) {
      const type = changes.slotDetails.currentValue.type
      if (type != this.selectedType) {
        this._selectType(type)
      }
    }
  }

  constructor() { }
}
