import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

// import { DhomeRoutingModule } from './dhome-routing.module';
// import { DhomeComponent } from './dhome.component';
import { SecretaryComponent } from './secretary.component';
import { ListSecComponent } from './list/list.component';
import { AvailabilityModule } from './availability/availability.module';
import { SlotModule } from './availability/slot/slot.module'
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { MyProfileSecComponent } from './listing/profile/profile.component';
import { MyListingSecComponent } from './listing/listing.component';
import { MyListSecComponent } from './listing/list/list.component';
import { AppRoutingModule } from '../../../app-routing.module';
import { SharedModule } from '../../../shared/shared.module';
import { DashboardRoutingModule } from '../dashboard-routing.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DataSheetSecComponent } from './listing/data-sheet/data-sheet.component';
import { DrServicesComponent } from './list/addons/dr-services/dr-services.component';
import { DoctorAppointmentService } from '../../../services';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
    dayGridPlugin,
    interactionPlugin
]);
@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        SlotModule,
        AvailabilityModule,
        FullCalendarModule,
        NgbModule,
        TypeaheadModule.forRoot(),
        PaginationModule.forRoot(),
        NgbTypeaheadModule,
        DashboardRoutingModule,
        SharedModule,
        TabsModule

        // DhomeRoutingModule,
        // SharedModule
    ],
    declarations: [
        SecretaryComponent,
        ListSecComponent,
        MyProfileSecComponent,
        MyListingSecComponent,
        MyListSecComponent,
        DataSheetSecComponent,
        DrServicesComponent,
    ],
    providers: [
        DoctorAppointmentService
    ]
})
export class SecretaryModule { }
