import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { UserService } from '../../../../../services/user.service';
import { RoleService } from '../../../../../services/role.service';

// STEP 4: IMPORT form
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { USER_MODEL, ROLES_MODEL, ERRORS } from '../../user.model';

// STEP 5: IMPORT settings
import { settings } from '../../settings';
import { forEach } from '@angular/router/esm2015/src/utils/collection';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent implements OnInit {
    public formType = 'edit';

    errors = ERRORS;
    formRoles: FormGroup;
    status : any;

    private roleService: RoleService;
    private roles: Array<any> = [];
    private optionsMap = {
        OptionA: false,
        OptionB: false,
        OptionC: false,
    };
    private user: any;
    public vcr: any;

    constructor(injector: Injector, vcr: ViewContainerRef, private toastr: ToastrManager) {
        super(injector);
        this.service = injector.get(UserService);
        this.roleService = injector.get(RoleService);
        this.setSettings(settings);
        this.vcr = vcr;

        this.formRoles = this.formBuilder.group({
            'roles': this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.rForm = this.formBuilder.group(USER_MODEL, { updateOn: 'blur' });

        this.roleService.index().subscribe(
            (result: any) => {
                this.roles = result.data;
            }
        );


        this._load({ mode: 'complete' }).subscribe((result: any) => {
            this.user = result.data;
            Object.keys(USER_MODEL).forEach(key => this.rForm.get(key).setValue(result.data[key]));

            this.user.roles.data.forEach((role: any) => {
                this.onRoleCheckboxChange(role.id, true);
            });
        });
    }


    onRoleCheckboxChange(roleId: string, isChecked: boolean) {
        const rolesFormArray = <FormArray>this.formRoles.controls.roles;

        if (isChecked) {
            rolesFormArray.push(new FormControl(roleId));
        } else {
            const index = rolesFormArray.controls.findIndex(x => x.value === roleId);
            rolesFormArray.removeAt(index);
        }
    }

    isChecked(roleId: any) {
        const a = this.user.roles.data.filter((role: any) => {
            return roleId === role.id;
        });

        if (a.length) {
            return true;
        }
        return false;
    }


    doUpdateRoles() {
        this.setStatus();
        this.service.setRoles(this.objectId, this.formRoles.value)
            .subscribe((result: any) => {
                this.toastr.successToastr('Data saving successful.');
                this.rForm.reset();
                this.doPostSave(event);
            },
                (err: any) => {
                    this.toastr.errorToastr(err.error.message || 'Something went wrong.');
                });
    }

    setStatus() {
        this.service.setStatus(this.objectId, this.rForm.value)
            .subscribe((result: any) => {

                this.rForm.reset();
                this.doPostSave(event);
            },
                (err: any) => {
                    console.log(err);
                });
    }
}
