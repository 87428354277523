import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ng6-toastr-notifications';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoteComponent } from './components/notes/note.component';
import { NgxEditorModule } from 'ngx-editor';
import { MedicationTableComponent } from './components/medication-table/medication-table.component';
import { ChatComponent } from './components/chat/chat.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { CalendarComponent } from './components/calendar.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NextMeetingComponent } from './components/next-meeting/next-meeting';
import { AvailabilityService, MentalHealthSheetService, UserService, } from '../services';
import { DataSheetComponent } from './components/data-sheet/data-sheet.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MentalHealthSheetServiceV2 } from '../services/v2/mental-health-sheet-service';

@NgModule({
    imports: [
        CommonModule,
        ToastrModule.forRoot(),
        AccordionModule,
        FormsModule,
        ReactiveFormsModule,
        NgxEditorModule,
        TypeaheadModule,
        TimepickerModule,
        FontAwesomeModule,
        BsDatepickerModule.forRoot(),
        SelectDropDownModule
    ],
    declarations: [
        NoteComponent,
        MedicationTableComponent,
        ChatComponent,
        FormErrorComponent,
        CalendarComponent,
        NextMeetingComponent,
        DataSheetComponent
    ],
    exports: [
        NoteComponent,
        MedicationTableComponent,
        ChatComponent,
        FormErrorComponent,
        CalendarComponent,
        NextMeetingComponent,
        DataSheetComponent
    ],
    providers: [
        AvailabilityService,
        MentalHealthSheetService,
        MentalHealthSheetServiceV2
    ]
})
export class SharedModule { }
