import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../authentication/authentication.service';
import { I18nService } from '../../i18n.service';
import { environment } from '../../../../environments/environment';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    menuHidden = true;

    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        private i18nService: I18nService) { }

    ngOnInit() { }

    toggleMenu() {
        this.menuHidden = !this.menuHidden;
    }

    setLanguage(language: string) {
        this.i18nService.language = language;
    }

    logout() {
        this.authenticationService.logout()
            .subscribe(() => {
                window.location.href = environment.BASE_URL + 'login';
            });
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }
}
