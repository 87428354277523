import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ERROR_MAP } from './form-errors.model';

@Component({
    selector: 'app-errors',
    template:
        `
            <div *ngIf="control && control.touched && control.invalid">
                <small *ngFor="let e of getErrors()" class="form-text text-danger">
                    {{errorMap[e]}}
                </small>
            </div>
        `
})
export class FormErrorComponent {
    @Input() public control: AbstractControl;

    errorMap = ERROR_MAP;

    constructor() { }

    getErrors() {
        return Object.keys(this.control.errors);
    }
}
