import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { VideoCallComponent } from './video-call.component';
import { OpenTokService, DiagnosisDSM5Service } from '../../services';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '../../shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxEditorModule } from 'ngx-editor';
import { VideoCallRoutingModule } from './video-call-routing.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FeedbackFormModule } from '../feedback/feedback.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { schema } from 'ngx-editor';
import { placeholder } from 'ngx-editor/plugins';
import { VideoCallV2Component } from './video-call-v2.component';
// NgxEditorModule.forRoot({
//   schema, // optional scheama, see https://sibiraj-s.github.io/ngx-editor/#/schema
//   plugins: [
//     // include other prosemirror plugins
//     placeholder('Type something here...'), // default
//   ],
// //   menu: [
// //     // default options (Optional)
// //     ['bold', 'italic'],
// //     ['underline', 'strike'],
// //     ['code', 'blockquote'],
// //     ['ordered_list', 'bullet_list'],
// //     [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
// //     ['link', 'image'],
// //     ['text_color', 'background_color'],
// //     ['align_left', 'align_center', 'align_right', 'align_justify'],
// //   ],
// });

@NgModule({
    imports: [
        VideoCallRoutingModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        SharedModule,
        BsDatepickerModule.forRoot(),
        NgxEditorModule,
        TabsModule,
        TimepickerModule,
        FontAwesomeModule,
        TypeaheadModule,
        FeedbackFormModule,
        NgbModule,
    ],
    declarations: [
        VideoCallComponent,
        VideoCallV2Component
    ],
    exports: [
    ],
    providers: [
        OpenTokService,
        DiagnosisDSM5Service,
    ]
})
export class VideoCallModule { }
