import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { AbstractService } from "../abstract.service";
import { map } from 'rxjs/operators';
import { SECTION_KEYS } from '../../shared/components/data-sheet/data-sheet';

@Injectable()
export class MentalHealthSheetServiceV2 {
    endpoint = environment.serverUrl + "mental-health-data";

    headers = {
        "Access-Control-Allow-Origin": "*",
        Accept: environment.API_HEADER_ACCEPT_v2,
    };

    constructor(public http: HttpClient) {}

    update(body: any, section: number) {
        return this.http
            .put(`${this.endpoint}/section/${section}`, body, { headers: this.headers })
            .pipe(map((response: any) => response.data));
    }
}
