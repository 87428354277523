import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { GenericNamesService } from '../../../../../services/generic-names.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { GENERIC_NAMES_MODEL, ROLES_MODEL, ERRORS } from '../../generic-names.model';

@Component({
  selector: 'app-create',
  templateUrl: './../edit/edit.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent extends CrudEditComponent {

  formType = 'create';

  errors = ERRORS;
  formRoles: FormGroup;

  genericList: any[];

  private roles: Array<any> = [];

  public vcr: any;

  constructor(
    injector: Injector, 
    vcr: ViewContainerRef,
  ) {
    super(injector)
    this.service = injector.get(GenericNamesService);
    this.setSettings(settings);
    this.vcr = vcr;

    this.formRoles = this.formBuilder.group({
      'roles': this.formBuilder.array([])
    });

  }

  ngOnInit() {
    this.rForm    = this.formBuilder.group(GENERIC_NAMES_MODEL, { updateOn: 'blur' })

  }

}
