import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PrescriptionFlagUpdateService {

    endpoint = environment.serverUrl + 'event-prescription/flag';

    constructor(private http: HttpClient) { }

    updateFlagDeliver(prescription_id: any, flag: any) {
        const data = { prescription_id, flag };
        return this.http.post(this.endpoint, data);
    }

    // removeCoupon(code: string) {
    //     return this.http.post(this.endpoint + 'remove', { code });
    // }
    
}
