import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router, UrlSegment, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';

import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';


@Component({
    selector: 'app-crud',
    templateUrl: './crud.component.html',
    styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit, OnDestroy {


    public objectId: any;
    public settings: any;
    public router: Router;
    public activatedRoute: ActivatedRoute;

    public displayedColumns: any = [];
    public service: any;

    public loading: Boolean = false;

    constructor(private injector: Injector) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
    }

    ngOnInit() { }

    ngOnDestroy() {
        // console.log('component destroyed');
        // this.activatedRoute.unsubscribe();
    }

    /** DEPRECATE */
    loadExtJS() {

        let isFound = false;
        const scripts = document.getElementsByTagName('script');
        for (let i = 0; i < scripts.length; ++i) {
            if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes('loader')) {
                isFound = true;
            }
        }
    }


    getLoadingStatus() {
        return this.loading;
    }

    loadingStatus(status?: any) {
        if (status) {
            this.loading = status;
        } else {
            this.loading = !this.loading;
        }
    }

    setSettings(settings: any) {
        this.settings = settings;
    }

    /** DEPRECATE */
    _updateModel(data: any, formService: any, formModel: any) {
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            // let input = formService.findById(key, formModel) as DynamicInputModel;
        }
    }

}
