import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class PrescriptionTransactionService extends AbstractService {

    endpoint = environment.serverUrl + 'prescription-transactions';

    constructor(http: HttpClient) {
        super(http);
    }


    addTransaction(data: any) { 
        return this.http.post( this.endpoint, { data } );
    }

    updateStatus(id: any, status: any) {
        return this.http.get(this.endpoint + '/status/' + id + '/' + status)
            .map(
                (response: any) => {
                    // console.log(response);
                    return response;
                },
                (err: any) => {
                    // console.log(err);
                    return err;
                });
    }

    getItemsByTransaction(query){
        
        let sq = this.buildQueryString(query);

        return this.http.get(this.endpoint + sq);
    }
    
}
