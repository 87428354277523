import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    email: string;
    emailSent = false;
    year = new Date();

    hasParams = false;
    hasReset = false;

    params: {
        email: string,
        token: string,
    };

    constructor(
        private userService: UserService,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrManager
    ) {}

    ngOnInit() {
        this.activatedRoute.params.subscribe(({email, token}) => {
            if (email && token) {
                this.hasParams = true;
                this.params = {email, token};
                // console.log(this.params);
            }
        });
    }

    requestReset({email}: any) {
        this.email = email;
        this.userService.passwordResetRequest(email)
            .subscribe(response => this.emailSent = response.status === 200,
                error => {
                    if(error.error.message == 'inactive') {
                        this.toastr.errorToastr('Please verify your email.', 'User is inactive')
                    } else {
                        this.toastr.errorToastr('That email address does not exist in our records.', 'Email address not found!')
                    }
                });
    }

    resetPassword({password, password_confirmation}: any) {
        const payload = {
            password,
            password_confirmation,
            token: this.params.token,
            email: this.params.email
        };

        this.userService.passwordReset(payload)
            .subscribe(
                response => this.hasReset = response.status === 200,
                error => {
                    this.toastr.errorToastr('Please verify if you have the correct and recent reset link from your email',  error.error.message)
                });
    }
}
