<div class="fixed-top">
    <app-header></app-header>
</div>

<div class="container-fluid mt-5 pt-2">
    <div class="row">
        <div class="col-sm-3 col-md-2 bg-dark py-2 px-0 mx-0 fixed-top sidebar-container" style="height: 100vh;">
            <app-sidebar></app-sidebar>
        </div>
        <main class="col-sm-9 ml-sm-auto col-md-10 pt-3">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>
