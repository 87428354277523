import { StorageType, StorageService } from "./storage.service";
import { environment } from "../../environments/environment";
import { EmailValidator } from "@angular/forms";
import moment from "moment";

// import { env } from 'process';

const store = StorageService.storage(StorageType.LOCAL);
const storedCredentials = JSON.parse(store.getItem("credentials"));

export const listProvinces = () => {
    const l = [
        "Abra",
        "Apayao",
        "Benguet",
        "Ifugao",
        "Kalinga",
        "Mountain Province",
        "Ilocos Norte",
        "Ilocos Sur",
        "La Union",
        "Pangasinan",
        "Batanes",
        "Cagayan",
        "Isabela",
        "Nueva Vizcaya",
        "Quirino",
        "Aurora",
        "Bataan",
        "Bulacan",
        "Nueva Ecija",
        "Pampanga",
        "Tarlac",
        "Zambales",
        "Batangas",
        "Cavite",
        "Laguna",
        "Quezon",
        "Rizal",
        "Marinduque",
        "Occidental Mindoro",
        "Oriental Mindoro",
        "Palawan",
        "Romblon",
        "Albay",
        "Camarines Norte",
        "Camarines Sur",
        "Catanduanes",
        "Masbate",
        "Sorsogon",
        "Aklan",
        "Antique",
        "Capiz",
        "Guimaras",
        "Iloilo",
        "Negros Occidental",
        "Bohol",
        "Cebu",
        "Negros Oriental",
        "Siquijor",
        "Biliran",
        "Eastern Samar",
        "Leyte",
        "Northern Samar",
        "Samar",
        "Southern Leyte",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibugay",
        "Bukidnon",
        "Camiguin",
        "Lanao del Norte",
        "Misamis Occidental",
        "Misamis Oriental",
        "Compostela Valley",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Cotabato",
        "Sarangani",
        "South Cotabato",
        "Sultan Kudarat",
        "Agusan del Norte",
        "Agusan del Sur",
        "Dinagat Islands",
        "Surigao del Norte",
        "Surigao del Sur",
        "Basilan",
        "Lanao del Sur",
        "Maguindanao",
        "Sulu",
        "Tawi-Tawi",
        "Metro Manila",
    ];

    return l.sort();
};


export const listCountries = () => {
    // All countries
// length 252
const country = [
    {name: "Afghanistan",code: "AF"},
    {name: "Åland Islands",code: "AX"},
    {name: "Albania",code: "AL"},
    {name: "Algeria",code: "DZ"},
    {name: "American Samoa",code: "AS"},
    {name: "Andorra",code: "AD"},
    {name: "Angola",code: "AO"},
    {name: "Anguilla",code: "AI"},
    {name: "Antarctica",code: "AQ"},
    {name: "Antigua & Barbuda",code: "AG"},
    {name: "Argentina",code: "AR"},
    {name: "Armenia",code: "AM"},
    {name: "Aruba",code: "AW"},
    {name: "Australia",code: "AU"},
    {name: "Austria",code: "AT"},
    {name: "Azerbaijan",code: "AZ"},
    {name: "Bahamas",code: "BS"},
    {name: "Bahrain",code: "BH"},
    {name: "Bangladesh",code: "BD"},
    {name: "Barbados",code: "BB"},
    {name: "Belarus",code: "BY"},
    {name: "Belgium",code: "BE"},
    {name: "Belize",code: "BZ"},
    {name: "Benin",code: "BJ"},
    {name: "Bermuda",code: "BM"},
    {name: "Bhutan",code: "BT"},
    {name: "Bolivia",code: "BO"},
    {name: "Caribbean Netherlands",code: "BQ"},
    {name: "Bosnia & Herzegovina",code: "BA"},
    {name: "Botswana",code: "BW"},
    {name: "Bouvet Island",code: "BV"},
    {name: "Brazil",code: "BR"},
    {name: "British Indian Ocean Territory",code: "IO"},
    {name: "Brunei",code: "BN"},
    {name: "Bulgaria",code: "BG"},
    {name: "Burkina Faso",code: "BF"},
    {name: "Burundi",code: "BI"},
    {name: "Cambodia",code: "KH"},
    {name: "Cameroon",code: "CM"},
    {name: "Canada",code: "CA"},
    {name: "Cape Verde",code: "CV"},
    {name: "Cayman Islands",code: "KY"},
    {name: "Central African Republic",code: "CF"},
    {name: "Chad",code: "TD"},
    {name: "Chile",code: "CL"},
    {name: "China",code: "CN"},
    {name: "Christmas Island",code: "CX"},
    {name: "Cocos (Keeling) Islands",code: "CC"},
    {name: "Colombia",code: "CO"},
    {name: "Comoros",code: "KM"},
    {name: "Congo - Brazzaville",code: "CG"},
    {name: "Congo - Kinshasa",code: "CD"},
    {name: "Cook Islands",code: "CK"},
    {name: "Costa Rica",code: "CR"},
    {name: "Côte d’Ivoire",code: "CI"},
    {name: "Croatia",code: "HR"},
    {name: "Cuba",code: "CU"},
    {name: "Curaçao",code: "CW"},
    {name: "Cyprus",code: "CY"},
    {name: "Czechia",code: "CZ"},
    {name: "Denmark",code: "DK"},
    {name: "Djibouti",code: "DJ"},
    {name: "Dominica",code: "DM"},
    {name: "Dominican Republic",code: "DO"},
    {name: "Ecuador",code: "EC"},
    {name: "Egypt",code: "EG"},
    {name: "El Salvador",code: "SV"},
    {name: "Equatorial Guinea",code: "GQ"},
    {name: "Eritrea",code: "ER"},
    {name: "Estonia",code: "EE"},
    {name: "Ethiopia",code: "ET"},
    {name: "Falkland Islands (Islas Malvinas)",code: "FK"},
    {name: "Faroe Islands",code: "FO"},
    {name: "Fiji",code: "FJ"},
    {name: "Finland",code: "FI"},
    {name: "France",code: "FR"},
    {name: "French Guiana",code: "GF"},
    {name: "French Polynesia",code: "PF"},
    {name: "French Southern Territories",code: "TF"},
    {name: "Gabon",code: "GA"},
    {name: "Gambia",code: "GM"},
    {name: "Georgia",code: "GE"},
    {name: "Germany",code: "DE"},
    {name: "Ghana",code: "GH"},
    {name: "Gibraltar",code: "GI"},
    {name: "Greece",code: "GR"},
    {name: "Greenland",code: "GL"},
    {name: "Grenada",code: "GD"},
    {name: "Guadeloupe",code: "GP"},
    {name: "Guam",code: "GU"},
    {name: "Guatemala",code: "GT"},
    {name: "Guernsey",code: "GG"},
    {name: "Guinea",code: "GN"},
    {name: "Guinea-Bissau",code: "GW"},
    {name: "Guyana",code: "GY"},
    {name: "Haiti",code: "HT"},
    {name: "Heard & McDonald Islands",code: "HM"},
    {name: "Vatican City",code: "VA"},
    {name: "Honduras",code: "HN"},
    {name: "Hong Kong",code: "HK"},
    {name: "Hungary",code: "HU"},
    {name: "Iceland",code: "IS"},
    {name: "India",code: "IN"},
    {name: "Indonesia",code: "ID"},
    {name: "Iran",code: "IR"},
    {name: "Iraq",code: "IQ"},
    {name: "Ireland",code: "IE"},
    {name: "Isle of Man",code: "IM"},
    {name: "Israel",code: "IL"},
    {name: "Italy",code: "IT"},
    {name: "Jamaica",code: "JM"},
    {name: "Japan",code: "JP"},
    {name: "Jersey",code: "JE"},
    {name: "Jordan",code: "JO"},
    {name: "Kazakhstan",code: "KZ"},
    {name: "Kenya",code: "KE"},
    {name: "Kiribati",code: "KI"},
    {name: "North Korea",code: "KP"},
    {name: "South Korea",code: "KR"},
    {name: "Kosovo",code: "XK"},
    {name: "Kuwait",code: "KW"},
    {name: "Kyrgyzstan",code: "KG"},
    {name: "Laos",code: "LA"},
    {name: "Latvia",code: "LV"},
    {name: "Lebanon",code: "LB"},
    {name: "Lesotho",code: "LS"},
    {name: "Liberia",code: "LR"},
    {name: "Libya",code: "LY"},
    {name: "Liechtenstein",code: "LI"},
    {name: "Lithuania",code: "LT"},
    {name: "Luxembourg",code: "LU"},
    {name: "Macao",code: "MO"},
    {name: "North Macedonia",code: "MK"},
    {name: "Madagascar",code: "MG"},
    {name: "Malawi",code: "MW"},
    {name: "Malaysia",code: "MY"},
    {name: "Maldives",code: "MV"},
    {name: "Mali",code: "ML"},
    {name: "Malta",code: "MT"},
    {name: "Marshall Islands",code: "MH"},
    {name: "Martinique",code: "MQ"},
    {name: "Mauritania",code: "MR"},
    {name: "Mauritius",code: "MU"},
    {name: "Mayotte",code: "YT"},
    {name: "Mexico",code: "MX"},
    {name: "Micronesia",code: "FM"},
    {name: "Moldova",code: "MD"},
    {name: "Monaco",code: "MC"},
    {name: "Mongolia",code: "MN"},
    {name: "Montenegro",code: "ME"},
    {name: "Montserrat",code: "MS"},
    {name: "Morocco",code: "MA"},
    {name: "Mozambique",code: "MZ"},
    {name: "Myanmar (Burma)",code: "MM"},
    {name: "Namibia",code: "NA"},
    {name: "Nauru",code: "NR"},
    {name: "Nepal",code: "NP"},
    {name: "Netherlands",code: "NL"},
    {name: "Curaçao",code: "AN"},
    {name: "New Caledonia",code: "NC"},
    {name: "New Zealand",code: "NZ"},
    {name: "Nicaragua",code: "NI"},
    {name: "Niger",code: "NE"},
    {name: "Nigeria",code: "NG"},
    {name: "Niue",code: "NU"},
    {name: "Norfolk Island",code: "NF"},
    {name: "Northern Mariana Islands",code: "MP"},
    {name: "Norway",code: "NO"},
    {name: "Oman",code: "OM"},
    {name: "Pakistan",code: "PK"},
    {name: "Palau",code: "PW"},
    {name: "Palestine",code: "PS"},
    {name: "Panama",code: "PA"},
    {name: "Papua New Guinea",code: "PG"},
    {name: "Paraguay",code: "PY"},
    {name: "Peru",code: "PE"},
    {name: "Philippines",code: "PH"},
    {name: "Pitcairn Islands",code: "PN"},
    {name: "Poland",code: "PL"},
    {name: "Portugal",code: "PT"},
    {name: "Puerto Rico",code: "PR"},
    {name: "Qatar",code: "QA"},
    {name: "Réunion",code: "RE"},
    {name: "Romania",code: "RO"},
    {name: "Russia",code: "RU"},
    {name: "Rwanda",code: "RW"},
    {name: "St. Barthélemy",code: "BL"},
    {name: "St. Helena",code: "SH"},
    {name: "St. Kitts & Nevis",code: "KN"},
    {name: "St. Lucia",code: "LC"},
    {name: "St. Martin",code: "MF"},
    {name: "St. Pierre & Miquelon",code: "PM"},
    {name: "St. Vincent & Grenadines",code: "VC"},
    {name: "Samoa",code: "WS"},
    {name: "San Marino",code: "SM"},
    {name: "São Tomé & Príncipe",code: "ST"},
    {name: "Saudi Arabia",code: "SA"},
    {name: "Senegal",code: "SN"},
    {name: "Serbia",code: "RS"},
    {name: "Serbia",code: "CS"},
    {name: "Seychelles",code: "SC"},
    {name: "Sierra Leone",code: "SL"},
    {name: "Singapore",code: "SG"},
    {name: "Sint Maarten",code: "SX"},
    {name: "Slovakia",code: "SK"},
    {name: "Slovenia",code: "SI"},
    {name: "Solomon Islands",code: "SB"},
    {name: "Somalia",code: "SO"},
    {name: "South Africa",code: "ZA"},
    {name: "South Georgia & South Sandwich Islands",code: "GS"},
    {name: "South Sudan",code: "SS"},
    {name: "Spain",code: "ES"},
    {name: "Sri Lanka",code: "LK"},
    {name: "Sudan",code: "SD"},
    {name: "Suriname",code: "SR"},
    {name: "Svalbard & Jan Mayen",code: "SJ"},
    {name: "Eswatini",code: "SZ"},
    {name: "Sweden",code: "SE"},
    {name: "Switzerland",code: "CH"},
    {name: "Syria",code: "SY"},
    {name: "Taiwan",code: "TW"},
    {name: "Tajikistan",code: "TJ"},
    {name: "Tanzania",code: "TZ"},
    {name: "Thailand",code: "TH"},
    {name: "Timor-Leste",code: "TL"},
    {name: "Togo",code: "TG"},
    {name: "Tokelau",code: "TK"},
    {name: "Tonga",code: "TO"},
    {name: "Trinidad & Tobago",code: "TT"},
    {name: "Tunisia",code: "TN"},
    {name: "Turkey",code: "TR"},
    {name: "Turkmenistan",code: "TM"},
    {name: "Turks & Caicos Islands",code: "TC"},
    {name: "Tuvalu",code: "TV"},
    {name: "Uganda",code: "UG"},
    {name: "Ukraine",code: "UA"},
    {name: "United Arab Emirates",code: "AE"},
    {name: "United Kingdom",code: "GB"},
    {name: "United States",code: "US"},
    {name: "U.S. Outlying Islands",code: "UM"},
    {name: "Uruguay",code: "UY"},
    {name: "Uzbekistan",code: "UZ"},
    {name: "Vanuatu",code: "VU"},
    {name: "Venezuela",code: "VE"},
    {name: "Vietnam",code: "VN"},
    {name: "British Virgin Islands",code: "VG"},
    {name: "U.S. Virgin Islands",code: "VI"},
    {name: "Wallis & Futuna",code: "WF"},
    {name: "Western Sahara",code: "EH"},
    {name: "Yemen",code: "YE"},
    {name: "Zambia",code: "ZM"},
    {name: "Zimbabwe",code: "ZW"}
];

    return country.sort();
};


export function ucwords(string) {
    if (typeof string == "string") {
        let str = string.toLowerCase().replace(/\b[a-z]/g, function (letter) {
            return letter.toUpperCase();
        });
        return str;
    }

    return string;
}

export function profilePicture(avatarSet, size = 300, returnDefault = true) {
    if (!avatarSet || !avatarSet[size]) {
        return "../../../assets/images/doctor-placeholder.png";
    }

    return avatarSet[size];
}

export function doctorTitle(title) {
    if (title == "medical-practitioner") {
        return "Psychiatrist";
    } else if (title == "general-practitioner") {
        return "General Practitioner";
    } else if (title == "family-physician") {
        return "Family Physician";
    } else if (title == "ent") {
        return "ENT";
    } else {
        return ucwords(title);
    }
}

export function doctorName(doctor, format = 1) {
    let name = "";

    switch (format) {
        case 1:
        default:
            // prefix first name , last name suffix
            name += doctor.prefix && doctor.prefix.length ? `${doctor.prefix} ` : "";
            name += doctor.first_name && doctor.first_name.length ? `${ucwords(doctor.first_name)} ` : "";
            name += doctor.last_name && doctor.last_name.length ? `${ucwords(doctor.last_name)} ` : "";
            name += doctor.suffix && doctor.suffix.length ? `${doctor.suffix}` : "";
            break;

        case 2:
            name += doctor.first_name && doctor.first_name.length ? `${ucwords(doctor.first_name)} ` : "";
            name += doctor.last_name && doctor.last_name.length ? `${ucwords(doctor.last_name)} ` : "";
            break;

        case 3:
            name += doctor.last_name && doctor.last_name.length ? `${ucwords(doctor.last_name)}, ` : "";
            name += doctor.first_name && doctor.first_name.length ? `${ucwords(doctor.first_name)} ` : "";
            break;
    }

    return name;
}

export function patientName(patient, format = 1) {
    let name = "";

    if (!patient) {
        return name;
    }

    switch (format) {
        case 1:
        default:
            // prefix first name , last name suffix
            name += patient.prefix && patient.prefix.length && patient.prefix ? `${patient.prefix} ` : "";
            name += patient.first_name && patient.first_name.length ? `${ucwords(patient.first_name)} ` : "";
            name += patient.last_name && patient.last_name.length ? `${ucwords(patient.last_name)} ` : "";
            name += patient.suffix && patient.suffix.length ? `${patient.suffix}` : "";
            break;

        case 2:
            name += patient.first_name && patient.first_name.length ? `${ucwords(patient.first_name)} ` : "";
            name += patient.last_name && patient.last_name.length ? `${ucwords(patient.last_name)} ` : "";
            break;

        case 3:
            name += patient.last_name && patient.last_name.length ? `${ucwords(patient.last_name)}, ` : "";
            name += patient.first_name && patient.first_name.length ? `${ucwords(patient.first_name)} ` : "";
            break;
    }

    return name;
}

export function specialization(roles) {
    let newRoles = [];

    roles.forEach((role) => {
        if (role != "dummy" && role != "doctor" && role != "medical-practitioner") {
            newRoles.push(doctorTitle(role));
        }
    });

    return newRoles.join(", ");
}

export function subspecialization(doctor) {
    let subspec = doctor.specialty && doctor.specialty.length ? `${ucwords(doctor.specialty)}` : "";
    return subspec;
}

export function medicalAssociation(medAssoc) {
    if (typeof medAssoc != "string") return "";

    if (typeof medAssoc == "string" && medAssoc.length) {
        if (medAssoc == "fellow") {
            medAssoc = "Fellow Philippine Psychiatric Association";
        } else if (medAssoc == "diplomate") {
            medAssoc = "Diplomate Philippine Psychiatric Association";
        }

        return ucwords(medAssoc);
    }

    return null;
}

/*
 * specific for Psycholigst and pscyhiatrist only
 */
export function isPsychRole(roles) {
    let status = false;

    roles.forEach((role) => {
        let r = role;

        if (role.name) {
            r = role.name;
        }

        if (r == "medical-practitioner" || r == "psychologist" || r == "psychiatrist") {
            status = true;
        }
    });

    return status;
}

export function isPsychologist(drInfo) {
    let isIt = false;

    // For backward compatibility:
    if (drInfo.roles && drInfo.roles.data) {
        drInfo.roles = drInfo.roles.data;
    }

    if(Array.isArray(drInfo.roles)) {
        drInfo.roles.forEach((role) => {
            if (role.name == "psychologist") {
                isIt = true;
            }
        });
    }

    return isIt;
}

export function isPsychiatrist(drInfo) {
    let isIt = false;

    // For backward compatibility:
    if (drInfo.roles && drInfo.roles.data) {
        drInfo.roles = drInfo.roles.data;
    }

    if(Array.isArray(drInfo.roles)) {
        drInfo.roles.forEach((role) => {
            if (role.name == "psychiatrist") {
                isIt = true;
            }
        });
    }

    return isIt;
}

export function isDoctorRole(roles) {
    let status = false;
    // console.log('roles',roles)
    if (typeof roles == "string") {
        if (roles == "doctor") {
            status = true;
        }
    } else if (typeof roles == "object") {
        roles.forEach((role) => {
            let r = role;

            if (role.name) {
                r = role.name;
            }

            if (r == "doctor") {
                status = true;
            }
        });
    }

    return status;
}

export function isPatientRole(roles) {
    let status = false;

    roles.forEach((role) => {
        let r = role;

        if (role.name) {
            r = role.name;
        }

        if (r == "patient" || r == "in-patient") {
            status = true;
        }
    });

    return status;
}

export function iAmPsychologist() {
    const store = StorageService.storage(StorageType.LOCAL);
    const storedCredentials = JSON.parse(store.getItem("credentials"));

    let isADoctor = false;
    let isPsychologist = false;

    storedCredentials.roles.forEach((role) => {
        if (role.name == "doctor") {
            isADoctor = true;
        }

        if (role.name == "psychologist") {
            isPsychologist = true;
        }
    });

    return isADoctor && isPsychologist;
}

export function iAmPsychiatrist() {
    const store = StorageService.storage(StorageType.LOCAL);
    const storedCredentials = JSON.parse(store.getItem("credentials"));

    let isADoctor = false;
    let isPsychiatrist = false;

    storedCredentials.roles.forEach((role) => {
        if (role.name == "doctor") {
            isADoctor = true;
        }

        if (role.name == "medical-practitioner" || role.name == "psychiatrist") {
            isPsychiatrist = true;
        }
    });

    return isADoctor && isPsychiatrist;
}

export function iAmPsychRole() {
    return iAmPsychologist() || iAmPsychiatrist();
}

export function canSelectMedicalAssociation(role?) {
    let canSelect = true;

    if (typeof role == "undefined") {
        if (iAmPsychologist()) {
            canSelect = false;
        }
    } else {
        if (role == "psychologist" || !role) {
            canSelect = false;
        }
    }

    return canSelect;
}

export function canSetS2(role?) {
    let canSet = true;

    if (typeof role == "undefined") {
        if (iAmPsychologist()) {
            canSet = false;
        }
    } else {
        if (role == "psychologist" || !role) {
            canSet = false;
        }
    }

    return canSet;
}

export function medicalAssociationOptions(role?) {
    // THIS SHOULD BE FROM BACKEND
    let canGetOptions = false;

    // THIS condition indicates that current logged in user
    // will be used
    if (typeof role == "undefined") {
        if (iAmDoctor() && canSelectMedicalAssociation()) {
            canGetOptions = true;
        }
    } else {
        if (canSelectMedicalAssociation(role)) {
            canGetOptions = true;
        }
    }

    if (canGetOptions) {
        let options = ["Fellow", "Diplomate", "Associate Member"];

        storedCredentials.roles.forEach((role) => {
            if (role.name != "patient" && role.name != "doctor" && role.name != "dummy") {
                role = role.name;
            }
        });

        switch (role) {
            case "dermatologist":
                options.push("Philippine Dermatological Society");
                break;
            case "pediatrician":
                options.push("Philippine Pediatric Society");
                break;
            case "surgeon":
                options.push("Philippine Society of General Surgery");
                break;
            case "physician":
                options.push("Philippine College of Physicians");
                break;
            case "family-medicine":
                options.push("Philippine Academy of Family Medicine");
                break;
            case "neurologist":
                options.push("Philippine Neurological association");
                break;
            case "ent":
                options.push("Philippine Society of Otolaryngology - Head & Neck Surgery");
                break;

            case "opthalmologist":
                options.push("Philippine Academy of Opthalmology");
                break;
            case "gynecologist":
                options.push("Philippine Society of Obstetrics & Gynecology");
                break;
            case "physiatrists": // Rehab Medicine
                options.push("Philippine Academy of Rehabilitation Medicine");
                break;
        }

        return options;
    }

    return [];
}

export function iAmDoctor() {
    const store = StorageService.storage(StorageType.LOCAL);
    const storedCredentials = JSON.parse(store.getItem("credentials"));

    let isADoctor = false;

    storedCredentials.roles.forEach((role) => {
        if (role.name == "doctor") {
            isADoctor = true;
        }
    });

    return isADoctor;
}

export function iAmSecretary() {
    const store = StorageService.storage(StorageType.LOCAL);
    const storedCredentials = JSON.parse(store.getItem("credentials"));

    let isASecretary = false;

    storedCredentials.roles.forEach((role) => {
        if (role.name == "secretary") {
            isASecretary = true;
        }
    });

    return isASecretary;
}

export function iAmPatient() {
    const store = StorageService.storage(StorageType.LOCAL);
    const storedCredentials = JSON.parse(store.getItem("credentials"));

    let isAPatient = false;

    storedCredentials.roles.forEach((role) => {
        if (role.name == "patient" || role.name == "in-patient") {
            isAPatient = true;
        }
    });

    return isAPatient;
}

export function avatar(size, source?) {
    let avatar = "../assets/images/doctor-placeholder.png";

    if (!source) {
        const store = StorageService.storage(StorageType.LOCAL);
        const storedCredentials = JSON.parse(store.getItem("credentials"));
        source = storedCredentials.avatar;
    }

    // console.log('avatar source', source)
    if (typeof source[size] != "undefined" && source[size].url && source[size].url.length) {
        return source[size].url;
    }

    if (typeof source["picture"] != "undefined" && source["picture"].url.length) {
        return source["picture"].url;
    }

    return avatar;
}

/**** CONSULTATION NOTES HELPERS ****/

export function canAccessConsultationNotes_LaboratoryRequest() {
    return iAmDoctor() && !iAmPsychologist() ? true : false;
}

export function canAccessConsultationNotes_Medication() {
    return iAmDoctor() && !iAmPsychologist() ? true : false;
}

export function canAccessConsultationNotes_Diagnosis() {
    // return (iAmDoctor() && !iAmPsychologist()) ? true : false
    return iAmDoctor() ? true : false;
}

export function canAccessConsultationNotes_Diagnosis_CustomInput() {
    return iAmDoctor() && !iAmPsychologist() && !iAmPsychiatrist() ? true : false;
    // return (iAmDoctor()) ? true : false
}

export function canAccessConsultationNotes_NextMeeting() {
    return iAmDoctor() ? true : false;
}

export function canAccessConsultationNotes_Diagnosis_PsychiatristDropdown() {
    // return (iAmPsychiatrist()) ? true : false
    return iAmPsychiatrist() || iAmPsychologist() ? true : false;
}

export function canAccessConsultationNotes_PhysicalExam() {
    return iAmDoctor() && !iAmPsychologist() ? true : false;
}

export function consulationNotes_Label_MedicalAbstract() {
    return iAmDoctor() && !iAmPsychologist() ? "Medical Abstract" : "Summary Evaluation";
}

export function consulationNotes_MedicalAbstractMultipleFields() {
    return iAmDoctor() && !iAmPsychologist() ? true : false;
}

export function consulationNotes_Label_MedicalCertificate() {
    return iAmDoctor() && !iAmPsychologist() ? "Medical Certificate" : "Consultation Report";
}

export function consulationNotes_Label_AdmittingOrder() {
    return iAmDoctor() && !iAmPsychologist() ? "Admitting Order" : "Referral Letter";
}

export function label_MedicalCertificate(drInfo) {
    if (drInfo) {
        if (isPsychiatrist(drInfo)) {
            return "Medical Certificate";
        } else if (isPsychologist(drInfo)) {
            return "Summary Evaluation";
        }
    }
}

export function label_MedicalAbstract(drInfo) {
    if (drInfo) {
        if (isPsychiatrist(drInfo)) {
            return "Medical Abstract";
        } else if (isPsychologist(drInfo)) {
            return "Consultation Report";
        }
    }
}

/** ROLES  **/
export function getRoles() {
    const roles = [
        "psychologist",
        "psychiatrist",
        "opthalmologist",
        "dermatologist",
        "surgeon",
        "internist",
        "family-physician",
        "neurologist",
        "ent",
        "opthalmologist",
        "pediatrician",
        "gynecologist",
        "physiatrists",
        "rehab-medicine",
        "general-practitioner",
    ];

    return roles;
}

export function parseRole(roleSet) {
    let r = "";
    // console.log('parseRole(roleSet)',roleSet)
    roleSet.forEach((role) => {
        if (role.name) {
            role = role.name;
        }

        if (role != "dummy" && role != "doctor") {
            r = role;
        }
    });

    return r;
}

/*** CALENDAR  ***/
export function calendarEventStatusBGColor(timeslot) {
    let className = "";

    if (timeslot.offline) {
        if (!timeslot.status) {
            return className;
        }

        switch (timeslot.status.toUpperCase()) {
            case "BOOKED":
                className = "status-booked-off";
                break;

            case "RESERVED":
                className = "status-reserved-off";
                break;

            case "OPEN":
            default:
                className = "status-open-off";
                break;
        }
    } else {
        if (!timeslot.status) {
            return className;
        }

        switch (timeslot.status.toUpperCase()) {
            case "BOOKED":
                className = "status-booked";
                break;

            case "RESERVED":
                className = "status-reserved";
                break;

            case "OPEN":
            default:
                className = "status-open";
                break;
        }
    }
    // console.log("className", className)

    return className;
}

export const downloadPDFFile = (response: any) => {
    const file = new Blob([response], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
};

export const activatedSpecialization = (specialization?) => {
    // alert(environment.production)
    // console.log('environment.production', environment.production)
    if (environment.production) {
        switch (specialization) {
            case "psychologist":
            case "psychiatrist":
            case "neurologist":
            case "pediatrician":
            case "general-practitioner":
                return true;
                break;

            default:
                return false;
                break;
        }
    } else {
        // STAGING / Local
        switch (specialization) {
            case "psychologist":
            case "psychiatrist":
            case "neurologist":
            case "pediatrician":
            case "dermatologist":
            case "internist":
            case "surgeon":
            case "familiy-physician":
            case "general-practitioner":
                return true;
                break;

            default:
                return false;
                break;
        }
    }
};

export const errorvalidationParse = (error) => {
    let m = [];
    if (error.error && error.error.message) {
        for (let key in error.error.message) {
            if (typeof error.error.message[key] == "object") {
                error.error.message[key].forEach((message) => {
                    m.push(message);
                });
            }
        }
    }
    return m;
};

export const typeOf = (value) => {
    return typeof value;
};

export const orderBadge = (status) => {
    switch (status.toLowerCase()) {
        case "completed":
            return "success";

        case "canceled":
            return "secondary";

        case "failed":
            return "danger";

        case "pending":
            return "warning";

        default:
            return "info";
    }
};

export const formatDate = (date: any, format = "LL") => {
    return moment(date).format(format);
};

export const formattedPrice = (rates: object, day: string, is30minutes = false, type: string = "virtual") => {
    let dayIndexDiscounted;
    let dayIndexStandard;

    if (type === "virtual") {
        if (is30minutes) {
            dayIndexStandard = `std_${day}30min`;
            dayIndexDiscounted = `${day}_30min`;

            if (rates["use_default_30min"]) {
                dayIndexDiscounted = "default_30min";
            }
        } else {
            dayIndexStandard = `std_${day}`;
            dayIndexDiscounted = day;

            if (rates["use_default"]) {
                dayIndexDiscounted = "default";
            }
        }
    } else if (type === "face") {
        // dayIndexDiscounted = `offl_${day}`
        // dayIndexStandard = `offl_std_${day}`

        if (is30minutes) {
            dayIndexStandard = `offl_std_${day}_min30`;
            dayIndexDiscounted = `offl_${day}_min30`;

            if (rates["use_offl_default_min30"]) {
                dayIndexDiscounted = "offl_default_min30";
            }
        } else {
            dayIndexDiscounted = `offl_${day}`;
            dayIndexStandard = `offl_std_${day}`;

            if (rates["use_offl_default"]) {
                dayIndexDiscounted = "offl_default";
            }
        }
    }

    let regularRate = rates[dayIndexStandard];
    let discountedRate = rates[dayIndexDiscounted];

    if (parseFloat(regularRate) > parseFloat(discountedRate)) {
        return `<span class="line-through text-muted">&#8369;${regularRate}</span> &#8369;${discountedRate}`;
    } else {
        regularRate = discountedRate;
    }

    if (regularRate === null || regularRate === undefined) {
        return null;
    }

    return `&#8369;${regularRate}`;
};

export const is30Minutes = (startAt: moment.Moment, endAt: moment.Moment) => {
    if (endAt.diff(startAt, "minutes") == 30) {
        return true;
    }

    return false;
};

export const is60Minutes = (startAt: moment.Moment, endAt: moment.Moment) => {
    if (endAt.diff(startAt, "minutes") == 60) {
        return true;
    }

    return false;
};

export const clinicAddress = (clinic) => {
    if (!clinic) {
        return "";
    }

    let line1 = "";
    let line2 = "";
    let line3 = "";

    if (clinic.address_1) {
        line1 += clinic.address_1 + ", ";
    }

    if (clinic.address_2) {
        line2 += clinic.address_2 + ", ";
    }

    if (clinic.city) {
        line3 += clinic.city + ", ";
    }

    if (clinic.state) {
        line3 += clinic.state;
    }

    return line1 + line2 + line3;
};

export const getDayOfWeek = (d) => {
    if (moment(d).isValid()) {
        d = moment(d).day();
        let daysList = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

        return daysList[d];
    }

    return null;
};

export const createMoment = (date, overrideTimezone?) => {
    let baseTimezone = date && date.timezone ? date.timezone : "Asia/Manila";

    if (!date) {
        return moment().tz(baseTimezone);
    }

    let m = moment.tz(date.date, baseTimezone);
    let deviceTimezone = moment.tz.guess();

    if (overrideTimezone) {
        deviceTimezone = overrideTimezone;
    }
    // console.log('baseTimezone', baseTimezone, deviceTimezone, overrideTimezone)
    if (deviceTimezone != "Asia/Manila") {
        return m.clone().tz(deviceTimezone);
    }

    return m;
};
