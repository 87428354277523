import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TransactionComponent } from './transaction.component';

import { PrescriptionTransactionService } from '../../../../services';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TransactionComponent
  ],
  providers: [
    PrescriptionTransactionService
  ],
  exports: [
    TransactionComponent
  ],
})
export class TransactionModule { }
