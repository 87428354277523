import { Component, Input, OnInit, NgZone, Output, EventEmitter } from '@angular/core';
import { Session } from '@opentok/client';
import { Message } from '../../models';
import { UserService } from '../../../services';
import * as _ from 'lodash';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.html',
    styleUrls: ['./chat.scss']
})
export class ChatComponent implements OnInit {

    @Input() session: Session;
    @Input() compHeight: string;
    @Input() receiver: string;
    @Output() dataMessage: EventEmitter<any> = new EventEmitter()


    currentUser: string;
    messages: Message[];
    _: any;
    sending: boolean = false;

    now = null;

    constructor(
        userService: UserService,
        private ngZone: NgZone
    ) {
        this.messages = [];
        const me = userService.getStoredCredentials();
        this.currentUser = me.fullName;
        this._ = _;
    }

    ngOnInit() {
        this.session.on('signal', event => {
            const myConnId = this.session.connection.connectionId;
            const eventConnId = event.from.connectionId;
            // console.log('rec signal', myConnId, eventConnId, event, event.from.id)
            if (event.type as string === 'signal:message') {
                
                this.ngZone.run( () => {
                    this.sending = false
                    const eventData = JSON.parse(event.data);
                    const message = new Message(eventData.message);
                    message.senderName = eventData.senderName;
                    message.isSender = myConnId === eventConnId;
                    this.messages = [
                        ...this.messages,
                        message
                    ];

                    this.now = message

                    this.dataMessage.emit(message)

                });
            }
        });
    }

    onEnter(message: string) {
        const msg = new Message(message);
        // console.log('send mes', msg, message.length)
        msg.senderName = this.currentUser;
        this.sending = true;
        this.session.signal(
            {
                data: JSON.stringify(msg),
                type: 'message'
            },
            e => {

                if (e) {

                } else {
                    this.ngZone.run( () => {
                        this.sending = false
                    });
                }
            }
        );
    }
}
