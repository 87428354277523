import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CartService extends AbstractService {
  endpoint = environment.serverUrl + 'cart';

  constructor(http: HttpClient) {
    super(http);
  }

  placeOrder(params) {
    return this.http.post(environment.serverUrl + 'cart/place-order', params)
    .map((result: any) => {
      return result;
    }, (err: any) => {
        return err;
    });
  }

  addEventToCart(eventId, params?) {
    console.log({
      ...{'event_id': eventId, 
      'quantity': 1},
      ...params
    })
    return this.http.post(environment.serverUrl + 'events/add-to-cart', {
      ...{'event_id': eventId, 
      'quantity': 1},
      ...params
    })
    .map((result: any) => {
        return result;
    }, (err: any) => {
        return err;
    });
  }
  
  addAddonToCart(eventId, params?) {
    // console.log({
    //   ...{
    //     'event_id': eventId, 
    //     'quantity': 1
    //   },
    //   ...params
    // })
    return this.http.post(environment.serverUrl + 'events/add-to-cart', {
      ...{
        'event_id': eventId, 
        'quantity': 1
      },
      ...params
    })
    .map((result: any) => {
        return result;
    }, (err: any) => {
        return err;
    });
  }

  removeItem(params) {
    return this.http.post(this.endpoint + '/item/delete', params)
      .map((result: any) => {
          return result;
      }, (err: any) => {
          return err;
      });
  }


  emptyCart() {
    return this.http.delete(environment.serverUrl + 'cart')
      .map((result: any) => {
        return result;
      }, (err: any) => {
        return err;
      });

    // .toPromise()
    //         .then((response) => {
    //             this.toastr.successToastr('Cart emptied.');
    //             this.cart = { 'items': [], 'meta': { 'count': 0, 'subtotal': 0, 'tax': 0, 'shipping': 0, 'total': 0 } };
    //         });
  }

}
