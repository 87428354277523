import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { extract } from '../../../core/i18n.service';
import { ListComponent, ViewComponent } from './crud';

const routes: Routes = [
  {
    path: '',
    data: { title: extract('Feedbacks') },
    children: [
        {
          path: '',
          component: ListComponent,
        },
        {
          path: 'view/:id',
          component: ViewComponent,
          data: { title: extract('View Feedback')  },
        },
      ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FeedbacksRoutingModule { }
