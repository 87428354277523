import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { Route } from '../../../core/route.service';
import { extract } from '../../../core/i18n.service';

import { ListComponent, EditComponent, CreateComponent } from './crud';

const routes: Routes = [
    {
        path: '',
        data: { title: extract('Users') },
        children: [
            {
                path: '',
                component: ListComponent,
            },
            {
                path: 'edit/:id',
                component: EditComponent,
                data: { title: extract('Edit User') },
            },
            {
                path: 'create',
                component: CreateComponent,
                data: { title: extract('Create User') },
            },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule {
}
