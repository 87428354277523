import { Component, OnInit, Injector } from '@angular/core';
import { CrudComponent } from '../crud.component';

import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-base-crud-form',
    template: '',
    styleUrls: ['./base-crud-form.component.scss']
})
export class BaseCrudFormComponent extends CrudComponent {

    reloadForm: Observable<Boolean>;
    formBuilder: FormBuilder;
    rForm: FormGroup;

    constructor(injector: Injector) {
        super(injector);
        this.formBuilder = injector.get(FormBuilder);
    }

    doSave(event?: any) {
        // console.log('Override this .doSave() if necessary');

        this.doPreSave(event);
        // console.log(this.rForm.value);
        this.service.store(this.rForm.value)
            .subscribe((result: any) => {

                this.rForm.reset();
                this.doPostSave(event);
            },
            (err: any) => {
                console.log(err);
                // this.toaster.error('There was a problem while trying to save the record!', 'Warning!');
            });
    }

    doUpdate(event?: any) {
        // console.log('Override this .doUpdate()  if necessary');
        this.service.update(this.objectId, this.rForm.value)
            .subscribe((result: any) => {

                this.rForm.reset();
                this.doPostSave(event);
            },
                (err: any) => {
                    console.log(err);
                    // this.toaster.error('There was a problem while trying to update the record!', 'Warning!');
                });
    }

    doDelete(event?: any) {
        this.service.destroy(this.objectId)
            .subscribe((result: any) => {
                this.rForm.reset();
                this.doPostSave(event);
            },
                (err: any) => {
                    console.log(err);
                    // this.toaster.error('There was a problem while trying to update the record!', 'Warning!');
                });
    }


    doPreSave(event: any) {
        // console.log(event);
    }

    doPostSave(event?: any) {

        //  this.toaster.success('Record saved successfully!', 'Success!');

        // POST PROCESS
        if (event && event.action) {
            switch (event.action) {
                case 'close':
                    // NAVIGATE BACK TO LSIT
                    this.router.navigateByUrl(this.settings.crud.list.url);
                    break;

                case 'continue':
                    // TEMPORARY: NAVIGATE BACK TO LSIT
                    // RELOAD THE EDIT PAGE
                    // this.router.navigate([this.settings.crud.update.url + this.objectId]);
                    break;
            }
        }
    }
}
