import { Component, OnInit } from "@angular/core";
import { OtsukaMARSService, MARSRecord, formatDate, MARSQuestion } from "../../../../../../services";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { map, switchMap } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: "app-mars",
    templateUrl: "./mars.component.html",
    styleUrls: ["./mars.component.scss"],
})
export class MARSComponent implements OnInit {
    questions: MARSQuestion[] = [];

    dateRange: [Date, Date];
    selectedRecord = new BehaviorSubject<MARSRecord>(undefined);
    records = new BehaviorSubject<MARSRecord[]>([]);
    chartData: [string, number][] = [];
    patient_id = this.activatedRoute.params.pipe(map(({ id }) => id));
    constructor(public service: OtsukaMARSService, public activatedRoute: ActivatedRoute) {}

    ngOnInit() {
        this.dateRange = [moment().startOf("year").toDate(), moment().endOf("year").toDate()];

        this.loadRecords(this.dateRange);
    }

    loadRecords(dateRange: [Date, Date]) {
        this.patient_id
            .pipe(switchMap((patiend_id) => this.service.getScores(patiend_id, dateRange)))
            .subscribe((records) => {
                this.records.next(records);
                this.chartData = records.map(({ score, date }) => [`${formatDate(date)}`, score]);
            });
    }

    onValueChange(dateRange: [Date, Date]) {
        this.dateRange = dateRange;
        this.loadRecords(dateRange);
    }

    onSelectRecord(record: MARSRecord) {
        const currentRecord = this.selectedRecord.value;
        if (currentRecord && currentRecord.id === record.id) {
            this.selectedRecord.next(undefined);
        } else {
            this.service.getDetails(record).subscribe((questions) => {
                this.selectedRecord.next(record);
                this.questions = questions.filter(({ isBooleanAnswer }) => isBooleanAnswer);
            });
        }
    }

    calcSize([_, value]: [string, number]) {
        return `--size: calc(${value} / 10)`;
    }
}
