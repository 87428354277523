<crud-header 
*ngIf="formType=='edit'"
[settings]="settings" 
[action]="'edit'"
></crud-header>

<crud-header 
*ngIf="formType=='create'"
[settings]="settings" 
[action]="'create'"
></crud-header>
      
      
      <form [formGroup]="rForm">
            <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group"
                                    [ngClass]="{
                                        'has-danger': rForm.controls.name.invalid && rForm.controls.name.dirty
                                    }"
                                >
                                    <label class="form-control-label" for="name">Name<span class="req"> *</span></label>
                                    <div class="controls">
                                        
                                        <input formControlName="name" id="name" type="text" class="form-control k-textbox" data-role="text" required="required" placeholder="" name="name">

                                        <div *ngIf="!rForm.controls.name.pristine && rForm.controls.name.invalid" 
                                            class="form-control-feedback">
                                            <div
                                                *ngIf="rForm.controls.name.errors.required" 
                                                class="text-small">Name is required.</div>
                                            <div
                                                *ngIf="rForm.controls.name.errors.minlength" 
                                                class="text-small">Name is too short</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                
        <crud-form-buttons
            *ngIf="formType=='edit'"
            [settings]="settings"
            [action]="'edit'"
            [rForm]="rForm"
            (doSave)="doUpdate($event)"
        ></crud-form-buttons>

        <crud-form-buttons
            *ngIf="formType=='create'"
            [settings]="settings"
            [action]="'create'"
            [rForm]="rForm"
            (doSave)="doSave($event)"
        ></crud-form-buttons>

    </form>