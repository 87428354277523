import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class BillingInformationService extends AbstractService {

    endpoint = environment.serverUrl + 'user/billing-information';
    httpOption =  {
        headers: {
            'Content-Type': 'application/json',
            'Accept': environment.API_HEADER_ACCEPT_v2
        }
    }

    constructor(http: HttpClient) {
        super(http);
    }


    getItem(query: any){
        let url = this.endpoint;
        let sq = this.buildQueryString(query);

        return this.http.get(url + sq, this.httpOption);
    }

    get() {

        let url = this.endpoint;

        return this.http.get(url, this.httpOption)
          .map((result: any) => {
              return result;
          }, (err: any) => {
              return err;
          });
    }

    create(params: Object) {
        let url = this.endpoint;

        return this.http.post(url, params, this.httpOption)
          .map((result: any) => {
              return result;
          }, (err: any) => {
              return err;
          });
    }

    update(params: Object) {
        let url = this.endpoint;

        return this.http.put(url, params, this.httpOption)
          .map((result: any) => {
              return result;
          }, (err: any) => {
              return err;
          });
    }

    delete(id: Number) {
        let url = this.endpoint;

        url = url + '/' + id;

        return this.http.delete(url, this.httpOption)
          .map((result: any) => {
              return result;
          }, (err: any) => {
              return err;
          });
    }

}
