<ul class="nav flex-column p-0 m-0">
    <li class="nav-item">
        <a class="nav-link" [routerLink]="'/admin'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">Dashboard</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/users'" routerLinkActive="active">Users</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/roles'" routerLinkActive="active">Roles</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/medical-practitioners'" routerLinkActive="active">Doctors</a>
    </li>

    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/patients'" routerLinkActive="active">Patients</a>
    </li>

    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/staff'" routerLinkActive="active">Staff</a>
    </li>

    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/coupons'" routerLinkActive="active">Coupons</a>
    </li>

    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/disbursements'" routerLinkActive="active">Doctor Fees</a>
    </li>

    <li class="nav-item" *ngIf="isAdmin || isRxfort">
        <a class="nav-link" [routerLink]="'/admin/medicines'" routerLinkActive="active">Medicines</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin || isRxfort">
        <a class="nav-link" [routerLink]="'/admin/generic-names'" routerLinkActive="active">Generic Names</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin || isRxfort">
        <a class="nav-link" [routerLink]="'/admin/orders'" routerLinkActive="active">Orders</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/messages'" routerLinkActive="active">Messages</a>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
        <a class="nav-link" [routerLink]="'/admin/feedbacks'" routerLinkActive="active">Feedbacks</a>
    </li>
    <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="'/admin/companies'" routerLinkActive="active">Companies</a>
    </li> -->
    <li class="nav-item" >
        <a class="nav-link" href="/" (click)="logout()">Logout</a>
    </li>
</ul>
