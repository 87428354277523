<div class="row">
  <div class="col-sm-7" *ngIf="actionMode!='view' && selectedEvent?.status!='RESERVED'">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [destroyOnHide]="false" [activeIds]="panelToOpen()" (panelChange)="beforeChange($event)">
      <ngb-panel id="panel-step-1" [ngClass]="{'success':slotDetails.type}">
        <ng-template ngbPanelTitle>
          Step 1: <strong>Choose Virtual or Face-to-Face</strong>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="">
            <app-consultation-type [slotDetails]="slotDetails" (selectType)="selectType($event)">
            </app-consultation-type>
          </div>
          <div class="text-center mt-2">
            <app-clinic [slotDetails]="slotDetails" (selectClinic)="selectClinic($event)"></app-clinic>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="panel-step-2" [disabled]="disablePanel(2)">
        <ng-template ngbPanelTitle>
          Step 2: <strong>Choose a duration</strong>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="text-center">
            <app-time-duration [slotDetails]="slotDetails" (panelToOpen)="panelToOpen($event)"
              (selectDuration)="selectDuration($event)"></app-time-duration>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="panel-step-3" [disabled]="disablePanel(3)">
        <ng-template ngbPanelTitle>
          Step 3: <strong>Set the time</strong>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-time-fields [datePicked]="datePicked" [slotDetails]="slotDetails" (selectStartAt)="selectStartAt($event)"
            (selectEndAt)="selectEndAt($event)" (selectTime)="selectTime($event)" [isValidTime]="isValidTime">
          </app-time-fields>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>

  <div class="col-sm-5 small" [ngClass]="{'col-sm-12' : actionMode=='view'}">
    <div class="row">
      <div class="col-sm-12">
        <app-summary class="row" [slotDetails]="slotDetails" [datePicked]="datePicked" [isValidTime]="isValidTime"
          [actionMode]="actionMode" [selectedEvent]="selectedEvent" [rateCharge]="rateCharge">
        </app-summary>
      </div>
      <div class="col-sm-12 mt-2" *ngIf="actionMode!='view' && selectedEvent?.status!='RESERVED'">
        <div class="row">

          <div class="" [ngClass]="{'col-sm-6' : actionMode=='edit', 'col-sm-12' : actionMode=='create' }">
            <button class="btn w-100 btn-primary text-white btnCta" [disabled]="disableSaveEventBtn()"
              (click)="_saveEvent()">
              <span *ngIf="!savingEvent">
                <fa-icon [icon]="fa.faSave" class=""></fa-icon>&nbsp;
                <span *ngIf="actionMode=='edit'">Update</span>
                <span *ngIf="actionMode=='create'">Create</span>
                <!-- this slot -->
              </span>
              <span *ngIf="savingEvent">
                <span *ngIf="actionMode=='edit'">Updating...</span>
                <span *ngIf="actionMode=='create'">Creating...</span>
              </span>
            </button>
          </div>

          <div class="col-lg-6" *ngIf="actionMode=='edit' && selectedEvent?.status=='OPEN'">
            <button class="btn w-100 btn-warning text-white btnCta" [disabled]="!isValidForm() || savingEvent"
              (click)="_deleteEvent()">
              <span *ngIf="!savingEvent">
                <fa-icon [icon]="fa.faTrash" class="text-danger"></fa-icon>&nbsp;
                Remove
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="row" *ngIf="selectedType && selectedType == 'face'">
  Clinic dropdown field here
</div> -->

<!-- 
<div class="row" *ngIf="selectedType && selectedType == 'virtual'">

</div> -->


<div class="border d-none">
  <!-- <button type="button" class="btn btn-primary mr-2" (click)="saveEvent()" [disabled]="loading" [disabled]="startDateValidity() || endDateValidity()">Save</button>
  <button type="button" class="btn btn-outline-dark" (click)="c()" [disabled]="loading" *ngIf="!confirm">Close</button> -->


  <!-- <div *ngIf="selectedEvent && selectedEvent.canEdit && !confirm">
      <button type="button" class="btn btn-outline-danger mr-2" (click)="confirm=true" [disabled]="loading" *ngIf="selectedEvent && selectedEvent.canDelete" >Remove</button>
      <button type="button" class="btn btn-primary" (click)="updateEvent()" *ngIf="selectedEvent" [disabled]="loading">Update</button>
  </div>
  <div *ngIf="selectedEvent && confirm">
          <button type="button" class="btn btn-outline-danger mr-2" (click)="deleteEvent()" [disabled]="loading" *ngIf="selectedEvent" >Yes</button>
          <button type="button" class="btn btn-outline-dark" (click)="confirm=false" [disabled]="loading" *ngIf="selectedEvent">No</button>
  </div>
  <button type="button" class="btn btn-primary mr-2" (click)="saveEvent()" [disabled]="loading" *ngIf="!selectedEvent" [disabled]="startDateValidity() || endDateValidity()">Save</button>
  <button type="button" class="btn btn-outline-dark" (click)="c()" [disabled]="loading" *ngIf="!confirm">Close</button> -->
</div>