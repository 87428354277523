import { Component, OnInit, Injector } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { CreateComponent as CrudCreateComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { RoleService } from '../../../../../services/role.service';

// STEP 4: IMPORT form
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ROLE_MODEL } from '../../role.model';

// STEP 5: IMPORT settings
import { settings } from '../../settings';

@Component({
  selector: 'app-create',
  templateUrl: './../edit/edit.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent extends CrudCreateComponent {
  formType = 'create';
  formGroup: FormGroup;

  constructor(injector: Injector) {
    super(injector)
    this.service = injector.get(RoleService);
    this.setSettings(settings);
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group(ROLE_MODEL);
  }

}
