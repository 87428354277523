export const settings = {
    plural: 'Companies',
    singular: 'Company',

    crud: {
        create: {
            allow: true, // true/false
            url: '/admin/companies/create'
        },
        read: {

        },
        update: {
            allow: true,
            url: '/admin/companies/edit/'
        },
        delete: {
            allow: true
        },
        list: {
            allow: true,
            url: '/admin/companies'
        }
    }
};
