import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import * as OT from '@opentok/client';
import * as io from 'socket.io-client';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotesService } from './notes.service';

@Injectable()
export class OpenTokService {

    key = environment.OT_KEY;
    endpoint = environment.serverUrl + 'user/events';

    openTokEndpoint = environment.serverUrl + 'rtc/session';

    connecting: any;
    socket: any;
    session: OT.Session;
    sessionId: string;
    token: string;
    myId: any;
    receiver: any;
    caller: any;
    inSession = new BehaviorSubject(false);
    wasDeclined = new BehaviorSubject(false);
    answered = false;
    audio: any;
    connectedTo = new BehaviorSubject<any>(undefined);
    currentCallType: any;
    currentCallEventId: number;
    isTherapist: any;

    constructor(
        public http: HttpClient,
        private userService: UserService,
        private router: Router,
        private noteService: NotesService) {
            
        // this.audio = new Audio('../../assets/audio/ringback.wav');
        // this.audio.loop = true;
        this.myId = userService.getStoredCredentials().id;
        // this.connecting = new BehaviorSubject('false');
        // this.socket = io(environment.ws_url);
        // this.socket.on('whois', () => {
        //     const me = this.userService.getStoredCredentials();
        //     this.socket.emit('iam', {
        //         id: me.id,
        //         name: me.fullName
        //     });
        // });


        // this.socket.on('serverEvent', (payload) => {
        //     const { action, data, targetUserId } = payload;
        //     switch (action) {
        //         case 'userStatusFeedback':
        //             const { userStatus } = data;
        //             this.connecting.next(userStatus);
        //             if (userStatus === 'userNotConnected' || userStatus === 'userInSession') {
        //                 this.audioStop();
        //             }
        //             break;
        //         case 'connectionRequest':
        //             if (this.getTheRapist() && !data.caller.isTherapist) {
        //                 localStorage.setItem('patient', JSON.stringify(data.caller));
        //             }
        //             localStorage.setItem('callername', data.caller.name);
        //             this.audio.play();
        //             this.caller = data.caller;
        //             this.currentCallType = data.callType;
        //             this.currentCallEventId = data.eventId;
        //             this.connecting.next('incoming');
        //             break;
        //         case 'connectionDeclined':
        //         case 'connectionCanceled':
        //         case 'closeRequest':
        //             this.connecting.next('false');
        //             this.receiver = undefined;
        //             this.caller = undefined;
        //             this.inSession.next(false);
        //             this.wasDeclined.next(false);
        //             this.answered = false;
        //             this.audioStop();
        //             break;
        //         case 'creatingSession':
        //             this.audioStop();
        //             this.connecting.next('creatingSession');
        //             break;
        //         case 'sessionCreated':
        //             this.audioStop();
        //             const { sessionConnectionInfo } = data;

        //             // fetch session here
        //             let sessionV2 = this.getSessionV2(this.currentCallEventId);
        //             let event_id = this.currentCallEventId;

        //             this.http.post(this.openTokEndpoint, { event_id })
        //                 .map((result: any) => {
        //                     return result;
        //                 }, (err: any) => {
        //                     return err;
        //                 }).subscribe(res => {
        //                     this.sessionId = res.data.session;
        //                     this.token = res.data.token;

        //                     // old script loaded inside subscribe
        //                     this.connecting.next('false');
        //                     this.answered = false;
        //                     localStorage.setItem('sessionEventInfo', JSON.stringify({
        //                         callType: this.currentCallType,
        //                         eventId: this.currentCallEventId
        //                     }));
        //                     this.setSession(this.currentCallEventId, this.sessionId);
        //                     localStorage.setItem('otSession', JSON.stringify(res.data));


        //                     const params = [
        //                         'height=' + screen.height,
        //                         'width=' + screen.width,
        //                         'status=0,toolbar=0,location=0,titlebar=no'

        //                     ].join(',');
        //                     router.navigate(['call', this.sessionId])
        //                     // old script loaded inside subscribe --- END ---
        //                 });

        //             // this.sessionId = sessionConnectionInfo.sessionId;
        //             // this.token = sessionConnectionInfo.token;
                    
                    
        //             break;
        //         default:
        //             console.log('Unhandled event:', action, data);
        //             break;
        //     }
        // });


    }

    getOT() {
        return OT;
    }

    emitEvent(data: any) {
        this.socket.emit('clientEvent', data);
    }

    initSession(sessionId: string) {
        this.session = OT.initSession(this.key, sessionId);
    }

    setSession(eventId: number, session_id: string) {
        const ep = `${this.endpoint}/${eventId}/set-session`;
        if (this.getTheRapist()) {
            this.http.post(ep, { session_id })
                .map((result: any) => {
                    return result;
                }, (err: any) => {
                    return err;
                }).subscribe(e => console.log('result', e));
        }
    }

    getTheRapist = () => {
        if (!this.isTherapist) {
            const me = this.userService.getStoredCredentials();
            this.isTherapist = !!me.roles.find(role => role.name === 'medical-practitioner');
        }

        return this.isTherapist;
    }

    callUser(receiver: any) {
        // this.audio.play();
        const me = this.userService.getStoredCredentials();
        this.myId = me.id;
        this.receiver = receiver;
        this.connecting.next('outgoing');
        this.currentCallType = receiver.type;
        this.currentCallEventId = receiver.eventId;

        if (this.getTheRapist()) {
            localStorage.setItem('patient', JSON.stringify(this.receiver));
        }

        

        // this.emitEvent({
        //     action: 'connectToUser',
        //     data: {
        //         receiver: this.receiver,
        //         callType: receiver.type,
        //         eventId: receiver.eventId,
        //         caller: {
        //             id: me.id,
        //             name: me.fullName,
        //             isTherapist: this.getTheRapist(),
        //             eventId: receiver.eventId
        //         }
        //     },
        // });
    }

    disconnect() {
        this.emitEvent({
            action: 'closeSession',
            data: {
                myId: this.myId,
            },
        });
        this.session.disconnect();

        // console.log('the rapist', this.getTheRapist());
        // if (!this.getTheRapist()) {
        // console.log(this.getTheRapist());
        const sessionEventInfo = JSON.parse(localStorage.getItem('sessionEventInfo'));
        // window.open('/feedback/' + sessionEventInfo.eventId, '_blank');
        this.router.navigate(['feedback', sessionEventInfo.eventId]);
        // }
        // this.router.navigate(['medications']);

        window.close();
    }

    answer() {
        if (!this.answered) {
            this.audioStop();
            this.emitEvent({
                action: 'connectionApproved',
                data: {
                    myId: this.myId,
                    caller: this.caller,
                }
            });
            this.answered = true;
        }
    }

    decline() {
        // console.log(this.caller);
        this.audioStop();
        this.connecting.next('false');
        this.emitEvent({
            action: 'connectionDeclined',
            data: {
                myId: this.myId,
                caller: this.caller,
            }
        });
    }

    cancelCall() {
        this.audioStop();
        this.connecting.next('false');
        this.emitEvent({
            action: 'connectionCanceled',
            data: {
                myId: this.myId,
                receiver: this.receiver,
            }
        });
    }


    generateToken(receiver: any) {
        const me = this.userService.getStoredCredentials();
        this.myId = me.id;
        this.receiver = receiver;
        // this.connecting.next('outgoing');
        this.currentCallType = receiver.type;
        this.currentCallEventId = receiver.eventId;

        if (this.getTheRapist()) {
            localStorage.setItem('patient', JSON.stringify(this.receiver));
        }
        

        localStorage.setItem('sessionEventInfo', JSON.stringify({
            callType: this.currentCallType,
            eventId: this.currentCallEventId
        }));
        
        
        return this.http.post(environment.serverUrl + 'rtc/generate-token', {event_id: this.currentCallEventId})
            .subscribe(result => {
                // this.setSession(this.currentCallEventId, result.data.session);

                const { token, session } = result['data'];

                localStorage.setItem('otSession', JSON.stringify({ token, session }));
                this.router.navigate(['call', session])
                // this.audio.play();
                return result;
            });

        // localStorage.setItem('otSession', JSON.stringify(res.data));
        // this.router.navigate(['call', this.sessionId])
    }

    generateTokenV2(receiver: any) {
        const me = this.userService.getStoredCredentials();
        this.myId = me.id;
        this.receiver = receiver;
        // this.connecting.next('outgoing');
        this.currentCallType = receiver.type;
        this.currentCallEventId = receiver.eventId;

        if (this.getTheRapist()) {
            localStorage.setItem('patient', JSON.stringify(this.receiver));
        }
        

        localStorage.setItem('sessionEventInfo', JSON.stringify({
            callType: this.currentCallType,
            eventId: this.currentCallEventId
        }));
        
        
        return this.http.post(environment.serverUrl + 'rtc/generate-token', {event_id: this.currentCallEventId})
            .subscribe(result => {
                // this.setSession(this.currentCallEventId, result.data.session);

                const { token, session } = result['data'];

                localStorage.setItem('otSession', JSON.stringify({ token, session }));
                this.router.navigate(['call-v2', session])
                // this.audio.play();
                return result;
            });

        // localStorage.setItem('otSession', JSON.stringify(res.data));
        // this.router.navigate(['call', this.sessionId])
    }

    generateToken_2(event_id) {
        return this.http.post(environment.serverUrl + 'rtc/generate-token', {event_id});
            // .subscribe(result => {
            //     const { token } = result['data'];
            //     return token;
            // }, 
            // err => {
            //     return null
            // });
    }

    log(data) {
        const { event_id, user_id, name, detail, event_at} = data

        return this.http.post(environment.serverUrl + 'rtc/log', { event_id, user_id, name, detail, event_at})
        .subscribe(res => {
            // console.log(res);
        },
            err => {
                //console.log(err)
            });
    }



    public audioStop() {
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    getSessionV2(event_id: any) {

        
    }
}
