import { Component, Input, OnChanges, SimpleChanges  } from '@angular/core';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';

import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'section-physical-exam',
    templateUrl: './physical-exam.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionPhysicalExamSectionProgressComponent implements OnChanges {
    @Input() data: object
    @Input() eventId

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    title = 'Physical Examination';
    value = '<em class="text-muted">Not specified</em>';
    match = true
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    constructor(
        private noteService: NotesService
    ) {}


    getValue = (key) => {
        let value = '<em class="text-muted">No observation added</em>'
        if (this.value[key]) {
            value = `<pre>${this.value[key]}</pre>`
        }

        return `<pre>${value}</pre>`
    }

    downloadPDF = () => {
    //    alert('To follow ni. huwat lang...')
       this.noteService.genReport('physical-exam', this.eventId)
       .subscribe(
           response => downloadPDFFile(response),
           e => {
               // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
           }
       );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue
        ) {
            this.value = changes.data.currentValue
        }
    }

}
