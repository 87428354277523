import { Component, EventEmitter, Output, Input } from "@angular/core";
import moment from "moment";
import { AvailabilityService } from "../../../services";
import { ClinicService } from '../../../services/clinic.service';
import { UserService } from '../../../services/user.service';
// improt

@Component({
    selector: "app-next-meeting",
    templateUrl: "./next-meeting.html",
})
export class NextMeetingComponent {
    public clinicList = []
    public isLoading: boolean = false
    public user = null

    selectType: boolean

    type: any
    clinic: any

    minDate: Date;
    time: {
        start: any;
        end: any;
    };
    saving = false;
    slots: any[];
    selectedSlot: any;
    selectedDay: any;
    loadingSlots = false;
    newTimeSlot: boolean = true;
    @Output() stateSaver = new EventEmitter<any>();
    @Output() selectedType = new EventEmitter<any>();
    @Output() selectedClinic = new EventEmitter<any>();
    @Input() event: any
    constructor(
        private availabilityService: AvailabilityService,
        private clinicService: ClinicService,
        private userService: UserService
        
        ) {
        this.minDate = new Date();
        this.slots = [];
        this.time = {
            start: moment()
                .minute(moment().minute() < 30 ? 0 : 30)
                .toDate(),
            end: moment()
                .minute(moment().minute() < 30 ? 0 : 30)
                .add(1, "h")
                .toDate(),
        };

        this.user = this.userService.getStoredCredentials()
    }

    getAppointments(e: any) {
        this.selectedDay = moment(e);
        const startDate = moment(e).format("YYYY-MM-DD");
        const endDate = moment(e).add(1, "d").format("YYYY-MM-DD");
        this.loadingSlots = true;
        this.selectedSlot = undefined;
        this.availabilityService
            .getDateRangeEvents(startDate, endDate, { with: "notes;bookings.patient" })
            .subscribe((response: any) => {
                const slots = response.data.map((slot) => {
                    const startTime = moment(slot.start_at.date).format("h:mm A");
                    const endTime = moment(slot.end_at.date).format("h:mm A");
                    return {
                        ...slot,
                        timeslot: `${startTime} - ${endTime}`,
                    };
                });
                this.slots = slots;
                this.loadingSlots = false;
            });
    }

    placeholder() {
        if (this.loadingSlots) {
            return "Loading Slots";
        } else if (!this.selectedDay) {
            return "Select timeslot";
        } else {
            if (this.slots.length) {
                return "Select timeslot";
            } else if (!this.slots.length) {
                return "No available timeslot";
            }
        }
    }

    getDuration() {
        const start = moment(this.time.start);
        const end = moment(this.time.end);
        return end.diff(start, "m");
    }

    saveNextAppointMent() {
        this.saving = true;
        const selectedDay = moment(this.selectedDay).format("LL");
        const nextMeetingType = this.type === 'virtual' ? 'Virtual' : 'Face-to-Face';

        if (this.newTimeSlot) {
            const startTime = moment(this.time.start).format("k:mm");
            const endTime = moment(this.time.end).format("k:mm");
            const nextMeeting = `${selectedDay} ${startTime} - ${endTime} ${nextMeetingType}`;
            this.selectedSlot = `${startTime} - ${endTime}`;
            this.stateSaver.emit(nextMeeting);
        } else {
            const nextMeeting = `${selectedDay} ${this.selectedSlot} ${nextMeetingType}`;
            this.stateSaver.emit(nextMeeting);
        }
        this.saving = false;
    }

    getList = () => {
        this.isLoading = true
        this.clinicService.getByDoctorId(
          this.user.id,
          { search: 'status:1', orderBy: 'name', sortedBy: 'asc' }
        )
          .subscribe(
            res => {
              this.clinicList = res.data
              this.isLoading = false
            },
            error => {
              this.isLoading = false
            }
          )
      }
    
    ngOnInit() {
        this.getList()
    }

    onSelectType = (event : any): void => {
        this.selectType = event.target.value === 'face';
        this.selectedType.emit(this.type);
    }

    onSelectClinic = (event : any) => {
        this.selectedClinic.emit(this.clinic)
    }

      
}
