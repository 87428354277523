import { Routes } from '@angular/router';

import { ShellComponent } from './shell/shell.component';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';

import { CheckAdminRoleGuard } from './authentication/guards/check-admin-role.guard';

/**
 * Provides helper methods to create routes.
 */
export class Route {

    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return {Routes} The new routes using shell as the base.
     */
    static withShell(routes: Routes, theme?: string): Routes {

        return [{
            path: '',
            component: ShellComponent,
            children: routes,
            canActivate: [AuthenticationGuard, CheckAdminRoleGuard],
            // Reuse ShellComponent instance when navigating between child views
            data: { reuse: true }
        }];
    }

}
