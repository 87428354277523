<div class="row login-container d-flex justify-content-center align-items-center bg-light m-0 p-0" style="height: 99vh">
    <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 login-con" >
        <div class="text-center my-3">
            <a [routerLink]="['/me']">
                <img src="./assets/images/logo.png" style="width:330px;">
            </a>
        </div>
        <div class="row  m-0">
            <div class="col-12 col-lg-10 col-xl-10 m-auto" >
                <div class="card bg-light border-0">
                    <div class="card-body p-4">
                        <h3 class="text-center">Login</h3>
                        <hr />
                        <form (ngSubmit)="login(loginForm.value)" [formGroup]="loginForm" novalidate>
                            <div class="alert alert-info" role="alert" *ngIf="emailVerified">
                                Your email has been verified, you can now log in.
                            </div>
                            <div class="alert alert-danger" *ngIf="error && error.error.error=='invalid_credentials'">
                                Email or password incorrect.
                            </div>
                            <div class="alert alert-danger" *ngIf="error && error.error.message=='not_verified'">
                                You have not verified your account. Please check your email.
                            </div>
                            <div class="alert alert-danger" *ngIf="error && error.error.message=='account_not_active'">
                                Account not Active
                            </div>

                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" class="form-control form-control-lg" formControlName="email" [placeholder]="'Email'" />
                                <span hidden>Email</span>
                                <app-errors [control]="loginForm.get('email')"></app-errors>
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" class="form-control form-control-lg" formControlName="password" [placeholder]="'Password'" required/>
                                <span hidden>Password</span>
                                <app-errors [control]="loginForm.get('password')"></app-errors>
                            </div>
                            <div class="form-group">
                                <div class="row">
                                    <div class="col d-flex justify-content-between">
                                        <div>
                                            <input type="checkbox" formControlName="remember" /> Remember me
                                        </div>
                                        <a [routerLink]="['/users/reset-password']">Forgot Password</a>
                                    </div>
                                </div>
                            </div>

                            <button 
                                class="btn btn-primary w-100 mt-4" 
                                type="submit" 
                                [disabled]="isLoading || loginForm.invalid">
                                <span>Login</span>
                            </button>


                            <div class="row m-3">
                                <div class="col text-center">
                                    New to Recoveryhub?
                                    <a class="" [routerLink]="['/register']">Patient Sign Up</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="colophon py-4 text-white">
    <div class="container">
        <div class="d-flex justify-content-between">
            <div class="copyright">
                <small>Copyright &copy;  {{year|date:'y'}} Metro Psych Facility. All Rights Reserved.</small>
            </div>
            <div class="footer-links">
                <small><a class="text-white" href="../../../../assets/docs/TermsAndConditions.pdf" target="_blank"><u>Terms and Conditions</u></a></small> |
                <small><a class="text-white" href="../../../../assets/docs/PrivacyPolicy.pdf" target="_blank"><u>Privacy Policy</u></a></small>
            </div>
        </div>
    </div>
</div>