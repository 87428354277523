import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class EventPrescriptionItemService extends AbstractService {

    baseUrl = environment.serverUrl + 'event-prescription-items';

    constructor(http: HttpClient) {
        super(http);
    }

    getPrescriptions(query: any) {
        // console.log(query);
        let sq = this.buildQueryString(query);
        // return this.http.get(this.endpoint+'/my' + sq)
        //     .map((result: any) => {
        //         if (result) {
        //             return result;
        //         }
        //     });
        return this.http.get(this.baseUrl + sq);
    }

    getItem(id){
        return this.http.get(this.baseUrl + "/" + id + "?with=medicine");
    }

    
    
}
