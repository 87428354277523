
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';


export const USER_MODEL = {
    'first_name': [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
    'last_name': [null, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
    'phone': [null, Validators.compose([Validators.required])],
    'address_1': [null, Validators.compose([Validators.required])],
    'city': [null, Validators.compose([Validators.required])],
    'state': [null, Validators.compose([Validators.required])],
    'zipcode': [null, Validators.compose([Validators.required, Validators.minLength(2)])],
    'email': [null, Validators.compose([Validators.required, Validators.email])],
    'password': [null, Validators.compose([Validators.minLength(8)])],
    'status': [null, Validators.compose([Validators.required])],
};

export const ERRORS = [
    { name: 'required', text: 'This field is required', rules: ['touched', 'dirty'] },
    { name: 'minlength', text: 'Minimum number of characters ', rules: ['touched', 'dirty'] }
];

export const ROLES_MODEL = {
    'roles': FormArray,
};
