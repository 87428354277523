import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { PatientService } from '../../../../../services/patient.service';

// STEP 4: IMPORT form
import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { COMPANY_MODEL, ROLES_MODEL, ERRORS } from '../../company.model';

// STEP 5: IMPORT settings
import { settings } from '../../settings';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent implements OnInit {
    public formType = 'edit';

    errors = ERRORS;
    formRoles: FormGroup;

    private roles: Array<any> = [];
    private optionsMap = {
        OptionA: false,
        OptionB: false,
        OptionC: false,
    };
    private user: any;
    public vcr: any;

    constructor(injector: Injector, vcr: ViewContainerRef) {
        super(injector);
        this.service = injector.get(PatientService);
        this.setSettings(settings);
        this.vcr = vcr;

        this.formRoles = this.formBuilder.group({
            'roles': this.formBuilder.array([])
        });
    }

    ngOnInit() {
        this.rForm = this.formBuilder.group(COMPANY_MODEL, { updateOn: 'blur' });

        this._load({ mode: 'complete' }).subscribe((result: any) => {
            this.user = result.data;
            // console.log(result);

            Object.keys(COMPANY_MODEL).forEach(key => this.rForm.get(key).setValue(result.data[key]));
            // for (const key of COMPANY_MODEL) {
            //     if (COMPANY_MODEL.) {
            //         ;
            //     }
            // }

            this.user.roles.data.forEach((role: any) => {
                this.onRoleCheckboxChange(role.id, true);
            });
            // UPDATE ROLES LIST
        });
    }


    onRoleCheckboxChange(roleId: string, isChecked: boolean) {
        const rolesFormArray = <FormArray>this.formRoles.controls.roles;

        if (isChecked) {
            rolesFormArray.push(new FormControl(roleId));
        } else {
            const index = rolesFormArray.controls.findIndex(x => x.value === roleId);
            rolesFormArray.removeAt(index);
        }
    }

    isChecked(roleId: any) {
        const checked = false;

        const a = this.user.roles.data.filter((role: any) => {
            return roleId === role.id;
        });

        if (a.length) {
            return true;
        }
        return false;
    }


    doUpdateRoles() {
        // console.log(this.objectId, this.formRoles.value);
        this.service.setRoles(this.objectId, this.formRoles.value)
            .subscribe((result: any) => {

                this.rForm.reset();
                this.doPostSave(event);
            },
                (err: any) => {
                    console.log(err);
                });
    }
}
