import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { MedicinesService } from '../../../../../services/medicines.service';

import { GenericNamesService } from '../../../../../services/generic-names.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { MEDICINES_MODEL, ROLES_MODEL, ERRORS } from '../../medicines.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent implements OnInit {
  public formType = 'edit';

  formRoles: FormGroup;
  errors = ERRORS;
  genericList: any[];

  private roles: Array<any> = [];

  public vcr: any;
  private medicine: any;

  constructor(
    injector: Injector,
    vcr: ViewContainerRef,
    private genericNamesService: GenericNamesService
  ) {
    super(injector);
    this.service = injector.get(MedicinesService);
    this.setSettings(settings);
    this.vcr = vcr;

    this.formRoles = this.formBuilder.group({
      'roles': this.formBuilder.array([])
    });

    this.genericNamesService.index('limit=100000000000').subscribe((data: any) => {
      this.genericList = data.data;
    });
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group(MEDICINES_MODEL, { updateOn: 'blur' })
    this._load({mode: 'complete'}).subscribe((result: any) => {
      this.medicine = result.data;
      Object.keys(MEDICINES_MODEL).forEach(key =>
        this.rForm.get(key).setValue(result.data[key])
      );
      this.medicine.roles.data.forEach((role: any) => {
        this.onRoleCheckboxChange(role.id, true);
      });
    });
  }

  onRoleCheckboxChange(roleId: string, isChecked: boolean) {
    const rolesFormArray = <FormArray>this.formRoles.controls.roles;

    if (isChecked) {
        rolesFormArray.push(new FormControl(roleId));
        // console.log('test', roleId);
    } else {
        const index = rolesFormArray.controls.findIndex(x => x.value === roleId);
        rolesFormArray.removeAt(index);
    }
  }

  isChecked(roleId: any) {
    const checked = false;

    const a = this.medicine.roles.data.filter((role: any) => {
        return roleId === role.id;
    });

    if (a.length) {
        return true;
    }
    return false;
  }

}
