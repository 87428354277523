import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

// PAGES
import { PublicModule } from './pages/public/public.module';
import { NoContentComponent } from './pages/no-content/no-content.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';

// ADMIN PAGES
import { AdminModule } from './pages/admin/admin.module';

// USER DASHBOARD PAGES
import { DashboardModule } from './pages/dashboard/dashboard.module';

import { ApiInterceptor } from './interceptors/api.interceptor';
import { HttpStatusInterceptor } from './interceptors/httpStatus.interceptor';

import { AuthenticationService } from './core/authentication/authentication.service';
import { VideoCallModule } from './pages/video-call/video-call.module';
import { LoaderService } from './services/loader.service';
import { NotesService } from './services/notes.service';
import { PrescriptionFlagUpdateService } from './services/prescription-flag-update.service';
import { DoctorAppointmentService, UserService } from './services';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule, BsLocaleService, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FeedbackFormModule } from './pages/feedback/feedback.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { OtsukaSurveyFormComponent } from './components/otsuka-survey-form/otsuka-survey-form.component'
import { OtsukaService } from './components/otsuka-survey-form/service'
import { TermsAgreementComponent } from './components/termsagreement/termsagreement.component'
import { TermsAgreementService } from './components/termsagreement/service'


declare module "@angular/core" {
    interface ModuleWithProviders<T = any> {
      ngModule: Type<T>;
      providers?: Provider[];
    }
  }


@NgModule({
    imports: [
        ProgressbarModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        // FormsModule,
        // ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        NgbModule,
        AccordionModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        // TypeaheadModule.forRoot(),
        TimepickerModule.forRoot(),
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule,
        CoreModule,

        PublicModule,
        // ADMIN
        AdminModule,
        DashboardModule,
        VideoCallModule,
        FeedbackFormModule,
        SelectDropDownModule,

        // PUBLIC PAGES

        // NoContentComponent,


        AppRoutingModule, // KEEP THIS AFTER ALL
        TabsModule.forRoot(),
        DeviceDetectorModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        NoContentComponent,
        MaintenanceComponent,

        OtsukaSurveyFormComponent,
        TermsAgreementComponent,
    ],
    providers: [
        AuthenticationService,
        LoaderService,
        NotesService,
        PrescriptionFlagUpdateService,
        DoctorAppointmentService,
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpStatusInterceptor, multi: true },
        BsLocaleService,
        BsDatepickerConfig,
        UserService,

        OtsukaService,
        TermsAgreementService,
    ],
    bootstrap: [AppComponent]
})


export class AppModule { }
