import { Component, OnInit, Injector } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { ListComponent as CrudListComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { RoleService } from '../../../../../services/role.service';

// STEP 5: IMPORT settings
import { settings } from '../../settings';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends CrudListComponent {

  public columns: any;

  constructor(injector: Injector) {
    super(injector);
    this.setSettings(settings)
    this.service = injector.get(RoleService);
  }

  getEditLink(row: any) {
      return this.settings.crud.update.url + row.id;
  }

}
