import { Component, OnInit, Injector, ViewContainerRef  } from '@angular/core';
// import { ActivatedRoute, Router } from '@angular/router';
import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { PrescriptionTransactionService } from '../../../../../services/prescription-transaction.service';
import { EventPrescriptionItemService } from '../../../../../services/event-prescription-items.service';
import { OrderTransactionService } from '../../../../../services/order-transaction.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';
import { ToastrManager } from 'ng6-toastr-notifications';

// import { MESSAGES_MODEL, ROLES_MODEL, ERRORS } from '../../orders.model';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent extends CrudEditComponent implements OnInit {

  // formRoles: FormGroup;
  // errors = ERRORS;
  // message: string;
  // from: string;
  // name: string;
  // phone: string;
  // href: string;

  public transaction: any;
  // public transaction: any;
  public prescriptionItems: any;
  public status: any;
  public params: any;
  // public prescriptionTransactionService: any;

  public vcr: any;

  constructor(
    injector: Injector,
    vcr: ViewContainerRef,
    private evntPrescriptionItemService: EventPrescriptionItemService,
    private prescriptionTransactionService: PrescriptionTransactionService,
    public toastr: ToastrManager
    // public activatedRoute: ActivatedRoute
  ) {
    super(injector);
    this.service = injector.get(OrderTransactionService);
    // this.orderTransaction = injector.get();
    this.setSettings(settings);
    this.vcr = vcr;
    // console.log("setting", settings);
    // this.toastr.setRootViewContainerRef(vcr);

    this.status = ['pending','processing','prepared','complete','failed','cancelled'];

    // console.log("objectId", this.objectId);
  }

  ngOnInit() {
    // this.rForm = this.formBuilder.group(MESSAGES_MODEL, { updateOn: 'blur' });
    

    this.loaddata();

    

    // this.loadCartTransaction();
  }

  loaddata()
  {
    this._load().subscribe((result: any) => {
      // console.log("result", result);
      this.transaction = result.data;
      // console.log("transaction", this.transaction);
      
      // this.params = {
      //   with: 'item.prescription;item.medicine',
      //   search: this.transaction.transaction_id,
      //   searchFields: 'transaction_number',
      //   orderBy: 'created_at',
      //   sortedBy: 'desc'
      // };

      // this.loadTransactions();

    });
  }

  loadTransactions()
  {
    this.prescriptionTransactionService
      .getItemsByTransaction(this.params)
      .subscribe( (res ) => {
        let transactionOrders = res;
        this.prescriptionItems = transactionOrders;
          // console.log("this tran",this.prescriptionItems.data);
      })
  }

  // loadCartTransaction()
  // {
  //   let param = {
  //       // with: 'item.prescription;item.medicine',
  //       search: this.objectId,
  //       searchFields: 'transaction_id',
  //       orderBy: 'created_at',
  //       sortedBy: 'desc'
  //   };

  //   this.orderTransactionService
  //     .getItem(param)
  //     .subscribe( (res) => {
  //       console.log("cart transaction", res);
  //     } );

  // }


  updateStatus(status)
  {
    this.service.updateStatus(this.transaction.id, status)
      .subscribe(response => {
          // console.log('success');
          this.toastr.successToastr('Item Status Saved', 'Updated!');
          this.loaddata();
      }, error => {
        this.toastr.errorToastr(error.message, 'Error!');
      });
    // console.log(status);
  }

  updateCartTransaction()
  {
    // console.log('update');
  }


}
