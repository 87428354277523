import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { SlotComponent } from './slot.component'
import { TimeFieldsComponent } from './time-fields/time-fields.component'
import { DurationComponent } from './duration/duration.component'
import { ConsultationTypeComponent } from './consultation-type/consultation-type.component'
import { SummaryComponent } from './summary/summary.component'
import { SummaryPxDetailsComponent } from './summary/px-details/px-details.component'
import { ClinicComponent } from './clinic/clinic.component'
import { ClinicService } from '../../../../services/clinic.service'

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        NgbModule,
        RouterModule,
    ],
    declarations: [
        SlotComponent,
        TimeFieldsComponent,
        DurationComponent,
        ConsultationTypeComponent,
        SummaryComponent,
        ClinicComponent,
        SummaryPxDetailsComponent,
    ],
    exports: [
        SlotComponent,
        TimeFieldsComponent,
        DurationComponent,
        ConsultationTypeComponent,
        SummaryComponent,
        ClinicComponent,
        SummaryPxDetailsComponent,
    ],
    providers: [
        ClinicService,
    ],
})
export class SlotModule { }
