<div class="bg-light fixed-top p-2">
    <a [routerLink]="['/me']">
        <img src="./assets/images/logo.png" style="width:330px;">
    </a>
</div>
<div class="container-fluid d-flex justify-content-center align-items-center" style="height:100vh">
    <div class="mx-auto text-center">
        <h1>You've found a page that doesn't exist</h1>
        <h5>... but that's okay. Things like this happens sometimes.</h5>
        <h4>
            Why don't we start from the beginning. 
            <br /> 
            <a class="btn btn-primary" [routerLink]="'/me'">Let's go back HOME</a>
        </h4>
    </div>

    
</div>


<!--
    #649d71
    #528cc9
    #e0984c
 -->
