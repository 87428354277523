export const settings = {
    plural: 'Feedbacks',
    singular: 'Feedback',
  
    crud: {
      list: {
        allow: true,
        url: '/admin/feedbacks'
      },
      view: {
        allow: true,
        url: '/admin/feedbacks/view/'
      }
    }
  };