import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CartItemService extends AbstractService {
  endpoint = environment.serverUrl + 'cart/items';

  constructor(http: HttpClient) {
    super(http);
  }
}
