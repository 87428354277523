<!-- <app-summary-px-details [selectedEvent]="selectedEvent" class="col-sm-6"
  *ngIf="actionMode=='view' && selectedEvent && selectedEvent.status=='BOOKED'"></app-summary-px-details> -->


<div [ngClass]="{'col-sm-6' : actionMode=='view'}">

  <div>
    <strong style="font-size: 16px;">{{ getPatientName()}}</strong>
  </div>
  
  <div>
    <fa-icon [icon]="faCheckCircle" [ngClass]="{'text-success' : slotDetails.type}"></fa-icon> Consultation Type: <span
      [innerHTML]="getSlotTypeLabel()"></span>
  </div>


  <div *ngIf="slotDetails.type=='face'">
    <fa-icon [icon]="fa.faCheckCircle" [ngClass]="{'text-success' : slotDetails.clinic_id}"
      *ngIf="slotDetails.clinic_id">
    </fa-icon>
    <fa-icon [icon]="fa.faExclamationCircle" class="text-warning" *ngIf="!slotDetails.clinic_id"></fa-icon>
    Clinic: <span [innerHTML]="getClinicLabel()"></span>
  </div>

  <div>
    <fa-icon [icon]="fa.faCheckCircle" [ngClass]="{'text-success' : slotDetails.duration}"></fa-icon> Duration: <span
      [innerHTML]="getDurationLabel()"></span>
  </div>

  <div>
    <fa-icon [icon]="fa.faCheckCircle" [ngClass]="{'text-success' : (slotDetails.start_at && slotDetails.end_at)}"
      *ngIf="isValidTime"></fa-icon>
    <fa-icon [icon]="fa.faExclamationCircle" class="text-warning" *ngIf="!isValidTime"></fa-icon>
    Time: <span [innerHTML]="getTimeLabel()"></span>&nbsp;<span
      *ngIf="slotDetails.start_at && slotDetails.end_at && isValidTime" [innerHTML]="timezone"></span>
  </div>


  <div *ngIf="getRateLabel()">
    <fa-icon [icon]="fa.faCheckCircle" [ngClass]="{'text-success' : (slotDetails.start_at && slotDetails.end_at)}"
      *ngIf="isValidTime"></fa-icon>
    <fa-icon [icon]="fa.faExclamationCircle" class="text-warning" *ngIf="!isValidTime"></fa-icon>
    Rate: <span [innerHTML]="getRateLabel()"></span>
  </div>

  <div class="alert alert-danger mt-3" *ngIf="!getRateLabel()">
    No clinic rates found. Doctor must set clinic rates.
  </div>
</div>



<!-- {{ selectedEvent | json}} -->

<!--
    <br />
    <fa-icon [icon]="fa.faCheckCircle" [ngClass]="{'text-success' : slotDetails.type}"></fa-icon> Timezone<br /> <span [innerHTML]="getTimezoneLabel()"></span>
-->
<!-- <br />
Number of slots:
<br />
Total time:
<br />
-->
<!-- {{ slotDetails | json}} -->