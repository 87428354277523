import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class OtsukaService extends AbstractService {
  endpoint = environment.serverUrl + 'otsuka-mars';

  setAnswer = (data) => {
    return this.http.post(
        `${this.endpoint}/answer` , 
        data,
        {
            headers: {
              'Accept': environment.API_HEADER_ACCEPT_v2
            }
        }
    )
    .map((result: any) => {
        return result;
    }, (err: any) => {
        return err;
    });
  }

  getPxQuestions = (pxId, bookingId) => {
    return this.http.post(
      `${this.endpoint}/px-questions`, 
      {
        booking_id: bookingId,
        patient_id: pxId,
      },
      {
          headers: {
            'Accept': environment.API_HEADER_ACCEPT_v2
          }
      }
  )
  .map((result: any) => {
      return result;
  }, (err: any) => {
      return err;
  });
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
