import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import 'rxjs/add/operator/map';

// export abstract class AbstractService implements ServiceInterface {
export class AbstractService {
    public endpoint = 'changethis';

    constructor(public http: HttpClient) {
        this.setEndpoint();
    }

    index(e?: any): Observable<any> {
        
        let sq = '';
        
        if (e) {
            if (e.offset) {
                sq += '&page=' + (e.offset + 1);
        } else if (e.pageSize) {
                sq += '&take=' + e.pageSize;
            } else if (typeof (e) === 'string') {

                sq += '&' + e;
            } else {
                sq += this.buildQueryString(e) 
            }
        }

        return this.http.get(this.endpoint + (sq.match(/^\?/) ? sq : `?${sq}`))
            .map((result: any) => {
                if (result) {
                    return result;
                }
            });
    }

    show(id: any, params?: any): Observable<any> {

        return this.http.get(this.endpoint + '/' + id + this.buildQueryString(params))
            .map((result: any) => {
                return result;
            });
        // .share();
    }

    destroy(id: any, params = {}) {
        return this.http.delete(this.endpoint + '/' + id, params)
            .map((result: any) => {
                return result;
            });
    }

    update(id: any, params: any) {
        return this.http.put(this.endpoint + '/' + id, params)
            .map((result: any) => {
                return result;
            }, (err: any) => {
                return err;
            });
    }

    store(data: any) {
        return this.http.post(this.endpoint, data)
            .map((result: any) => {
                return result;
            }, (err: any) => {
                return err;
            });
    }

    storeMultiple(data: any) {
        const requests = [];
        data.forEach(d => requests.push(this.http.post(this.endpoint, d)));
        
        // return forkJoin(requests)
        //     .map((result: any) => {
        //         return result;
        //     }, (err: any) => {
        //         return err;
        //     });
    }


    setEndpoint(endpoint?: any) {
        if (endpoint) {
            this.endpoint = environment.serverUrl + endpoint;
        }
    }


    public buildQueryString(params: any) {
        let sq = '';

        if (params) {
            sq += '?';
            if (typeof (params) === 'string') {
                sq += params;
            } else if (typeof (params) === 'object') {
                Object.keys(params).map(
                    (key) => {
                        // console.log(params[key]);
                        sq += key + '=' + params[key] + '&';
                    }
                );

            } else { }
            // if (params.offset) {
            //     sq += '&page=' + (params.offset + 1);
            // } else if (params.pageSize) {
            //     sq += '&take=' + params.pageSize;
            // } else if (typeof(params) == 'string') {

            //     sq += '&' + params
            // } else {
            //     sq +=
            // }
        }

        return sq;
    }
}
