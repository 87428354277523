import { Injectable } from '@angular/core';
import { AbstractService } from './abstract.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PermissionService extends AbstractService {

    constructor(http: HttpClient) {
        super(http);
    }

}
