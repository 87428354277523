<div 
    *ngIf="isModalShown" 
    [config]="{ 
        show: true,
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false
     }" 
    (onHidden)="onHidden()" 
    bsModal 
    #autoShownModal="bs-modal"
    class="modal fade ots" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="dialog-auto-name"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div *ngIf="processingAnswer" class="answer-processing-overlay">
                <table style="height:100%;min-height: 100px; width:100%;">
                    <tbody>
                        <tr>
                            <td class="align-middle text-center">
                                <div>Submitting answer selection...</div>
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="modal-header">
                <h4 id="dialog-auto-name" class="modal-title pull-left">Medical Adherance Form</h4>
                <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button> -->
            </div>
            <div class="modal-body" style="position: relative;">
                <table style="min-height: 100px; width:100%;" *ngIf="loadingQuestions">
                    <tbody>
                        <tr>
                            <td class="align-middle text-center">
                                <div>Loading survey form...</div>
                                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                            </td>
                        </tr>
                    </tbody>                    
                </table>
                <table class="table small" *ngIf="!loadingQuestions && questions.length">
                    <!-- <thead>
                        <tr>
                            <td >Questions</td>
                            <td>Answer</td>
                        </tr>
                    </thead> -->
                    <tbody>
                        <tr *ngFor="let question of questions" [class.no-answer]="question.answer === null">
                            <td>{{ question.question }}</td>
                            <td>
                                <div class="btn-group btn-group-toggle" data-toggle="buttons" style="cursor:pointer">
                
                                    <label class="btn btn-secondary" (click)="setAnswer(1, question.id)" [class.active]="question.answer == 1">
                                      <input type="radio" name="answer" id="option2" autocomplete="off" value="1" [checked]="(question.answer == 1)"> Yes
                                    </label>
                                    <label class="btn btn-secondary" (click)="setAnswer(0, question.id)" [class.active]="question.answer == 0">
                                      <input type="radio" name="options" id="option3" autocomplete="off" value="0" [checked]="question.answer == 0"> No
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2">
                                <div class="row">
                                    <div class="col-12">
                                        <label class="form-label">
                                            <strong>List your medications&nbsp;</strong>
                                            <span *ngIf="medicationRequiredError" class="small text-danger">Please list the medications you have taken</span>    
                                        </label>
                                    </div>
                                    <div class="col-sm-10">
                                        <textarea name="medications" rows="3" class="form-control" (keyup)="setMedication($event)">{{ medications }}</textarea>
                                    </div>
                                    <div class="col-sm-2">
                                        <a (click)="submit()" class="btn-submit btn btn-primary btn-sm text-center">
                                            <span>Submit</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pt-3">
                                        <label class="font-italic">
                                            * For any concerns related to adverse events and product safety, you may discuss it with your doctor.&nbsp;
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>


<div 
    *ngIf="showThankYouModal" 
    [config]="{ 
        show: true
     }" 
    (onHidden)="onHidden()" 
    bsModal 
    #autoShownModal="bs-modal"
    class="modal fade" 
    tabindex="-1" 
    role="dialog" 
    aria-labelledby="dialog-auto-name"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="dialog-auto-name" class="modal-title pull-left">Medical Adherance Form</h4>
                <!-- <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <h1 class="display-4 m-5 text-center">
                    Thank you for your response.
                </h1>

                <div class="text-right">
                    <button type="button" class="btn btn-primary" (click)="closeThankYou()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>