import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { RegisterComponent } from './register/register.component';
import { PhomeComponent } from './phome/phome.component';
import { DoctorsComponent } from './doctors/doctors.components';

@NgModule({
  imports: [
    CommonModule,
    PublicRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule
  ],
  declarations: [
    PublicComponent,
    ForgotPasswordComponent,
    LoginComponent,
    RegisterComponent,
    PhomeComponent,
    DoctorsComponent
  ]
})
export class PublicModule { }
