import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// REQUIRED MODULE AND COMPONENT
// import { ThemeModule } from './../../../theme.module';
import { HeaderComponent } from './header/header.component';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
// import { HeaderModule } from './header/header.module';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { CreateComponent } from './create/create.component';
import { CrudComponent } from './crud.component';


// ALTERNATIVE
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// FOR THE TABLE LIST
import { BaseCrudFormComponent } from './base-crud-form/base-crud-form.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // HeaderModule,
        /** START :: REQUIRED ABOVE */

        FormsModule,
        ReactiveFormsModule,  // Add this!
        ModalModule,

        /** END :: REQUIRED ABOVE */
    ],
    declarations: [
        ListComponent,
        EditComponent,
        CreateComponent,
        HeaderComponent,
        FormButtonsComponent,
        BaseCrudFormComponent,
        CrudComponent,
    ],
    providers: [
    ],
    exports: [
        ListComponent,
        EditComponent,
        CreateComponent,
        HeaderComponent,
        FormButtonsComponent,
        CrudComponent
    ]
})
export class CrudModule { }
