import { Component, OnInit, NgZone, Input } from "@angular/core";
import {
    UserService,
    FeedbackService,
    AvailabilityService,
    avatar,
    formatDate,
    doctorName,
    patientName,
    NotesService,
} from "../../services";
import { DOCTOR_MED_SHEET_NEW } from "./doctor-med-sheet-new";
import * as _ from "lodash";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "../../shared/models";
import { ToastrManager } from "ng6-toastr-notifications";
import { faLock } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-feedback-form",
    templateUrl: "./feedback.component.html",
    styleUrls: ["./feedback.component.scss"],
})
export class FeedbackFormComponent implements OnInit {
    @Input() showHeader = false;
    questions: any[];
    feedbackData: any;
    isPatient = true;
    docMedSheet: any[];
    eventId: string;
    user: User;
    patientResponse: string;
    rating: number;
    saving = false;
    patientRecord = null;
    doctorRecord = null;

    event: any;
    isAllowed = false;
    loading = true;
    expired = false;
    problem = false;
    faLock = faLock;

    selectedSection = null;

    avatar = avatar;
    formatDate = formatDate;
    doctorName = doctorName;
    patientName = patientName;

    max = 5;
    isReadonly = false;

    constructor(
        userService: UserService,
        private router: Router,
        private feedbackService: FeedbackService,
        private noteService: NotesService,
        private toastr: ToastrManager,
        private activatedRoute: ActivatedRoute,
        private availabilityService: AvailabilityService
    ) {
        this.user = userService.getStoredCredentials();
        if (!this.user.id) {
            this.router.navigate(["login"]);
        }
        this.isPatient = !!this.user.roles.find(({ id }) => id === 3);

        this.activatedRoute.params.subscribe((data) => {
            this.eventId = data.eventId;
        });

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.s) {
                this.selectedSection = params.s;
                // set section to
            }
        });

        // VERIFY
        if (this.showHeader !== undefined) {
            this.showHeader = true;
        }
    }

    async ngOnInit() {

        this.noteService.saveLatestConsultation(this.eventId).subscribe((data: any) => {});

        await this.check()
            .then((res) => {
                this.loading = false;
            })
            .catch((e) => {
                if (e.error.message === "restricted") {
                    this.expired = true;
                } else {
                    this.problem = true;
                }

                this.loading = false;
            });
        this.questions = [
            {
                query:
                    "We at Recovery Hub would gladly want to know how our online services have been able to" +
                    " cater to your needs so we could likewise know what we could do to improve our services for the " +
                    "better. Thus it would be with our full gratitude that you could provide us with your feedback on" +
                    " how our services went.",
                type: "textbox",
            },
        ];

        let data = await this.getData()
            .then((res) => {
                this.loading = false;
                return res.data[0];
            })
            .catch((err) => {
                // console.log(err)
            });

        if (!this.isPatient) {
            // CHeck if current user (doctor) has access to this event
            if (data.bookings.bookable.doctor.id == this.user.id) {
                this.isAllowed = true;
                this.generateSheet();
                this.patientRecord = data.bookings.patient;
            }
        } else {
            if (data.bookings.patient.id == this.user.id) {
                this.isAllowed = true;
                this.doctorRecord = data.doctor;
            }
        }

        this.availabilityService
            .show(this.eventId, {
                search: this.eventId,
                with: "physicalExam;prescriptions.customs;prescriptions.items.medicine.generic;notes.dsmnotes;notes.customDiagnosis;clinic",
                limit: 1,
            })
            .subscribe(
                (res) => {
                    this.event = res.data;
                    // console.log(res, this.event)
                },
                (err) => {
                    console.log(err);
                }
            );

        this.feedbackService
            .index({
                search: this.eventId,
                searchFields: "event_id",
                orderBy: "created_at",
                sortedBy: "desc",
            })
            .subscribe((res) => {
                if (res.data.length) {
                    this.feedbackData = res.data[0];
                }
            });
    }

    async getData(): Promise<any> {
        this.loading = true;
        return await this.availabilityService
            .index({
                with: "bookings.event.doctor;bookings.patient",
                search: `${this.eventId}`,
                searchFields: "id",
                limit: 0,
            })
            .toPromise();
    }

    async check(): Promise<any> {
        this.loading = true;
        return await this.feedbackService.check(this.eventId).toPromise();
    }

    submitClickBtn() {
        if (this.isPatient) {
            this.saving = true;
            this.feedbackService.savePatientFeedback(this.eventId, this.user.id, this.patientResponse, this.rating).subscribe(
                (data) => {
                    this.saving = false;
                    this.router.navigate(["me"]);
                    this.toastr.successToastr("Saved!");
                },
                (e) => {
                    this.toastr.errorToastr(e.error.message || "Something went wrong.");
                    this.saving = false;
                }
            );
        }

        if (!this.isPatient) {
            this.saving = true;
            this.feedbackService.saveDoctorFeedback(this.eventId, this.user.id, this.feedbackData).subscribe(
                (data) => {
                    this.saving = false;
                    this.router.navigate(["me"]);
                },
                (e) => {
                    this.toastr.errorToastr(e.error.message || "Something went wrong.");
                    this.saving = false;
                }
            );
        }
    }

    goToAppointment() {
        this.router.navigate(["me/appointments"]);
    }

    generateSheet() {
        const medSheet = DOCTOR_MED_SHEET_NEW.map((val) => {
            let item;
            if (_.isString(val)) {
                item = {
                    label: val,
                    key: _.camelCase(val),
                    type: val === "hr" ? "hr" : "textarea",
                };
            }

            if (_.isObject(val)) {
                const obj = val as any;
                item = {
                    ...obj,
                    key: obj.key ? obj.key : _.camelCase(obj.label),
                };
            }
            return item;
        });

        this.docMedSheet = medSheet;
        this.feedbackData = {};
    }

    displayRate() {
        switch (this.rating) {
            case 1:
                return 'Terrible';
            case 2:
                return 'Poor';
            case 3:
                return 'Fair';
            case 4:
                return 'Good';
            case 5:
                return 'Amazing';
        }
    }
}
