<div class="mb-1">
    {{label_MedicalCertificate(doctor)}}: 
    <span 
        [innerHTML]="ctaAddToCart('medical_certificate')" 
        >
    </span>

    <span 
        (click)="actionAddToCart('medical_certificate')"    
        class="btn btn-success badge text-bg-primary p-1" 
        *ngIf="ctaAddToCartBtnDisplay.medical_certificate"
    >
        <fa-icon [icon]="fa.faCartPlus"></fa-icon> {{getPrice('medical_certificate')}}
    </span>
</div>
<div class="mb-1">
    {{label_MedicalAbstract(doctor)}}: 
    <span [innerHTML]="ctaAddToCart('abstract_report')"></span>
    <span 
        (click)="actionAddToCart('abstract_report')" 
        class="btn btn-success badge text-bg-primary p-1" 
        *ngIf="ctaAddToCartBtnDisplay['abstract_report']"
    >
        <fa-icon [icon]="fa.faCartPlus"></fa-icon> {{getPrice('abstract_report')}}
    </span>
</div>