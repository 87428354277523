import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { MessagesService } from '../../../../../services/messages.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { MESSAGES_MODEL, ROLES_MODEL, ERRORS } from '../../messages.model';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent extends CrudEditComponent implements OnInit {

  formRoles: FormGroup;
  errors = ERRORS;
  message: string;
  from: string;
  name: string;
  phone: string;
  href: string;

  public vcr: any;

  constructor(
    injector: Injector,
    vcr: ViewContainerRef
  ) {
    super(injector);
    this.service = injector.get(MessagesService);
    this.setSettings(settings);
    this.vcr = vcr;
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group(MESSAGES_MODEL, { updateOn: 'blur' });
    this._load({mode: 'complete'}).subscribe((result: any) => {
      this.message = result.data.form_data.message;
      this.name = result.data.form_data.name;
      this.from = result.data.form_data.from;
      this.phone = result.data.form_data.phone;
      this.href = 'mailto:'+result.data.form_data.from;
    });
  }
}
