import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from '../../../services'
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-phome',
    templateUrl: './phome.component.html',
    styleUrls: ['./phome.component.scss']
})
export class PhomeComponent implements OnInit {
    contactForm: FormGroup;
    user = {
        id: null
    }

    year = new Date();

    constructor(
        fb: FormBuilder, 
        private http: HttpClient, 
        private toastr: ToastrManager,
        private userService: UserService,
        private viewportScroller: ViewportScroller,
    ) {
        this.contactForm = fb.group({
            from: [null],
            message: [null],
            name: [null],
            phone: [null],
        });

        this.user = this.userService.getStoredCredentials()
    }

    ngOnInit() { }


    public onClick(posY: number): void { 
        // this.viewportScroller.scrollToAnchor(elementId);
        this.viewportScroller.scrollToPosition([0, posY])
    }

    onSubmit() {
        // console.log('test', this.contactForm.value);
        return this.http.post(environment.serverUrl + 'contact-form-submissions', this.contactForm.value)
            .toPromise().then(() => {
                this.toastr.successToastr('Contact request sent!');
                this.contactForm.reset();
            }, e => this.toastr.errorToastr('Something went wrong!', e.message));

    }
}
