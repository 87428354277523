<div class="phome">
    <div class="container">
        <nav class="navbar navbar-expand-md bg-white fixed-top text-uppercase clearfix">
            <div class="container">
                <a class="navbar-brand" [routerLink]="['/']">
                    <img src="./assets/images/logo.png" class="brand-logo">
                </a>
                <button class="navbar-toggler navbar-toggler-right text-uppercase btn-primary text-white rounded"
                    type="button" data-toggle="collapse" data-target="#navbarResponsive"
                    aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">

                    <i class="fa fa-bars"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarResponsive">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center" routerLink="/"
                                fragment="what-is-recovery-hub">
                                What is RecoveryHub
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center as-pointer" (click)="doctor=undefined"
                                id="who-is">
                                Our Psychiatrists
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-dark mb-1 text-center" routerLink="/">
                                Blog
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-center text-dark mb-1" routerLink="/"
                                fragment="how-do-we-recover">
                                Get In Touch
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link py-2 px-0 px-lg-2 text-center text-dark mb-1"
                                href="/assets/docs/RecoveryHub_Doctor_SignupForm-MOA.pdf">
                                Apply as Doctor
                            </a>
                        </li>
                        <li class="nav-item mx-0 mx-lg-1">
                            <a class="nav-link btn-primary btn-green mx-3 rounded" href="/login"
                                *ngIf="!user.id">Login</a>
                            <a class="nav-link btn-primary btn-green mx-3 rounded" href="/me" *ngIf="user.id">My App</a>
                        </li>

                        <!-- <li class="nav-item btn-links" style="font-size:10px !important;line-height: 1px;padding: 0 10px;">
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- Who is recovery hub -->
<section class="my-5 p-3" id="who-is-recovery-hub">
    <div *ngIf="!doctor" class="container-fluid">
        <h1 class="text-center">OUR PSYCHIATRISTS {{doctor}}</h1>
        <div class="d-flex justify-content-center align-items-center mobile-flex-unwrap">
            <div class="rounded p-2 m-3 text-ce">
                <div class="rounded-top doctorBox" style="background-image: url('./assets/images/flores.jpg')"></div>
                <div class="border rounded-bottom border-top-0 d-flex align-items-center justify-content-center p-2">
                    <button type="button" class="btn btn-link border-0 btn-sm control-text" (click)="doctor='flores'">
                        Dr. Fareda Fatima A. Flores<br>
                        <small>MD, FPPA</small><br>
                        <small>Psychiatrist</small>
                    </button>
                </div>

            </div>
            <div class="rounded p-2 m-3 text-ce">
                <div class="rounded-top doctorBox" style="background-image: url('./assets/images/ponio.jpg')"></div>
                <div class="border rounded-bottom border-top-0 d-flex align-items-center justify-content-center p-2">
                    <button type="button" class="btn btn-link border-0 btn-sm control-text" (click)="doctor='ponio'">
                        Dr. Benita Sta Ana Ponio<br>
                        <small>MD, FPPA (Life)</small><br>
                        <small>Psychiatrist</small>

                    </button>
                </div>
            </div>
            <div class="rounded p-2 m-3 text-ce">
                <div class="rounded-top doctorBox" style="background-image: url('./assets/images/sy.jpg')"></div>
                <div class="border rounded-bottom border-top-0 d-flex align-items-center justify-content-center p-2">
                    <button type="button" class="btn btn-link border-0 btn-sm control-text" (click)="doctor='sy'">
                        Dr. Jacqueline Te Sy<br>
                        <small>MD, FPPA</small><br>
                        <small>Psychiatrist</small>
                    </button>
                </div>
            </div>

            <div class="rounded p-2 m-3 text-ce">
                <div class="rounded-top doctorBox" style="background-image: url('./assets/images/reyes.jpg')"></div>
                <div class="border rounded-bottom border-top-0 d-flex align-items-center justify-content-center p-2">
                    <button type="button" class="btn btn-link border-0 btn-sm control-text" (click)="doctor='reyes'">
                        Dr. Jermeine De Las Armas - Reyes<br>
                        <small>MD, RMT, DSBPP</small><br>
                        <small>Psychiatrist</small>
                    </button>
                </div>
            </div>
            <div class="rounded p-2 m-3 text-ce">
                <div class="rounded doctorBox" style="background-image: url('./assets/images/roces.jpg')"></div>
                <div class="border border-top-0 d-flex align-items-center justify-content-center p-2">
                    <button type="button" class="btn btn-link border-0 btn-sm control-text" (click)="doctor='roces'">
                        Dr. Myra Dee Lopez Roces<br>
                        <small>MD, FPPA</small><br>
                        <small>Psychiatrist</small>
                    </button>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="doctor" class="container">
        <div class="row py-3">
            <div class="col-4" style="height: 500px">
                <img [src]="imgSrc" alt="Dr. Flores" width="100%">
            </div>
            <div class="col">
                <div *ngIf="doctor==='flores'">
                    <ng-container *ngTemplateOutlet="flores"></ng-container>
                </div>
                <div *ngIf="doctor==='ponio'">
                    <ng-container *ngTemplateOutlet="ponio"></ng-container>
                </div>
                <div *ngIf="doctor==='sy'">
                    <ng-container *ngTemplateOutlet="sy"></ng-container>
                </div>
                <div *ngIf="doctor==='reyes'">
                    <ng-container *ngTemplateOutlet="reyes"></ng-container>
                </div>
                <div *ngIf="doctor==='roces'">
                    <ng-container *ngTemplateOutlet="roces"></ng-container>
                </div>
            </div>
        </div>
    </div>
</section>




<ng-template #flores>
    <h3> Dr. Fareda Fatima A. Flores , MD, FPPA</h3>
    <p>
        Graduated Doctor Of Medicine at the University of Santo Tomas in 1993 <br>
        Passed the Medical Licensure Examination given by the Professional Regulations Commission in 1994
    </p>
    <p>
        Residency Training in Psychiatry at the National Center for Mental Health, Mandaluyong City, Philip-pines from
        1995
        to 1999.
    </p>
    <p>
        Passed the Diplomate Examination given by the Philippine Board of Psychiatry in 2001 and con-ferred a Fellow
        Status
        by the Philippine Psychiatric Association in 2004.
    </p>
    <p>
        Treasurer - Philippine Psychiatric Association 2013 - 2015 <br>
        Vice President - Philippine Psychiatric Association 2015 - 2016 <br>
        President - Philippine Psychiatric Association 2016 - 2017 <br>
    </p>
    <p>

        Treasurer - Group for Addiction Psychiatry in the Philippines 2004 - 2014 <br>
        Vice President - Group for Addiction Psychiatry in the Philippines 2014 - 2017
    </p>
    <p>

        Awarded Most Outstanding Psychiatrist in Community Service in 2008 by the Philippines Psychiatric Association.
    </p>
    <hr>
    <h5>

        Other Related Trainings/Experience:
    </h5>
    <p>

        Basic Management Course at the Asian Institute of Management in 2001
    </p>
    <p>

        Executive Masters in Business Management at the Asian Institute of Management 2002 to 2004
    </p>
    <p>

        Goldman and Sach's 10,000 Women Scholar at the University of the Asia and the Pacific on Busi-ness Training
        Program
        for Women in 2010
    </p>
    <p>

        Managing Health Care Delivery Program at Harvard Business School October 2017 to May 2018
    </p>
    <p>

        Anger Management by Anderson and Anderson in San Diego California in 2003
    </p>
    <p>

        Trainings in Mindfulness Self Compassion as participant in 2014 and its Teacher Training Course in November
        2015
        conducted by University of California San Diego
    </p>
    <p>

        Training in Mindfulness Based Relapsed Prevention (On Addiction) UC San Diego June 2015 and Mindfulness Based
        Cognitive Therapy by UC San Diego in August 2015 in Seattle Wa State.
    </p>
    <p>

        Training in Mindfulness Stress Reduction with the University of Massachusetts 2016 in Rhinebeck New York.
    </p>
    <p>

        Training in Mindful Self Compassion for Teens Teacher Training with UC San Diego March 2017
    </p>
    <hr>
    <h5>
        Current Positions
    </h5>
    <p>
        Managing Director
        Metro Psych Facility
        Roads and Bridges to Recovery Drug Treatment and Rehabilitation Center
        Manila and Cebu
    </p>
    <p>

        Immediate Past President
        Philippine Psychiatric Association
    </p>
    <p>

        President
        Group for Addiction Psychiatry in the Philippines
    </p>

</ng-template>


<ng-template #ponio>
    <h3> Dr. Benita Sta Ana Ponio, MD, FPPA (Life)</h3>
    <p>
        Graduated Doctor of Medicine at the Far Eastern University - Dr Nicanor Reyes Memorial Medical Foundation in
        1980, passed the Medical Licensure Examination given by the Professional Regulations Commission in 1981.
    </p>
    <p>
        Residency training in Psychiatry at the National Center for Mental Health, Mandaluyong City, Philippines from
        1984 to 1988.
    </p>

    <p>
        Passed the Medical Specialist Examination in Psychiatry given by the Department of Health in 1989.
    </p>
    <p>
        Passed the Diplomate Examination given by the Philippine Board of Psychiatry in 1991
    </p>
    <p>
        Conferred Fellow status by the Philippine Psychiatric Association in 1993.
    </p>
    <p>
        Post Graduate Training Course in Psychiatry at the National University of Singapore and Woodbridge Hospital in
        Singapore as a full scholar of the NEDA (National Economic Development Authority) 1993.
    </p>
    <hr>
    <h5>
        Other related trainings/experience:
    </h5>
    <p>
        Immersion training in Therapeutic Community as a modality of treatment for drug abuse conducted by Le Portage
        of Canada in La Union in 1991 and part of the group that pioneered Welcome Station (Department of Health Drug
        Treatment Rehabilitation Center at the National Center for Mental Health) in 1991.
    </p>
    <p>
        Anger Management by Anderson and Anderson in San Diego California in 2003
    </p>
    <p>
        Development of Family and Peer Support Groups, The Colombo Plan, Malaysia in 2003.
    </p>
    <p>
        Awarded Most Outstanding Psychiatrist in Community Service by the Philippine Psychiatric Association 2008
    </p>

    <p>
        Training in Mindfulness Self Compassion as participant in Nov. 2014 and its Teachers Training Course in
        November 2015 conducted by University of California San Diego.
    </p>
    <p>

        Training in Mindfulness Based Relapse Prevention (On Addiction) UC San Diego June 2015
    </p>



    <p>
        Mindfulness Based Cognitive Behavioral Therapy by UC San Diego in August 2015 in Seattle Wa State.
    </p>
    <p>
        Training in Mindfulness Based Stress Reduction University of Massachusetts 2016 in Rhinebeck New York.
    </p>

    <p>
        Mindfulness Based Self Compassion for Teens (Making Friends with Yourself) UC San Diego in 2017
    </p>


    <hr>
    <h5>
        Current Position
    </h5>
    <p>
        Founding President and Executive Director: <br>
        Metro Psych Facility<br>
        Roads and Bridges to Recovery Drug Treatment and Rehabilitation Center<br>
        Manila and Cebu<br>
        2000 to present<br>
    </p>


    <h5>
        Other relevant positions:
    </h5>
    <p>
        Medical Training Officer<br>
        National Center for Mental Health 1999 to 2000
    </p>


    <p>
        Chairperson: Subcommittee on Addiction Psychiatry<br>
        Philippine Psychiatric Association<br>
        2001 to 2005<br>
    </p>

    <p>
        Founding President: Group for Addiction Psychiatry of the Philippines in 2004<br>
        Currently member, Board of Advisers
    </p>
    <p>
        Chairperson: Technical Working Group on Clinical Practice Guidelines for Methamphetamine Abuse and Dependence
        2005<br>
        Philippine Psychiatric Association<br>
        2005 to 2007

    </p>
    <p>
        Member: Experts Panel Group in the formulation of Concensus Guidelines for Schizophrenia for the Philippine
        Psychiatric Association January 2017
    </p>


</ng-template>
<ng-template #sy>
    <h3> Dr. Jacqueline Te-Sy, MD, FPPA</h3>
    <p><strong>Education</strong></p>
    <p>BS Biology - 1978-1982<br />Velez College, F. Ramos Street, Cebu City, 6000 Cebu</p>
    <p>Doctor of Medicine - 1982-1986<br />Gullas College of Medicine, University of the Visayas,<br />Gullas Medical
        Center, Banilad, Mandaue City, 6014, Cebu</p>
    <p>Post Graduate Internship - 1986-1987<br />Chong Hua Hospital,<br />Don Mariano Cui Street, Fuente Osme&ntilde;a,
        Cebu City, 6000</p>
    <p>Family Medicine Residency Training - 1990-1992<br />Chong Hua Hospital,<br />Don Mariano Cui Street, Fuente
        Osme&ntilde;a, Cebu City, 6000</p>
    <p>Psychiatry Residency Training -1995-1999<br />Department of Psychiatry, Vicente Sotto Memorial Medical
        Centre,<br />B.
        Rodriguez Street, Cebu City, Cebu</p>
    <p><strong>Positions</strong><br />Current Head<br />Section of Psychiatry and Counseling, Department of Internal
        Medicine,<br />Chong Hua Hospital</p>
    <p>Current Medical Director<br />Metro Psych Facility, Cebu City</p>
    <p>Past Chairperson<br />Philippine Mental Health Association, Cebu Chapter</p>
    <p>Vice President, 2009-2010<br />Philippine Psychiatric Association</p>
    <p>President, 2010-2011<br />Philippine Psychiatric Association</p>
    <p><strong>Hospital Affiliations</strong><br />Active Medical Staff<br />Chong Hua Hospital, Cebu City</p>
    <p>Visiting Medical Staff<br />Perpetual Succour Hospital, Cebu City</p>
    <p>Consulting Physician<br />Department of Psychiatry, Vicente Sotto Memorial Medical Center, B.
        Rodriquez<br />Street,
        Cebu City<br /><br /><strong>Academic Appointments</strong><br />Clinical Professor<br />Gullas College of
        Medicine, University of the Visayas,<br />Gullas Medical Center, Banilad, Mandaue City, 6014, Cebu</p>
    <p>Clinical Professor<br />Matias H. Aznar Memorial College of Medicine,<br />Matias H. Aznar Memorial Building,
        Villa Aznar Road, Cebu City, 6000, Cebu</p>
    <p><strong>Organizations</strong><br />Fellow, Life Member: Philippine Psychiatric Association<br />Diplomate:
        Philippine Board of Psychiatry<br />Diplomate: Philippine Academy of Family Physicians<br />International
        Member: American Psychiatric Association<br />Member: Philippine College of Occupational Medicine<br />Member:
        Cebu Medical Society</p>
    <p><strong>Research and Trainings</strong><br />1. Schizophrenia and Depression Training Program, Melbourne July
        11-17,1999<br />2. Iloperidone investigator&rsquo;s and study coordinator&rsquo;s meeting in Phuket,Thailand,
        1999<br />3. Regional Ziprasidone study A1281050. Investigators Meeting &amp; Raters<br />&nbsp; &nbsp;
        Training in Hong Kong, November 3-4, 2001<br />4. Good Clinical Practice, Shangrila Edsa, Manila, September
        1999<br />5. Good Clinical Practice in Caylabne, Laguna, 1999<br />6. Investigators Meeting and Raters
        Training, Bifeprunox study 10199, 10200,<br />March 17-18, 2004 in Singapore (primary investigator)<br />7.
        Investigator&rsquo;s meeting, Asenapine trial, January 22-23, 2005 in Barcelona,<br />Spain (primary
        investigator)<br />8. Principal Investigator, Protocol: RIS-BMN-3001 phase III bipolar I disorder<br />9.
        Principal Investigator, Protocol 11918A phase II, Major Depressive Disorder<br />10. Principal Investigator,
        Protocol 12022A phase II, treatment of depression in<br />patients with bipolar I and bipolar II
        disorder<br />11.
        Principal Investigator, Protocol RVP- 201-001, phase II, acute exacerbation<br />of acute schizophrenia or
        schizoaffective disorder<br />12. The Seven Habits of Highly Effective People, Stephen Covey
        Institute,<br />Pioneer
        Ave, Mandaluyong City, July 2010<br />13. Trial Based Cognitive Behavioral Therapy, Neuroscience Education
        Institute,<br />Carlsbad, California, 2012<br />14. EMDR part 1 training workshop: Manila, Philippines January
        10-12, 2015<br />15. Intensive Short-term Dynamic Psychotherapy: Whidbey Island, Washington,<br />August 21-26,
        2016</p>
    <p>&nbsp;</p>
</ng-template>

<ng-template #roces>
    <h3> Dr. Myra Dee Lopez Roces, MD, FPPA</h3>
    <p>Certification &amp; Licensure&nbsp;</p>
    <ul>
        <li>Fellow, Philippine Psychiatric Association&nbsp;</li>
        <li>Diplomate, Philippine Board of Psychiatry&nbsp;</li>
        <li>Licensed Medical Doctor&nbsp;</li>
    </ul>
    <p>Educational Background&nbsp;</p>
    <ul>
        <li>Graduate, Residency in Psychiatry (January 2005 - December 2008)&nbsp; The Medical City, Department of
            Psychiatry&nbsp; Ortigas Ave., Pasig City&nbsp;</li>
        <li>Doctor of Medicine (April 2003)&nbsp; University of the East Ramon Magsaysay Memorial Medical Center
            (UERMMMC) &nbsp; Aurora Blvd., Quezon City &nbsp;</li>
        <li>Bachelor of Science in BS Public Health, April 1998&nbsp; University of the Philippines, Manila&nbsp;</li>
    </ul>
    <p>Seminars/ Trainings Attended&nbsp; &nbsp;</p>
    <ul>
        <li>Dialectical Behavior Therapy Comprehensive Implementation &amp; Training Initiative (DBT CITI) , Portland
            DBT Institute, ( Portland, Oregon March 19-23, 2018)&nbsp;</li>
        <li>Mindfulness Based Cognitive Therapy, Facilitated by Zindel Segal, PhD &amp; Susan Woods, LICSW&nbsp; under
            the UCSD (School of Medicine), Center for Mindfulness (Petaluma, California&nbsp; February 14-19, 2016)
            &nbsp; &nbsp;</li>
        <li>Mindful Self-Compassion Teacher Training, Facilitated by Christopher&nbsp; Germer, PhD , Michelle Becker
            MA, LMFT and Pittman McGeehee, PhD under the University of California San Diego (UCSD), School of Medicine,
            Center for Mindfulness (Royal Way, Lucerne Valley, CA; November&nbsp; 8-14,2015)&nbsp; &nbsp;</li>
        <li>Mindfulness Based Relapse Prevention Training, Facilitated by Neha Chawla, PhD , Sarah Bowen, PhD&nbsp;
            under the UCSD (School of Medicine), Center for Mindfulness (Vashon Island, Washington; June 28-July
            3,2015) &nbsp;</li>
        <li>Yongin Mental Health Fellowship, World Health Organization Collaborating Center for&nbsp; Psychosocial
            Rehabilitation and Community Mental Health (Yongin, South Korea; April 20 to May 24, 2013)&nbsp; &nbsp;</li>
    </ul>
    <p>Others Positions: &nbsp;</p>
    <ul>
        <li>Clinical faculty, Ateneo de Manila School of Medicine &amp; Public Health (ASMPH)&nbsp;</li>
    </ul>
</ng-template>

<ng-template #reyes>
    <h3> Dr. Jermeine De Las Armas - Reyes, MD, RMT, DSBPP</h3>
    <div>
        <p><strong>Licensure and board certification</strong></p>
        <p style="padding-left: 30px;">Physician
            &nbsp;&nbsp;Aug 27, 2009</p>
        <p style="padding-left: 30px;">Professional Regulation Commission License No. 115888&nbsp;&nbsp;&nbsp;</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Medical Technology&nbsp;Sept 22, 2005</p>
        <p style="padding-left: 30px;">Professional Regulation Commission License No. 49019&nbsp; &nbsp;</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Diplomate of Specialty Board of Philippine Psychiatry&nbsp;&nbsp; January 2016
        </p>
        <p style="padding-left: 30px;">Philippine Psychiatric Association</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p><strong>Education and training</strong></p>
        <p style="padding-left: 30px;">The Medical City</p>
        <p style="padding-left: 30px;">Psychiatry Residency Training</p>
        <p style="padding-left: 30px;">2010-2013</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">The Medical City</p>
        <p style="padding-left: 30px;">Medical Internship</p>
        <p style="padding-left: 30px;">2008-2009</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">University of Santo Tomas Faculty of Medicine and Surgery</p>
        <p style="padding-left: 30px;">Doctor of Medicine</p>
        <p style="padding-left: 30px;">2004-2008</p>
        <p>&nbsp;</p>
        <p style="padding-left: 30px;">University of Santo Tomas College of Pharmacy</p>
        <p style="padding-left: 30px;">B.S. Medical Technology</p>
        <p style="padding-left: 30px;">2000-2004</p>
        <p>&nbsp;</p>
        <p style="padding-left: 30px;">St. Bridget School</p>
        <p style="padding-left: 30px;">Elementary and High School</p>
        <p style="padding-left: 30px;">1989-2000</p>
        <p>&nbsp;</p>
        <p><strong>ADDITIONAL TRAINING</strong></p>
        <p style="padding-left: 30px;">Sensitivity Training on How to Handle Persons with Disabilities for Healthcare
            Professionals and Frontliners</p>
        <p style="padding-left: 30px;">June 19-20, 2018</p>
        <p style="padding-left: 30px;">Bayview Park Hotel, Manila</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Mindful Self-Compassion Seminar Retreat</p>
        <p style="padding-left: 30px;">December 4-7, 2017</p>
        <p style="padding-left: 30px;">St. Paul Renewal Center, Alfonso, Cavite</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Mindfulness-Based Relapse Prevention Program</p>
        <p style="padding-left: 30px;">January 2016</p>
        <p style="padding-left: 30px;">Metropsych Facility Inc.</p>
        <p>&nbsp;</p>
        <p style="padding-left: 30px;">Personal and Collective Journey</p>
        <p style="padding-left: 30px;">(A Sensitivity and Team Building Seminar)</p>
        <p style="padding-left: 30px;">June 2014</p>
        <p style="padding-left: 30px;">Metropsych Facility Inc.</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Basic Life Support, Cardiopulmonary Resuscitation</p>
        <p style="padding-left: 30px;">May 2012</p>
        <p style="padding-left: 30px;">The Medical City</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">Advanced Cardiac Life Support, Cardiopulmonary Resuscitation
        </p>
        <p style="padding-left: 30px;">July 4, 2008
        </p>
        <p style="padding-left: 30px;">The Medical City</p>
        <p>&nbsp;</p>
        <p><strong>RESEARCH PAPERS</strong></p>
        <p style="padding-left: 30px;">&ldquo;The Effect of Moringa oleifera (Malunggay) on Common Gram-Negative
            Organisms&rdquo;, December 2005</p>
        <p style="padding-left: 30px;">&ldquo;Break a Leg, Save a Life: Van Nes Rotationplasty on a Pediatric Patient
            with Osteosarcoma&rdquo;, April 2008</p>
        <p style="padding-left: 30px;">&ldquo;Benign Afebrile Seizure in Acute Gastroenteritis with No Signs of
            Dehydration: A Case Report&rdquo;, February 2009</p>
        <p style="padding-left: 30px;">&ldquo;A Cross-Sectional Study on the Attitudes and Perceptions Towards Mental
            Illness of the Medical Clerks (Year Level 8) of Ateneo School of Medicine and Public Health (ASMPH) who
            Rotated in the Psychiatry Department in The Medical City from May 2012 - December 2012&rdquo; ,&nbsp; April
            4, 2013</p>
        <p style="padding-left: 30px;">&nbsp;&nbsp;</p>
        <p><strong>LANGUAGES</strong></p>
        <p style="padding-left: 30px;">English &ndash; speak fluently and read/write with excellent proficiency.</p>
        <p style="padding-left: 30px;">Tagalog &ndash; speak fluently and read/write with excellent proficiency.</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p><strong>SEMINARS</strong></p>
        <p style="padding-left: 30px;">&ldquo;Advances, Impacts, and Future Directions of Psychiatry: The Asian
            Experience&rdquo; ,&nbsp; Philippine International Convention Center, Manila</p>
        <p style="padding-left: 30px;">January 24-26, 2018</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Reflections in Psychiatry: Integrating a Multifaceted Sojourn&rdquo;,
            Taal Vista Hotel, Tagaytay City</p>
        <p style="padding-left: 30px;">June 26-28,2017</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&nbsp;&ldquo;Research Update: New Developments in the Treatment of Mood
            Disorders&rdquo; ,&nbsp; Hawaii Convention Center, Hawaii USA</p>
        <p style="padding-left: 30px;">May 16, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Enhancing Outcomes in Schizophrenia: New Treatment
            Approaches&rdquo;,&nbsp; Sheraton Waikiki, Hawaii Ballroom, Honolulu, Hawaii USA</p>
        <p style="padding-left: 30px;">May 16, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Bullying: Understanding its Impact and Etiology&rdquo; , Hawaii
            Convention Center, Hawaii USA</p>
        <p style="padding-left: 30px;">&nbsp;May 17, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Advances in Personality Disorders&rdquo;,&nbsp;Hawaii Convention Center,
            Hawaii USA</p>
        <p style="padding-left: 30px;">May 17, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Madly Gifted: Media Workshop About Bipolar Disorder&rdquo;, Hawaii
            Convention Center, Hawaii USA</p>
        <p style="padding-left: 30px;">May 18, 2011 &nbsp;</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Smart Psychopharmacology&rdquo;, Imperial Palace Suites, QC .</p>
        <p style="padding-left: 30px;">December 2, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Issues and Controversies in Psychiatry&rdquo;, Buenafe Hall, UP College
            of Medicine, Manila</p>
        <p style="padding-left: 30px;">December 8, 2011</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">PPA 38th Annual Convention, Taal Vista Hotel, Tagaytay City</p>
        <p style="padding-left: 30px;">Jan 24-27, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">St. Luke&rsquo;s Neurodevelopmental 10th yr Anniversary, St. Luke&rsquo;s
            Hospital, QC</p>
        <p style="padding-left: 30px;">June 13, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">PPA Pre-Convention Psychogeriatrics, Makati City</p>
        <p style="padding-left: 30px;">July 25, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">PSCAP National Convention, Dusit Thani Hotel, Makati</p>
        <p style="padding-left: 30px;">September 14, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Personality Disorder and Dangerousness&rdquo;,&nbsp; Bayanihan Ctr.,
            Unilab</p>
        <p style="padding-left: 30px;">October 22, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">&ldquo;Seminars in Forensic Psychiatry&rdquo;,&nbsp; Pag-Asa Hall, NCMH,
            Mandaluyong City</p>
        <p style="padding-left: 30px;">October 24, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">PPA 28th PGC and 23rd Mid-year Convention,&nbsp; Crowne Plaza Hotel</p>
        <p style="padding-left: 30px;">July 25-27, 2012</p>
        <p style="padding-left: 30px;">&nbsp;</p>
        <p style="padding-left: 30px;">PPA 39th Annual Convention,&nbsp; Holiday Inn, Clark Pampanga</p>
        <p style="padding-left: 30px;">January 22-25, 2013</p>
        <p>&nbsp;</p>
    </div>
</ng-template>