export const settings = {
    plural: 'Messages',
    singular: 'Message',
  
    crud: {
      list: {
        allow: true,
        url: '/admin/messages'
      },
      view: {
        allow: true,
        url: '/admin/messages/view/'
      }
    }
  };