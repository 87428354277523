import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders.component';
import { OrdersRoutingModule } from './orders-routing.module';
import { CrudModule } from '../../../core/base/crud';
import { ListComponent } from './crud/list/list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PrescriptionTransactionService } from '../../../services/prescription-transaction.service';
import { OrderTransactionService } from '../../../services/order-transaction.service';
import { EventPrescriptionItemService } from '../../../services/event-prescription-items.service';
import { ViewComponent } from './crud/view/view.component';
import { EditComponent } from './crud/edit/edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxErrorsModule } from '@ultimate/ngxerrors';
import { HttpModule } from '@angular/http';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  imports: [
    CommonModule,
    OrdersRoutingModule,
    CrudModule,
    NgxDatatableModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    HttpModule
  ],
  declarations: [
    OrdersComponent,
    ListComponent,
    ViewComponent,
    EditComponent
  ],
  providers: [
    PrescriptionTransactionService,
    EventPrescriptionItemService,
    OrderTransactionService,
    BsModalService,
  ]
})
export class OrdersModule { }
