import { Component, OnInit, Injector, TemplateRef, ViewEncapsulation } from '@angular/core';

import { ListComponent as CrudListComponent } from '../../../../../core/base/crud';

import { EventFeedbacksService } from '../../../../../services/event-feedbacks.service';

import { settings } from '../../settings';

import { BsModalService } from 'ngx-bootstrap/modal';

import { isNull } from 'util';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent extends CrudListComponent implements OnInit {

  public columns: any;
  selectedRow: any;

  constructor(injector: Injector, private modalService: BsModalService) {
    super(injector);
    this.setSettings(settings);
    this.service = injector.get(EventFeedbacksService);
  }

  ngOnInit() {
      const feedbacks = this.service.getPatientFeedback()
      .subscribe(
        (result: any) => {
            this.rows = result['data'];
            this.setPager(result);
            this.page.totalPages = result['last_page'];
            this.loadingStatus(false);
        },
        (error: any) => {
            console.log('ERROR', error);
        },
      );
  }

  getViewLink(row: any) {
    return this.settings.crud.view.url + row.id;
  }
}
