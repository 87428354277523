import { Component, OnInit, Injector } from '@angular/core';

// STEP 1: IMPORT THE necessary base component
import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

// STEP 2: IMPORT the service
import { RoleService } from '../../../../../services/role.service';

// STEP 4: IMPORT form
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { ROLE_MODEL } from '../../role.model';

// STEP 5: IMPORT settings
import { settings } from '../../settings';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent {
  formType = 'edit';
  formGroup: FormGroup;

  constructor(injector: Injector) {
    super(injector)
    this.service = injector.get(RoleService);
    this.setSettings(settings);
  }

  ngOnInit() {
    this.rForm = this.formBuilder.group(ROLE_MODEL);
    this._load().subscribe((result: any) => {
      for (var key in ROLE_MODEL) {
        this.rForm.get(key).setValue(result.data[key]);
      }
    });
  }

}
