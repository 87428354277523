import { Component, OnInit, Injector, ViewContainerRef } from '@angular/core';

import { EditComponent as CrudEditComponent } from '../../../../../core/base/crud';

import { GenericNamesService } from '../../../../../services/generic-names.service';

import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';

import { settings } from '../../settings';

import { GENERIC_NAMES_MODEL, ROLES_MODEL, ERRORS } from '../../generic-names.model';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent extends CrudEditComponent {
  public formType = 'edit';

  formRoles: FormGroup;
  errors = ERRORS;
  genericList: any[];

  private roles: Array<any> = [];

  public vcr: any;
  private genericNames: any;

  constructor(
    injector: Injector, 
    vcr: ViewContainerRef,
  ) {
    super(injector)
    this.service = injector.get(GenericNamesService);
    this.setSettings(settings);
    this.vcr = vcr;

    this.formRoles = this.formBuilder.group({
      'roles': this.formBuilder.array([])
    });

  }

  ngOnInit() {
    this.rForm    = this.formBuilder.group(GENERIC_NAMES_MODEL, { updateOn: 'blur' })
    this._load({mode: 'complete'}).subscribe((result: any) => {
      this.genericNames = result.data;
      for (var key in GENERIC_NAMES_MODEL) {

        this.rForm.get(key).setValue(result.data[key]);
      }

      this.genericNames.roles.data.forEach((role: any) => {
        this.onRoleCheckboxChange(role.id, true);
      });
    });
  }

  onRoleCheckboxChange(roleId: string, isChecked: boolean) {
    const rolesFormArray = <FormArray>this.formRoles.controls.roles;

    if (isChecked) {
        rolesFormArray.push(new FormControl(roleId));
        // console.log('test', roleId);
    } else {
        const index = rolesFormArray.controls.findIndex(x => x.value === roleId);
        rolesFormArray.removeAt(index);
    }
  }

  isChecked(roleId: any) {
    const checked = false;

    const a = this.genericNames.roles.data.filter((role: any) => {
        return roleId === role.id;
    });

    if (a.length) {
        return true;
    }
    return false;
  }

}
