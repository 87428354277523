import { Component, Input, OnChanges, Output, SimpleChanges, TemplateRef, EventEmitter } from '@angular/core';
import { iAmDoctor, iAmPsychologist, NotesService, downloadPDFFile } from '../../../../../../services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { faDownload, faEdit, faPencilAlt, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
    selector: 'section-medical-abstract',
    templateUrl: './medical-abstract.component.html',
    styleUrls: ['./styles.component.scss']
})
export class ConsultationNotesSectionMedicalAbstractComponent implements OnChanges {
    @Input() data: object
    @Input() eventId
    @Output() newData = new EventEmitter

    modalRef: BsModalRef;
    match = true
    title = (iAmDoctor() && !iAmPsychologist()) ? 'Medical Abstract' : 'Summary Evaluation';
    value = '<em class="text-muted">Not specified</em>'
    fa = {
        faDownload,
        faEdit,
        faPencilAlt,
        faPaperPlane,
    }

    sections = {
        general_data: 'General Data',
        chief_complaint: 'Chief Complaint',
        history_of_present_illness: 'History of Present Illness',
        course_in_the_ward_out_patient: 'Course in the Ward/Out Patient',
        laboratory_examination_result: 'Laboratory Examination Result',
        psychological_examination_result: 'Psychological Examination Result',
        diagnosis: 'Diagnosis',
        medication: 'Medication',
        recommendation: 'Recommendation',
    }

    iAmDoctor = iAmDoctor
    iAmPsychologist = iAmPsychologist

    constructor(
        private modalService: BsModalService,
        private noteService: NotesService,
        private toastr: ToastrManager
    ) {}

    asIsOrder(a, b) {
        return 1;
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
        this.modalRef.setClass('modal-lg');
    }

    downloadPDF = () => {
        this.noteService.genReport('feedback', this.eventId)
        .subscribe(
            response => downloadPDFFile(response),
            e => {
                // this.toastr.errorToastr('Couldnt retrieve data for Feedback.')
            }
        );
    }

    updatedData = (value) => {
        this.newData.emit(value)
    }

    modalDecline = () => {
        this.modalRef.hide()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.data && 
            changes.data.currentValue
        ) {
            this.value = changes.data.currentValue
        }
    }

}
