import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { REGISTRATION_FORM } from './register.model';
import { errorvalidationParse } from '../../../services/helpers'

import { UserService } from '../../../services/user.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

    registrationForm: FormGroup;
    year = new Date();
    public errorMessages = [];
    data: any;
    registered = false;
    loading = false;
    constructor(
        private userService: UserService,
        private router: Router,
        activatedRoute: ActivatedRoute,
        zone: NgZone,
        fb: FormBuilder, 
        private toastr: ToastrManager
    ) {
        this.registrationForm = fb.group(REGISTRATION_FORM);
        this.registrationForm.reset();
    }

    submitRegistration() {
        this.registrationForm.updateValueAndValidity();
        if (this.registrationForm.valid) {
            const data = Object.assign({}, this.registrationForm.value);
            this.loading = true;
            this.userService.register(data)
                .subscribe((response) => {
                    this.registered = true;
                    this.loading = false;
                }, (e) => {

                    let m = errorvalidationParse(e)
                    this.toastr.errorToastr(m.join(','), 'Warning!', {enableHTML: true});
                    this.loading = false;
                    this.registered = false;
                });
        }
    }

    onSuccess(response: any) {
    }
}
