import { Medication } from './medication.model';

export class NoteContent {
    progress: any;
    diagnosis: string;
    patientInstructions: any;

    constructor() {
        this.progress = '';
        this.diagnosis = '';
        this.patientInstructions = '';
    }
}
