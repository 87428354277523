import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    loader: BehaviorSubject<boolean>;

    constructor() {
        this.loader = new BehaviorSubject<boolean>(false);

        // this.loader.subscribe(v => {
        //     console.log(v);
        // });
    }
}
