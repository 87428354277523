import { Injectable } from '@angular/core';
import { AbstractService } from '../../services/abstract.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TermsAgreementService extends AbstractService {
  endpoint = environment.serverUrl + 'terms';

  read = (termId) => {
    return this.http.get(
            `${this.endpoint}/${termId}`,
            {
              headers: {
                'Accept': environment.API_HEADER_ACCEPT_v2
              }
            })
  }

  iAccept = (user_id, term_id) => {
    return this.http.post(
      `${this.endpoint}/tracker`, 
      {
        user_id,
        term_id, 
      },
      {
          headers: {
            'Accept': environment.API_HEADER_ACCEPT_v2
          }
      }
  )
  .map((result: any) => {
      return result;
  }, (err: any) => {
      return err;
  });
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
