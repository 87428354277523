import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isArray } from "lodash";
import { of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { AbstractService } from "../abstract.service";
import { downloadPDFFile, formatDate, doctorName } from "../helpers";

@Injectable()
export class PatientFileService extends AbstractService {
    endpoint = environment.serverUrl + "files";
    headers = {
        "Access-Control-Allow-Origin": "*",
        Accept: environment.API_HEADER_ACCEPT_v2,
    };

    constructor(public http: HttpClient) {
        super(http);
    }

    loadFiles(type: string, roles?: string) {
        const url = this.endpoint + `/${type}`;
        return this.http
            .get(url, {
                headers: { ...this.headers },
                params: roles ? {roles}:{},
            })
            .pipe(
                map((response) => {
                    if(type === 'abstract') {
                        return response['data'];
                    }

                    return isArray(response) ? response : Object.values(response);
                }),
                map((arr) => {

                    if(type === 'abstract') {
                        return arr.map(obj =>( {
                            consultationDate: formatDate(obj.start_at),
                            doctor: obj.dr,
                            id: obj.id,
                            meta: obj,
                        }))
                    }

                    return arr.map((obj: any) => {
                        const consultation = {
                            // created_at: obj.start_at.date,
                            consultationDate: formatDate(obj.start_at.date),
                            doctor: doctorName(obj.doctor),
                            id: obj.notes.event_id,
                            meta: obj,
                        };
                        return consultation;
                    });
                }),
                catchError((err) => {
                    console.error(err);
                    return of([]);
                })
            );
    }

    async viewFile(event_id: number, type: string) {
        const headers = { ...this.headers };

        await this.http
            .post(
                this.endpoint + "/view",
                { event_id, type },
                {
                    headers,
                    responseType: "blob",
                }
            )
            .pipe(tap((res) => downloadPDFFile(res)))
            .toPromise();
    }
}
